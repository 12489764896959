import { API_PATH_CONSTANT } from "@/api/constant";
import {
  getFileExtension,
  getLinkMedia,
  getSleepTimeAdjustedToFileSize,
} from "@/shared/get";
import type { FileLoader } from "@ckeditor/ckeditor5-upload";
import { v4 as uuidv4 } from "uuid";
import { request } from "@/api/axios";
import { getErrorMessage } from "@/shared/validation/yup";
import { MESSAGES } from "@/shared/validation/message";
import { convertBytetoMB, convertMBtoByte } from "@/shared/transform/file";
import { StatusJobResponseBody } from "@/api/upload/request";
import { getRoute } from "@/shared/get/route";
export type UploadResponse = Record<string, unknown>;
interface OptionUploadAdapter {
  mimeSize: number;
  mimeTypes: string[];
  onError: (msg: string) => void;
  onStart: (key: string) => void;
  onFinish: (key: string) => void;
}

const checkStatusJob = async (
  jobId: string,
  sleepTime = 5000,
  loader: any,
): Promise<{ youtube_id?: string; message?: string } | null> => {
  let status = "processing";
  let res: StatusJobResponseBody | null = null;
  while (["processing"].includes(status)) {
    request({
      url: getRoute(API_PATH_CONSTANT.TRACK_JOB_ID, { id: jobId }),
      method: "GET",
      onUploadProgress: function (progressEvent: any) {
        loader.uploadTotal = progressEvent.total;
        loader.uploaded = progressEvent.uploaded;
      },
    }).then((axiosRes: any) => {
      res = axiosRes;
      status = res?.status || "processing";
    });

    await new Promise((resolve) => setTimeout(resolve, sleepTime));
  }

  return res;
};

export default function MyUploadAdapter(
  loader: FileLoader,
  options: OptionUploadAdapter,
) {
  return {
    upload: (): Promise<UploadResponse> => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        loader.file.then((file: File | null) => {
          let error = "";
          if (!file) {
            return reject();
          }

          if (!options.mimeTypes.includes(file.type)) {
            error = getErrorMessage(MESSAGES.MSG_016, {
              type: `png・jpeg・jpg・gif・bmp`,
            });
            // options.onError(error);

            return reject(error);
          }
          if (file.size > convertMBtoByte(options.mimeSize)) {
            error = getErrorMessage(MESSAGES.MSG_014, {
              max: `${options.mimeSize}MB`,
            });
            // options.onError(error);
            return reject(error);
          }
          const key = `${uuidv4()}.${getFileExtension(file.name)}`;
          options.onStart(key);
          const fileSize = convertBytetoMB(file.size);
          const sleep = getSleepTimeAdjustedToFileSize(fileSize);

          body.append("file", file);
          body.append("object_name", key);
          request({
            url: API_PATH_CONSTANT.UPLOAD,
            method: "POST",
            data: {
              file,
              object_name: key,
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent: any) {
              loader.uploadTotal = progressEvent.total;
              loader.uploaded = progressEvent.uploaded;
            },
          })
            .then(async (res: any) => {
              const job = await checkStatusJob(res.job_id, sleep, loader);
              if (job?.message) {
                options.onFinish(key);
                reject(job.message);
              } else {
                options.onError("");
                options.onFinish(key);
                resolve({
                  default: getLinkMedia(key),
                });
              }
            })
            .catch((e) => {
              console.error(e);
              options.onFinish(key);
              reject(MESSAGES.MSG_036);
            });
        });
      });
    },
    abort: () => {},
  };
}
