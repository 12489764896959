import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function ELearningManagementPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/admin/management/e-learning")
      navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST);
  }, [location.pathname]);

  return (
    <>
      <Outlet />
    </>
  );
}
