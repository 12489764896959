import { Skeleton } from "@chakra-ui/react";

/**
 * eLearningItem is a component that displays information about an item e-learning.
 * @param props - Properties of e-Learning Item
 * @returns A JSX element that displays information about an item e-learning.
 */
export const ELearningItemSkeleton = () => {
  return (
    <div
      className={`w-full rounded-xl flex gap-2 p-4 border-primary bg-white cursor-pointer`}
    >
      <div className="flex desktop:justify-between w-full mobile:flex-col-reverse desktop:flex-row  gap-2">
        <div className="flex gap-2 flex-col">
          <div className="flex gap-2 items-start">
            <h6 className="font-black line-clamp-2 line-break-anywhere">
              <Skeleton height="28px" width="240px" />
            </h6>

            <div className="rounded-xl py-1 px-2 font-normal text-xs text-primary--main bg-primary--lighter mt-1">
              <Skeleton height="16px" width="70px" />
            </div>
          </div>
          <div className="font-normal text-xs text-secondary-custom">
            <Skeleton height="16px" width="360px" />
          </div>
        </div>
      </div>
    </div>
  );
};
