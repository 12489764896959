import FrontHeader from "@/components/molecules/FrontHeader/header";
import LoadingFC from "@/components/molecules/LoadingFC/loading.fullscreen";
import { useGlobalStore } from "@/states/global.state";
import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function FrontLayout({ children }: { children: ReactNode }) {
  const loading = useGlobalStore((state) => state.loading);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      position={"relative"}
      overflow="hidden"
    >
      {loading.open && loading.isFC && <LoadingFC />}
      <FrontHeader />
      {children}
    </Box>
  );
}
