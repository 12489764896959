import Radio from "@/components/atoms/Radio/radio";

/**
 * Interface: QuizzSingleAnswerProps
 *
 * Represents the props for the QuizzSingleAnswer component.
 *
 * @property questionNumber - The number of the question.
 * @property answers - The list of answers.
 */
interface QuizzSingleAnswerProps {
  questionNumber: number;
  answers: any[];
}

export default function QuizzSingleAnswer({
  questionNumber,
  answers,
}: QuizzSingleAnswerProps) {
  return (
    <div className="flex flex-col gap-2">
      {answers.map((answer: any, index: number) => (
        <Radio
          key={`question.${questionNumber}.answers.${index}`}
          name={`question.${questionNumber}.answer`}
          value={answer.title}
          label={answer.title}
          disableCursorPointer
        />
      ))}
    </div>
  );
}
