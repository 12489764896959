import useScreenSize from "@/hooks/screen";
import { useGlobalStore } from "@/states/global.state";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";

export default function ToastForFront() {
  const { notifications } = useGlobalStore();
  const { isMobile } = useScreenSize();

  return (
    <div
      className={clsx(
        "absolute left-[50%] translate-x-[-50%]  w-full max-w-[960px] z-[9999] px-[13px]",
        isMobile ? "top-[72px]" : "top-[96px]",
      )}
    >
      <AnimatePresence>
        {notifications
          .filter((notification) => notification.site === "front")
          .map((notification) => (
            <motion.div
              key={notification.id}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              className="px-[18px] py-[15px] flex flex-row items-center gap-x-[14px] bg-[#F1F9E8] [box-shadow:0px_2px_16px_0px_#22390924] rounded-[8px] mb-1 whitespace-pre-wrap break-all"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 10C0 4.47715 4.47715 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM9.73 13.61L14.3 7.61V7.58C14.5179 7.29419 14.5668 6.91382 14.4283 6.58218C14.2897 6.25054 13.9848 6.01801 13.6283 5.97218C13.2718 5.92635 12.9179 6.07419 12.7 6.36L8.92 11.36L7.29 9.28C7.07028 8.99776 6.71668 8.85418 6.36239 8.90334C6.00811 8.9525 5.70696 9.18694 5.57239 9.51834C5.43783 9.84974 5.49028 10.2278 5.71 10.51L8.15 13.62C8.34082 13.8615 8.63222 14.0017 8.94 14C9.2495 13.9993 9.54121 13.8552 9.73 13.61Z"
                  fill="#73BE1E"
                />
              </svg>
              <p className="Body2 text-[#223909]">
                {notification?.title?.replace(/<br\s*\/?>/, "\n")}
              </p>
            </motion.div>
          ))}
      </AnimatePresence>
    </div>
  );
}
