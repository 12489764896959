import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/shared/validation/yup";
import { Tag } from "@/api/type";
import { MAX_LENGTH } from "@/configs/constants";
import { MESSAGES } from "@/shared/validation/message";

export interface ITagSettingForm {
  isEdit: boolean;
  tags: Tag[];
}

export const useTagSettingForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        isEdit: yup.boolean().required(),
        tags: yup
          .array()
          .of(
            yup.object().shape({
              id: yup.number().nullable(),
              name: yup
                .string()
                .label("tag.name")
                .max(MAX_LENGTH.VARCHAR)
                .noEmoji()
                .unique("tags", "name")
                .required(MESSAGES.MSG_029),
            }),
          )
          .required(),
      }),
    [],
  );

  return useForm<ITagSettingForm>({
    resolver: yupResolver(validationScheme),
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      tags: [],
    },
  });
};
