import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import CreateUserForm from "@/components/organisms/UserManagement/create";
import AnimatedRoute from "@/router/transition.route";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";

const breadcrumbs: BeardcrumbOption[] = [
  {
    label: "ユーザー管理",
    route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_LIST,
  },
  {
    label: "新規作成",
  },
];

/**
 * Renders the page for creating a new user.
 *
 * @returns {JSX.Element} The rendered CreateUserPage component.
 */
function CreateUserPage(): JSX.Element {
  const { isAdminSystem, isAdminContent } = useRole(true, [
    ROLE_ENUM.ADMIN_SYSTEM,
  ]);

  return (
    <div className="max-w-[100%] m-auto my-6">
      <Breadcrumb options={breadcrumbs} />
      <h4 className="mt-3 font-black">新規作成</h4>
      <div className="mt-8">
        <CreateUserForm
          role={{
            isAdminContent: Boolean(isAdminContent),
            isAdminSystem: Boolean(isAdminSystem),
          }}
        />
      </div>
    </div>
  );
}
const AnimatedCreateUserPage = AnimatedRoute(CreateUserPage);
export default AnimatedCreateUserPage;
