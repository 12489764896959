import DataTable from "@/components/molecules/Table/table";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchUserForm } from "./search.validate";
import { ControlledSelect } from "@/components/molecules/ControlledSelect/controlled-select";
import Avatar from "@/components/atoms/Avatar/avatar";
import ThreeDotActionTable from "@/components/molecules/ActionButtonTable/three-dot";
import PencilIcon from "@/assets/icon/pencil.svg";
import CloseIcon from "@/assets/icon/close.svg";
import { useListRoleQuery } from "@/api/role";
import { debounce, isString, pick } from "lodash";
import { useDeleteUserMutation, useListUserQuery } from "@/api/user";
import {
  ADMIN_ROUTES_CONSTANT,
  PAGINATION_CONSTANT,
} from "@/configs/constants";
import { OrderDirection } from "@/api/type";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getRoute } from "@/shared/get/route";
import { ModalConfirm } from "@/components/molecules/ModalConfirm/modal-confirm";
import { useGlobalStore } from "@/states/global.state";
import useToast from "@/hooks/toast";
import { handleApiError } from "@/hooks/error";
import qs from "qs";
import { useListOfficeQuery } from "@/api/office";
import { useListDepartmentQuery } from "@/api/department";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";
import { getDepartmentOptions, getLinkMedia } from "@/shared/get";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import { SuggestionSearch } from "@/components/molecules/SuggestionSearch/suggestion-search";
import { Option } from "@/components/atoms/SelectBox/select-box";
import { HttpStatusCode } from "axios";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import { cutString } from "@/shared/transform";
import clsx from "clsx";

type User = {
  id: number;
  avatar: string;
  fullname: string;
  role: string;
  department: string;
  email: string;
  learning_status: string | null;
};

const pageSize = PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE;

/**
 * UserListForAdmin component displays a table of users with various information such as username, authority, affiliation information, email, and status.
 * It also provides search functionality to filter users based on title, permission, and branch.
 *
 * @returns {JSX.Element} The rendered UserListForAdmin component.
 */
export default function UserListForAdmin(): JSX.Element {
  const { isAdminContent } = useRole(true, [
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [err, setError] = useState("");
  const { setLoading, loading } = useGlobalStore();
  const [modalConfig, setModalConfig] = useState({
    open: false,
    data: {
      id: 0,
      fullname: "",
    },
  });

  const [searchForm, setSearchForm] = useState({
    page: parseInt(searchParams.get("page") ?? "1"),
    fullname: searchParams.get("fullname"),
    order_by: searchParams.get("order_by")?.length
      ? searchParams.get("order_by")
      : "updated_at",
    order_direction: (searchParams.get("order_direction") ??
      "desc") as OrderDirection,
    role: searchParams.get("role") ?? "",
    department_id: searchParams.get("department_id"),
    office_id: searchParams.get("office_id"),
  });

  const navigate = useNavigate();
  const { showToast } = useToast();
  const { control, reset } = useSearchUserForm();
  const { data: roles } = useListRoleQuery();
  const {
    data: usersResponse,
    refetch,
    isFetching,
  } = useListUserQuery({
    page: searchForm.page,
    per_page: pageSize,
    fullname: searchForm.fullname ?? "",
    department_id: searchForm.department_id ?? "",
    office_id: searchForm.office_id ?? "",
    order_by: searchForm.order_by || "updated_at",
    order_direction: searchForm.order_direction,
    role_id: searchForm.role ?? "",
  });
  const deleteMutation = useDeleteUserMutation();
  const { data: officeRes } = useListOfficeQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });
  const { data: departmentRes } = useListDepartmentQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });

  const menuItems = useMemo(() => {
    const items = [
      {
        icon: <PencilIcon />,
        text: <span className="body1">編集する</span>,
        onClick: (data: User) => {
          navigate(
            `${getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_EDIT, {
              id: data.id,
            })}`,
          );
        },
      },
    ];

    if (!isAdminContent) {
      items.push({
        icon: <CloseIcon />,
        text: <span className="body1 text-error--main">削除する</span>,
        onClick: (data: User) => {
          setModalConfig({
            open: true,
            data: {
              id: data.id,
              fullname: data.fullname,
            },
          });
        },
      });
    }
    return items;
  }, [loading, navigate, isAdminContent]);

  const paging = useMemo(() => usersResponse?.paging, [usersResponse]);

  const datas: User[] = useMemo(() => {
    return (
      usersResponse?.users.map((user) => {
        return {
          id: user.id,
          avatar: getLinkMedia(user.thumbnail?.key || user.avatar?.key),
          fullname: `${user.first_name} ${user.last_name}`,
          role: user.role.name,
          department: `${user.department?.name || ""} ${
            user.office?.name ? " / " + user.office?.name : ""
          }`,
          email: user.email,
          learning_status: user.course_title
            ? `${user.course_title}を学習中`
            : null,
        };
      }) || []
    );
  }, [usersResponse]);

  const roleOptions = useMemo(() => {
    const list =
      roles?.map((role) => ({ value: role.id.toString(), label: role.name })) ??
      [];

    return [
      {
        value: "",
        label: "全ての権限",
      },
      ...list,
    ];
  }, [roles]);

  const columns = useMemo<ColumnDef<any>[]>(() => {
    const arr = [
      {
        accessorKey: "fullname",
        header: () => "ユーザー名",
        cell: (info) => {
          return (
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClickCapture={() => goToShowUser(info.row.original.id)}
            >
              <Avatar url={info.row.original.avatar} size="sm" />

              <span className="body1 text-black-custom break-all whitespace-normal">
                <CustomTooltip text={info.row.original.fullname} max={30} />
              </span>
            </div>
          );
        },
        meta: {
          minWidth: 200,
        },
      },
      {
        id: "roles.name",
        accessorKey: "roles.name",
        header: () => "権限",
        cell: (info) => {
          return (
            <div className="flex justify-center px-[8px] py-[4px] rounded-[4px] text-primary--main bg-primary--lighter body1 w-fit !font-bold">
              {info.row.original.role}
            </div>
          );
        },
        meta: {
          width: 120,
        },
      },
      {
        id: "departments.name",
        accessorKey: "department",
        header: () => "所属情報",
        cell: (info) => {
          return (
            <div className="body1 text-black-custom">
              <CustomTooltip text={info.row.original.department} max={30} />
            </div>
          );
        },
      },
      {
        accessorKey: "email",
        header: () => "メールアドレス",
        cell: (info) => (
          <CustomTooltip text={info.row.original.email} max={30} />
        ),
      },
      {
        id: "course_title",
        accessorKey: "learning_status",
        header: "学習状況",
        cell: (info) => {
          return (
            <div className="body1 text-black-custom min-w-[160px]">
              <CustomTooltip
                text={info.row.original.learning_status}
                max={30}
              />
            </div>
          );
        },
        meta: {
          width: 160,
        },
      },
      {
        id: "action",
        accessorKey: "",
        enableSorting: false,
        meta: {
          width: 80,
        },
        cell: (info) => (
          <div>
            <ThreeDotActionTable
              menuItems={menuItems}
              data={info.row.original}
            />
          </div>
        ),
      },
    ] as ColumnDef<any>[];

    return arr;
  }, []);

  const departmentOptions = useMemo(() => {
    const list = getDepartmentOptions(
      officeRes?.offices,
      departmentRes?.departments,
    );

    return [
      {
        value: "",
        label: "全ての支社",
      },
      ...list,
    ];
  }, [officeRes, departmentRes]);

  const goToShowUser = (id: string) => {
    navigate(getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_DETAIL, { id }));
  };

  const debouncedSearch = debounce((text: string) => {
    setSearchForm((prev) => ({ ...prev, fullname: text, page: 1 }));
  }, 500);

  const onChangePage = useCallback((p: number) => {
    setSearchForm((prev) => ({ ...prev, page: p }));
  }, []);

  const onSorting = useCallback((sortState: SortingState) => {
    if (!sortState[0]) return;

    setSearchForm((prev) => ({
      ...prev,
      order_by: sortState[0].id,
      order_direction: sortState[0].desc ? "desc" : "asc",
    }));
  }, []);

  /** handle close modal delete */
  const onCloseModal = useCallback(() => {
    setModalConfig({
      open: false,
      data: {
        id: 0,
        fullname: "",
      },
    });
    setError("");
  }, []);

  const refreshSearch = useCallback(() => {
    if (searchForm.page != PAGINATION_CONSTANT.DEFAULT_PAGE) {
      setSearchForm((prev) => ({ ...prev, page: 1 }));
    } else {
      refetch();
    }
  }, [searchForm.page]);

  /** handle confirm delete */
  const onSubmitDelete = useCallback(async () => {
    setLoading(true);
    try {
      await deleteMutation.mutateAsync(modalConfig.data.id);
      showToast({
        title: `${modalConfig.data.fullname}を削除しました`,
        type: "success",
      });
      onCloseModal();
      refreshSearch();
    } catch (errRes) {
      const error = handleApiError(errRes);

      if (
        error?.[0]?.path === "id" ||
        error?.[0]?.path === "base" ||
        error?.[0]?.path === "popup"
      ) {
        onCloseModal();
        setError(error[0].message);
      } else if (error.statusCode !== HttpStatusCode.UnprocessableEntity) {
        onCloseModal();
        setError(error.message);
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [modalConfig, deleteMutation, onCloseModal, setLoading, showToast]);

  useEffect(() => {
    const params = qs.stringify(
      isAdminContent
        ? pick(searchForm, ["page", "order_direction", "order_by"])
        : searchForm,
    );
    setSearchParams(params);
    return () => {};
  }, [searchForm, setSearchParams, isAdminContent]);

  const handleChangeDepartment = useCallback((option: Option) => {
    const departmentId = String(option?.value ?? "");
    setSearchForm((prev) => ({
      ...prev,
      department_id: departmentId?.split("-")[0] ?? "",
      office_id: departmentId?.split("-")[1] ?? "",
      page: departmentId ? 1 : prev.page,
    }));
  }, []);

  const handleChangeRole = useCallback((option: Option) => {
    setSearchForm((prev) => ({
      ...prev,
      role: String(option?.value ?? ""),
      page: option?.value ? 1 : prev.page,
    }));
  }, []);

  useEffect(() => {
    reset((prev) => ({
      ...prev,
      fullname: searchForm.fullname ?? "",
      role: roleOptions.find((i) => {
        return i.value == searchForm.role;
      }),
      department: departmentOptions.find(
        (i) =>
          i.value === `${searchForm.department_id}-${searchForm.office_id}`,
      ),
    }));
    return () => {
      debouncedSearch?.cancel();
    };
  }, [roleOptions, departmentOptions]);

  return (
    <>
      <DataTable
        className="mt-6"
        data={datas}
        columns={columns}
        total={paging?.total_records || 0}
        loading={isFetching}
        defaultSort={[
          {
            id: searchForm.order_by ?? "updated_at",
            desc: searchForm.order_direction === "desc",
          },
        ]}
        defaultPage={searchForm.page}
        defaultPageSize={pageSize}
        onChangePage={onChangePage}
        onSorting={onSorting}
        headerSlot={
          <div className={clsx("flex gap-3", isAdminContent && "hidden")}>
            <SuggestionSearch
              oldValue={searchParams.get("fullname") as string}
              model="users"
              labelKey={"fullname"}
              width="33%"
              placeholder="ユーザー名で絞り込み"
              onSearch={(data) => {
                if (data) {
                  if (isString(data)) debouncedSearch(data);
                  else
                    debouncedSearch(data?.first_name + " " + data?.last_name);
                } else {
                  debouncedSearch("");
                }
              }}
            />
            <ControlledSelect
              options={roleOptions}
              isClearable={true}
              control={control}
              formField="role"
              placeholder="全ての権限"
              className="w-[33%]"
              onChangeCallback={handleChangeRole}
            />
            <ControlledSelect
              isClearable={true}
              options={departmentOptions}
              onChangeCallback={handleChangeDepartment}
              control={control}
              formField="department"
              placeholder="全ての支社"
              className="w-[33%]"
              isVirtualList={true}
            />
          </div>
        }
      />
      <ModalConfirm
        isOpen={modalConfig.open}
        header={`${cutString(modalConfig.data.fullname)}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">
              {modalConfig.data.fullname}を削除します。
            </p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onClose={onCloseModal}
        onSubmit={onSubmitDelete}
      />
      <ModalError
        message={
          err.length > 0 && (
            <p className="text-error--main input-error__message mb-1 body1">
              {err.replace(/<br\s*\/?>/, "\n")}
            </p>
          )
        }
        isOpen={!!err.length}
        onClose={() => {
          setError("");
        }}
      />
    </>
  );
}
