import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function ContentPage() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/admin/management/content")
      navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_DOCUMENT_LIST);
  }, [location.pathname]);
  return <Outlet />;
}
