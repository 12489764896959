import { Option } from "@/components/atoms/SelectBox/select-box";
import { MAX_LENGTH } from "@/configs/constants";
import { QUESTION_KIND_ENUM } from "@/configs/enum";
import { MESSAGES } from "@/shared/validation/message";
import yup, { getErrorMessage, getFieldName } from "@/shared/validation/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

export interface QuizzQuestion {
  _id: string;
  title: string;
  kind: { label: string; value: string } | null;
  answers?: {
    _id: string;
    title: string | null;
    point: number | null;
  }[];
  point?: number | null;
  correct_answers?: { label: string; value: string }[];
  correct_answer?: { label: string; value: string } | null;
  answers_deleted: {
    _id: string;
    title: string | null;
    point: number | null;
  }[];
}
export interface ICreateQuizzForm {
  title: string;
  base?: string | null; // just only for show message
  pass_point: number | null;
  questions: QuizzQuestion[];
  questions_deleted: QuizzQuestion[];
}

export const useCreateQuizzForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        base: yup.string().nullable(),
        title: yup
          .string()
          .label("admin.quizz.create.title")
          .max(MAX_LENGTH.VARCHAR)
          .required(),
        pass_point: yup
          .number()
          .label("admin.quizz.create.pass_point")
          .min(0)
          .max(100)
          .when("questions", {
            is: (questions: any) => {
              const isNotAllFreeInput = questions.some(
                (question: any) =>
                  question.kind?.value !== QUESTION_KIND_ENUM.FREE_INPUT,
              );
              return isNotAllFreeInput;
            },
            then: (schema) =>
              schema
                .transform((v) => {
                  return !v && v !== 0 ? null : v;
                })
                .required(),
            otherwise: (schema) => schema.nullable(),
          }),
        questions: yup.array().of(
          yup.object().shape({
            _id: yup.string().nullable(),
            title: yup
              .string()
              .label("admin.quizz.create.question.title")
              .max(MAX_LENGTH.VARCHAR)
              .required(),
            kind: yup
              .object()
              .label("admin.quizz.create.question.kind")
              .required(),
            answers: yup
              .array()
              .of(
                yup.object({
                  _id: yup.string().nullable(),
                  title: yup
                    .string()
                    .label("admin.quizz.create.answer.title")
                    .max(MAX_LENGTH.VARCHAR)
                    .nullable(),
                  point: yup
                    .number()
                    .label("admin.quizz.create.answer.point")
                    .transform((v) => {
                      return isNaN(v) ? 0 : v;
                    })
                    .integer()
                    .min(0)
                    .max(100)
                    .nullable(),
                }),
              )
              .min(0)
              .required(),
            point: yup
              .number()
              .label("admin.quizz.create.answer.point")
              .transform((v) => {
                return isNaN(v) ? 0 : v;
              })
              .when("kind", {
                is: (option: Option) => {
                  if (!option) return false;
                  return option.value === QUESTION_KIND_ENUM.SINGLE_ANSWER;
                },
                then: (schema) => schema.integer().min(0).max(100).nullable(),
                otherwise: (schema) => schema.nullable(),
              }),
            correct_answers: yup
              .array()
              .label("admin.quizz.create.answer.correct_answers")
              .of(
                yup.object().shape({
                  _id: yup.string(),
                  label: yup.string().nullable(),
                  value: yup.string().nullable(),
                }),
              )
              .when("kind", {
                is: (option: Option) => {
                  if (!option) return false;
                  return option.value === QUESTION_KIND_ENUM.MULTI_ANSWER;
                },
                then: (schema) =>
                  schema
                    .min(
                      1,
                      getErrorMessage(MESSAGES.MSG_001, {
                        field: getFieldName(
                          "admin.quizz.create.answer.correct_answers",
                        ),
                      }),
                    )
                    .required(),
                otherwise: (schema) => schema.min(0).nullable(),
              }),
            correct_answer: yup
              .object()
              .label("admin.quizz.create.answer.correct_answers")
              .when("kind", {
                is: (option: Option) => {
                  if (!option) return false;
                  return option.value === QUESTION_KIND_ENUM.SINGLE_ANSWER;
                },
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            answers_deleted: yup.array().default([]).nullable(),
          }),
        ),
        questions_deleted: yup.array().nullable().default([]),
      }),
    [],
  );

  return useForm<ICreateQuizzForm>({
    resolver: yupResolver(validationScheme) as any,
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });
};
