import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

/**
 * UserManagementPage function.
 *
 * This function is a React component that renders the User Management page.
 * It uses the `useNavigate` hook from React Router DOM to navigate to the
 * "Users" management route defined in the `ADMIN_ROUTES_CONSTANT` constant.
 *
 * @returns {JSX.Element} The rendered User Management page.
 */
export default function UserManagementPage(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/admin/management/user")
      navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_LIST);
  }, [location.pathname]);

  return (
    <>
      <Outlet />
    </>
  );
}
