import { QueryFunctionContext } from "@tanstack/react-query";
import { Office, OrderDirection, Paging } from "../type";
import { request } from "../axios";
import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";
import { omit } from "lodash";

export type FilterOfficeRequestBody = {
  page: number;
  per_page: number;
  order_by?: string | null;
  order_direction?: OrderDirection;
};

export type FilterOfficeResponseBody = {
  offices: Office[];
  paging: Paging;
};

export const listOfficeApi = (
  context: QueryFunctionContext,
): Promise<FilterOfficeResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_OFFICE),
    params: context.queryKey[1],
  });
};

export type UpsertOfficeRequestBody = {
  id?: number;
  name: string;
};

export type UpsertOfficeResponseBody = {
  id: number;
};

export const upsertOfficeApi = (
  data: UpsertOfficeRequestBody,
): Promise<UpsertOfficeResponseBody> => {
  return request({
    method: data.id ? "PATCH" : "POST",
    url: data.id
      ? getRoute(API_PATH_CONSTANT.UPDATE_OFFICE, { id: data.id })
      : API_PATH_CONSTANT.CREATE_OFFICE,
    data: {
      offices: omit(data, "id"),
    },
  });
};

export type DeleteOfficeResponseBody = {
  id: string;
};
export const deleteOfficeApi = (
  id: number,
): Promise<DeleteOfficeResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.DELETE_OFFICE, { id }),
  });
};
