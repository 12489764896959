import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import AnimatedRoute from "@/router/transition.route";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";
import CreateQuizzForm from "@/components/organisms/QuizzManagement/create";
import { useParams } from "react-router-dom";
import { useShowQuizzQuery } from "@/api/quizz";
import { useMemo } from "react";

function EditQuizzPage(): JSX.Element {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);
  const params = useParams();
  const id = parseInt(params.id as string);
  const { data: quizzInfo } = useShowQuizzQuery(id, {
    enabled: !!id,
  });

  const breadcrumbs: BeardcrumbOption[] = useMemo(
    () => [
      {
        label: "テスト一覧",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_LIST,
      },
      {
        label: `「${quizzInfo?.title}」の編集`,
      },
    ],
    [quizzInfo],
  );

  return (
    <div className="max-w-[100%] m-auto my-6">
      <Breadcrumb options={breadcrumbs} />
      <h4 className="mt-3 font-black">編集</h4>
      <div className="mt-8">
        <CreateQuizzForm id={id} quizzInfo={quizzInfo} />
      </div>
    </div>
  );
}
const AnimatedEditQuizzPage = AnimatedRoute(EditQuizzPage);
export default AnimatedEditQuizzPage;
