import clsx from "clsx";
import "./radio.scss";
import { ReactNode } from "react";

export interface RadioProps {
  className?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  disableCursorPointer?: boolean;
  isError?: boolean;
  value?: string | number | undefined;
  name?: string;
  label?: string;
  description?: string | ReactNode;
  checked?: boolean;
  onChange?: (value?: string | number) => void;
}

export default function Radio({
  isError = false,
  className,
  isDisabled = false,
  isReadOnly = false,
  disableCursorPointer = false,
  name,
  label,
  checked,
  value,
  onChange,
  description = <></>,
}: RadioProps) {
  const handleClick = () => {
    if (isDisabled || isReadOnly) {
      return;
    }
    onChange?.(value);
  };
  return (
    <div className={clsx("flex gap-x-3", className)}>
      <div
        className={clsx(
          "relative radio-container h-4 w-4 mt-[6px]",
          isReadOnly || isDisabled
            ? "cursor-not-allowed"
            : disableCursorPointer
            ? ""
            : "cursor-pointer",
        )}
        onClick={handleClick}
      >
        <input
          type="radio"
          className={clsx(
            "absolute w-0 h-0 radio",
            isDisabled ? "cursor-not-allowed	" : "cursor-pointer",
          )}
          value={value ?? ""}
          name={name}
          checked={checked}
          disabled={isDisabled}
          onChange={() => {}}
          readOnly={true}
        />
        <span
          className={clsx(
            "radio-mark absolute left-0 top-0 h-4 w-4 rounded-full",
            isError && "is-error",
          )}
        ></span>
      </div>
      <div className="flex flex-col w-full">
        {label && (
          <label
            className={`body1 radio-label ${
              disableCursorPointer ? "" : "cursor-pointer"
            }`}
            onClick={handleClick}
          >
            {label}
          </label>
        )}
        {description && <div className="radio-description">{description}</div>}
      </div>
    </div>
  );
}
