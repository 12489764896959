import AuthLayout from "@/pages/_layouts/auth";
import ArrowLeftIcon from "@/assets/icon/arrow-left.svg";
import FormResetPassword from "@/components/organisms/FormResetPassword";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
export default function ResetPasswordPage() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(ADMIN_ROUTES_CONSTANT.LOGIN);
  };
  return (
    <AuthLayout>
      <div className="py-[80px] flex w-full items-center flex-col px-[20px]">
        <div className="flex mb-[16px] justify-center w-full relative">
          <div
            className="absolute left-0 cursor-pointer"
            onClickCapture={goBack}
          >
            <ArrowLeftIcon />
          </div>
          <h4 className="font-[900] text-center">パスワードをお忘れですか？</h4>
        </div>

        <div className="leading-[21px] font-[400] text-[14px] text-center">
          パスワードをリセットします。
          <br />
          ご登録済みのメールアドレスを入力してください。
        </div>
        <div className="mt-[48px] w-full flex justify-center">
          <FormResetPassword isAdminPage={true} />
        </div>
      </div>
    </AuthLayout>
  );
}
