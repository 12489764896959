import FrontPage from "@/pages/front";
import FrontResetPasswordPage from "@/pages/front/auth/forgot-password";
import FrontResetPasswordSuccessPage from "@/pages/front/auth/forgot-password/success";
import FrontLoginPage from "@/pages/front/auth/login";
import FrontSettingInformationFirstLogin from "@/pages/front/auth/setting-account/basic-information";
import SetAccountPasswordFailPage from "@/pages/front/auth/setting-account/fail";
import FrontSettingPasswordFirstLogin from "@/pages/front/auth/setting-account/password";
import FrontSetPasswordPage from "@/pages/front/auth/setting-password";
import SetPasswordFailPage from "@/pages/front/auth/setting-password/fail";
import FrontSetPasswordSuccessPage from "@/pages/front/auth/setting-password/success";
import ContentDocumentPage from "@/pages/front/contents/document";
import AnimatedListDocumentPage from "@/pages/front/contents/document/list";
import ContentVideoPage from "@/pages/front/contents/video";
import AnimatedListVideoPage from "@/pages/front/contents/video/list";
import FrontELearning from "@/pages/front/elearning";
import AnimatedELearningDetailPage from "@/pages/front/elearning/detail";
import AnimatedELearningListPage from "@/pages/front/elearning/list";
import FrontMyPage from "@/pages/front/my-page";
import FrontChangeEmailPage from "@/pages/front/my-page/change-email";
import FrontChangeInforPage from "@/pages/front/my-page/change-infor";
import FrontChangePasswordPage from "@/pages/front/my-page/change-password";
import FrontContent from "@/pages/front/contents";
import AnimatedFrontCreateDocumentPage from "@/pages/front/contents/document/create";
import AnimatedFrontEditDocumentPage from "@/pages/front/contents/document/edit";
import AnimatedFrontCreateVideoPage from "@/pages/front/contents/video/create";
import AnimatedFrontEditVideoPage from "@/pages/front/contents/video/edit";
import PageForbidden from "@/pages/errors/403";
import PageNotFound from "@/pages/errors/404";
import PageInternalServer from "@/pages/errors/500";
import ErrorBoundary from "@/pages/errors/ErrorBoundary";
const appName = "Green Book Cloud";

export default {
  path: "/",
  element: <FrontPage />,
  children: [
    {
      path: "login",
      loader: () => {
        document.title = `${appName} - ログイン`;
        return null;
      },
      element: <FrontLoginPage />,
    },
    {
      path: "forgot-password",
      loader: () => {
        document.title = `${appName} - パスワード忘れ`;
        return null;
      },
      element: <FrontResetPasswordPage />,
    },
    {
      path: "forgot-password/success",
      loader: () => {
        document.title = `${appName} - 再設定メール送信完了`;
        return null;
      },
      element: <FrontResetPasswordSuccessPage />,
    },
    {
      path: "setting-password",
      loader: () => {
        document.title = `${appName} - パスワード忘れ - 再設定`;
        return null;
      },
      element: <FrontSetPasswordPage />,
    },
    {
      path: "setting-password/fail",
      loader: () => {
        document.title = `${appName}`;
        return null;
      },
      element: <SetPasswordFailPage />,
    },
    {
      path: "setting-password/success",
      loader: () => {
        document.title = `${appName} - 再設定メール送信完了`;
        return null;
      },
      element: <FrontSetPasswordSuccessPage />,
    },
    {
      path: "setting-account/password",
      loader: () => {
        document.title = `${appName} - 新規登録`;
        return null;
      },
      element: <FrontSettingPasswordFirstLogin />,
    },
    {
      path: "setting-account/password/fail",
      element: <SetAccountPasswordFailPage />,
    },
    {
      path: "setting-account/basic-information",
      loader: () => {
        document.title = `${appName} - アカウント情報設定`;
        return null;
      },
      element: <FrontSettingInformationFirstLogin />,
    },
    {
      path: "403",
      element: <PageForbidden isAdmin={false} />,
    },
    {
      path: "404",
      element: <PageNotFound isAdmin={false} />,
    },
    {
      path: "500",
      element: <PageInternalServer isAdmin={false} />,
    },
    {
      path: "my-page",
      element: <FrontMyPage />,
    },
    {
      path: "my-page/change-email",
      loader: () => {
        document.title = `${appName} - メールアドレス変更`;
        return null;
      },
      element: <FrontChangeEmailPage />,
    },
    {
      path: "my-page/change-password",
      loader: () => {
        document.title = `${appName} - パスワード変更`;
        return null;
      },
      element: <FrontChangePasswordPage />,
    },
    {
      path: "my-page/change-infor",
      loader: () => {
        document.title = `${appName} - ユーザー情報の変更`;
        return null;
      },
      element: <FrontChangeInforPage />,
    },
    {
      path: "my-page/e-learning/:id",
      loader: () => {
        document.title = `${appName} - Eラーニング`;
        return null;
      },
      element: <AnimatedELearningDetailPage />,
    },
    {
      path: "e-learning",
      element: <FrontELearning />,
      children: [
        {
          path: "list",
          loader: () => {
            document.title = `${appName} - Eラーニング`;
            return null;
          },
          element: <AnimatedELearningListPage />,
        },
        {
          path: ":id",
          loader: () => {
            document.title = `${appName} - Eラーニング`;
            return null;
          },
          element: <AnimatedELearningDetailPage />,
        },
      ],
    },
    {
      path: "content",
      element: <FrontContent />,
      children: [
        {
          path: "document",
          element: <ContentDocumentPage />,
          children: [
            {
              path: "list",
              loader: () => {
                document.title = `${appName} - コンテンツ一覧`;

                return null;
              },
              element: <AnimatedListDocumentPage />,
            },
            {
              path: ":folder_id/create",
              element: <AnimatedFrontCreateDocumentPage />,
            },
            {
              path: ":folder_id/:id/edit",
              element: <AnimatedFrontEditDocumentPage />,
            },
          ],
        },
        {
          path: "video",
          element: <ContentVideoPage />,
          children: [
            {
              path: "list",
              loader: () => {
                document.title = `${appName} - コンテンツ一覧`;
                return null;
              },
              element: <AnimatedListVideoPage />,
            },
            {
              path: ":folder_id/create",
              element: <AnimatedFrontCreateVideoPage />,
            },
            {
              path: ":folder_id/:id/edit",
              element: <AnimatedFrontEditVideoPage />,
            },
          ],
        },
      ],
    },
  ],
  errorElement: <ErrorBoundary />,
};
