import AnimatedRoute from "@/router/transition.route";
import { useEffect, useState } from "react";
import TagSetting from "@/components/molecules/TagSetting";
import { PAGINATION_CONSTANT } from "@/configs/constants";
import { useListTagQuery } from "@/api/tag";
import { ITagSettingForm } from "@/components/molecules/TagSetting/validation";

function TagListPage() {
  const {
    data: tagRes,
    refetch,
    isFetching,
  } = useListTagQuery({
    page: PAGINATION_CONSTANT.DEFAULT_PAGE,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });
  const [tags, setTags] = useState<ITagSettingForm>({
    isEdit: false,
    tags: [],
  });

  useEffect(() => {
    setTags({
      isEdit: false,
      tags: tagRes?.tags || [],
    });
  }, [tagRes]);

  const onChange = (isEdit?: boolean) => {
    refetch();
    setTags({
      isEdit: !!isEdit,
      tags: tagRes?.tags || [],
    });
  };

  return (
    <div className="max-w-[100%] m-auto my-6">
      <h4 className="mb-6 font-black">タグ一覧</h4>
      <div className="flex flex-col gap-y-6">
        <TagSetting tags={tags} onChange={onChange} isFetching={isFetching} />
      </div>
    </div>
  );
}

const AnimatedTagListPage = AnimatedRoute(TagListPage);
export default AnimatedTagListPage;
