import {
  MutateOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  FilterTagRequestBody,
  FilterTagResponseBody,
  UpdateTagsRequestBody,
  UpdateTagsResponseBody,
  listTagApi,
  updateTagsApi,
} from "./request";

export const useListTagQuery = (
  params: Partial<FilterTagRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterTagResponseBody,
      unknown, // TError,
      FilterTagResponseBody,
      (string | Partial<FilterTagRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-tags", params],
    queryFn: listTagApi,
    enabled: !!params,
    ...options,
  });
};

export const useUpdateTagsMutation = (
  options: MutateOptions<
    UpdateTagsResponseBody,
    unknown,
    UpdateTagsRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updateTagsApi,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["filter-tags"],
          exact: true,
        });
      },
    },
    queryClient,
  );
};
