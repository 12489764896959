import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ADMIN_ROUTES_CONSTANT, MAX_LENGTH } from "@/configs/constants";
import { Attachment, Compliance } from "@/api/type";
import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import { ControlledRadio } from "@/components/molecules/ControlledRadio/controlled-radio";
import { ControlledRichTextEditor } from "@/components/molecules/ControlledRichTextEditor/controlled-rich-text-editor";
import {
  ICreateComplianceTrainingForm,
  useCreateComplianceTrainingForm,
} from "./create.validation";
import Button from "@/components/atoms/Button/button";
import UploadPdf from "@/components/atoms/UploadDocument/upload-pdf";
import { UploadResponse } from "@/hooks/upload";
import {
  handleApiError,
  mapAPIError,
  useScrollToFirstError,
} from "@/hooks/error";
import { useGlobalStore } from "@/states/global.state";
import {
  useCreateComplianceMutation,
  useUpdateComplianceMutation,
} from "@/api/compliance";
import useToast from "@/hooks/toast";
import { cutString } from "@/shared/transform";
import { useDisclosure } from "@chakra-ui/react";
import { ModalError } from "@/components/molecules/ModalError/modal-error";

interface CreateComplianceTrainingFormProps {
  id?: number | null;
  complianceInfo?: Compliance;
}

export default function CreateComplianceTrainingForm({
  id,
  complianceInfo,
}: CreateComplianceTrainingFormProps): JSX.Element {
  // #region Data
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { loading, setLoading } = useGlobalStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorApi, setErrorApi] = useState("");
  const { control, setValue, setError, reset, formState, handleSubmit } =
    useCreateComplianceTrainingForm();
  useScrollToFirstError(formState);
  const { errors } = formState;

  const createComplianceMutation = useCreateComplianceMutation();
  const updateComplianceMutation = useUpdateComplianceMutation();

  // #region Event
  const onUpload = async (content: UploadResponse | null) => {
    if (!content) {
      if (!id) {
        setValue("attachment_attributes", null);
      }

      return;
    }

    setValue("attachment_attributes", {
      key: content.key,
      name: content.name,
      content_type: content.content_type,
      _destroy: content._destroy,
    });
  };

  const onCancel = () => {
    navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.COMPLIANCE_TRAINING_LIST);
  };

  const onSuccess = (title: string) => {
    showToast({ title, type: "success" });

    onCancel();
  };

  const onSubmit = async (values: ICreateComplianceTrainingForm) => {
    try {
      setLoading(true);

      const payload = {
        title: values.title ?? "",
        is_public: Boolean(values.is_public),
        content: values.content,
        attachment_attributes: values.attachment_attributes,
      };

      if (id) {
        const res = await updateComplianceMutation.mutateAsync({
          id,
          ...payload,
        });

        res.id && onSuccess(`${cutString(payload.title)}を保存しました`);
      } else {
        const res = await createComplianceMutation.mutateAsync({
          ...payload,
        });

        res.id && onSuccess(`${cutString(payload.title)}を作成しました`);
      }
    } catch (errRes) {
      const err = handleApiError(errRes);

      mapAPIError(err, setError, (e) => {
        let path = e.path;

        if (e.path === "base") {
          path = "content";
        } else if (e.path === "id") {
          path = "title";
        } else if (e.path === "popup") {
          onOpen();
          setErrorApi(e.message);
          return;
        }

        setError(path, { message: e.message });
      });
    } finally {
      setLoading(false);
    }
  };
  // #endregion

  useEffect(() => {
    if (complianceInfo) {
      reset({
        title: complianceInfo.title,
        content: complianceInfo.content,
        is_public: Number(complianceInfo.is_public),
        attachment_attributes: complianceInfo?.attachment,
      });
    }
  }, [complianceInfo, reset]);

  return (
    <div className="flex flex-col gap-y-8 mb-16">
      <div className="shadow-card bg-white p-6 rounded-[20px] flex flex-col gap-y-6">
        <ControlledInput
          control={control}
          isRequired={true}
          isTrim={true}
          formField="title"
          label="タイトル"
          placeholder="タイトルを入力してください"
          maxLength={MAX_LENGTH.VARCHAR}
          errorMessage={errors.title?.message}
        />
        <ControlledRadio
          control={control}
          options={[
            { label: "公開", value: 1 },
            { label: "非公開", value: 0 },
          ]}
          formField="is_public"
          direction="horizontal"
          label="公開設定"
        />
      </div>
      <div>
        <h6 className="font-black mb-3">コンテンツ</h6>
        <div className="shadow-card bg-white p-6 rounded-[20px] mb-3">
          <UploadPdf
            onChange={onUpload}
            onError={(message) => {
              setError("attachment_attributes", {
                message,
              });
            }}
            onRemove={onUpload}
            isError={
              !!errors?.content?.message || !!errors?.content_not_null?.message
            }
            attachment={complianceInfo?.attachment as Attachment | undefined}
          />
          {errors.attachment_attributes?.message && (
            <div className="mt-1 w-full text-error--main input-error__message is-invalid">
              {errors.attachment_attributes?.message}
            </div>
          )}
        </div>

        <ControlledRichTextEditor
          control={control}
          formField="content"
          errorMessage={
            errors?.content?.message || errors?.content_not_null?.message
          }
        />
      </div>
      <div className="flex gap-[10px] justify-end">
        <Button
          size="sm"
          variant="info"
          className="min-w-[128px] w-fit"
          buttonCustom={{ padding: "12px 12px" }}
          onClick={onCancel}
        >
          キャンセル
        </Button>

        <Button
          size="sm"
          className="min-w-[128px] w-fit"
          buttonCustom={{ padding: "12px 12px" }}
          onClick={handleSubmit(onSubmit)}
          isLoading={loading?.open}
        >
          保存
        </Button>
      </div>
      <ModalError
        isOpen={isOpen}
        message={
          <p className="w-full text-error--main input-error__message">
            {errorApi}
          </p>
        }
        onClose={() => {
          setErrorApi("");
          onClose();
        }}
      />
    </div>
  );
}
