import React from "react";
import { SpinnerCircle, SpinnerWrapper } from "./styled";

export default function Spinner({
  styles,
  circleStyles,
}: {
  styles?: React.CSSProperties;
  circleStyles?: React.CSSProperties;
}) {
  return (
    <SpinnerWrapper style={styles}>
      <SpinnerCircle style={circleStyles} />
    </SpinnerWrapper>
  );
}
