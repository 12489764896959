import { Department, Office } from "@/api/type";
import { Option } from "@/components/atoms/SelectBox/select-box";
import { ROLE_ENUM } from "@/configs/enum";
import { cutString } from "../transform";
import { API_PATH_CONSTANT } from "@/api/constant";

/**
 * Returns the corresponding role text for the given role value.
 *
 * @param value - The role value to get the role text for.
 * @returns The role text for the given role value.
 */
export const getRoleText = (value: number) => {
  if (value === ROLE_ENUM.ADMIN_SYSTEM) return "システム管理者";
  if (value === ROLE_ENUM.CONTENT_MANAGER) return "コンテンツ管理者";
  return "メンバー";
};

/**
 * Concatenates the first and last name with a space in between.
 * If either first or last is not provided, it will be treated as undefined.
 *
 * @param first - Optional string representing the first name.
 * @param last - Optional string representing the last name.
 * @returns A string representing the full name.
 */
export const getFullname = (first?: string, last?: string): string => {
  return `${first ?? ""} ${last ?? ""}`;
};

/**
 * Returns the file extension of the given filename.
 *
 * @param filename - The filename to get the file extension from.
 * @returns The file extension of the given filename.
 */
export function getFileExtension(filename: string) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

/**
 * Generates an array of options for departments based on the provided offices and departments.
 *
 * @param {Office[]} offices - An array of office objects.
 * @param {Department[]} departments - An array of department objects.
 * @returns {Option[]} - An array of options for departments.
 */
export function getDepartmentOptions(
  offices?: Office[],
  departments?: Department[],
): Option[] {
  const options: Option[] = [];
  if (departments?.length && offices?.length) {
    for (const department of departments) {
      for (const office of offices) {
        options.push({
          value: `${department.id}-${office.id}`,
          label: `${cutString(department.name)} / ${cutString(office.name)}`,
        });
      }
    }
  } else if (departments?.length && !offices?.length) {
    for (const department of departments) {
      options.push({
        value: `${department.id}-`,
        label: `${cutString(department.name)}`,
      });
    }
  } else if (!departments?.length && offices?.length) {
    for (const office of offices) {
      options.push({
        value: `-${office.id}`,
        label: `${cutString(office.name)}`,
      });
    }
  }
  return options;
}

export const getVideoContentType = (mimeType: string, name?: string) => {
  switch (mimeType) {
    case "video/quicktime":
      return "video/mp4";
    case "video/x-flv":
      return "video/flv";
    default:
      if (mimeType === "" && isFLV(mimeType, name)) {
        return "video/x-flv";
      }
      return mimeType;
  }
};

export const getExtensionFromTypeFile = (type: string) => {
  switch (type) {
    case "video/mp4":
      return "mp4";
    case "video/quicktime":
      return "mov";
    case "video/flv":
    case "video/x-flv":
      return "flv";
    case "video/mpeg":
      return "mpeg";
    default:
      return "";
  }
};

export const isFLV = (type: string, name?: string) => {
  const ext = name ? getFileExtension(name) : "";
  return ["video/x-flv", "video/flv"].includes(type) || ["flv"].includes(ext);
};

export const isMpeg = (type: string) => {
  return ["video/mpeg", "video/flv"].includes(type);
};

/**
 * Retrieves the link to the media based on the provided key.
 *
 * @param {string} key - The key used to retrieve the media link.
 * @return {string} The URL of the media based on the key.
 */
export const getLinkMedia = (key?: string, fromBE = false): string => {
  if (!key) return "";
  const baseURL = fromBE
    ? `${import.meta.env.VITE_API_URL}${
        API_PATH_CONSTANT.GET_FILE
      }?object_name=`
    : `${import.meta.env.VITE_OBJECT_STORAGE_URL}/`;
  return `${baseURL}${key}`;
};

export const getLinkYoutube = (id?: string | null): string => {
  if (!id) return "";
  return `https://www.youtube.com/watch?v=${id}`;
};

export const getSourceVideoJS = (url: string, type: string, name?: string) => {
  if (url?.startsWith("blob:")) {
    return {
      src: url,
      type: getVideoContentType(type, name),
    };
  }
  return {
    src: getLinkYoutube(url),
    type: "video/youtube",
  };
};

export const getRedirectRoute = (adminRoute: string, userRoute: string) => {
  const path = location.pathname;
  return path.startsWith("/admin") ? adminRoute : userRoute;
};

export const getSleepTimeAdjustedToFileSize = (fileSize: number): number => {
  const sleepTime = 2000;
  if (fileSize >= 20 && fileSize <= 50) {
    return 5000;
  } else if (fileSize > 50 && fileSize <= 100) {
    return 10000;
  } else if (fileSize > 100 && fileSize <= 300) {
    return 15000;
  } else if (fileSize > 300) {
    return 20000;
  }
  return sleepTime;
};

export const getMimeTypeRecorder = () => {
  if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
    return "video/webm;codecs=vp9";
  } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8")) {
    return "video/webm;codecs=vp8";
  } else if (MediaRecorder.isTypeSupported("video/webm;codecs=h264")) {
    return "video/webm;codecs=h264";
  } else if (MediaRecorder.isTypeSupported("video/webm")) {
    return "video/webm";
  } else if (MediaRecorder.isTypeSupported("video/mp4")) {
    return "video/mp4";
  } else {
    console.error("no suitable mimetype found for this device");
  }
};
