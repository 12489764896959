import { createBrowserRouter } from "react-router-dom";
import adminRoutes from "./admin.routes";
import frontRoutes from "./front.routes";
import PageNotFound from "@/pages/errors/404";

const router = createBrowserRouter([
  adminRoutes,
  frontRoutes,
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export { router };
