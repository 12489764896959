import { useMemo } from "react";

interface RadioUICheckboxUIProps {
  kind: "checked" | "uncheck" | "error";
  text: string;
  className?: string;
}
const RadioUI = ({ kind, text, className }: RadioUICheckboxUIProps) => {
  const renderIcon = useMemo(() => {
    if (kind === "checked")
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="7.5" fill="white" stroke="#73BE1E" />
          <circle cx="8" cy="8" r="5" fill="#73BE1E" />
        </svg>
      );
    if (kind === "error")
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="7.5" fill="white" stroke="#F70905" />
          <path
            d="M10.0418 5.37493C10.2029 5.21384 10.4641 5.21384 10.6252 5.37493C10.7862 5.53601 10.7862 5.79718 10.6252 5.95826L5.9585 10.6249C5.79741 10.786 5.53625 10.786 5.37516 10.6249C5.21408 10.4638 5.21408 10.2027 5.37516 10.0416L10.0418 5.37493Z"
            fill="#F70905"
          />
          <path
            d="M10.6252 10.0416C10.7862 10.2027 10.7862 10.4638 10.6252 10.6249C10.4641 10.786 10.2029 10.786 10.0418 10.6249L5.37516 5.95825C5.21408 5.79717 5.21408 5.536 5.37516 5.37492C5.53625 5.21384 5.79741 5.21384 5.9585 5.37492L10.6252 10.0416Z"
            fill="#F70905"
          />
        </svg>
      );
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="7.5" fill="white" stroke="#637381" />
      </svg>
    );
  }, [kind]);
  return (
    <div className={`flex flexRow gap-x-2 items-start ${className}`}>
      {renderIcon}
      <p className="caption1 text-[#000]">{text}</p>
    </div>
  );
};

const CheckboxUI = ({ kind, text, className }: RadioUICheckboxUIProps) => {
  const renderIcon = useMemo(() => {
    if (kind === "checked")
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
            fill="#73BE1E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9322 5.51654C11.1765 5.27226 11.5725 5.27226 11.8168 5.51654C12.0611 5.76082 12.0611 6.15686 11.8168 6.40114L7.12184 11.0961C6.87757 11.3404 6.48153 11.3404 6.23725 11.0961L4.18321 9.04204C3.93892 8.79776 3.93894 8.40172 4.18321 8.15745C4.42748 7.91318 4.82352 7.91316 5.06781 8.15745L6.67955 9.76919L10.9322 5.51654Z"
            fill="white"
          />
        </svg>
      );
    if (kind === "error")
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H12C13.933 0.5 15.5 2.067 15.5 4V12C15.5 13.933 13.933 15.5 12 15.5H4C2.067 15.5 0.5 13.933 0.5 12V4Z"
            fill="white"
            stroke="#F70905"
          />
          <path
            d="M10.0418 5.37493C10.2029 5.21384 10.4641 5.21384 10.6252 5.37493C10.7862 5.53601 10.7862 5.79718 10.6252 5.95826L5.9585 10.6249C5.79741 10.786 5.53625 10.786 5.37516 10.6249C5.21408 10.4638 5.21408 10.2027 5.37516 10.0416L10.0418 5.37493Z"
            fill="#F70905"
          />
          <path
            d="M10.6252 10.0416C10.7862 10.2027 10.7862 10.4638 10.6252 10.6249C10.4641 10.786 10.2029 10.786 10.0418 10.6249L5.37516 5.95825C5.21408 5.79717 5.21408 5.536 5.37516 5.37492C5.53625 5.21384 5.79741 5.21384 5.9585 5.37492L10.6252 10.0416Z"
            fill="#F70905"
          />
        </svg>
      );
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H12C13.933 0.5 15.5 2.067 15.5 4V12C15.5 13.933 13.933 15.5 12 15.5H4C2.067 15.5 0.5 13.933 0.5 12V4Z"
          fill="white"
          stroke="#637381"
        />
      </svg>
    );
  }, [kind]);
  return (
    <div className={`flex flexRow gap-x-2 items-start ${className}`}>
      <div>{renderIcon}</div>
      <p className="caption1 text-[#000]">{text}</p>
    </div>
  );
};

export { RadioUI, CheckboxUI };
