import clsx from "clsx";
import "./checkbox.scss";

interface CheckboxProps {
  isError?: boolean;
  checked?: boolean;
  className?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  disableCursorPointer?: boolean;
  value?: string | number;
  name?: string;
  label?: string;
  onChange?: (value?: string | number) => void;
}
export default function Checkbox({
  className,
  isError = false,
  checked = false,
  value,
  isDisabled = false,
  isReadOnly = false,
  disableCursorPointer = false,
  name,
  label,
  onChange,
}: CheckboxProps) {
  const handleClick = () => {
    if (isDisabled || isReadOnly) {
      return;
    }
    onChange?.(value);
  };
  return (
    <div className={clsx("flex gap-x-3 items-center", className)}>
      <div
        className={clsx(
          "relative checkbox-container  h-4 w-4",
          className,
          isReadOnly || isDisabled
            ? "cursor-not-allowed	"
            : disableCursorPointer
            ? ""
            : "cursor-pointer",
        )}
        onClick={handleClick}
      >
        <input
          type="checkbox"
          className={clsx(
            "absolute w-0 h-0 checkbox",
            isDisabled ? "cursor-not-allowed	" : "cursor-pointer",
          )}
          value={value ?? ""}
          name={name}
          checked={checked}
          disabled={isDisabled}
          onChange={() => {}}
        />
        <span
          className={clsx(
            "checkmark absolute left-0 top-0 h-4 w-4 rounded",
            isError && "is-error",
          )}
        ></span>
      </div>
      {label && (
        <label
          onClick={handleClick}
          className={`body1 ${disableCursorPointer ? "" : "cursor-pointer"}`}
        >
          {label}
        </label>
      )}
    </div>
  );
}
