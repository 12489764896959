import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import FormChangeInfor from "@/components/organisms/FormChangeInfor";
import { ROUTES_CONSTANT } from "@/configs/constants";
import FrontLayout from "@/pages/_layouts/front-main";
import AnimatedRoute from "@/router/transition.route";

const breadcrumbs: BeardcrumbOption[] = [
  {
    label: "マイページ",
    route: ROUTES_CONSTANT.MY_PAGE,
  },
  {
    label: "ユーザー情報の変更",
  },
];
function ChangeInforPage() {
  return (
    <div className="desktop:py-6 max-w-[960px] m-auto w-full mobile:py-6 mobile:px-4">
      <div className="">
        <Breadcrumb options={breadcrumbs} />
        <h4 className="font-black mt-5 desktop:mb-8 mobile:mb-6">
          ユーザー情報の変更
        </h4>
        <FormChangeInfor />
      </div>
    </div>
  );
}

const AnimatedFrontChangeInforPage = AnimatedRoute(ChangeInforPage, false);

export default function FrontChangeInforPage() {
  return (
    <FrontLayout>
      <AnimatedFrontChangeInforPage />
    </FrontLayout>
  );
}
