import { ReactNode, useEffect, useState } from "react";
import ArrowDownIcon from "@/assets/icon/arrow-down.svg";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  ColumnDef,
  Header,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { PAGINATION_CONSTANT } from "@/configs/constants";
import Pagination from "@/components/atoms/Pagination/pagination";
import { MESSAGES } from "@/shared/validation/message";
import Spinner from "@/components/atoms/Spinner/spinner";

const emptyArray: any[] = [];

interface DatatableProps<T> {
  className?: string;
  data: T[];
  columns: ColumnDef<T, any>[];
  total: number;
  headerSlot?: ReactNode;
  defaultPage?: number;
  defaultPageSize?: number;
  defaultSort?: SortingState;
  defaultColumn?: { size?: number; minSize?: number; maxSize?: number };
  onChangePage: (page: number) => void;
  onSorting: (sortState: SortingState) => void;
  loading?: boolean;
}

type MetaType = {
  minWidth?: string | number;
  width?: string | number;
};

export default function DataTable<T>({
  className,
  data,
  columns,
  total,
  defaultPageSize = PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
  defaultPage = 1,
  defaultSort,
  onChangePage,
  defaultColumn = {},
  onSorting,
  headerSlot = <></>,
  loading = false,
}: DatatableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>(defaultSort ?? []);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: defaultPage - 1,
    pageSize: defaultPageSize,
  });

  useEffect(() => {
    setPagination({
      pageIndex: defaultPage - 1,
      pageSize: defaultPageSize,
    });
  }, [defaultPage, defaultPageSize]);

  const table = useReactTable({
    defaultColumn: {
      size: 130, //starting column size
      minSize: 50, //enforced during column resizing
      maxSize: 1000, //enforced during column resizing
      ...defaultColumn,
    },
    data: data || emptyArray,
    columns: columns || emptyArray,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    enableSortingRemoval: false,
    manualPagination: true,
    onPaginationChange: setPagination,
    rowCount: total,
    autoResetPageIndex: false,
    debugTable: false,
  });

  const handleSorting = (header: Header<T, unknown>) => {
    const isSortable = header.column.getCanSort();
    if (isSortable) {
      const ctx = header.getContext();
      const isSorted = header.column.getIsSorted();
      const isSortDescFirst =
        !!header.column.columnDef.sortDescFirst ||
        !!ctx.table.options.sortDescFirst;
      const isEnableSortingRemoval = !!ctx.table.options.enableSortingRemoval;

      if (!isSorted) {
        header.column.toggleSorting(isSortDescFirst);
      }

      if (isSorted === "asc") {
        if (isSortDescFirst && isEnableSortingRemoval) {
          header.column.clearSorting();
        } else {
          header.column.toggleSorting(true);
        }
      }

      if (isSorted === "desc") {
        if (!isSortDescFirst && isEnableSortingRemoval) {
          header.column.clearSorting();
        } else {
          header.column.toggleSorting(false);
        }
      }
    }
  };

  useEffect(() => {
    onChangePage(pagination.pageIndex + 1);
  }, [pagination.pageIndex, onChangePage]);

  useEffect(() => {
    onSorting(sorting);
  }, [sorting, onSorting]);

  return (
    <div
      className={`rounded-[20px] bg-white shadow-card w-full ${className} overflow-hidden`}
    >
      {headerSlot && <div className="p-4 min-h-8 ">{headerSlot}</div>}
      <TableContainer>
        <Table>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr
                key={headerGroup.id}
                className={clsx(
                  "bg-[#FAFAFA] [border-top:1px_solid_#E6E8EA] [border-bottom:1px_solid_#E6E8EA]",
                  // !headerSlot && "rounded-[20px]",
                )}
              >
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    className={clsx(
                      "!px-2 !py-4",
                      header.index === 0 && "!pl-6",
                      header.index === columns.length - 1 && "!pr-4",
                    )}
                    style={{
                      minWidth:
                        (header.column.columnDef.meta as MetaType)?.minWidth ??
                        "auto",
                      width: (header.column.columnDef.meta as MetaType)?.width
                        ? (header.column.columnDef.meta as MetaType)?.width
                        : "auto",
                    }}
                  >
                    <div
                      className={clsx(
                        "flex justify-start items-center gap-2  subtitle2-important !text-[#637381]",
                        header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : "",
                      )}
                      onClickCapture={() => handleSorting(header)}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {header.column.getCanSort() && (
                        <div
                          className={clsx(
                            "transition-all",
                            header.column.getIsSorted() === "asc" &&
                              "rotate-180",
                          )}
                        >
                          <ArrowDownIcon />
                        </div>
                      )}
                    </div>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={columns.length}>
                  <div className="flex justify-center">
                    <Spinner
                      circleStyles={{
                        borderColor: `var(--primary-main-color) transparent transparent transparent`,
                      }}
                    />
                  </div>
                </Td>
              </Tr>
            ) : (
              <>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <Tr key={row.id} className={clsx("bg-[#FFF] ")}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            className={clsx(
                              "!px-2 !py-4 !border-b-white",
                              cell.column.getIndex() === 0 && "!pl-6",
                              cell.column.getIndex() === columns.length - 1 &&
                                "!pr-4",
                            )}
                          >
                            <div className="body1 text-[#212B36] break-all">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </div>
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
                {table.getRowModel().rows.length == 0 && (
                  <Tr>
                    <Td
                      colSpan={table.getAllColumns().length}
                      className="!py-8 !text-[16px] !leading-6 !text-center subtitle2-important !text-[#637381]"
                    >
                      {MESSAGES.MSG_010}
                    </Td>
                  </Tr>
                )}
              </>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <div className="p-4 bg-[#FAFAFA] [border-top:1px_solid_#E6E8EA] rounded-bl-[20px] rounded-br-[20px]">
        <Pagination
          total={total}
          current={pagination.pageIndex}
          perPage={pagination.pageSize}
          goToFirst={() => table.firstPage()}
          goToLast={() => table.lastPage()}
          goToPage={(page) => table.setPageIndex(page)}
        />
      </div>
    </div>
  );
}
