import {
  MutateOptions,
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DeleteYoutubeResponseBody,
  UploadRequestBody,
  UploadResponseBody,
  UploadYoutubeRequestBody,
  UploadYoutubeResponseBody,
  deleteYoutubeApi,
  getFileApi,
  getStatusJobApi,
  uploadApi,
  uploadYoutubeApi,
} from "./request";
import { useState } from "react";

export const useUploadFileMutation = (
  options: MutateOptions<
    UploadResponseBody,
    unknown,
    UploadRequestBody,
    unknown
  > = {},
) => {
  const [progress, setProgress] = useState(0);
  const queryClient = useQueryClient();
  const mutation = useMutation(
    {
      ...options,
      mutationFn: (data) => uploadApi(data, setProgress),
      onSuccess: () => {
        // queryClient.invalidateQueries("login");
      },
    },
    queryClient,
  );

  return {
    ...mutation,
    progress,
  };
};

export const useGetFileQuery = (
  params: string,
  options: Omit<
    UseQueryOptions<
      unknown,
      unknown, // TError,
      unknown,
      string[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["get-file", params],
    queryFn: getFileApi,
    enabled: !!params,
    ...options,
  });
};

export const getFileUpload = async (
  queryClient: QueryClient,
  params: string,
) => {
  return queryClient.fetchQuery({
    queryKey: ["get-file", params],
    queryFn: getFileApi,
  });
};

export const useUploadFileToYoutubeMutation = (
  options: MutateOptions<
    UploadYoutubeResponseBody,
    unknown,
    UploadYoutubeRequestBody,
    unknown
  > = {},
) => {
  const [progress, setProgress] = useState(0);
  const queryClient = useQueryClient();
  const mutation = useMutation(
    {
      ...options,
      mutationFn: (data) => uploadYoutubeApi(data, setProgress),
      onSuccess: () => {},
    },
    queryClient,
  );

  return {
    ...mutation,
    progress,
  };
};

export const useDeleteYoutubeMutation = (
  options: MutateOptions<
    string,
    unknown,
    DeleteYoutubeResponseBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    {
      ...options,
      mutationFn: deleteYoutubeApi,
      onSuccess: () => {},
    },
    queryClient,
  );

  return {
    ...mutation,
  };
};

export const getStatusJob = async (queryClient: QueryClient, id: string) => {
  return queryClient.fetchQuery({
    queryKey: ["status-job", id],
    queryFn: getStatusJobApi,
  });
};
