import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function QuizzManagementPage(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/admin/management/quizz")
      navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_LIST);
  }, [location.pathname]);

  return (
    <>
      <Outlet />
    </>
  );
}
