import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import CreateUserForm from "@/components/organisms/UserManagement/create";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import AnimatedRoute from "@/router/transition.route";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";
import { useShowUserQuery } from "@/api/user";
import { getFullname } from "@/shared/get";
import { getRoute } from "@/shared/get/route";
import { useShowELearningQuery } from "@/api/e-learning";
import { useShowQuizzQuery } from "@/api/quizz";

/**
 * Renders the page for edit user page.
 *
 * @returns {JSX.Element} The rendered EditUserPage component.
 */
function EditUserPage(): JSX.Element {
  const { isAdminSystem, isAdminContent } = useRole(true, [
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);

  const { id, quiz_id: quizId, elearning_id: elearningId } = useParams();

  const { data: userInfo } = useShowUserQuery(id as unknown as number, {
    enabled: !!id,
  });

  const { data: courseRes } = useShowELearningQuery(
    elearningId as unknown as number,
    {
      enabled: !!elearningId,
    },
  );

  const { data: quizResponse } = useShowQuizzQuery(
    quizId as unknown as number,
    {
      enabled: !!quizId,
    },
  );

  const fullName = useMemo(
    () => getFullname(userInfo?.first_name, userInfo?.last_name),
    [userInfo],
  );

  const elearningTitle = useMemo(() => {
    return courseRes?.title;
  }, [courseRes]);

  const quizTitle = useMemo(() => {
    return quizResponse?.title;
  }, [quizResponse]);

  const breadcrumbs: BeardcrumbOption[] = useMemo(() => {
    if (elearningTitle) {
      return [
        {
          label: "Eラーニング一覧",
          route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST,
        },
        {
          label: `${elearningTitle}`,
          route: getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_DETAIL, {
            id: elearningId,
          }),
        },
        {
          label: `「${fullName}」の編集`,
        },
      ];
    } else if (quizTitle) {
      return [
        {
          label: "テスト一覧",
          route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_LIST,
        },
        {
          label: `${quizTitle}`,
          route: getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_DETAIL, {
            id: quizId,
          }),
        },
        {
          label: `「${fullName}」の編集`,
        },
      ];
    }
    return [
      {
        label: "ユーザー管理",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_LIST,
      },
      {
        label: `「${fullName}」の編集`,
      },
    ];
  }, [fullName, elearningTitle, quizTitle, quizId, elearningId]);

  const urlSuccess = useMemo(() => {
    if (quizId)
      return getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_DETAIL_USER, {
        quiz_id: quizId,
        id,
      });
    if (elearningId)
      return getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_DETAIL_USER, {
        elearning_id: elearningId,
        id,
      });
    return undefined;
  }, [quizId, elearningId]);

  return (
    <div className="max-w-[100%] m-auto my-6">
      <Breadcrumb options={breadcrumbs} />
      <h4 className="mt-3 font-black">編集</h4>
      <div className="mt-8">
        <CreateUserForm
          id={id as unknown as number}
          userInfo={userInfo}
          role={{
            isAdminContent: Boolean(isAdminContent),
            isAdminSystem: Boolean(isAdminSystem),
          }}
          urlSuccess={urlSuccess}
        />
      </div>
    </div>
  );
}

const AnimatedEditUserPage = AnimatedRoute(EditUserPage);
export default AnimatedEditUserPage;
