import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import { getExtensionFromTypeFile, getFileExtension, isFLV } from "../get";

/**
 * Converts a File or Blob object to a base64 encoded string.
 *
 * @param file - The File or Blob object to convert.
 * @returns A Promise that resolves to the base64 encoded string.
 */
const getBase64FromFile = (file: File | Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      resolve(reader.result as string);
    });
    reader.readAsDataURL(file);
  });
};

/**
 * Converts a size in gigabytes to byte.
 *
 * @param size - The size in gigabytes to convert.
 * @returns The size in byte.
 */
const convertGBtoByte = (size: number) => {
  if (!size) {
    return 0;
  }
  return size * 1024 * 1024 * 1024;
};

/**
 * Converts a size in megabytes to byte.
 *
 * @param size - The size in megabytes to convert.
 * @returns The size in byte.
 */
const convertMBtoByte = (size: number) => {
  if (!size) {
    return 0;
  }
  return size * 1024 * 1024;
};

/**
 * Converts a size in kilobyte to byte.
 *
 * @param size - The size in kilobyte to convert.
 * @returns The size in byte.
 */
const convertKBtoByte = (size: number) => {
  if (!size) {
    return 0;
  }
  return size * 1024;
};

/**
 * Converts a size in byte to kilobyte.
 *
 * @param bytes - The size in byte to convert.
 * @returns The size in megabytes.
 */
const convertBytetoMB = (bytes: number) => {
  if (!bytes) return 0; // handle special case
  const mb = bytes / (1024 * 1024);
  return Number(mb.toFixed(2));
};

const removeExtension = (fileName?: string) => {
  if (!fileName) return null;
  // Find the position of the last dot in the file name
  const lastDotIndex = fileName.lastIndexOf(".");

  // If no dot is found, return the original file name
  if (lastDotIndex === -1) {
    return fileName;
  }

  // Return the part of the file name from the start to the position of the last dot
  return fileName.substring(0, lastDotIndex);
};

const createFileFromBlobURL = async (
  blobUrl: string,
  fileName: string,
  mimeType: string,
) => {
  const uint8Array = await fetchFile(blobUrl);
  const blob = new Blob([uint8Array], { type: mimeType });

  return new File([blob], fileName, { type: mimeType });
};

const transcode = async (ffmpeg: FFmpeg, file: File) => {
  const ext = getFileExtension(file.name);
  let type = getExtensionFromTypeFile(file.type);
  if (isFLV(file.type)) {
    if (ext === "flv") type = ext;
  }

  await ffmpeg.writeFile(`input.${type}`, await fetchFile(file));
  await ffmpeg.exec(["-i", `input.${type}`, "output.mp4"]);
  const fileData = await ffmpeg.readFile("output.mp4");
  const data = new Uint8Array(fileData as ArrayBuffer);
  return new Blob([data.buffer], { type: "video/mp4" });
};

export const convertToMp4 = async (ffmpeg: FFmpeg, file: File) => {
  if (!file) return;
  // const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm";
  // ffmpeg.on("log", ({ message }) => {
  //   console.log(message);
  // });
  // toBlobURL is used to bypass CORS issue, urls with the same
  // domain can be used directly.

  const coreJSURL = new URL(
    "../../assets/ffmpeg/ffmpeg-core.js",
    import.meta.url,
  ).toString();

  const coreWasmURL = new URL(
    "../../assets/ffmpeg/ffmpeg-core.wasm",
    import.meta.url,
  ).toString();

  await ffmpeg.load({
    coreURL: coreJSURL,
    wasmURL: coreWasmURL,
  });

  return transcode(ffmpeg, file);
};

export {
  convertMBtoByte,
  getBase64FromFile,
  convertKBtoByte,
  convertGBtoByte,
  removeExtension,
  convertBytetoMB,
  createFileFromBlobURL,
};
