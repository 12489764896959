import AuthLayout from "@/pages/_layouts/auth";
import Logo from "@/assets/logo.svg";
import LogoSp from "@/assets/logo-front-sp.svg";

import FormLogin from "@/components/organisms/FormLogin";
import useIcon from "@/hooks/get-icon";
import useScreenSize from "@/hooks/screen";
export default function FrontLoginPage() {
  const { isMobile } = useScreenSize();
  const logo = useIcon(<LogoSp />, <Logo />);
  return (
    <AuthLayout>
      <div className="mobile:py-[48px] desktop:py-[80px] flex w-full items-center flex-col mobile:px-4 desktop:px-5">
        {logo}
        {isMobile ? (
          <h5 className="font-[900] mt-[16px] mb-[48px] text-center">
            ログイン
          </h5>
        ) : (
          <h4 className="font-[900] mt-[16px] mb-[48px] text-center">
            ログイン
          </h4>
        )}

        <FormLogin isAdminPage={false} />
      </div>
    </AuthLayout>
  );
}
