import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/shared/validation/yup";
import { QUESTION_KIND_ENUM } from "@/configs/enum";

export interface IQuizContentForm {
  questions?: {
    kind: string;
    answers: number[] | number | string | null;
    correct_answers: {
      value: number;
      index_correct: number;
    }[];
  }[];
}

export const useQuizContentForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        questions: yup
          .array(
            yup.object().shape({
              kind: yup.string().required(),
              answers: yup.mixed().when("kind", {
                is: (v: string) => v === QUESTION_KIND_ENUM.MULTI_ANSWER,
                then: () => yup.array().of(yup.number().required()).default([]),
                otherwise: () =>
                  yup.mixed().when("kind", {
                    is: (v: string) => v === QUESTION_KIND_ENUM.SINGLE_ANSWER,
                    then: () => yup.number().nullable(),
                    otherwise: () => yup.string().nullable(),
                  }),
              }),
              correct_answers: yup
                .array()
                .of(yup.object().required())
                .default([])
                .nullable(),
            }),
          )
          .default([]),
      }),
    [],
  );

  return useForm<IQuizContentForm>({
    resolver: yupResolver(validationScheme) as any,
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });
};
