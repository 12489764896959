import Button from "@/components/atoms/Button/button";
import { QUESTION_KIND_ENUM } from "@/configs/enum";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import clsx from "clsx";
import { CheckboxUI, RadioUI } from "./radio-checkbox-ui";
import { useMemo } from "react";
import { isNil } from "lodash";
export interface QuestionDataOfModalResult {
  id: number;
  title: string;
  kind: string;
  content: string;
  options: {
    id: number;
    option_kind: "error" | "checked" | "uncheck";
    title: string;
  }[];
}
interface ModalResultProps {
  id?: string;
  onClose: (status: boolean) => void;
  isOpen: boolean;
  point?: number;
  passPoint?: number;
  totalPoint?: number;
  data: QuestionDataOfModalResult[];
}
export default function ModalResult({
  id,
  isOpen,
  onClose,
  data,
  point = 0,
  passPoint = 0,
  totalPoint = 0,
}: ModalResultProps) {
  const isPassed = useMemo(() => {
    if (isNil(point) || isNil(passPoint)) return true;
    return point >= passPoint;
  }, [point, passPoint]);

  return (
    <Modal
      id={id}
      isCentered
      autoFocus={false}
      onClose={() => onClose(isPassed)}
      isOpen={isOpen}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent className="bg-white max-h-[498px] w-full max-w-[480px] p-6 rounded-[16px] mx-[16px] my-[0]">
        <ModalBody className="!p-0">
          <div
            className={clsx(
              "rounded-[10px] p-2 w-full flex flex-col items-center gap-[10px]",
              isPassed ? "bg-[#F1F9E8]" : "bg-[#F1F3EC]",
            )}
          >
            <h6 className={isPassed ? "text-[#73BE1E]" : "text-[#223909]"}>
              {isPassed ? "合格" : "不合格"}
            </h6>
            <div className="flex gap-x-2 items-end">
              <h4>{point}</h4>
              <h5>/</h5>
              <div className="flex gap-x-1">
                <span className="subtitle1">{totalPoint}</span>
                <span className="subtitle1">点</span>
              </div>
            </div>
          </div>
          <div className="mt-6 overflow-auto max-h-[260px]">
            {data.map((item, index) => {
              return (
                <div key={item.id}>
                  <p className="mt-[20px]">
                    <span className="subtitle1 text-[#000]">Q.{index + 1}</span>
                    <span className="subtitle1 text-[#000] ml-2">
                      {item.title}
                    </span>
                  </p>
                  <div>
                    {item.kind === QUESTION_KIND_ENUM.FREE_INPUT && (
                      <p className="caption1 text-[#000] mt-2">
                        {item.content}
                      </p>
                    )}
                    {item.kind === QUESTION_KIND_ENUM.MULTI_ANSWER &&
                      item.options.map((option) => {
                        return (
                          <CheckboxUI
                            key={option.id}
                            kind={option.option_kind}
                            text={option.title}
                            className={"mt-2"}
                          />
                        );
                      })}
                    {item.kind === QUESTION_KIND_ENUM.SINGLE_ANSWER &&
                      item.options.map((option) => {
                        return (
                          <RadioUI
                            key={option.id}
                            kind={option.option_kind}
                            text={option.title}
                            className={"mt-2"}
                          />
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-6 flex justify-end">
            <Button
              variant="primary"
              onClick={() => onClose(isPassed)}
              buttonCustom={{
                borderRadius: "8px",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "700",
                padding: "8px 16px",
              }}
            >
              OK
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
