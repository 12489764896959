import {
  MutateOptions,
  useQueryClient,
  useMutation,
  UseQueryOptions,
  useQuery,
  QueryClient,
} from "@tanstack/react-query";

import {
  CreateComplianceRequestBody,
  CreateComplianceResponseBody,
  ShowComplianceResponseBody,
  UpdateComplianceRequestBody,
  UpdateComplianceResponseBody,
  createComplianceApi,
  showComplianceApi,
  updateComplianceApi,
  listComplianceApi,
  FilterComplianceResponseBody,
  FilterComplianceRequestBody,
  DeleteComplianceResponseBody,
  deleteComplianceApi,
} from "./request";
export const useCreateComplianceMutation = (
  options: MutateOptions<
    CreateComplianceResponseBody,
    unknown,
    CreateComplianceRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: createComplianceApi,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["filter-compliance"],
        });
      },
    },
    queryClient,
  );
};

export const useUpdateComplianceMutation = (
  options: MutateOptions<
    UpdateComplianceResponseBody,
    unknown,
    UpdateComplianceRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updateComplianceApi,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["filter-compliance"],
        });
      },
    },
    queryClient,
  );
};

export const useShowComplianceQuery = (
  params: number | string,
  options: Omit<
    UseQueryOptions<
      ShowComplianceResponseBody,
      unknown,
      ShowComplianceResponseBody,
      (string | number)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["show-compliance", params],
    queryFn: showComplianceApi,
    ...options,
  });
};

export const fetchFilterComplianceList = async (
  queryClient: QueryClient,
  params?: Partial<FilterComplianceRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["filter-compliances", params],
    queryFn: listComplianceApi,
  });
};

export const useListComplianceQuery = (
  params: Partial<FilterComplianceRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterComplianceResponseBody,
      unknown, // TError,
      FilterComplianceResponseBody,
      (string | Partial<FilterComplianceRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-compliances", params],
    queryFn: listComplianceApi,
    enabled: !!params,
    ...options,
  });
};

export const useDeleteComplianceMutation = (
  options: MutateOptions<
    DeleteComplianceResponseBody,
    unknown,
    number,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: deleteComplianceApi,
    },
    queryClient,
  );
};
