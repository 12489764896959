import RichTextEditor from "@/components/atoms/RichTextEditor/rich-text-editor";
import {
  Control,
  Controller,
  FieldValue,
  FieldValues,
  Path,
} from "react-hook-form";

interface ControlledRichTextEditorProps {
  className?: string;
  errorMessage?: string;
  placeholder?: string;
  isDisabled?: boolean;
  onChange?: (v: string) => void;
  onError?: (msg: string) => void;
}

interface OwnProps<Type extends FieldValues>
  extends Omit<ControlledRichTextEditorProps, "value"> {
  control: Control<FieldValue<Type>>;
  formField: Path<FieldValue<Type>>;
}

export const ControlledRichTextEditor = <T extends FieldValues>({
  control,
  formField,
  errorMessage,
  // placeholder,
  // onChange,
  isDisabled,
  className,
  onError,
}: OwnProps<T>) => {
  return (
    <Controller
      control={control}
      name={formField}
      render={({ field }) => {
        return (
          <div className={className}>
            <RichTextEditor
              value={field.value}
              errorMessage={errorMessage}
              disabled={isDisabled}
              onChange={field.onChange}
              onError={onError}
            />
            {errorMessage && errorMessage.length > 0 && (
              <div className="mt-1 w-full text-error--main input-error__message">
                {errorMessage}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
