import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import CreateQuizzForm from "@/components/organisms/QuizzManagement/create/index";
import AnimatedRoute from "@/router/transition.route";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";

const breadcrumbs: BeardcrumbOption[] = [
  {
    label: "テスト一覧",
    route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_LIST,
  },
  {
    label: "新規作成",
  },
];

function CreateQuizzPage(): JSX.Element {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);

  return (
    <div className="max-w-[100%] m-auto my-6">
      <Breadcrumb options={breadcrumbs} />
      <h4 className="mt-3 font-black">新規作成</h4>
      <div className="mt-8">
        <CreateQuizzForm />
      </div>
    </div>
  );
}
const AnimatedCreateQuizzPage = AnimatedRoute(CreateQuizzPage);
export default AnimatedCreateQuizzPage;
