import { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-youtube";
import Player from "video.js/dist/types/player";

export interface VideoJsPlayerOptions {
  autoplay?: boolean | "play" | "muted" | "any" | undefined;
  controls?: boolean;
  muted?: boolean;
  fluid?: boolean;
  width?: number;
  height?: number;
  controlBar?: {
    fullscreenToggle?: boolean;
    volumePanel?: {
      inline?: boolean;
    };
    pictureInPictureToggle?: boolean;
  };
  playbackRates?: number[];
  techOrder?: string[];
  language?: string;
  loop?: boolean;
  disablePictureInPicture?: boolean;
  enableSmoothSeeking?: boolean;
  fullscreen?: { options: { navigationUI: string } };
  aspectRatio?: "16:9" | "4:3" | "1:1" | "9:16" | string;
  sources?: {
    src: string;
    type: string;
  }[];
  youtube?: {
    ytControls?: number;
    rel?: number;
    autohide?: number;
    loop?: number;
    playlist?: string | null;
  };
}
interface IVideoPlayerProps {
  options?: VideoJsPlayerOptions;
  onReady?: (player: any) => void;
  className?: string;
  isFlip?: boolean;
}

const initialOptions: VideoJsPlayerOptions = {
  playbackRates: [0.5, 1, 1.25, 2],
  controls: true,
  muted: false,
  fluid: false,
  language: "ja",
  loop: true,
  disablePictureInPicture: true,
  aspectRatio: "16:9",
  techOrder: ["youtube", "html5"],
  fullscreen: { options: { navigationUI: "hide" } },
  controlBar: {
    fullscreenToggle: true,
    volumePanel: {
      inline: false,
    },
    pictureInPictureToggle: false,
  },
  youtube: {
    loop: 1,
  },
};

const VideoPlayer = ({
  options = {},
  onReady,
  className,
  isFlip,
}: IVideoPlayerProps) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<Player | null>(null);
  useEffect(() => {
    const _options = {
      ...initialOptions,
      ...options,
    };
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");

      const classList = videoElement.classList;
      if (classList && !classList.contains('"vjs-mirror')) {
        isFlip && classList.add("vjs-mirror");
      }

      if (videoRef.current) videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(
        videoElement,
        _options,
        () => {
          videojs.log("player is ready");
          onReady && onReady(player);
        },
      ));
    } else {
      const player = playerRef.current;
      player.autoplay(_options.autoplay);
      player.src(_options.sources);
    }
  }, [options, videoRef, isFlip]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div
      data-vjs-player
      className={`w-full h-full ${className}`}
      onContextMenu={(e) => e.preventDefault()}
    >
      <div ref={videoRef} className="w-full h-full" />
    </div>
  );
};

export default VideoPlayer;
