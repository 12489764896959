import {
  Option,
  SelectBox,
  SelectProps,
} from "@/components/atoms/SelectBox/select-box";
import { VirtualSelectBox } from "@/components/atoms/SelectBox/virtual-select-box";
import clsx from "clsx";
import { useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldValue,
  FieldValues,
  Path,
} from "react-hook-form";
import { GroupBase, SelectComponentsConfig } from "react-select";

interface ControlledSelectProps
  extends Omit<SelectProps, "defaultValue" | "value" | "components"> {
  label?: string;
  note?: string;
  isRequired?: boolean;
  components?:
    | Partial<SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>>
    | undefined;
  onChange?: (val: any) => void;
  onMenuScrollToBottom?: ((event: WheelEvent | TouchEvent) => void) | undefined;
  isVirtualList?: boolean;
  onChangeCallback?: (v: any, oldValue?: any) => void;
  unsetMenuPortalTarget?: boolean;
  filterOption?: (option: { data: Option }, inputValue: string) => boolean;
}

interface OwnProps<Type extends FieldValues>
  extends Omit<ControlledSelectProps, "value"> {
  control: Control<FieldValue<Type>>;
  formField: Path<FieldValue<Type>>;
}

export const ControlledSelect = <T extends FieldValues>({
  control,
  formField,
  className,
  label,
  note,
  isRequired,
  errorMessage,
  components,
  onChange,
  onMenuScrollToBottom,
  onChangeCallback,
  isVirtualList,
  unsetMenuPortalTarget,
  filterOption,
  ...props
}: OwnProps<T>) => {
  const [isFocusing] = useState(false);
  const isError = useMemo(
    () => errorMessage && errorMessage.length > 0,
    [errorMessage],
  );

  return (
    <Controller
      control={control}
      name={formField}
      render={({ field }) => {
        return (
          <div className={className}>
            {label?.length && (
              <div className="flex gap-2 mb-1 justify-between">
                <div className="flex flex-col justify-center text-xs max-md:max-w-full">
                  <div className="flex gap-1 max-md:flex-wrap">
                    <div
                      className={clsx(
                        isFocusing && !isError
                          ? "text-[var(--primary-main-color)]"
                          : "text-[var(--text-secondary)]",
                      )}
                    >
                      {label}
                    </div>
                    {isRequired && <div className="text-error--main">*</div>}
                  </div>
                </div>
                {note && (
                  <div className="text-[var(--text-secondary)] caption1">
                    {note}
                  </div>
                )}
              </div>
            )}
            {isVirtualList ? (
              <VirtualSelectBox
                {...props}
                defaultValue={field.value}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  onChange?.(e);
                  onChangeCallback && onChangeCallback(e, field.value);
                }}
                onBlur={field.onBlur}
                errorMessage={errorMessage}
                // components={components}
                onMenuScrollToBottom={onMenuScrollToBottom}
              />
            ) : (
              <SelectBox
                {...props}
                defaultValue={field.value}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  onChange?.(e);
                  onChangeCallback && onChangeCallback(e, field.value);
                }}
                onBlur={field.onBlur}
                errorMessage={errorMessage}
                components={components}
                onMenuScrollToBottom={onMenuScrollToBottom}
                unsetMenuPortalTarget={unsetMenuPortalTarget}
                filterOption={filterOption}
              />
            )}
          </div>
        );
      }}
    />
  );
};
