import { ROUTES_CONSTANT } from "@/configs/constants";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function ContentVideoPage() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/content/video")
      navigate(ROUTES_CONSTANT.CONTENT_VIDEO_LIST);
  }, [location.pathname]);
  return <Outlet />;
}
