import {
  MutateOptions,
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CheckValidateVideoRequestBody,
  CheckValidateVideoResponseBody,
  DeleteVideoRequestBody,
  FilterVideoRequestBody,
  FilterVideoResponseBody,
  ShowVideoResponseBody,
  UpsertVideoRequestBody,
  UpsertVideoResponseBody,
  checkValidateVideoApi,
  deleteVideoApi,
  listVideoApi,
  showVideoApi,
  upsertVideoApi,
} from "./request";

export const useListVideoQuery = (
  params: Partial<FilterVideoRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterVideoResponseBody,
      unknown, // TError,
      FilterVideoResponseBody,
      (string | Partial<FilterVideoRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-video", params],
    queryFn: listVideoApi,
    ...options,
  });
};

export const fetchListVideo = async (
  queryClient: QueryClient,
  params?: Partial<FilterVideoRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["filter-video-1", params],
    queryFn: listVideoApi,
  });
};

export const useSuggestionVideoListQuery = (
  params: Partial<FilterVideoRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterVideoResponseBody,
      unknown, // TError,
      FilterVideoResponseBody,
      (string | Partial<FilterVideoRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-suggestion-Video", params],
    queryFn: listVideoApi,
    ...options,
  });
};

export const useUpsertVideoMutation = (
  options: MutateOptions<
    UpsertVideoResponseBody,
    unknown,
    UpsertVideoRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: upsertVideoApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useCheckValidateMutation = (
  options: MutateOptions<
    CheckValidateVideoResponseBody,
    unknown,
    CheckValidateVideoRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: checkValidateVideoApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useShowVideoQuery = (
  id: number | string,
  folder_id: number | string,
  options: Omit<
    UseQueryOptions<
      ShowVideoResponseBody,
      unknown,
      ShowVideoResponseBody,
      (string | number)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["show-video", id, folder_id],
    queryFn: showVideoApi,
    ...options,
  });
};

export const useDeleteVideoMutation = (
  options: MutateOptions<
    UpsertVideoResponseBody,
    unknown,
    DeleteVideoRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: deleteVideoApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};
