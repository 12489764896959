import { TUserCategory } from "@/api/type";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import clsx from "clsx";

interface ElearningTabItemProps {
  data: TUserCategory;
  selected: boolean;
  onClick: () => void;
}

export default function ElearningTabItem({
  data,
  selected,
  onClick,
}: ElearningTabItemProps) {
  return (
    <div
      key={data.id}
      className={clsx(
        "elearning-tab-items select-none font-bold",
        selected && "active",
      )}
      onClick={onClick}
    >
      <CustomTooltip text={data.name} max={30} />
    </div>
  );
}
