import { Info } from "@/components/molecules/Info/Info";
import { ELearningList } from "@/components/organisms/ELearningList/e-learning-list";
import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import {
  ADMIN_ROUTES_CONSTANT,
  ELEARNING_CONTENT_TYPE_LABEL,
  PAGINATION_CONSTANT,
} from "@/configs/constants";
import Button from "@/components/atoms/Button/button";
import EditWhiteIcon from "@/assets/icon/edit-white-02.svg";
import CloseIcon from "@/assets/icon/close.svg";
import { ModalConfirm } from "@/components/molecules/ModalConfirm/modal-confirm";
import { useDisclosure } from "@chakra-ui/react";
import AnimatedRoute from "@/router/transition.route";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteUserMutation, useShowUserQuery } from "@/api/user";
import { ROLE_ENUM } from "@/configs/enum";
import useRole from "@/hooks/role";
import { handleApiError } from "@/hooks/error";
import { HttpStatusCode } from "axios";
import { delay } from "framer-motion";
import useToast from "@/hooks/toast";
import { useResetPasswordInitMutation } from "@/api/auth";
import { getRoute } from "@/shared/get/route";
import { User } from "@/api/type";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import { cutString } from "@/shared/transform";
import { useBoolean } from "usehooks-ts";
import {
  useListELearningQuery,
  useListMyCourseQuery,
  useShowELearningQuery,
} from "@/api/e-learning";
import { formatDate } from "@/shared/format";
import { InfoSkeleton } from "@/components/molecules/Info/Info.skeleton";
import { ElearinngListSkeleton } from "@/components/organisms/ELearningList/e-learning-list.skeleton";
import { useShowQuizzQuery } from "@/api/quizz";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import { getFullname } from "@/shared/get";
/**
 * Function component representing the UserDetailPage.
 *
 * @returns {JSX.Element} The rendered UserDetailPage component.
 */

function UserDetailPage(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [coursesJoined, setCoursesJoined] = useState<
    {
      id: number;
      titleName: string;
      type: string;
      description: string;
    }[]
  >([]);
  const [coursesRequired, setCoursesRequired] = useState<
    {
      id: number;
      titleName: string;
      type: string;
      description: string;
      typeJoin: string;
      isJoin: boolean;
      timeLearning: string | null;
    }[]
  >([]);
  const [totalCoursesJoined, setTotalCoursesJoined] = useState(0);
  const [totalCoursesRequired, setTotalCoursesRequired] = useState(0);
  const {
    value: hasMoreListCourseJoined,
    setValue: setHasMoreListCourseJoined,
  } = useBoolean(true);
  const {
    value: hasMoreListCourseRequired,
    setValue: setHasMoreListCourseRequired,
  } = useBoolean(true);
  const [err, setError] = useState("");
  const { id, elearning_id: elearningId, quiz_id: quizId } = useParams();
  const [isLoadingResetPasswordInit, setIsLoadingResetPasswordInit] =
    useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [userInfoState, setUserInfoState] = useState<User | null>();
  const { isAdminSystem, userInfo } = useRole(true, [
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);

  const { data: userResponse, isFetching: isUserFetching } = useShowUserQuery(
    id as unknown as number,
  );
  const { data: courseRes } = useShowELearningQuery(
    elearningId as unknown as number,
    {
      enabled: !!elearningId,
    },
  );
  const { data: quizResponse } = useShowQuizzQuery(
    quizId as unknown as number,
    {
      enabled: !!quizId,
    },
  );

  useEffect(() => {
    if (isAdminSystem) {
      setUserInfoState(userResponse);
    } else {
      setUserInfoState(userInfo);
    }
  }, [isAdminSystem, userInfo, userResponse]);

  const navigate = useNavigate();
  const { showToast } = useToast();
  const deleteMutation = useDeleteUserMutation();
  const resetPasswordInitMutation = useResetPasswordInitMutation();

  const isMyProfile = useMemo(() => {
    return Number(userInfo?.id) === Number(id);
  }, [userInfo?.id, id]);
  const fullName = useMemo(
    () => getFullname(userInfoState?.first_name, userInfoState?.last_name),
    [userInfoState],
  );

  const elearningTitle = useMemo(() => {
    return courseRes?.title;
  }, [courseRes]);

  const quizTitle = useMemo(() => {
    return quizResponse?.title;
  }, [quizResponse]);

  const setPasswordRoute = useMemo(() => {
    if (elearningId) {
      return getRoute(
        ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_DETAIL_USER_SET_PASSWORD,
        {
          elearning_id: elearningId,
          id,
        },
      );
    }
    if (quizId) {
      return getRoute(
        ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_DETAIL_USER_SET_PASSWORD,
        {
          quiz_id: quizId,
          id,
        },
      );
    }
    return getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_SET_PASSWORD, { id });
  }, [id, quizId, elearningId]);

  const breadcrumbs: BeardcrumbOption[] = useMemo(() => {
    if (elearningTitle) {
      return [
        {
          label: "Eラーニング一覧",
          route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST,
        },
        {
          label: `${elearningTitle}`,
          route: getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_DETAIL, {
            id: elearningId,
          }),
        },
        {
          label: `${fullName}`,
        },
      ];
    } else if (quizTitle) {
      return [
        {
          label: "テスト一覧",
          route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_LIST,
        },
        {
          label: `${quizTitle}`,
          route: getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_DETAIL, {
            id: quizId,
          }),
        },
        {
          label: `${fullName}`,
        },
      ];
    }
    return [
      {
        label: "ユーザー管理",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_LIST,
      },
      {
        label: `${fullName}`,
      },
    ];
  }, [fullName, elearningTitle, quizTitle, quizId, elearningId]);

  /** handle confirm delete */
  const onSubmitDelete = useCallback(async () => {
    setIsLoadingDelete(true);
    try {
      await deleteMutation.mutateAsync(Number(id));
      showToast({
        title: `${cutString(fullName)}を削除しました`,
        type: "success",
      });
      if (isMyProfile) {
        delay(() => {
          navigate(ADMIN_ROUTES_CONSTANT.LOGIN);
        }, 500);
      } else {
        delay(() => {
          let route = ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_LIST.toString();
          if (elearningId) {
            route = getRoute(
              ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_DETAIL,
              {
                id: elearningId,
              },
            );
          }
          if (quizId) {
            route = getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_DETAIL, {
              id: quizId,
            });
          }

          navigate(route);
        }, 500);
      }
    } catch (errRes: any) {
      const err = handleApiError(errRes);

      if (
        err?.[0]?.path === "id" ||
        err?.[0]?.path === "base" ||
        err?.[0]?.path === "popup"
      ) {
        onClose();
        setError(err[0].message);
      } else if (err.statusCode !== HttpStatusCode.UnprocessableEntity) {
        onClose();
        setError(err.message);
      }
    } finally {
      setIsLoadingDelete(false);
    }
  }, [
    deleteMutation,
    onClose,
    setIsLoadingDelete,
    showToast,
    elearningId,
    quizId,
  ]);

  /** handle Reset PassWord */
  const onResetPassword = useCallback(async () => {
    setIsLoadingResetPasswordInit(true);
    try {
      await resetPasswordInitMutation.mutateAsync({
        id: userInfoState?.id ?? null,
      });
      showToast({
        title: `初期登録メールを再送しました`,
        type: "success",
      });
    } catch (errRes) {
      const error = handleApiError(errRes);
      if (error?.statusCode !== HttpStatusCode.UnprocessableEntity)
        showToast({
          title: error?.message,
          type: "error",
        });
    } finally {
      setIsLoadingResetPasswordInit(false);
    }
  }, [
    resetPasswordInitMutation,
    onClose,
    setIsLoadingResetPasswordInit,
    showToast,
  ]);

  /** handle Edit User */
  const onEditUser = useCallback(() => {
    let route = getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_EDIT, {
      id,
    });
    if (elearningId) {
      route = getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_EDIT_USER, {
        elearning_id: elearningId,
        id,
      });
    }
    if (quizId) {
      route = getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_EDIT_USER, {
        id,
        quiz_id: quizId,
      });
    }
    navigate(route);
  }, [id, quizId, elearningId]);

  /** handle get course joined */
  const [pageCourseJoined, setPageCourseJoined] = useState(1);
  const { data: coursesJoinedResponse, isFetching: isCourseJoinedFetching } =
    useListMyCourseQuery({
      user_id: Number(id),
      page: pageCourseJoined,
      per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
    });

  useEffect(() => {
    if (!coursesJoinedResponse?.courses) return;
    const currentPage = coursesJoinedResponse.paging.current_page;
    const totalPage = coursesJoinedResponse.paging.total_pages;
    setTotalCoursesJoined(coursesJoinedResponse.paging.total_records);
    const list = coursesJoinedResponse.courses.map((course) => ({
      id: course.id,
      titleName: course?.title ?? "",
      type:
        course.category && course.sub_category
          ? cutString(course.category?.name, 30) +
            " / " +
            cutString(course.sub_category.name, 30)
          : cutString(course.category?.name, 30) ?? "",
      description:
        (
          course.kinds?.map((kind) => {
            return ELEARNING_CONTENT_TYPE_LABEL?.[
              kind as keyof typeof ELEARNING_CONTENT_TYPE_LABEL
            ] as string;
          }) ?? []
        ).join(" - ") ?? "",
    }));
    if (currentPage === 1) {
      setCoursesJoined(list);
    } else {
      setCoursesJoined((prev) => [...prev, ...list]);
    }
    setHasMoreListCourseJoined(totalPage > currentPage);
  }, [coursesJoinedResponse]);

  /** handle get course required */
  const [pageCourseRequired, setPageCourseRequired] = useState(1);
  const {
    data: coursesRequiredResponse,
    isFetching: isCourseRequiredFetching,
  } = useListELearningQuery({
    page: pageCourseRequired,
    per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
    user_id: Number(id),
    required: true,
    is_public: true,
  });

  useEffect(() => {
    if (!coursesRequiredResponse?.courses) return;
    const currentPage = coursesRequiredResponse.paging.current_page;
    const totalPage = coursesRequiredResponse.paging.total_pages;
    setTotalCoursesRequired(coursesRequiredResponse.paging.total_records);
    const list = coursesRequiredResponse.courses.map((course) => ({
      id: course.id,
      titleName: course?.title ?? "",
      type:
        course.category && course.sub_category
          ? cutString(course.category?.name, 30) +
            " / " +
            cutString(course.sub_category.name, 30)
          : cutString(course.category?.name, 30) ?? "",
      description:
        (
          course.course_contents?.map((i) => {
            return ELEARNING_CONTENT_TYPE_LABEL?.[
              i.kind as keyof typeof ELEARNING_CONTENT_TYPE_LABEL
            ] as string;
          }) ?? []
        ).join(" - ") ?? "",
      isJoin: course.current_user_course?.status === "done" ? true : false,
      typeJoin:
        course.current_user_course?.status === "done" ? "受講済み" : "未受講",
      timeLearning:
        course.current_user_course?.created_at &&
        course.current_user_course?.status === "done"
          ? formatDate(course.current_user_course.created_at)
          : null,
    }));
    if (currentPage === 1) {
      setCoursesRequired(list);
    } else {
      setCoursesRequired((prev) => [...prev, ...list]);
    }
    setHasMoreListCourseRequired(totalPage > currentPage);
  }, [coursesRequiredResponse]);

  const onLoadMore = (type: "course_joined" | "course_required") => {
    setTimeout(() => {
      type === "course_joined"
        ? setPageCourseJoined(pageCourseJoined + 1)
        : setPageCourseRequired(pageCourseRequired + 1);
    }, 300);
  };

  return (
    <>
      <div className="desktop:py-6 flex w-full gap-8 flex-col desktop:mx-auto mobile:py-6 px-4">
        <div className="flex gap-3 flex-col">
          <div className="w-full">
            <Breadcrumb options={breadcrumbs} />
          </div>
          <div className="flex justify-between items-center flex-wrap">
            <h4 className="font-black max-w-[70%] line-break-anywhere">
              <CustomTooltip text={fullName} max={50} />
            </h4>
            <div className="flex items-center gap-2">
              {isAdminSystem && (
                <Button
                  startSlot={<CloseIcon width={16} height={16} />}
                  className="h-8"
                  buttonCustom={{
                    padding: "4px 12px",
                    borderRadius: "7px",
                    borderColor: "var(--error-main-color)",
                    color: "var(--error-main-color)",
                    circleStyles: "var(--error-main-color)",
                  }}
                  size="xs2"
                  variant="outline"
                  onClick={onOpen}
                  isLoading={isLoadingDelete}
                >
                  削除
                </Button>
              )}
              <Button
                startSlot={<EditWhiteIcon />}
                className=""
                buttonCustom={{
                  padding: "4px 12px",
                  borderRadius: "7px",
                }}
                size="xs2"
                onClick={onEditUser}
              >
                編集
              </Button>
              {isAdminSystem &&
                !isMyProfile &&
                userInfoState?.password_unchanged_flag && (
                  <Button
                    className=""
                    buttonCustom={{
                      padding: "4px 12px",
                      borderRadius: "7px",
                    }}
                    size="xs2"
                    variant="outline"
                    onClick={onResetPassword}
                    isLoading={isLoadingResetPasswordInit}
                  >
                    初期PW再送
                  </Button>
                )}
            </div>
          </div>
        </div>
        {isUserFetching ? (
          <InfoSkeleton isAdmin={true} />
        ) : (
          <Info
            data={userInfoState}
            isAdmin={true}
            setPasswordRoute={setPasswordRoute}
          />
        )}
        {isCourseJoinedFetching ? (
          <ElearinngListSkeleton title="Eラーニング受講履歴" />
        ) : (
          <ELearningList
            data={coursesJoined}
            title="Eラーニング受講履歴"
            idScroll={"courseJoinedScrollDiv"}
            scrollableTarget="courseJoinedScrollDiv"
            height={totalCoursesJoined > 10 ? `860px` : "auto"}
            hasMore={hasMoreListCourseJoined}
            onLoadMore={() => onLoadMore("course_joined")}
            allowGoDetailByClick={false}
          />
        )}

        {isCourseRequiredFetching ? (
          <ElearinngListSkeleton title="必須受講の状況" />
        ) : (
          <ELearningList
            data={coursesRequired}
            idScroll={"courseRequiredScrollDiv"}
            scrollableTarget="courseRequiredScrollDiv"
            title="必須受講の状況"
            height={totalCoursesRequired > 10 ? `860px` : "auto"}
            hasMore={hasMoreListCourseRequired}
            onLoadMore={() => onLoadMore("course_required")}
            allowGoDetailByClick={false}
          />
        )}
      </div>
      <ModalConfirm
        isOpen={isOpen}
        isLoadingSubmit={isLoadingDelete}
        onClose={() => {
          onClose(), setError("");
        }}
        header={`${fullName}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">{fullName}を削除します。</p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onSubmit={onSubmitDelete}
      />

      <ModalError
        message={
          err.length > 0 && (
            <p className="text-error--main input-error__message mb-1 body1">
              {err.replace(/<br\s*\/?>/, "\n")}
            </p>
          )
        }
        isOpen={!!err.length}
        onClose={() => {
          setError("");
        }}
      />
    </>
  );
}
const AnimatedUserDetailPage = AnimatedRoute(UserDetailPage);
export default AnimatedUserDetailPage;
