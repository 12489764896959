import dayjs from "dayjs";

/**
 * Formats a date string according to the specified format.
 * If no date is provided, an empty string is returned.
 * @param date - The date string to format.
 * @param format - The desired format for the date string. Default is "YYYY/MM/DD".
 * @returns The formatted date string.
 */
const formatDate = (date?: string | null, format = "YYYY/MM/DD"): string => {
  if (!date) {
    return "";
  }

  const day = dayjs(date);
  return day.format(format);
};

type ErrorIndexObject = {
  [key: string]: {
    [key: string]: string[];
  };
};

const formatError = (errObj: ErrorIndexObject) => {
  return Object.keys(errObj).map((item) => {
    return {
      index: Number(item),
      child_key: Object.keys(errObj?.[Number(item)])?.[0],
      message: Object.values(errObj?.[Number(item)])?.[0]?.[0]?.replace(
        /<br\s*\/?>/gm,
        "\n",
      ) as string,
    };
  });
};

/**
 * Formats the given time into a human-readable format.
 *
 * @param {number} time - The time to be formatted in seconds.
 * @return {string} The formatted time string in the format: "X時間X分X秒".
 */
const formatTimeRecord = (time: number): string => {
  if (time < 60) {
    return `0分${Math.floor(time)}秒`;
  }
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  return `${hours ? `${hours}時間` : ""}${minutes}分${seconds}秒`;
};

const latinConvertRE = {
  full: /[！-～]/g,
  delta: 0xfee0,
};

const formatNumberToHalfWidth = (str: string) => {
  const { full, delta } = latinConvertRE;
  const d = -delta;
  return str.replace(full, (c) => String.fromCharCode(c.charCodeAt(0) + d));
};

export { formatDate, formatError, formatTimeRecord, formatNumberToHalfWidth };
export type { ErrorIndexObject };
