import AuthLayout from "@/pages/_layouts/auth";
import { useMemo } from "react";

export default function ResetPasswordSuccessPage() {
  const imgUrl = new URL("@/assets/fly.png", import.meta.url).href;
  const email = useMemo(() => {
    const history = window.history;
    if (!history || !history.state?.usr?.email) return "";
    return history.state.usr.email;
  }, []);

  return (
    <AuthLayout>
      <div className="py-[80px] flex w-full items-center flex-col px-[20px]">
        <h4 className="font-[900] text-center">
          パスワード再設定メールを送信しました
        </h4>
        <div className="flex flex-col justify-center p-8 whitespace-nowrap bg-white rounded-3xl w-full max-w-[572px] max-md:px-5 mt-[48px]">
          <div className="flex justify-center">
            <img src={imgUrl} width={146} height={99} />
          </div>
          <p className="body1 mt-[20px] text-[#212B36] whitespace-pre-wrap">
            パスワード再設定用のメールを（{email}）宛にお送りいたしました。
            ご確認の上、メールに記載のあるURLからパスワードを再設定してください。
          </p>
          <div className="w-full h-[1px] bg-[var(--border-primary)] my-[20px]"></div>
          <p className="body2 text-secondary-custom  whitespace-pre-wrap">
            ※しばらく経ってもメールが届かない場合は以下をご確認ください <br />
            ・メールアドレスに間違いがないか <br />
            ・受信拒否設定をしていないか <br />
            ・迷惑メールフォルダに入っていないか <br />
          </p>
        </div>
      </div>
    </AuthLayout>
  );
}
