import { QueryFunctionContext } from "@tanstack/react-query";
import { Department, OrderDirection, Paging } from "../type";
import { request } from "../axios";
import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";
import { omit } from "lodash";

export type FilterDepartmentRequestBody = {
  page: number;
  per_page: number;
  order_by?: string | null;
  order_direction?: OrderDirection;
};

export type FilterDepartmentResponseBody = {
  departments: Department[];
  paging: Paging;
};

export const listDepartmentApi = (
  context: QueryFunctionContext,
): Promise<FilterDepartmentResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_DEPARTMENT),
    params: context.queryKey[1],
  });
};

export type UpsertDepartmentRequestBody = {
  id?: number;
  name: string;
};

export type UpsertDepartmentResponseBody = {
  id: number;
};

export const upsertDepartmentApi = (
  data: UpsertDepartmentRequestBody,
): Promise<UpsertDepartmentResponseBody> => {
  return request({
    method: data.id ? "PATCH" : "POST",
    url: data.id
      ? getRoute(API_PATH_CONSTANT.UPDATE_DEPARTMENT, { id: data.id })
      : API_PATH_CONSTANT.CREATE_DEPARTMENT,
    data: {
      departments: omit(data, "id"),
    },
  });
};

export type DeleteDepartmentResponseBody = {
  id: string;
};
export const deleteDepartmentApi = (
  id: number,
): Promise<DeleteDepartmentResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.DELETE_DEPARTMENT, { id }),
  });
};
