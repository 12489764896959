import { Skeleton } from "@chakra-ui/react";

export default function ComplianceTrainingContentSkeleton() {
  return (
    <div className="shadow-card rounded-[20px] p-5 w-full min-h-[640px]">
      <Skeleton height="40px" className="rounded-[10px] mb-[50px]" />
      <Skeleton height="35px" className="rounded-[10px] w-[350px] mb-4" />
      <Skeleton height="35px" className="rounded-[10px] w-[300px] mb-4" />
      <Skeleton height="35px" className="rounded-[10px] mb-4" />

      <Skeleton height="266px" width="400px" className="rounded-[10px] mb-4" />
      <Skeleton height="35px" className="rounded-[10px w-[300px]" />
    </div>
  );
}
