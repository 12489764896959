import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";

import Button from "@/components/atoms/Button/button";
import AnimatedRoute from "@/router/transition.route";
import PlusIcon from "@/assets/icon/plus.svg";
import FolderList from "@/components/organisms/FolderManagement/folder-list";
import { ModalAddFolder } from "@/components/organisms/FolderManagement/modal-add-folder";
import { OrderDirection, TVideoList } from "@/api/type";
import { PAGINATION_CONSTANT, ROUTES_CONSTANT } from "@/configs/constants";
import { useListFolderQuery } from "@/api/folder";

import { FolderItem } from "@/api/folder/request";
import { useListVideoQuery } from "@/api/video";
import { isNumeric } from "@/shared/get/check";
import { SuggestionSearch } from "@/components/molecules/SuggestionSearch/suggestion-search";
import { isString } from "lodash";

const FrontContentVideoListPage = () => {
  // #region Data
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [folderList, setFolderList] = useState<FolderItem[]>([]);
  const [videoList, setVideoList] = useState<TVideoList[]>([]);
  const [searchFolderForm, setSearchFolderForm] = useState({
    page: 1,
    per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
    order_by: "updated_at",
    order_direction: "desc" as OrderDirection,
  });
  const [searchVideoForm, setSearchVideoForm] = useState({
    page: 1,
    per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
    order_by: "updated_at",
    order_direction: "desc" as OrderDirection,
    folder_id: null as string | null,
    title: "",
  });
  const {
    data: folderResponse,
    refetch: refreshFolderList,
    isFetching: isFetchingFolder,
  } = useListFolderQuery({
    page: searchFolderForm.page,
    per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
    order_by: searchFolderForm.order_by,
    order_direction: searchFolderForm.order_direction,
    kind: "video",
  });

  const {
    data: videoResponse,
    refetch: refreshDocumentList,
    isFetching: isFetchingFile,
  } = useListVideoQuery(
    {
      page: searchVideoForm.page,
      per_page:
        searchVideoForm.page === 1 ? 20 : PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
      order_by: searchVideoForm.order_by,
      order_direction: searchVideoForm.order_direction,
      folder_id: searchVideoForm.folder_id
        ? Number(searchVideoForm.folder_id)
        : null,
      title: searchVideoForm.title,
    },
    {
      enabled:
        Boolean(searchVideoForm.title) || Boolean(searchVideoForm.folder_id),
    },
  );

  const totalFolder = useMemo(() => {
    return folderResponse?.paging.total_records ?? 0;
  }, [folderResponse]);

  const totalFile = useMemo(() => {
    return 0;
  }, [folderResponse]);

  const {
    isOpen: isOpenModalAdd,
    onOpen: openModalAdd,
    onClose: closeModalAdd,
  } = useDisclosure();

  const [dataModalUpsert] = useState<FolderItem>({
    id: 0,
    childs: [],
    name: "",
    kind: "video",
    parent_id: null,
  });

  // #endregion

  // #region Function

  const refreshSearch = useCallback(() => {
    if (searchFolderForm.page != PAGINATION_CONSTANT.DEFAULT_PAGE) {
      setSearchFolderForm((prev) => ({ ...prev, page: 1 }));
    } else {
      refreshFolderList();
    }
  }, [searchFolderForm.page]);

  const handleRefreshDocumentList = useCallback(() => {
    if (searchFolderForm.page != PAGINATION_CONSTANT.DEFAULT_PAGE) {
      setSearchVideoForm((prev) => ({ ...prev, page: 1 }));
    } else {
      refreshDocumentList();
    }
  }, [searchVideoForm.page]);

  // #endregion

  // #region Events
  const onNavigate = () => {
    navigate(ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST);
  };

  const onNextPage = useCallback(
    (kind: "document" | "video" | "folder") => {
      if (kind === "folder") {
        setTimeout(() => {
          setSearchFolderForm((prev) => ({
            ...prev,
            page: Number(prev.page) + 1,
          }));
        }, 500);
      }
      if (kind === "video") {
        setTimeout(() => {
          setSearchVideoForm((prev) => ({
            ...prev,
            page: Number(prev.page) + 1,
          }));
        }, 500);
      }
    },
    [isFetchingFolder],
  );
  // #endregion

  useEffect(() => {
    if (!videoResponse) return;
    if (videoResponse.paging.current_page === 1) {
      setVideoList(videoResponse.videos);
    } else {
      setVideoList((prev) => [...prev, ...videoResponse.videos]);
    }
  }, [videoResponse]);

  useEffect(() => {
    if (!folderResponse) return;
    if (folderResponse.paging.current_page === 1) {
      const folderIdFromSearchParams = searchParams.get("id");
      setSearchParams(
        `?id=${
          folderIdFromSearchParams ?? folderResponse.folders?.[0]?.id ?? ""
        }`,
      );
      setFolderList(folderResponse.folders);
    } else {
      setFolderList((prev) => [...prev, ...folderResponse.folders]);
    }
  }, [folderResponse]);

  useEffect(() => {
    setSearchVideoForm((prev) => ({
      ...prev,
      folder_id: !isNumeric(searchParams.get("id"))
        ? null
        : searchParams.get("id"),
      title: "",
    }));
  }, [searchParams]);

  return (
    <div className="content-page desktop:py-12 flex max-w-[960px] w-full mobile:gap-6 desktop:gap-8 flex-col desktop:mx-auto mobile:py-6 mobile:px-4 desktop:px-0">
      <div className="flex gap-y-3 mobile:flex-col desktop:flex-row mobile:justify-start desktop:justify-between mobile:items-start desktop:items-center">
        <h4 className="font-black">コンテンツ</h4>
        <SuggestionSearch
          oldValue={searchVideoForm.title}
          model="videos"
          labelKey={"title"}
          width="320px"
          onSearch={(data) => {
            let folder_id: string | null = null;
            if (!data) {
              folder_id = folderList.length ? String(folderList[0].id) : "";
              setSearchParams(`?id=${folder_id}`);
            }

            setSearchVideoForm((prev) => ({
              ...prev,
              folder_id: folder_id,
              title: isString(data) ? data : data?.title ?? "",
            }));
          }}
          isAdminPage={false}
        />
      </div>
      <div className="flex justify-between">
        <div className="elearning-tab flex gap-x-5">
          <div
            className={"elearning-tab-items select-none font-bold"}
            onClickCapture={onNavigate}
          >
            ドキュメント
          </div>
          <div className={"elearning-tab-items select-none font-bold active"}>
            動画
          </div>
        </div>
        <Button
          size="sm"
          buttonCustom={{
            borderRadius: "6px",
            padding: "8px 14px",
          }}
          startSlot={<PlusIcon />}
          onClick={openModalAdd}
        >
          フォルダ作成
        </Button>
      </div>

      <FolderList
        isAdmin={false}
        kind="video"
        folderItems={folderList}
        fileItems={videoList}
        refreshSearch={refreshSearch}
        refreshFileList={handleRefreshDocumentList}
        isFetchingFolder={searchFolderForm.page === 1 && isFetchingFolder}
        isFetchingFile={searchVideoForm.page === 1 && isFetchingFile}
        onNextPage={onNextPage}
        totalFolder={totalFolder}
        totalFile={totalFile}
        hasMoreFolder={folderList.length < totalFolder}
        hasMoreFile={videoList.length < totalFile}
      />

      <ModalAddFolder
        isOpen={isOpenModalAdd}
        onClose={closeModalAdd}
        data={dataModalUpsert}
        onSuccess={() => {
          refreshSearch();
          closeModalAdd();
        }}
      />
    </div>
  );
};

const AnimatedListVideoPage = AnimatedRoute(FrontContentVideoListPage);
export default AnimatedListVideoPage;
