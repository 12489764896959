import AuthLayout from "@/pages/_layouts/auth";
import ArrowLeftIcon from "@/assets/icon/arrow-left-02.svg";
import FormResetPassword from "@/components/organisms/FormResetPassword";
import { useNavigate } from "react-router-dom";
import useScreenSize from "@/hooks/screen";

export default function FrontResetPasswordPage() {
  const { isMobile } = useScreenSize();

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <AuthLayout>
      <div className="mobile:py-[48px] desktop:py-[80px] flex w-full items-center flex-col mobile:px-4 desktop:px-5">
        <div className="flex flex-col md:flex-row gap-y-4 sm:gap-y-0 mb-[16px] justify-center w-full relative">
          <div
            className={"cursor-pointer md:absolute relative top-0 left-0"}
            onClickCapture={goBack}
          >
            <ArrowLeftIcon />
          </div>
          {isMobile ? (
            <h5 className="font-[900] text-center">
              パスワードをお忘れですか？
            </h5>
          ) : (
            <h4 className="font-[900] text-center">
              パスワードをお忘れですか？
            </h4>
          )}
        </div>

        <div className="mobile:text-xs desktop:text-sm text-center font-[400]">
          パスワードをリセットします。
          <br />
          ご登録済みのメールアドレスを入力してください。
        </div>
        <div className="mt-[48px] w-full flex justify-center">
          <FormResetPassword />
        </div>
      </div>
    </AuthLayout>
  );
}
