import {
  Switch as CRSwitch,
  type SwitchProps as CRSwitchProps,
} from "@chakra-ui/react";

interface SwitchProps extends CRSwitchProps {}

export default function Switch({ ...props }: SwitchProps) {
  return (
    <div className="custom-switch inline-block">
      <CRSwitch {...props} />
    </div>
  );
}
