import { ShowUserCoursesResponseBody } from "@/api/e-learning/request";
import Button from "@/components/atoms/Button/button";
import { ModalCommon } from "@/components/atoms/Modal/modal";
import { useMemo } from "react";
import { ExamResult } from "./exam-result";

interface ExamResultModalProps {
  isOpen: boolean;
  oldCourseContents: ShowUserCoursesResponseBody["user_course_contents"];
  onClose: () => void;
}

export const ExamResultModal = (props: ExamResultModalProps) => {
  const { isOpen, onClose, oldCourseContents } = props;

  const header = "テスト結果";

  const body = useMemo(() => {
    return (
      <div className="flex flex-col w-full gap-4">
        {oldCourseContents?.map((content) => (
          <div className="bg-gray-200 rounded-lg p-2" key={content.id}>
            <h6 className="font-bold mb-2">{content.title}</h6>
            <div className="px-4 py-1">
              <ExamResult
                questions={content.user_quiz_question}
                key={content.id}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }, [oldCourseContents]);

  return (
    <ModalCommon
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      size="4xl"
      body={body}
      blockScrollOnMount={true}
      scrollBehavior="inside"
      footer={
        <div className="flex gap-3">
          <Button
            className="h-10"
            buttonCustom={{
              padding: "8px 16px",
              borderRadius: "8px",
              borderColor: "#E6E8EA",
              color: "#212B36",
              circleStyles: "#212B36",
            }}
            size="sm"
            variant="outline"
            onClick={onClose}
          >
            閉じる
          </Button>
        </div>
      }
    />
  );
};
