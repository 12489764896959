// import { DragDealer } from "@/components/organisms/ElearningTab/drag-dealer";
import clsx from "clsx";
import { useEffect, useRef } from "react";

/** Properties for the ELearningItem component*/
interface StepperProps {
  listStep: StepperItem[];
  activeIndex: number;
  onChange?: (index: number) => void;
  className?: string;
}
export interface StepperItem {
  label: string;
  isDisabled?: boolean;
}
/**
 * Renders a stepper component with the given configuration.
 *
 * @param {number} activeStep - The index of the active step.
 * @param {Array<string>} steps - An array of step labels.
 * @param {Function} onNext - Callback function to handle next step.
 * @param {Function} onBack - Callback function to handle previous step.
 * @param {Function} onFinish - Callback function to handle finish step.
 * @returns {JSX.Element} - The stepper component.
 */
export const Stepper = (prop: StepperProps): JSX.Element => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const onChange = (index: number) => {
    prop.onChange?.(index);
  };

  const scrollToIndex = (index: number) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = index * 145;
    }
  };

  useEffect(() => {
    scrollToIndex(prop.activeIndex);
  }, [prop.activeIndex]);

  return (
    <div
      ref={scrollContainerRef}
      className={clsx(
        "flex justify-between overflow-x-auto custom-scrollbar",
        prop.className,
      )}
    >
      {prop?.listStep?.map((item: StepperItem, index) => (
        <div
          key={index}
          className={clsx(
            "flex",
            index !== prop.listStep.length - 1 && "w-full",
          )}
          id={`stepper_item_${index}`}
        >
          <div className="flex flex-col gap-2 justify-start items-center min-w-[145px]">
            <button
              className={clsx(
                "flex items-center justify-center rounded-[50px] text-sm leading-6 font-normal text-white w-6 h-6 select-none",
                prop.activeIndex >= index
                  ? "bg-primary--main"
                  : "bg-[var(--text-disabled)]",
                item.isDisabled && "cursor-default",
              )}
              disabled={item.isDisabled}
              onClickCapture={() => onChange(index)}
            >
              {index + 1}
            </button>
            <div
              className={clsx(
                "text-sm leading-6 font-bold select-none",
                prop.activeIndex >= index
                  ? "text-black-custom"
                  : "text-disabled",
              )}
            >
              {item.label}
            </div>
          </div>
          {index !== prop.listStep.length - 1 && (
            <div
              className={clsx(
                "my-3 h-[1px] w-full min-w-10 mobile:hidden desktop:block",
                prop.activeIndex - 1 >= index
                  ? "bg-primary--main"
                  : "bg-[#919EAB3D]",
              )}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};
