import PencilIcon from "@/assets/icon/edit-02.svg";
import Button from "@/components/atoms/Button/button";
import PlusIcon from "@/assets/icon/plus-02.svg";
import CircleCloseIcon from "@/assets/icon/circle-close.svg";
import { useCallback, useEffect, useState } from "react";
import { ControlledInput } from "../ControlledInput/controlled-input";
import { ITagSettingForm, useTagSettingForm } from "./validation";
import { MAX_LENGTH } from "@/configs/constants";
import { useFieldArray } from "react-hook-form";
import { useUpdateTagsMutation } from "@/api/tag";
import {
  handleApiError,
  mapAPIError,
  useScrollToFirstError,
} from "@/hooks/error";
import { useGlobalStore } from "@/states/global.state";
import { ModalError } from "../ModalError/modal-error";
import { useDisclosure } from "@chakra-ui/react";
import Spinner from "@/components/atoms/Spinner/spinner";
import useToast from "@/hooks/toast";

interface TagSettingProps {
  tags: ITagSettingForm;
  onChange: (isEdit?: boolean) => void;
  isFetching: boolean;
}
const MAX_CHILD_ITEM = 99999;
export default function TagSetting({
  tags,
  isFetching,
  onChange,
}: TagSettingProps) {
  const { control, setValue, handleSubmit, reset, watch, formState, setError } =
    useTagSettingForm();
  const { showToast } = useToast();
  const { errors } = formState;
  useScrollToFirstError(formState);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "tags",
  });
  const watchField = watch();

  const updateTagsMutation = useUpdateTagsMutation();
  const { loading, setLoading } = useGlobalStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorApi, setErrorApi] = useState<string>("");

  useEffect(() => {
    reset(tags);
  }, [tags]);

  const handleChangeMode = (type: "edit" | "readonly") => {
    setValue("isEdit", type === "edit");
    onChange(type === "edit");
  };

  const onSubmit = async (values: ITagSettingForm) => {
    try {
      setLoading(true);
      await updateTagsMutation.mutateAsync({
        tags: values.tags,
      });
      showToast({
        title: `変更を保存しました`,
        type: "success",
      });
      setValue("isEdit", false);
      onChange(false);
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError, (e) => {
        const path = e.path;
        if (
          e.path === "sub_categories.name" ||
          e.path === "base" ||
          e.path === "id"
        ) {
          onOpen();
          setErrorApi(e.message);
        } else if (e.path === "popup") {
          onOpen();
          setErrorApi(e.message);
        } else {
          setError(path, {
            message: e.message,
          });
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const onCloseModalError = () => {
    onClose();
    setErrorApi("");
    onChange(true);
  };

  const onCancel = useCallback(() => {
    reset(tags);
    handleChangeMode("readonly");
  }, [tags]);

  const removeTag = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const addNew = useCallback(() => {
    append({
      name: "",
    });
  }, [append]);

  return (
    <div>
      <div className="rounded-[20px] shadow-card bg-white">
        <div className="px-6 py-5 flex justify-between gap-x-4 w-full items-center [border-bottom:1px_solid_#E6E8EA]">
          <div className="flex items-center gap-2 flex-1">
            <Button
              variant="outline"
              buttonCustom={{
                padding: "6px 8px",
                borderRadius: "8px",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "16px",
                borderColor: "#E6E8EA",
              }}
              startSlot={<PlusIcon width={20} height={20} />}
              className={watchField.isEdit ? "hover:border-primary" : ""}
              isDisabled={
                !watchField.isEdit || watchField.tags.length >= MAX_CHILD_ITEM
              }
              onClick={addNew}
            >
              タグを追加する
            </Button>
          </div>
          {watchField.isEdit ? (
            <></>
          ) : (
            <PencilIcon
              className="cursor-pointer edit-tag"
              onClickCapture={() => handleChangeMode("edit")}
            />
          )}
        </div>
        <div className={`px-6 pt-3 pb-6`}>
          <div
            className={`${
              watchField.isEdit ? "gap-y-4" : "gap-[10px]"
            } flex flex-col`}
          >
            {isFetching ? (
              <div className="flex justify-center">
                <Spinner
                  circleStyles={{
                    borderColor: `var(--primary-main-color) transparent transparent transparent`,
                  }}
                />
              </div>
            ) : (
              <>
                {watchField.isEdit ? (
                  <>
                    {fields?.map((tag, index) => (
                      <div className="flex gap-x-4 w-full" key={tag.id}>
                        <div className="flex items-center gap-x-3 w-full">
                          <span className="ml-1 w-2 h-2 rounded-full bg-primary--main"></span>
                          <ControlledInput
                            control={control}
                            formField={`tags.${index}.name`}
                            className="w-full"
                            placeholder="タグを入力してください"
                            maxLength={MAX_LENGTH.VARCHAR}
                            errorMessage={errors.tags?.[index]?.name?.message}
                            isTrim={true}
                          />
                        </div>
                        <CircleCloseIcon
                          className="cursor-pointer mt-3"
                          onClickCapture={() => removeTag(index)}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {watchField.tags.map((item) => (
                      <div key={item.id}>
                        <div
                          className="flex rounded-sm gap-x-2 w-full cursor-pointer items-center"
                          key={item.id}
                        >
                          <div className="flex h-6 w-6 items-center justify-center">
                            <span className="ml-1 w-2 h-2 rounded-full bg-primary--main"></span>
                          </div>
                          <p className="body1 mb-0">{item.name}</p>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {watchField.isEdit ? (
        <div className="flex gap-x-4 justify-end mt-[36px]">
          <Button
            variant="info"
            size="sm"
            className="min-w-[128px] w-fit"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="min-w-[128px] w-fit"
            onClick={handleSubmit(onSubmit)}
            isLoading={loading.open}
          >
            保存
          </Button>
        </div>
      ) : (
        <></>
      )}
      <ModalError
        isOpen={isOpen}
        message={
          <p className="w-full text-error--main input-error__message">
            {errorApi.replace(/<br\s*\/?>/, "\n")}
          </p>
        }
        onClose={onCloseModalError}
      />
    </div>
  );
}
