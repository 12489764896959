import { MAX_LENGTH } from "@/configs/constants";
import yup from "@/shared/validation/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

export interface ICreateComplianceTrainingForm {
  title: string;
  content: string | null;
  is_public: number | null;
  attachment_attributes: {
    _destroy?: boolean;
    key: string;
    name: string;
    content_type: string;
  } | null;
  content_not_null: string | null | undefined;
}

export const useCreateComplianceTrainingForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        title: yup
          .string()
          .label("admin.complicate.create.title")
          .max(MAX_LENGTH.VARCHAR)
          .noEmoji()
          .required(),
        is_public: yup
          .number()
          .label("admin.complicate.create.is_public")
          .oneOf([0, 1])
          .required(),
        content: yup
          .string()
          .label("admin.complicate.create.content")
          .max(MAX_LENGTH.TEXT)
          .nullable(),
        attachment_attributes: yup
          .object()
          .shape({
            id: yup.string().nullable(),
            _destroy: yup.boolean().nullable(),
            key: yup.string().nullable(),
            name: yup.string().nullable(),
            content_type: yup.string().nullable(),
          })
          .default(null)
          .nullable(),
        content_not_null: yup
          .string()
          .when(["content", "attachment_attributes"], {
            is: (value1: string, value2: any) => {
              const isAttachmentNull =
                value2 == null || !value2.name || value2._destroy;
              return !value1 && isAttachmentNull;
            },
            then: (schema) => schema.required("コンテンツを入力してください。"),
            otherwise: (schema) => schema.nullable(),
          }),
      }),
    [],
  );

  return useForm<ICreateComplianceTrainingForm>({
    resolver: yupResolver(validationScheme) as any,
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      is_public: 1,
    },
  });
};
