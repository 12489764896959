import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { ROLE_ENUM } from "@/configs/enum";
import { useShowComplianceQuery } from "@/api/compliance";
import AnimatedRoute from "@/router/transition.route";
import CreateComplianceTrainingForm from "@/components/organisms/ComplianceTrainingManagement/create";
import CreateSkeleton from "@/components/organisms/ComplianceTrainingManagement/create.skeleton";
import useRole from "@/hooks/role";

function EditComplianceTrainingPage(): JSX.Element {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);

  // #region Data
  const params = useParams();
  const id = parseInt(params.id as string);

  const navigate = useNavigate();

  const { data: complianceInfo, isFetching } = useShowComplianceQuery(id || 0, {
    enabled: !!id,
    // * If complicate not exist then return to complicate list
    throwOnError() {
      navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.COMPLIANCE_TRAINING_LIST);
      return false;
    },
  });

  const breadcrumbs: BeardcrumbOption[] = useMemo(
    () => [
      {
        label: "コンプライアンス研修",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.COMPLIANCE_TRAINING,
      },
      {
        label: `「${complianceInfo?.title ?? ""}」の編集`,
      },
    ],
    [complianceInfo],
  );
  // #endregion

  return (
    <div className="max-w-[100%] m-auto my-6">
      <Breadcrumb options={breadcrumbs} />
      <h4 className="mt-3 font-black">編集</h4>
      <div className="mt-8">
        <h6 className="font-black mb-3">基本情報</h6>
        {isFetching ? (
          <CreateSkeleton />
        ) : (
          <CreateComplianceTrainingForm
            id={id}
            complianceInfo={complianceInfo}
          />
        )}
      </div>
    </div>
  );
}
const AnimatedEditComplianceTrainingPage = AnimatedRoute(
  EditComplianceTrainingPage,
);
export default AnimatedEditComplianceTrainingPage;
