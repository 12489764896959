import Button from "@/components/atoms/Button/button";
import AuthLayout from "@/pages/_layouts/auth";
import { useCallback } from "react";
import { ROUTES_CONSTANT } from "@/configs/constants";
import { useNavigate } from "react-router-dom";
export default function SetPasswordFailPage() {
  const navigate = useNavigate();

  const goToForgotPassword = useCallback(() => {
    navigate(ROUTES_CONSTANT.FORGOT);
  }, []);
  return (
    <AuthLayout>
      <div className="py-[80px] flex w-full items-center flex-col px-[20px]">
        <h4 className="font-[900] text-center">URLの有効期限が切れています</h4>
        <div className="flex flex-col justify-center p-8 whitespace-nowrap bg-white rounded-3xl w-full max-w-[572px] max-md:px-5 mt-[48px]">
          <div className="flex flex-col justify-center max-md:max-w-full">
            <div className="flex justify-center items-center px-16 max-md:px-5 max-md:max-w-full">
              <div className="flex justify-center items-center px-5 bg-[var(--error-main-color)] h-[88px] rounded-[44px] w-[88px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="100"
                  height="100"
                  viewBox="0,0,256,256"
                >
                  <g
                    fill="#fff"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeDasharray=""
                    strokeDashoffset="0"
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                    style={{
                      mixBlendMode: "normal",
                    }}
                  >
                    <g transform="scale(3.55556,3.55556)">
                      <path d="M19,15c-1.023,0 -2.04812,0.39087 -2.82812,1.17188c-1.562,1.562 -1.562,4.09425 0,5.65625l14.17188,14.17188l-14.17187,14.17188c-1.562,1.562 -1.562,4.09425 0,5.65625c0.78,0.78 1.80513,1.17188 2.82813,1.17188c1.023,0 2.04812,-0.39088 2.82813,-1.17187l14.17188,-14.17187l14.17188,14.17188c1.56,1.562 4.09525,1.562 5.65625,0c1.563,-1.563 1.563,-4.09325 0,-5.65625l-14.17187,-14.17187l14.17188,-14.17187c1.562,-1.562 1.562,-4.09425 0,-5.65625c-1.56,-1.561 -4.09625,-1.562 -5.65625,0l-14.17187,14.17188l-14.17187,-14.17187c-0.78,-0.78 -1.80513,-1.17187 -2.82812,-1.17187z"></path>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <p className="body1 mt-5 text-black-custom text-center">
              URLの有効期限が期限切れとなっています。
              <br />
              お手数ですが、再度やり直してください。
            </p>
            <div className="w-full h-[1px] bg-[var(--border-primary)] my-5"></div>
            <Button
              size="sm"
              className={"max-w-[335px] w-full m-auto"}
              type="button"
              onClick={goToForgotPassword}
            >
              パスワード再設定ページへ
            </Button>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
