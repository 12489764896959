import { QUESTION_KIND_ENUM } from "@/configs/enum";

import QuizzFreeInput from "./quizz-free-input";
import QuizzSingleAnswer from "./quizz-single-answer";
import QuizzMultiAnswer from "./quizz-multi-answer";

/**
 * Interface: QuizzItemProps
 *
 * Represents the props for the QuizzItem component.
 *
 * @property questionNumber - The number of the question.
 * @property title - The title of the question.
 * @property answers - The list of answers.
 * @property kind - The kind of the question.
 */
interface QuizzItemProps {
  questionNumber: number;

  title: string;

  answers: any;

  kind: QUESTION_KIND_ENUM;
}

export default function QuizzItem({
  questionNumber,
  title,
  kind,
  answers,
}: QuizzItemProps) {
  let Component = null;

  switch (kind) {
    case QUESTION_KIND_ENUM.MULTI_ANSWER:
      Component = QuizzMultiAnswer;
      break;

    case QUESTION_KIND_ENUM.SINGLE_ANSWER:
      Component = QuizzSingleAnswer;
      break;

    case QUESTION_KIND_ENUM.FREE_INPUT:
      Component = QuizzFreeInput;
      break;
  }

  return (
    <div className="flex flex-col gap-4">
      <h6>{`Q.${questionNumber} ${title}`}</h6>
      <div>
        <Component questionNumber={questionNumber} answers={answers} />
      </div>
    </div>
  );
}
