import VideoView from "@/components/molecules/VideoView/video-view";
import { getLinkYoutube } from "@/shared/get";
import { useMemo, useRef } from "react";

interface ElearningVideoContentProps {
  attachment?: {
    youtube_id: string | null;
    youtube_file_name: string | null;
  } | null;
  videoAttachment?: {
    youtube_id: string | null;
    youtube_file_name: string | null;
  } | null;
  handleWatchFullVideo?: () => void;
}

export default function ElearningVideoContent({
  attachment,
  videoAttachment,
  handleWatchFullVideo,
}: ElearningVideoContentProps) {
  const playerRef = useRef<any>(null);
  const videoJsOptions = useMemo(() => {
    const youtubeId = attachment?.youtube_id ?? videoAttachment?.youtube_id;
    if (youtubeId) {
      return {
        autoplay: false,
        fluid: true,
        aspectRatio: "16:9",
        controls: true,
        disablePictureInPicture: false,
        sources: [
          {
            src: getLinkYoutube(youtubeId),
            type: "video/youtube",
          },
        ],

        controlBar: {
          volumePanel: {
            inline: false,
          },
          pictureInPictureToggle: false,
        },
      };
    }

    return {};
  }, [attachment, videoAttachment]);

  const onReady = (player: any) => {
    playerRef.current = player;
    player.on("ended", () => {
      handleWatchFullVideo?.();
    });
  };

  return attachment?.youtube_id || videoAttachment?.youtube_id ? (
    <VideoView options={videoJsOptions} onReady={onReady} />
  ) : (
    <div className="h-[682px]"></div>
  );
}
