import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import Button from "@/components/atoms/Button/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ADMIN_ROUTES_CONSTANT,
  MAX_LENGTH,
  ROUTES_CONSTANT,
} from "@/configs/constants";
import { useSetPassForm, ISetPassForm } from "./validation";
import LockIcon from "@/assets/icon/lock.svg";
import EyeCloseIcon from "@/assets/icon/eye-close.svg";
import EyeOpenIcon from "@/assets/icon/eye-open.svg";
import { useEffect, useState } from "react";
import { useGlobalStore } from "@/states/global.state";
import {
  useVerifyTokenResetPasswordMutation,
  useVerifyResetPasswordMutation,
} from "@/api/auth";
import { handleApiError, mapAPIError } from "@/hooks/error";
import { HttpStatusCode } from "axios";

/**
 * Represents the props interface for the FormSetInformationFirstLogin component.
 * @interface FormSetPasswordProps
 * @property {boolean} [isAdminPage] - Indicates whether the component is rendered on an admin page.
 */
interface FormSetPasswordProps {
  isAdminPage?: boolean;
}
/**
 * This component renders a form for setting a new password.
 */
export default function FormSetPassword({
  isAdminPage = false,
}: FormSetPasswordProps) {
  const navigate = useNavigate();
  const verifyTokenResetPasswordMutation =
    useVerifyTokenResetPasswordMutation();
  const verifyResetPasswordMutation = useVerifyResetPasswordMutation();
  const [typeInput, setTypeInput] = useState<"password" | "text">("password");
  const [typeInput2, setTypeInput2] = useState<"password" | "text">("password");
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get("token");
  const { loading, setLoading } = useGlobalStore();
  useEffect(() => {
    (async () => {
      const handleNavigate = () => {
        navigate(
          isAdminPage
            ? ADMIN_ROUTES_CONSTANT.SET_PASSWORD_FAIL
            : ROUTES_CONSTANT.SET_PASSWORD_FAIL,
        );
      };
      if (!resetToken) {
        handleNavigate();
      } else {
        try {
          await verifyTokenResetPasswordMutation.mutateAsync({
            reset_token: resetToken,
          });
        } catch (e) {
          handleNavigate();
        }
      }
    })();
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useSetPassForm();

  const onSubmit = async (datas: ISetPassForm) => {
    try {
      setLoading(true);
      await verifyResetPasswordMutation.mutateAsync({
        reset_token: resetToken ?? "",
        password: datas.password,
        password_confirmation: datas.password_confirmation,
      });

      navigate(
        isAdminPage
          ? ADMIN_ROUTES_CONSTANT.SET_PASSWORD_SUCCESS
          : ROUTES_CONSTANT.SET_PASSWORD_SUCCESS,
      );
    } catch (errRes) {
      const err = handleApiError(errRes);
      if (err.statusCode === HttpStatusCode.BadRequest) {
        navigate(
          isAdminPage
            ? ADMIN_ROUTES_CONSTANT.SET_PASSWORD_FAIL
            : ROUTES_CONSTANT.SET_PASSWORD_FAIL,
          {
            replace: true,
          },
        );
      } else mapAPIError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center mobile:py-8 mobile:px-4 desktop:p-8 whitespace-nowrap bg-white rounded-3xl w-full max-w-[572px]">
      <form
        className="flex flex-col max-md:max-w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlledInput
          type={typeInput}
          control={control}
          formField="password"
          label="パスワード"
          note="※半角英数記号10文字以上"
          isRequired={true}
          maxLength={MAX_LENGTH.PASSWORD}
          placeholder="パスワードを入力してください"
          startSlot={<LockIcon />}
          endSlot={
            <div
              className="cursor-pointer"
              onClick={() => {
                setTypeInput((prev) => (prev === "text" ? "password" : "text"));
              }}
            >
              {typeInput === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          }
          errorMessage={errors.password?.message}
        />

        <ControlledInput
          type={typeInput2}
          className="mt-8"
          control={control}
          formField="password_confirmation"
          label="パスワード確認"
          note="※半角英数記号10文字以上"
          isRequired={true}
          maxLength={MAX_LENGTH.PASSWORD}
          placeholder="パスワードを再度入力してください"
          startSlot={<LockIcon />}
          endSlot={
            <div
              className="cursor-pointer"
              onClick={() => {
                setTypeInput2((prev) =>
                  prev === "text" ? "password" : "text",
                );
              }}
            >
              {typeInput2 === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          }
          errorMessage={errors.password_confirmation?.message}
        />

        <Button
          size="sm"
          className={"mt-[48px] max-w-[335px] w-full m-auto"}
          type="submit"
          isLoading={loading?.open}
        >
          パスワード再登録
        </Button>
      </form>
    </div>
  );
}
