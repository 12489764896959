import {
  MutateOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DeleteDepartmentResponseBody,
  FilterDepartmentRequestBody,
  FilterDepartmentResponseBody,
  UpsertDepartmentRequestBody,
  UpsertDepartmentResponseBody,
  deleteDepartmentApi,
  listDepartmentApi,
  upsertDepartmentApi,
} from "./request";

export const useListDepartmentQuery = (
  params: Partial<FilterDepartmentRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterDepartmentResponseBody,
      unknown, // TError,
      FilterDepartmentResponseBody,
      (string | Partial<FilterDepartmentRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-department", params],
    queryFn: listDepartmentApi,
    ...options,
  });
};

export const useUpsertDepartmentMutation = (
  options: MutateOptions<
    UpsertDepartmentResponseBody,
    unknown,
    UpsertDepartmentRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: upsertDepartmentApi,
      onSuccess: () => {
        // queryClient.invalidateQueries({
        //   queryKey: ["filter-department"],
        // });
      },
    },
    queryClient,
  );
};

export const useDeletedepartmentMutation = (
  options: MutateOptions<
    DeleteDepartmentResponseBody,
    unknown,
    number,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: deleteDepartmentApi,
      onSuccess: () => {
        // queryClient.invalidateQueries({
        //   queryKey: ["filter-department"],
        // });
      },
    },
    queryClient,
  );
};
