import {
  MutateOptions,
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CreateUserRequestBody,
  CreateUserResponseBody,
  DeleteUserResponseBody,
  FilterUserRequestBody,
  FilterUserResponseBody,
  ShowUserResponseBody,
  TUpdateEmailRequestBody,
  TUpdateEmailResponseBody,
  UpdatePasswordUserRequestBody,
  UpdatePasswordUserResponseBody,
  UpdateUserRequestBody,
  UpdateUserResponseBody,
  createUserApi,
  deleteUserApi,
  listUserApi,
  showUserApi,
  updateEmailApi,
  updatePasswordUserApi,
  updateUserApi,
} from "./request";

const useCreateUserMutation = (
  options: MutateOptions<
    CreateUserResponseBody,
    unknown,
    CreateUserRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: createUserApi,
      onSuccess: () => {
        // queryClient.invalidateQueries({
        //   queryKey: ["filter-users"],
        // });
      },
    },
    queryClient,
  );
};

const useUpdateUserMutation = (
  options: MutateOptions<
    UpdateUserResponseBody,
    unknown,
    UpdateUserRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updateUserApi,
      onSuccess: () => {
        // queryClient.invalidateQueries({
        //   queryKey: ["filter-users"],
        // });
      },
    },
    queryClient,
  );
};

export const fetchFilterUser = async (
  queryClient: QueryClient,
  params?: Partial<FilterUserRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["filter-users", params],
    queryFn: listUserApi,
  });
};

const useListUserQuery = (
  params: Partial<FilterUserRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterUserResponseBody,
      unknown, // TError,
      FilterUserResponseBody,
      (string | Partial<FilterUserRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-users", params],
    queryFn: listUserApi,
    enabled: !!params,
    ...options,
  });
};

const useDeleteUserMutation = (
  options: MutateOptions<DeleteUserResponseBody, unknown, number, unknown> = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: deleteUserApi,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["filter-users"],
          exact: true,
        });
      },
    },
    queryClient,
  );
};

const useShowUserQuery = (
  params: number | string,
  options: Omit<
    UseQueryOptions<
      ShowUserResponseBody,
      unknown,
      ShowUserResponseBody,
      (string | number)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["show-users", params],
    queryFn: showUserApi,
    enabled: !!params,
    ...options,
  });
};

const useUpdatePasswordUserMutation = (
  options: MutateOptions<
    UpdatePasswordUserResponseBody,
    unknown,
    UpdatePasswordUserRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updatePasswordUserApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

const useUpdateEmailMutation = (
  options: MutateOptions<
    TUpdateEmailResponseBody,
    unknown,
    TUpdateEmailRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updateEmailApi,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["my-profile"],
          exact: true,
        });
      },
    },
    queryClient,
  );
};
export {
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useShowUserQuery,
  useListUserQuery,
  useUpdatePasswordUserMutation,
  useUpdateEmailMutation,
};
