import { useGetDetailELearningQuery } from "@/api/e-learning";
import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import CreateElearningComponent from "@/components/organisms/ElearningCreate";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import AnimatedRoute from "@/router/transition.route";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

function EditELearningPage() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const { data: course } = useGetDetailELearningQuery(parseInt(id as string), {
    enabled: !!id,
  });

  const breadcrumbs: BeardcrumbOption[] = useMemo(() => {
    return [
      {
        label: "Eラーニング一覧",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST,
      },
      {
        label: `「${course?.title}」の編集`,
      },
    ];
  }, [searchParams, course?.title]);

  return (
    <div className="max-w-[100%] m-auto my-6">
      <Breadcrumb options={breadcrumbs} />
      <h4 className="mt-3 font-black">編集</h4>
      <div className="mt-8">
        <CreateElearningComponent course={course} />
      </div>
    </div>
  );
}

const AnimatedEditELearningPage = AnimatedRoute(EditELearningPage);
export default AnimatedEditELearningPage;
