import AuthLayout from "@/pages/_layouts/auth";
import ArrowLeftIcon from "@/assets/icon/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import FormSetInformationFirstLogin from "@/components/organisms/FormSettingAccount/setting-information";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";
import { useEffect } from "react";
export default function SettingInformationFirstLogin() {
  const { userInfo } = useRole(true, [
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(ADMIN_ROUTES_CONSTANT.LOGIN);
  };

  useEffect(() => {
    if (userInfo?.first_info_changed_flag) {
      navigate(ADMIN_ROUTES_CONSTANT.FORBIDDEN);
    }
  }, [userInfo]);

  return (
    <AuthLayout>
      <div className="py-[80px] flex w-full items-center flex-col px-[20px]">
        <div className="flex mb-[16px] justify-center w-full relative">
          <div className="absolute left-0 cursor-pointer" onClick={goBack}>
            <ArrowLeftIcon />
          </div>
          <h4 className="font-[900] text-center">アカウント情報設定</h4>
        </div>
        <div className="leading-[21px] font-[400] text-[14px] text-center mb-[48px] ">
          アカウント情報を入力し、設定を完了させてください
        </div>
        <FormSetInformationFirstLogin isAdminPage={true} userInfo={userInfo} />
      </div>
    </AuthLayout>
  );
}
