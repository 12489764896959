import { Skeleton } from "@chakra-ui/react";

interface InfoSkeletonProps {
  className?: string;
  isAdmin?: boolean;
}

export const InfoSkeleton = ({ className, isAdmin }: InfoSkeletonProps) => {
  return (
    <div
      className={`${className} w-full rounded-xl flex desktop:gap-4  mobile:gap-6 p-8 border-primary bg-white flex-col`}
    >
      <div className="flex desktop:gap-5 mobile:gap-3 w-full desktop:flex-row mobile:flex-col items-center justify-between">
        <div className="flex desktop:gap-5 mobile:gap-3 desktop:flex-row mobile:flex-col items-center">
          <Skeleton height="78px" width="78px" rounded={64} />

          <div className="flex flex-1 gap-1 flex-col  mobile:text-center desktop:text-left">
            <h5 className="font-black">
              <Skeleton height="32px" width="540px" />
            </h5>
            <div className="font-normal text-xs text-secondary-custom mobile:text-center desktop:text-left">
              <Skeleton height="48px" width="840px" />
            </div>
          </div>
        </div>
        {!isAdmin && (
          <button
            type="button"
            className={
              "font-normal min-w-[124px] max-w-[126px] hover:opacity-80 h-[32px] text-primary--dark border-primary-dark rounded-[7px] p-2 text-xs"
            }
          >
            ユーザ情報の変更
          </button>
        )}
      </div>
      <div className="flex desktop:gap-4 mobile:gap-6 w-ful flex-col">
        <div className="flex justify-between desktop:gap-8 mobile:gap-3 desktop:flex-row mobile:flex-col">
          <div className="flex desktop:items-center desktop:gap-8 mobile:gap-3 desktop:flex-row mobile:flex-col">
            <div className="text-sm font-bold leading-6 text-secondary-custom  w-[160px]">
              メールアドレス
            </div>
            <div className="font-normal text-base leading-6">
              <Skeleton height="24px" width="360px" />
            </div>
          </div>
          {!isAdmin && (
            <button
              className={
                "font-normal min-w-[124px] max-w-[126px] hover:opacity-80 h-[32px] text-primary--dark border-primary-dark rounded-[7px] p-2 text-xs"
              }
            >
              メールアドレス変更
            </button>
          )}
        </div>
        <div className="flex justify-between desktop:gap-8 mobile:gap-3 desktop:flex-row mobile:flex-col">
          <div className="flex desktop:items-center desktop:gap-8 mobile:gap-3 desktop:flex-row mobile:flex-col">
            <div className="text-sm font-bold leading-6 text-secondary-custom w-[160px]">
              パスワード
            </div>
            <div className="flex gap-8 items-center">
              <div className="font-normal text-base leading-6">********</div>
              {isAdmin && (
                <button className="font-normal min-w-[124px] max-w-[126px] hover:opacity-80 h-[32px] text-primary--dark border-primary-dark rounded-[7px] p-2 text-xs">
                  パスワード変更
                </button>
              )}
            </div>
          </div>
          {!isAdmin && (
            <button className="font-normal min-w-[124px] max-w-[126px] hover:opacity-80 h-[32px] text-primary--dark border-primary-dark rounded-[7px] p-2 text-xs">
              パスワード変更
            </button>
          )}
        </div>
      </div>
      <div className="h-[1px] border-primary"></div>
      <div className="flex gap-8 items-center mobile:justify-between desktop:justify-normal	">
        <div className="text-sm font-bold leading-6 text-secondary-custom w-[160px]">
          学習理解度
        </div>
        <div className="font-bold text-base leading-6 w-[102px]">
          <Skeleton height="24px" />
        </div>
      </div>
    </div>
  );
};
