import AuthLayout from "@/pages/_layouts/auth";
import Logo from "@/assets/logo.svg";
import FormLogin from "@/components/organisms/FormLogin";

/**
 * Renders the login page component with the admin layout.
 *
 * @return {JSX.Element} The login page component JSX
 */
export default function LoginPage(): JSX.Element {
  return (
    <AuthLayout>
      <div className="py-[80px] flex w-full items-center flex-col px-[20px]">
        <Logo />
        <h4 className="font-[900] mt-[16px] mb-[48px] text-center">管理画面</h4>
        <FormLogin isAdminPage={true} />
      </div>
    </AuthLayout>
  );
}
