import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import Button from "@/components/atoms/Button/button";
import { useNavigate } from "react-router-dom";
import { MAX_LENGTH, ROUTES_CONSTANT } from "@/configs/constants";
import { useChangeEmailForm, IChangeEmailForm } from "./validation";
import EmailIcon from "@/assets/icon/mail.svg";
import { useState } from "react";
import { useAuthStore } from "@/states/auth.state";
import useToast from "@/hooks/toast";
import { useGlobalStore } from "@/states/global.state";
import { useUpdateEmailMutation } from "@/api/user";
import { handleApiError, mapAPIError } from "@/hooks/error";

export default function FormChangeEmail() {
  const navigate = useNavigate();
  const [typeInput] = useState<"text">("text");
  const { loading, setLoading } = useGlobalStore();
  const { showToast } = useToast();
  const { userInfo } = useAuthStore();
  const updateEmailMutation = useUpdateEmailMutation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useChangeEmailForm();

  const onSubmit = async (datas: IChangeEmailForm) => {
    if (!datas.email) return navigate(ROUTES_CONSTANT.MY_PAGE);
    try {
      setLoading(true);
      await updateEmailMutation.mutateAsync({ users: { email: datas.email } });
      showToast({
        title: `メールアドレスの変更が完了しました`,
        type: "success",
        site: "front",
      });
      navigate(ROUTES_CONSTANT.MY_PAGE);
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center whitespace-nowrap bg-white rounded-3xl w-full max-w-[960px] desktop:p-8 mobile:p-8">
      <div className="subtitle2 mb-4 text-secondary-custom">
        現在のメールアドレス
      </div>

      <p className="subtitle1 text-black-custom mt-4 mb-12 whitespace-pre-line">
        {userInfo?.email}
      </p>

      <div className="subtitle2 mb-4 text-secondary-custom">
        新しいメールアドレス
      </div>

      <form
        className="flex flex-col max-md:max-w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlledInput
          type={typeInput}
          control={control}
          formField="email"
          label="新しいメールアドレス"
          isRequired={true}
          isTrim={true}
          maxLength={MAX_LENGTH.EMAIL}
          placeholder="メールアドレスを入力してください"
          startSlot={<EmailIcon />}
          errorMessage={errors.email?.message}
        />

        <Button
          size="sm"
          className={"mt-12 max-w-[335px] w-full m-auto"}
          type="submit"
          isLoading={loading.open}
        >
          設定する
        </Button>
      </form>
    </div>
  );
}
