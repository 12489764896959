import clsx from "clsx";

/** Properties for the TabViewProps component*/
interface TabViewProps {
  listTab: TabItem[];
  activeIndex: number;
  onChange: (index: number) => void;
}
export interface TabItem {
  label: string;
  isDisable?: boolean;
}

/**
 * Function: TabView
 *
 * Description: Renders a tab view component.
 *
 * @param prop - The props for the TabView component.
 * @returns The rendered tab view component.
 */
export const TabView = (prop: TabViewProps) => {
  const onChange = (index: number) => {
    prop.onChange(index);
  };
  return (
    <div className="flex pb-3 gap-4 text-base font-normal">
      {prop?.listTab &&
        prop.listTab.map((item: TabItem, index) => (
          <button
            key={index}
            className={clsx(
              "px-3 py-[6px] gap-[10px] rounded-[8px] text-base",
              prop.activeIndex === index
                ? "text-primary--main bg-white"
                : " font-normal text-disabled",
              item.isDisable && "cursor-not-allowed",
            )}
            disabled={item.isDisable}
            onClickCapture={() => onChange(index)}
          >
            {item.label}
          </button>
        ))}
    </div>
  );
};
