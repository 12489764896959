import CustomDatePicker from "@/components/atoms/Datepicker/datepicker";
import clsx from "clsx";
import { forwardRef, useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldValue,
  FieldValues,
  Path,
} from "react-hook-form";

interface ControlledInputProps {
  className?: string;
  label?: string;
  note?: string;
  isRequired?: boolean;
  errorMessage?: string;
}

interface OwnProps<Type extends FieldValues>
  extends Omit<ControlledInputProps, "value"> {
  control: Control<FieldValue<Type>>;
  formField: Path<FieldValue<Type>>;
  minDate?: Date;
}

export const ControlledDatePicker = <T extends FieldValues>({
  control,
  formField,
  className,
  label,
  note,
  isRequired,
  errorMessage,
  minDate,
}: OwnProps<T>) => {
  const [isFocusing] = useState(false);
  const isError = useMemo(
    () => errorMessage && errorMessage.length > 0,
    [errorMessage],
  );

  const CustomInput = forwardRef((props: any, ref: any) => {
    return (
      <div
        className={`custom-date-picker body1 cursor-pointer hover:border-[#73BE1E] focus:border-[#73BE1E] ${
          isError && "is-invalid"
        }`}
        onClick={props.onClick}
        ref={ref}
      >
        {props.value}
      </div>
    );
  });

  return (
    <Controller
      control={control}
      name={formField}
      render={({ field }) => {
        return (
          <div className={className}>
            {label?.length && (
              <div className="flex gap-2 mb-1 justify-between">
                <div className="flex flex-col justify-center text-xs max-md:max-w-full">
                  <div className="flex gap-1 max-md:flex-wrap">
                    <div
                      className={clsx(
                        isFocusing && !isError
                          ? "text-[var(--primary-main-color)]"
                          : "text-[var(--text-secondary)]",
                      )}
                    >
                      {label}
                    </div>
                    {isRequired && <div className="text-error--main">*</div>}
                  </div>
                </div>
                {note && (
                  <div className="text-[var(--text-secondary)] caption1">
                    {note}
                  </div>
                )}
              </div>
            )}
            <CustomDatePicker
              value={field.value}
              onChange={field.onChange}
              customInput={<CustomInput />}
              minDate={minDate}
            />
            {isError && (
              <div className="mt-1 w-full text-error--main input-error__message">
                {errorMessage}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
