import { QueryFunctionContext } from "@tanstack/react-query";
import { OrderDirection, Paging, Tag } from "../type";
import { request } from "../axios";
import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";

export type FilterTagRequestBody = {
  page: number;
  per_page: number;
  order_by?: string;
  order_direction?: OrderDirection;
};

export type FilterTagResponseBody = {
  tags: Tag[];
  paging: Paging;
};

export const listTagApi = (
  context: QueryFunctionContext,
): Promise<FilterTagResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_TAG),
    params: context.queryKey[1],
  });
};

export type UpdateTagsRequestBody = {
  tags: Tag[];
};
export type UpdateTagsResponseBody =
  | {
      success: boolean;
    }
  | {
      error_message: string;
    }
  | {
      error_message: {
        name: string[];
      };
    };

export const updateTagsApi = (
  data: UpdateTagsRequestBody,
): Promise<UpdateTagsResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.UPDATE_TAG),
    data: data,
  });
};
