import { modalAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

  const baseStyle = definePartsStyle({
    dialog: {
      borderRadius: '16px',
      boxShadow: "-40px 40px 80px -8px #919EAB3D",
      backgroundColor: "#FFFFFF",
    },
    header: {
      padding: "20px 24px",
      fontWeight: "900",
      fontSize: "18px",
      lineHeight: "28px"
    },
    body: {
      padding: "0 24px",
      whiteSpace: "break-spaces"
    },
    footer: {
      padding: "24px"
    }
  })
  
  const md = defineStyle({
    maxWidth: "480px",
  })
  
  const sizes = {
    md: definePartsStyle({ dialog: md }),
  }
  export const modalTheme = defineMultiStyleConfig({
    baseStyle,
    sizes,
  })
