import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/shared/validation/yup";
import { MAX_LENGTH } from "@/configs/constants";

export interface ILoginForm {
  email: string;
  password: string;
}

export const useLoginForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .label("login.email")
          .email()
          .max(MAX_LENGTH.EMAIL)
          .required(),
        password: yup
          .string()
          .label("login.password")
          .max(MAX_LENGTH.PASSWORD)
          .password()
          .required(),
      }),
    [],
  );

  return useForm<ILoginForm>({
    resolver: yupResolver(validationScheme),
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });
};
