import { getRoute } from "@/shared/get/route";
import { request } from "../axios";
import { API_PATH_CONSTANT } from "../constant";
import { OrderDirection, Paging, UserNotification } from "../type";
import { QueryFunctionContext } from "@tanstack/react-query";

export type FilterNotificationRequestBody = {
  page: number;
  per_page: number;
  for_admin: boolean;
  order_by: string;
  order_direction: OrderDirection;
};
export type FilterNotificationResponseBody = {
  user_notifications: UserNotification[];
  paging: Paging;
};

export const listNotificationApi = (
  context: QueryFunctionContext,
): Promise<FilterNotificationResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(`${API_PATH_CONSTANT.LIST_NOTIFICATION}`),
    params: context.queryKey[1],
  });
};

export type ReadUserNotificationResponseBody = {
  id: number;
};

export type ReadUserNotificationRequestBody = {
  ids: number[];
};

export const readUserNotificationApi = async (
  data: ReadUserNotificationRequestBody,
): Promise<ReadUserNotificationResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.READ_NOTIFICATION),
    data,
  });
};

export type CheckUnreadNotificationResponseBody = {
  unread: boolean;
};

export type CheckUnreadNotificationRequestBody = {
  for_admin: boolean;
};
export const checkUnreadNotificationApi = (
  context: QueryFunctionContext,
): Promise<CheckUnreadNotificationResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(`${API_PATH_CONSTANT.CHECK_UNREAD_NOTIFICATION}`),
    params: context.queryKey[1],
  });
};
