import UploadAvatar from "@/components/atoms/UploadAvatar/upload-avatar";
import { ICreateUserForm, useCreateUserForm } from "./create.validate";
import { useEffect, useMemo, useState } from "react";
import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import { ControlledSelect } from "@/components/molecules/ControlledSelect/controlled-select";
import EyeCloseIcon from "@/assets/icon/eye-close.svg";
import EyeOpenIcon from "@/assets/icon/eye-open.svg";
import EmailIcon from "@/assets/icon/mail.svg";
import Button from "@/components/atoms/Button/button";
import { useNavigate } from "react-router-dom";
import useToast from "@/hooks/toast";
import { useCreateUserMutation, useUpdateUserMutation } from "@/api/user";
import {
  ADMIN_ROUTES_CONSTANT,
  PAGINATION_CONSTANT,
} from "@/configs/constants";
import { useListRoleQuery } from "@/api/role";
import {
  handleApiError,
  mapAPIError,
  useScrollToFirstError,
} from "@/hooks/error";
import { useGlobalStore } from "@/states/global.state";
import { useListOfficeQuery } from "@/api/office";
import { useListDepartmentQuery } from "@/api/department";
import { UploadResponse } from "@/hooks/upload";
import { User } from "@/api/type";
import { ROLE_ENUM } from "@/configs/enum";
import { getDepartmentOptions, getLinkMedia } from "@/shared/get";
import { cutString } from "@/shared/transform";

interface CreateUserFormProps {
  id?: number | null;
  role: {
    isAdminContent: boolean;
    isAdminSystem: boolean;
  };
  userInfo?: User;
  urlSuccess?: string;
}

export default function CreateUserForm({
  id = null,
  role: currentRole,
  userInfo,
  urlSuccess = ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_LIST,
}: CreateUserFormProps) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { loading, setLoading } = useGlobalStore();
  const [typeInput, setTypeInput] = useState<"password" | "text">("password");
  const { control, handleSubmit, formState, reset, setError, setValue } =
    useCreateUserForm();
  useScrollToFirstError(formState);
  const { errors } = formState;
  const { data: roles } = useListRoleQuery();
  const createUserMutation = useCreateUserMutation();
  const updateUserMutation = useUpdateUserMutation();
  const { data: officeRes } = useListOfficeQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });
  const { data: departmentRes } = useListDepartmentQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });

  const roleOptions = useMemo(
    () =>
      roles
        ?.filter((role) => {
          if (currentRole.isAdminContent)
            return role.id === ROLE_ENUM.CONTENT_MANAGER;

          return true;
        })
        .map((role) => ({ value: role.id, label: role.name })) ?? [],
    [roles],
  );

  /** handle when click create/edit button */
  const onSubmit = async (values: ICreateUserForm) => {
    try {
      setLoading(true);
      const payload = {
        department_id: values.department?.value.split("-")[0] ?? "",
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        office_id: values.department?.value.split("-")[1] ?? "",
        role_id: `${values.role.value}`,
        avatar_attributes: values.avatar_attributes,
      };
      /** when is edit page */
      if (id) {
        await updateUserMutation.mutateAsync({
          id,
          ...payload,
          avatar_attributes: values.avatar_attributes
            ? {
                id: userInfo?.avatar?.id,
                ...values.avatar_attributes,
              }
            : null,
        });
      } else {
        /** when is create page */
        await createUserMutation.mutateAsync(payload);
      }

      /** show message */
      showToast({
        title: `${cutString(`${values.first_name} ${values.last_name}`)}${
          id ? "の変更を保存しました" : "を作成しました"
        }`,
        type: "success",
      });
      navigate(urlSuccess);
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError, (e) => {
        let path = e.path;
        if (e.path === "department_id" || e.path === "office_id") {
          path = "department";
        }
        if (e.path === "base" || e.path === "id") {
          path = "avatar_attributes";
        }
        if (e.path === "role_id") path = "role";

        setError(path, {
          message: e.message,
        });
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    userInfo &&
      reset({
        email: userInfo?.email,
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
        role: { value: userInfo.role.id, label: userInfo.role.name },
        password: userInfo?.decrypted_password,
        department:
          userInfo.department && userInfo.office
            ? {
                value: `${userInfo.department?.id}-${userInfo.office.id}`,
                label: `${userInfo.department?.name} / ${userInfo.office.name}`,
              }
            : null,
      });
  }, [userInfo, reset]);

  const departmentOptions = useMemo(() => {
    return getDepartmentOptions(officeRes?.offices, departmentRes?.departments);
  }, [officeRes, departmentRes]);

  const onUpload = async (content: UploadResponse | null) => {
    if (!content) return;
    setValue("avatar_attributes", {
      key: content.key,
      name: content.name,
      content_type: content.content_type,
    });
  };

  return (
    <div className="w-full">
      <div className="flex p-8 rounded-[24px] bg-[#fff] gap-x-8">
        <UploadAvatar
          onChange={onUpload}
          onError={(message) =>
            setError("avatar_attributes", {
              message,
            })
          }
          imageUrl={getLinkMedia(userInfo?.avatar?.key)}
        />
        <div className="flex flex-col gap-y-4 flex-1">
          {errors.avatar_attributes?.message ? (
            <div className="w-full text-error--main input-error__message is-invalid">
              {errors.avatar_attributes?.message}
            </div>
          ) : (
            <></>
          )}
          <ControlledInput
            control={control}
            formField="first_name"
            label="姓"
            isTrim={true}
            isRequired={true}
            placeholder="佐藤"
            maxLength={126}
            name="first_name"
            autoComplete="first_name"
            errorMessage={errors.first_name?.message}
          />
          <ControlledInput
            control={control}
            formField="last_name"
            label="名"
            isTrim={true}
            isRequired={true}
            placeholder="太郎"
            maxLength={126}
            name="last_name"
            autoComplete="last_name"
            errorMessage={errors.last_name?.message}
          />
          <ControlledSelect
            options={departmentOptions}
            control={control}
            formField="department"
            label="所属"
            isClearable={true}
            isRequired={true}
            placeholder="所属を選択してください"
            errorMessage={errors.department?.message?.toString()}
          />
        </div>
      </div>

      <div className="flex flex-col p-8 rounded-[24px] bg-[#fff] gap-x-8 gap-y-4 mt-8">
        <ControlledSelect
          options={roleOptions}
          control={control}
          formField="role"
          label="権限"
          isRequired={true}
          placeholder="営業担当者"
          className="max-w-[400px] w-full"
          errorMessage={errors?.role?.message?.toString()}
        />
        <ControlledInput
          startSlot={<EmailIcon />}
          control={control}
          formField="email"
          name="email"
          maxLength={100}
          autoComplete="email"
          label="メールアドレス"
          isRequired={true}
          placeholder="sample@mail.co.jp"
          errorMessage={errors.email?.message}
        />
        {id && (
          <ControlledInput
            type={typeInput}
            control={control}
            formField="password"
            label="パスワード"
            note="※半角英数記号10文字以上"
            isRequired={true}
            readOnly
            endSlot={
              <div
                className="cursor-pointer"
                onClick={() => {
                  setTypeInput((prev) =>
                    prev === "text" ? "password" : "text",
                  );
                }}
              >
                {typeInput === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />}
              </div>
            }
          />
        )}
      </div>
      <div className="flex flex-row mt-8 gap-[10px] justify-end">
        <Button
          type="button"
          size="sm"
          variant="info"
          className={"min-w-[128px] w-fit !rounded-[24px]"}
          onClick={() => {
            navigate(-1);
          }}
        >
          キャンセル
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          size="sm"
          className={"min-w-[128px] w-fit !rounded-[24px]"}
          isLoading={loading?.open}
        >
          {id ? "保存" : "登録"}
        </Button>
      </div>
    </div>
  );
}
