import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import CreateVideoForm from "@/components/organisms/ContentManagement/video";
import { ROUTES_CONSTANT } from "@/configs/constants";
import { ROLE_ENUM } from "@/configs/enum";
import useRole from "@/hooks/role";
import AnimatedRoute from "@/router/transition.route";
import { useParams } from "react-router-dom";

const breadcrumbs: BeardcrumbOption[] = [
  {
    label: "コンテンツ",
    route: ROUTES_CONSTANT.CONTENT_VIDEO_LIST,
  },
  {
    label: "新規作成",
  },
];

function CreateVideoPage() {
  useRole(false, [
    ROLE_ENUM.MEMBER,
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);
  const params = useParams();
  return (
    <div className="m-auto max-w-[960px] w-full desktop:mt-12 mobile:mt-6 px-4">
      <Breadcrumb options={breadcrumbs} />
      <h4 className="mt-3 font-black">新規作成</h4>
      <div className="desktop:my-8 mobile:my-6">
        <CreateVideoForm folder_id={Number(params!.folder_id)} forUser={true} />
      </div>
    </div>
  );
}

const AnimatedFrontCreateVideoPage = AnimatedRoute(CreateVideoPage);
export default AnimatedFrontCreateVideoPage;
