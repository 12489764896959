import { ColumnDef, SortingState } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrderDirection } from "@/api/type";
import Avatar from "@/components/atoms/Avatar/avatar";
import DataTable from "@/components/molecules/Table/table";
import {
  ADMIN_ROUTES_CONSTANT,
  PAGINATION_CONSTANT,
} from "@/configs/constants";
import { getRoute } from "@/shared/get/route";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";
import { useJoinedUsersQuizzQuery } from "@/api/quizz";
import { getLinkMedia } from "@/shared/get";
import clsx from "clsx";

/**
 * Interface: ManagerQuizzUserProps
 *
 * Represents the props for the ManagerQuizzUser component.
 *
 * @property id - The id of the quizz.
 *
 */
interface ManagerQuizzUserProps {
  id: number;
}

const pageSize = PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE;

export default function ManagerQuizzUser({ id }: ManagerQuizzUserProps) {
  const navigate = useNavigate();
  const { isAdminSystem } = useRole(true, [
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);
  const [searchForm, setSearchForm] = useState({
    page: 1,
    order_by: "updated_at",
    order_direction: "desc" as OrderDirection,
  });

  const { data: usersResponse } = useJoinedUsersQuizzQuery({
    quizz_id: id,
    page: searchForm.page,
    per_page: 10,
    order_by: searchForm.order_by || "updated_at",
    order_direction: searchForm.order_direction,
  });

  const users = useMemo(
    () =>
      usersResponse?.users?.map((user) => ({
        id: user.id,
        avatar: getLinkMedia(user.thumbnail?.key || user.avatar?.key),
        fullname: `${user.first_name} ${user.last_name}`,
        role: user.role.name,
        department: `${user.department?.name || ""} ${
          user.office?.name ? " / " + user.office?.name : ""
        }`,
        email: user.email,
        score: user.score,
      })) ?? [],
    [usersResponse],
  );

  const paging = useMemo(() => usersResponse?.paging, [usersResponse]);

  const goToShowUser = (user_id: string) => {
    if (isAdminSystem)
      navigate(
        getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_DETAIL_USER, {
          quiz_id: id,
          id: user_id,
        }),
      );
  };

  const onSorting = useCallback((sortState: SortingState) => {
    if (!sortState[0]) return;

    setSearchForm((prev) => ({
      ...prev,
      order_by: sortState[0].id,
      order_direction: sortState[0].desc ? "desc" : "asc",
    }));
  }, []);

  const onChangePage = useCallback((page: number) => {
    setSearchForm((prev) => ({ ...prev, page }));
  }, []);

  const columns = useMemo<ColumnDef<any>[]>(() => {
    return [
      {
        accessorKey: "fullname",
        header: () => "ユーザー名",
        cell: (info) => {
          return (
            <div
              className={clsx(
                "flex gap-2 items-center",
                isAdminSystem && "cursor-pointer",
              )}
              onClickCapture={() => goToShowUser(info.row.original.id)}
            >
              <Avatar url={info.row.original.avatar} size="sm" />

              <span className="body1 text-black-custom break-all whitespace-normal">
                <CustomTooltip text={info.row.original.fullname} max={30} />
              </span>
            </div>
          );
        },
        meta: {
          minWidth: 200,
        },
      },
      {
        id: "departments.name",
        accessorKey: "department",
        header: () => "所属情報",
        cell: (info) => {
          return (
            <div className="body1 text-black-custom">
              <CustomTooltip text={info.row.original.department} max={30} />
            </div>
          );
        },
      },
      {
        accessorKey: "email",
        header: () => "メールアドレス",
        cell: (info) => (
          <CustomTooltip text={info.row.original.email} max={30} />
        ),
      },
      {
        accessorKey: "score",
        header: () => "テスト点数",
        cell: (info) => (
          <div className="body1 text-black-custom">
            {info.row.original.score}
          </div>
        ),
        meta: {
          width: 160,
        },
      },
    ];
  }, []);

  return (
    <div>
      <div className="mb-5 flex items-center justify-between">
        <h5 className="font-black">受講者一覧</h5>
      </div>

      <DataTable
        className="w-full"
        data={users}
        columns={columns}
        total={paging?.total_records ?? 0}
        defaultPage={searchForm.page}
        defaultPageSize={pageSize}
        defaultSort={[
          {
            id: searchForm.order_by ?? "updated_at",
            desc: searchForm.order_direction === "desc",
          },
        ]}
        onChangePage={onChangePage}
        onSorting={onSorting}
        headerSlot={null}
      />
    </div>
  );
}
