import FirstPageIcon from "@/assets/icon/double-arrow-left.svg";
import PrevPageIcon from "@/assets/icon/arrow-left_16x16.svg";
import NextPageIcon from "@/assets/icon/arrow-right_16x16.svg";
import LastPageIcon from "@/assets/icon/double-arrow-right.svg";
import { ReactNode, useMemo } from "react";
import clsx from "clsx";

/**
 * Interface for the Pagination component props.
 *
 * @interface PaginationProps
 * @property {number} total - The total number of items.
 * @property {number} current - The current page number.
 * @property {number} perPage - The number of items per page.
 * @property {() => void} [goToFirst] - Function to go to the first page.
 * @property {() => void} [goToLast] - Function to go to the last page.
 * @property {(page: number) => void} [goToPage] - Function to go to a specific page.
 */
export interface PaginationProps {
  total: number;
  current: number;
  perPage: number;
  goToFirst?: () => void;
  goToLast?: () => void;
  goToPage?: (page: number) => void;
}

/**
 * Interface for a PageDiv.
 *
 * @interface PageDiv
 * @property {ReactNode} template - The template for the page.
 * @property {() => void} onClick - The function to be executed when the page is clicked.
 * @property {string} class - The class of the page.
 */
interface PageDiv {
  template: ReactNode;
  onClick: () => void;
  class: string;
}

/**
 * Renders a pagination component.
 *
 * @param {PaginationProps} props - The pagination props.
 * @param {number} props.total - The total number of items.
 * @param {number} props.current - The current page number.
 * @param {number} props.perPage - The number of items per page.
 * @param {() => void} [props.goToFirst] - Function to go to the first page.
 * @param {() => void} [props.goToLast] - Function to go to the last page.
 * @param {(page: number) => void} [props.goToPage] - Function to go to a specific page.
 * @returns {ReactNode} The rendered pagination component.
 */
export default function Pagination({
  total,
  current,
  perPage,
  goToFirst,
  goToLast,
  goToPage,
}: PaginationProps): JSX.Element {
  const delta = 1;
  const totalPage = useMemo(() => Math.ceil(total / perPage), [total, perPage]);
  const isFirstPage = useMemo(() => current === 0, [current]);
  const isLastPage = useMemo(() => {
    return current === totalPage - 1;
  }, [current, totalPage]);
  const range = delta + 4;
  const numberTruncateLeft = useMemo(
    () => current + 1 - delta,
    [current, delta],
  );
  const numberTruncateRight = useMemo(
    () => current + 1 + delta,
    [current, delta],
  );

  const getPageNums = useMemo(() => {
    const arr: PageDiv[] = [];
    const curPage = current + 1;
    const renderTwoSide: PageDiv[] = [];
    let countTruncate = 0; // use for ellipsis - truncate left side or right side
    for (let pos = 1; pos <= totalPage; pos++) {
      if (totalPage >= 2 * range - 1) {
        if (numberTruncateLeft > 3 && numberTruncateRight < totalPage - 3 + 1) {
          // truncate 2 side
          if (pos >= numberTruncateLeft && pos <= numberTruncateRight) {
            renderTwoSide.push({
              template: pos,
              class: pos == curPage ? "bg-primary--main text-white" : "",
              onClick: () => {
                goToPage?.(pos - 1);
              },
            });
          }
        } else {
          // truncate left side or right side
          if (
            (curPage < range && pos <= range) ||
            (curPage > totalPage - range && pos >= totalPage - range + 1) ||
            pos === totalPage ||
            pos === 1
          ) {
            arr.push({
              template: pos,
              class: pos == curPage ? "bg-primary--main text-white" : "",
              onClick: () => {
                goToPage?.(pos - 1);
              },
            });
          } else {
            countTruncate++;
            if (countTruncate === 1)
              arr.push({
                template: "...",
                class: "",
                onClick: () => {},
              });
          }
        }
      } else {
        arr.push({
          template: pos,
          class: pos == curPage ? "bg-primary--main text-white" : "",
          onClick: () => {
            goToPage?.(pos - 1);
          },
        });
      }
    }
    if (renderTwoSide.length) {
      return [
        {
          template: 1,
          class: curPage === 1 ? "bg-primary--main text-white" : "",
          onClick: () => {
            goToFirst?.();
          },
        },
        {
          template: "...",
          class: "",
          onClick: () => {},
        },
        ...renderTwoSide,
        {
          template: "...",
          class: "",
          onClick: () => {},
        },
        {
          template: totalPage,
          class: curPage === totalPage ? "bg-primary--main text-white" : "",
          onClick: () => {
            goToLast?.();
          },
        },
      ];
    } else {
      return arr;
    }
  }, [
    totalPage,
    current,
    goToPage,
    range,
    numberTruncateLeft,
    numberTruncateRight,
    goToLast,
    goToFirst,
  ]);

  const pages = useMemo<PageDiv[]>(() => {
    return [
      {
        template: <FirstPageIcon />,
        class: isFirstPage ? "!cursor-not-allowed !bg-[#B8BFC7]" : "",
        onClick: () => {
          !isFirstPage && goToFirst?.();
        },
      },
      {
        template: <PrevPageIcon />,
        class: isFirstPage ? "!cursor-not-allowed !bg-[#B8BFC7]" : "",
        onClick: () => {
          !isFirstPage && goToPage?.(current - 1);
        },
      },
      ...getPageNums,
      {
        template: <NextPageIcon />,
        class: isLastPage ? "!cursor-not-allowed !bg-[#B8BFC7]" : "",
        onClick: () => {
          !isLastPage && goToPage?.(current + 1);
        },
      },
      {
        template: <LastPageIcon />,
        class: isLastPage ? "!cursor-not-allowed !bg-[#B8BFC7]" : "",
        onClick: () => {
          !isLastPage && goToLast?.();
        },
      },
    ];
  }, [
    current,
    isLastPage,
    isFirstPage,
    goToFirst,
    goToLast,
    goToPage,
    getPageNums,
  ]);
  if (totalPage < 1) return <></>;
  return (
    <div className="flex flex-row gap-3 justify-end ">
      {pages.map((page, index) => {
        return (
          <div
            key={index}
            className={clsx(
              "box-border flex flex-col justify-center items-center gap-[10px] w-[32px] h-[32px] bg-[#FFFFFF] border-[1px] border-solid border-[#E6E8EA] rounded-[4px] text-[#637381] font-normal text-xs leading-6 cursor-pointer",
              page.class,
            )}
            onClickCapture={page.onClick}
          >
            {page.template}
          </div>
        );
      })}
    </div>
  );
}
