import Button from "@/components/atoms/Button/button";
import EditWhite from "@/assets/icon/edit-white-02.svg";
import { Skeleton } from "@chakra-ui/react";

export default function ManagerQuizzQuestionsSkeleton() {
  return (
    <div>
      <div className="mb-5 flex items-center justify-between">
        <h5>コンテンツ</h5>
        <Button
          size="sm"
          startSlot={<EditWhite className="size-6" />}
          className="w-[140px] h-10 gap-1"
        >
          編集する
        </Button>
      </div>

      <div className="shadow-card p-6 bg-white rounded-[20px] flex flex-col gap-10">
        <div className="flex flex-col gap-4">
          <Skeleton height="26px" width="360px" />

          <div className="flex flex-col gap-2">
            <Skeleton height="24px" width="100%" />
            <Skeleton height="24px" width="100%" />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <Skeleton height="26px" width="360px" />

          <div className="flex flex-col gap-2">
            <Skeleton height="24px" width="100%" />
            <Skeleton height="24px" width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}
