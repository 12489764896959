import { useScreen } from "usehooks-ts";

function useScreenSize() {
  const screen = useScreen();

  return {
    isMobile: screen.width < 1024,
    isDesktop: screen.width >= 1024,
    screenWidth: screen.width,
  };
}

export default useScreenSize;
