import Button from "@/components/atoms/Button/button";
import { ModalCommon } from "@/components/atoms/Modal/modal";
import { useGlobalStore } from "@/states/global.state";
import { ReactNode } from "react";

/**
 * Interface: ModalProps
 *
 * Represents the props object for the ModalConfirm component.
 *
 * @property header - Optional ReactNode or string representing the modal header.
 * @property isOpen - Optional boolean indicating whether the modal is open.
 * @property isLoadingSubmit - Optional boolean indicating whether the submit button is loading.
 * @property message - Optional ReactNode or string representing the modal header.
 * @property onClose - Function to be called when the modal is closed.
 * @property onSubmit - Function to be called when the confirm button is clicked.
 */
interface ModalProps {
  header?: ReactNode | string;
  message?: ReactNode | string;
  isOpen?: boolean;
  isLoadingSubmit?: boolean;
  onClose?: () => void;
  onSubmit: () => void;
}

/**
 * Function component: ModalConfirm
 *
 * Renders a confirmation modal with a header, body, and footer.
 *
 * @param prop - The props object containing the following properties:
 *   - header: Optional string representing the modal header.
 *   - isOpen: Optional boolean indicating whether the modal is open.
 *   - onClose: Function to be called when the modal is closed.
 *   - onClick: Function to be called when the confirm button is clicked.
 *
 * @returns The rendered ModalConfirm component.
 */
export const ModalConfirm = (prop: ModalProps) => {
  const { loading } = useGlobalStore();

  return (
    <ModalCommon
      onClose={() => {
        if (prop?.onClose) prop.onClose();
      }}
      isOpen={!!prop.isOpen}
      header={prop.header}
      body={
        <div className="flex flex-col">
          <div className="text-base font-normal whitespace-break-spaces">
            {prop.message}
          </div>
        </div>
      }
      footer={
        <div className="flex gap-3">
          <Button
            className="h-10"
            buttonCustom={{
              padding: "8px 16px",
              borderRadius: "8px",
              borderColor: "#E6E8EA",
              color: "#212B36",
              circleStyles: "#212B36",
            }}
            size="sm"
            variant="outline"
            onClick={prop.onClose}
          >
            キャンセル
          </Button>
          <Button
            className="h-10"
            buttonCustom={{
              padding: "8px 16px",
              borderRadius: "8px",
              circleStyles: "white",
              color: "white",
              bg: "var(--error-main-color)",
            }}
            size="sm"
            isLoading={prop?.isLoadingSubmit ?? loading?.open}
            onClick={prop.onSubmit}
          >
            削除する
          </Button>
        </div>
      }
    />
  );
};
