import Sidebar from "@/components/molecules/Sidebar/sidebar";
import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";
import LoadingFC from "@/components/molecules/LoadingFC/loading.fullscreen";
import { useGlobalStore } from "@/states/global.state";
import Notification from "@/components/molecules/Notification/notification.admin";

/**
 * AdminMainLayout component.
 *
 * This component is responsible for rendering the main layout of the admin dashboard.
 * It includes a sidebar and a content area where the children components are rendered.
 *
 * Props:
 * - children: ReactNode - The components to be rendered inside the content area.
 *
 */
export default function AdminMainLayout({ children }: { children: ReactNode }) {
  const loading = useGlobalStore((state) => state.loading);

  return (
    <Box display="flex" position={"relative"}>
      {loading.open && loading.isFC && <LoadingFC />}
      <Sidebar />
      <Box
        display="flex"
        flexDirection={"column"}
        flex="1"
        width={"calc(100% - 280px)"}
        height={"100vh"}
        overflowX="hidden"
        overflowY={"auto"}
        position={"relative"}
        id="main-container"
      >
        <Box
          width={"100%"}
          display="flex"
          padding={"0 32px"}
          marginTop={"32px"}
          justifyContent={"end"}
          // position={'absolute'}
          // top={0}
          // right={0}
        >
          <Notification />
        </Box>
        <Box
          padding={"0 48px"}
          //  marginTop={'64px'}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
