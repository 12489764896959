import {
  MutateOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DeleteFolderRequestBody,
  DeleteFolderResponseBody,
  FilterFolderRequestBody,
  FilterFolderResponseBody,
  UpsertFolderRequestBody,
  UpsertFolderResponseBody,
  deleteFolderApi,
  listFolderApi,
  upsertFolderApi,
} from "./request";

export const useUpsertFolderMutation = (
  options: MutateOptions<
    UpsertFolderResponseBody,
    unknown,
    UpsertFolderRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: upsertFolderApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useListFolderQuery = (
  params: Partial<FilterFolderRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterFolderResponseBody,
      unknown, // TError,
      FilterFolderResponseBody,
      (string | Partial<FilterFolderRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-folder", params],
    queryFn: listFolderApi,
    ...options,
  });
};

export const useDeleteFolderMutation = (
  options: MutateOptions<
    DeleteFolderResponseBody,
    unknown,
    DeleteFolderRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: deleteFolderApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};
