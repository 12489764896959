import { QUESTION_KIND_ENUM } from "@/configs/enum";
import FrontQuizzItem from "./quizz-item";
import { useQuizContentForm } from "./validation";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDisclosure } from "@chakra-ui/react";
import { isArray, isNil } from "lodash";
import ModalResult, { QuestionDataOfModalResult } from "./modal-result";
import { CreateUserCourseContentRequestBody } from "@/api/e-learning/request";
import { useBoolean } from "usehooks-ts";
import clsx from "clsx";
import { ShowQuizzResponseBody } from "@/api/quizz/request";

interface ElearningExamContentControlProps {
  passPoint?: number;
  totalPoint?: number;
  questions?: ShowQuizzResponseBody["quiz_questions"] | null;
  className?: string;
  oldQuestionAnswers?: {
    id: number;
    title: string;
    kind: string;
    point: number;
    answer: string;
    user_quiz_question_answers: {
      id: number;
      title: string;
      point: number;
      is_correct: boolean;
      is_answer: boolean;
    }[];
  }[];
  isLearnedAllCourse: boolean;
  onStatus?: (
    status: "is-completed" | "is-not-completed" | "is-pass" | "is-fail",
  ) => void;
}
export interface ElearningExamContentControlEvent {
  onSubmit: () => boolean;
  getUserQuizQuestionAttribute: () => CreateUserCourseContentRequestBody["user_course_contents"]["user_quiz_questions_attributes"];
}

export const ElearningExamContentControl = forwardRef<
  ElearningExamContentControlEvent,
  ElearningExamContentControlProps
>(
  (
    {
      questions,
      onStatus,
      passPoint,
      totalPoint,
      oldQuestionAnswers,
      isLearnedAllCourse,
      className,
    },
    ref,
  ) => {
    const [point, setPoint] = useState(0);
    const { control, reset, getValues, setValue } = useQuizContentForm();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { value: isAllInputFree, setTrue: setIsAllInputFree } =
      useBoolean(false);

    const handleCheckFinishQuiz = useCallback(() => {
      const questions = getValues("questions");
      const isNotComplete = questions?.some((question) => {
        if (!question.answers) return true;
        if (!question.answers?.toString().trim()) return true;
        if (isArray(question.answers) && question.answers.length === 0)
          return true;
        return false;
      });
      if (onStatus) {
        if (!isNotComplete) {
          onStatus("is-completed");
        } else {
          onStatus("is-not-completed");
        }
      }
    }, [getValues, onStatus, isLearnedAllCourse]);

    const getCorrectAnswers = (question: {
      id?: number;
      kind: any;
      point?: number;
      title?: string;
      quiz_question_answers?: {
        id: number;
        title: string;
        is_correct: boolean;
        point: number;
      }[];
    }) => {
      if (question.kind === QUESTION_KIND_ENUM.FREE_INPUT) {
        return [];
      }

      return (
        question.quiz_question_answers
          ?.map((i, index) => ({
            index,
            ...i,
          }))
          ?.filter((i) => i.is_correct)
          .map((i) => {
            return {
              value: i.id,
              index_correct: i.index,
            };
          }) ?? []
      );
    };

    const [dataModal, setDataModal] = useState<QuestionDataOfModalResult[]>([]);
    const onSubmit = () => {
      setDataModal([]);
      let _point = 0;
      let countInputFreeKind = 0;
      if (!questions) return false;
      for (const qIndex in questions) {
        const index = +qIndex;
        const question = questions[index];
        if (question.kind === QUESTION_KIND_ENUM.FREE_INPUT) {
          countInputFreeKind++;
          setDataModal((prev) => [
            ...prev,
            {
              id: question.id,
              title: question.title,
              content: (getValues(`questions.${index}.answers`) ??
                "") as string,
              kind: question.kind,
              options: [],
            },
          ]);
        } else if (question.kind === QUESTION_KIND_ENUM.SINGLE_ANSWER) {
          const correctAnswers = getValues(
            `questions.${index}.correct_answers`,
          );
          const selectedAnswers = getValues(`questions.${index}.answers`);
          const optionsKind = (qAnswer: {
            id: number;
            title: string;
            is_correct: boolean;
            point: number;
          }): "checked" | "error" | "uncheck" => {
            if (qAnswer.id === correctAnswers[0].value) {
              if (selectedAnswers === qAnswer.id) _point += question.point;
              return "checked";
            }
            if (
              selectedAnswers === qAnswer.id &&
              qAnswer.id !== correctAnswers[0].value
            )
              return "error";

            return "uncheck";
          };
          const options = question.quiz_question_answers.map((qAnswer) => {
            return {
              id: qAnswer.id,
              option_kind: optionsKind(qAnswer),
              title: qAnswer.title,
            };
          });
          setDataModal((prev) => [
            ...prev,
            {
              id: question.id,
              title: question.title,
              content: "",
              kind: question.kind,
              options,
            },
          ]);
        } else if (question.kind === QUESTION_KIND_ENUM.MULTI_ANSWER) {
          const correctAnswers = getValues(
            `questions.${index}.correct_answers`,
          ).map((correctA) => correctA.value);
          const selectedAnswers = getValues(`questions.${index}.answers`);
          const optionsKind = (qAnswer: {
            id: number;
            title: string;
            is_correct: boolean;
            point: number;
          }): "checked" | "error" | "uncheck" => {
            if ((selectedAnswers as number[]).includes(qAnswer.id)) {
              if ((correctAnswers as number[]).includes(qAnswer.id)) {
                _point += qAnswer.point;
                return "checked";
              } else {
                return "error";
              }
            } else if ((correctAnswers as number[]).includes(qAnswer.id)) {
              return "checked";
            } else {
              return "uncheck";
            }
          };
          const options = question.quiz_question_answers.map((qAnswer) => {
            return {
              id: qAnswer.id,
              option_kind: optionsKind(qAnswer),
              title: qAnswer.title,
            };
          });
          setDataModal((prev) => [
            ...prev,
            {
              id: question.id,
              title: question.title,
              content: "",
              kind: question.kind,
              options,
            },
          ]);
        }
      }
      setPoint(_point);
      onOpen();

      if (isNil(_point) || isNil(passPoint)) {
        return false;
      }
      if (countInputFreeKind === questions.length) {
        setIsAllInputFree();
        return true;
      }
      return _point >= passPoint;
    };

    useEffect(() => {
      if (oldQuestionAnswers && questions) {
        questions.forEach((question, index) => {
          const oldVal = oldQuestionAnswers[index];
          if (question.kind === QUESTION_KIND_ENUM.FREE_INPUT) {
            setValue(`questions.${index}.answers`, oldVal?.answer);
          }
          if (question.kind === QUESTION_KIND_ENUM.SINGLE_ANSWER) {
            setValue(`questions.${index}.answers`, oldVal?.answer);
          }
        });
      }
    }, [oldQuestionAnswers, questions]);

    const getAnswer = useCallback(
      (
        question: {
          id: number;
          title: string;
          kind: string;
          point: number;
          answer: string;
          user_quiz_question_answers: {
            id: number;
            title: string;
            point: number;
            is_correct: boolean;
            is_answer: boolean;
          }[];
        },
        index: number,
      ) => {
        if (!oldQuestionAnswers?.length || !question) return null;
        if (question.kind === QUESTION_KIND_ENUM.FREE_INPUT) {
          return oldQuestionAnswers[index].answer;
        }
        if (question.kind === QUESTION_KIND_ENUM.MULTI_ANSWER) {
          return oldQuestionAnswers[index].user_quiz_question_answers
            .filter((i) => i.is_answer)
            .map((i) => i.id);
        }
        if (question.kind === QUESTION_KIND_ENUM.SINGLE_ANSWER) {
          return oldQuestionAnswers[index].user_quiz_question_answers
            .filter((i) => i.is_answer)
            .map((i) => i.id)?.[0];
        }
      },
      [oldQuestionAnswers],
    );

    const renderQuestionItem = useCallback(() => {
      if (oldQuestionAnswers?.length) {
        return oldQuestionAnswers?.map((question, index) => {
          return (
            <FrontQuizzItem
              key={`questions.${question.id}`}
              control={control}
              index={index}
              title={question.title ?? ""}
              kind={question.kind as QUESTION_KIND_ENUM}
              answers={question.user_quiz_question_answers}
              onChange={() => {
                handleCheckFinishQuiz();
              }}
              isReadOnly={true}
            />
          );
        });
      }
      if (questions?.length) {
        return questions?.map((question, index) => {
          return (
            <FrontQuizzItem
              key={`questions.${question.id}`}
              control={control}
              index={index}
              title={question.title ?? ""}
              kind={question.kind as QUESTION_KIND_ENUM}
              answers={question.quiz_question_answers}
              onChange={() => {
                handleCheckFinishQuiz();
              }}
              isReadOnly={isLearnedAllCourse}
            />
          );
        });
      }
    }, [questions, oldQuestionAnswers]);

    useEffect(() => {
      if (oldQuestionAnswers?.length) {
        reset({
          questions:
            oldQuestionAnswers?.map((question, index) => {
              return {
                answers: getAnswer(question, index),
                kind: question.kind,
                correct_answers: getCorrectAnswers(question),
              };
            }) ?? [],
        });
      } else if (questions?.length) {
        reset({
          questions:
            questions?.map((question) => {
              return {
                answers: [],
                kind: question.kind,
                correct_answers: getCorrectAnswers(question),
              };
            }) ?? [],
        });
      }
    }, [questions, oldQuestionAnswers]);

    useImperativeHandle(
      ref,
      () => {
        return {
          onSubmit,
          getUserQuizQuestionAttribute: () => {
            const yupAnswers = getValues("questions");
            if (!questions || !yupAnswers) return [];
            return questions.map((question, index) => {
              if (question.kind === QUESTION_KIND_ENUM.FREE_INPUT) {
                return {
                  title: question.title,
                  point: question.point,
                  kind: question.kind,
                  answer: yupAnswers[index].answers as string,
                  user_quiz_question_answers_attributes: [],
                };
              }
              if (question.kind === QUESTION_KIND_ENUM.SINGLE_ANSWER) {
                return {
                  title: question.title,
                  point: question.point,
                  kind: question.kind,
                  answer: "",
                  user_quiz_question_answers_attributes:
                    question.quiz_question_answers.map((qAnswer) => {
                      return {
                        is_correct: qAnswer.is_correct,
                        is_answer: qAnswer.id === yupAnswers[index].answers,
                        point: qAnswer.point,
                        title: qAnswer.title,
                      };
                    }),
                };
              }
              return {
                title: question.title,
                point: question.point,
                kind: question.kind,
                answer: "",
                user_quiz_question_answers_attributes:
                  question.quiz_question_answers.map((qAnswer) => {
                    return {
                      is_correct: qAnswer.is_correct,
                      is_answer: (
                        yupAnswers[index].answers as number[]
                      ).includes(qAnswer.id),
                      point: qAnswer.point,
                      title: qAnswer.title,
                    };
                  }),
              };
            });
          },
        };
      },
      [],
    );

    return (
      <div className={clsx("bg-white flex flex-col gap-10 w-full", className)}>
        {renderQuestionItem()}
        <ModalResult
          data={dataModal}
          isOpen={isOpen}
          id="modal-result"
          point={point}
          totalPoint={totalPoint}
          passPoint={isAllInputFree ? 0 : passPoint}
          onClose={() => {
            onClose();
          }}
        />
      </div>
    );
  },
);

export default ElearningExamContentControl;
