import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import Button from "@/components/atoms/Button/button";
import { useNavigate, useParams } from "react-router-dom";
import { ADMIN_ROUTES_CONSTANT, MAX_LENGTH } from "@/configs/constants";
import { useSetPassForm, ISetPassForm } from "./validation";
import LockIcon from "@/assets/icon/lock.svg";
import EyeCloseIcon from "@/assets/icon/eye-close.svg";
import EyeOpenIcon from "@/assets/icon/eye-open.svg";
import { useState } from "react";
import { useGlobalStore } from "@/states/global.state";
import { useUpdatePasswordAdminUserDetailMutation } from "@/api/auth";
import { handleApiError, mapAPIError } from "@/hooks/error";
import { getRoute } from "@/shared/get/route";
import useToast from "@/hooks/toast";

/**
 * Represents the props interface for the FormSetInformationFirstLogin component.
 * @interface FormSetPasswordProps
 * @property {boolean} [isAdminPage] - Indicates whether the component is rendered on an admin page.
 */

/**
 * This component renders a form for setting a new password.
 */
export default function FormSetPasswordUser() {
  const navigate = useNavigate();
  const updatePasswordAdminUserDetail =
    useUpdatePasswordAdminUserDetailMutation();
  const [typeInput, setTypeInput] = useState<"password" | "text">("password");
  const [typeInput2, setTypeInput2] = useState<"password" | "text">("password");
  const { loading, setLoading } = useGlobalStore();
  const { id, elearning_id: elearningId, quiz_id: quizId } = useParams();
  const { showToast } = useToast();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useSetPassForm();

  const onSubmit = async (datas: ISetPassForm) => {
    try {
      setLoading(true);
      await updatePasswordAdminUserDetail.mutateAsync({
        id: Number(id) ?? 0,
        password: datas.password,
        password_confirmation: datas.password_confirmation,
      });
      showToast({
        title: `パスワードの再設定が完了しました`,
        type: "success",
      });
      if (elearningId) {
        navigate(
          `${getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_DETAIL_USER, {
            id,
            elearningId,
          })}`,
        );
      } else if (quizId) {
        navigate(
          `${getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_DETAIL_USER, {
            id,
            quiz_id: quizId,
          })}`,
        );
      } else {
        navigate(
          `${getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_DETAIL, {
            id: id ?? 0,
          })}`,
        );
      }
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center mobile:py-8 mobile:px-4 desktop:p-8 whitespace-nowrap bg-white rounded-3xl w-full max-w-[572px]">
      <form
        className="flex flex-col max-md:max-w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlledInput
          type={typeInput}
          className="mt-8"
          control={control}
          formField="password"
          label="パスワード"
          note="※半角英数記号10文字以上"
          isRequired={true}
          maxLength={MAX_LENGTH.PASSWORD}
          placeholder="パスワードを入力してください"
          startSlot={<LockIcon />}
          endSlot={
            <div
              className="cursor-pointer"
              onClick={() => {
                setTypeInput((prev) => (prev === "text" ? "password" : "text"));
              }}
            >
              {typeInput === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          }
          errorMessage={errors.password?.message}
        />

        <ControlledInput
          type={typeInput2}
          className="mt-8"
          control={control}
          formField="password_confirmation"
          label="パスワード確認"
          note="※半角英数記号10文字以上"
          isRequired={true}
          maxLength={MAX_LENGTH.PASSWORD}
          placeholder="パスワードを再度入力してください"
          startSlot={<LockIcon />}
          endSlot={
            <div
              className="cursor-pointer"
              onClick={() => {
                setTypeInput2((prev) =>
                  prev === "text" ? "password" : "text",
                );
              }}
            >
              {typeInput2 === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          }
          errorMessage={errors.password_confirmation?.message}
        />

        <Button
          size="sm"
          className={"mt-[48px] max-w-[335px] w-full m-auto"}
          type="submit"
          isLoading={loading?.open}
        >
          パスワード再登録
        </Button>
      </form>
    </div>
  );
}
