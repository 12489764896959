import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import FormChangeEmail from "@/components/organisms/FormChangeEmail";
import { ROUTES_CONSTANT } from "@/configs/constants";
import FrontLayout from "@/pages/_layouts/front-main";
import AnimatedRoute from "@/router/transition.route";

const breadcrumbs: BeardcrumbOption[] = [
  {
    label: "マイページ",
    route: ROUTES_CONSTANT.MY_PAGE,
  },
  {
    label: "メールアドレス変更",
  },
];
function ChangeEmailPage() {
  return (
    <div className="desktop:py-6 max-w-[960px] m-auto w-full mobile:py-6 mobile:px-4">
      <div className="">
        <Breadcrumb options={breadcrumbs} />
        <h4 className="font-black mt-5 desktop:mb-8 mobile:mb-6">
          メールアドレス変更
        </h4>
        <FormChangeEmail />
      </div>
    </div>
  );
}

const AnimatedFrontChangeEmailPage = AnimatedRoute(ChangeEmailPage, false);

export default function FrontChangeEmailPage() {
  return (
    <FrontLayout>
      <AnimatedFrontChangeEmailPage />
    </FrontLayout>
  );
}
