import Checkbox from "@/components/atoms/Checkbox/checkbox";

/**
 * Interface: QuizzMultiAnswerProps
 *
 * Represents the props for the QuizzMultiAnswer component.
 *
 * @property questionNumber - The number of the question.
 * @property answers - The list of answers.
 */
interface QuizzMultiAnswerProps {
  questionNumber: number;
  answers: any[];
}

export default function QuizzMultiAnswer({
  questionNumber,
  answers,
}: QuizzMultiAnswerProps) {
  return (
    <div className="flex flex-col gap-2">
      {answers.map((answer, index) => (
        <Checkbox
          key={`question.${questionNumber}.answers.${index}`}
          name={`question.${questionNumber}.answer`}
          value={answer.title}
          label={answer.title}
          disableCursorPointer
        />
      ))}
    </div>
  );
}
