import Button from "@/components/atoms/Button/button";
import PlusIcon from "@/assets/icon/plus.svg";
import UserListForAdmin from "@/components/organisms/UserManagement/user-list-management";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { useCallback } from "react";
import AnimatedRoute from "@/router/transition.route";
import { ROLE_ENUM } from "@/configs/enum";
import useRole from "@/hooks/role";

/**
 * Renders the User List Page component.
 *
 * This component displays a page for managing users. It includes a heading, a button for creating a new user, and a UserListForAdmin component.
 *
 * @returns {JSX.Element} The rendered User List Page component.
 */
function UserListPage(): JSX.Element {
  const navigate = useNavigate();
  const { isAdminSystem } = useRole(true, [
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);

  const goToCreateUserPage = useCallback(() => {
    navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_CREATE);
  }, []);

  return (
    <div className="max-w-[100%] m-auto my-6">
      <div className="flex justify-between items-start">
        <h4 className="font-black">ユーザー管理</h4>
        {isAdminSystem && (
          <Button
            startSlot={<PlusIcon />}
            className="w-[160px]"
            onClick={goToCreateUserPage}
          >
            新規作成
          </Button>
        )}
      </div>
      <UserListForAdmin />
    </div>
  );
}
const AnimatedUserListPage = AnimatedRoute(UserListPage);
export default AnimatedUserListPage;
