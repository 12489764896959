import { Box, Collapse } from "@chakra-ui/react";
import clsx from "clsx";
import { ReactNode, useCallback, useEffect, useState } from "react";
import "./sidebar-item.scss";
import ArrowDownIcon from "@/assets/icon/arrow-down.svg";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { ROLE_ENUM } from "@/configs/enum";

export interface Option {
  icon?: ReactNode;
  label: string;
  key: string;
  route?: string;
  roles?: ROLE_ENUM[];
  children?: Option[];
}
interface SidebarItemsProps {
  options: Option[];
}
export default function SidebarItems({ options }: SidebarItemsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [itemActive, setItemActive] = useState(
    options
      .filter((i) => i.children?.length)
      .reduce((a, v) => ({ ...a, [v.key]: false }), {}),
  );

  const matchRoute = useCallback(
    (option: Option) => {
      if (!option.route) return false;
      const matched = matchPath(
        {
          caseSensitive: false,
          path: option.route,
          end: false,
        },
        location.pathname,
      );
      return matched;
    },
    [location],
  );

  const handleNavigate = (option: Option) => {
    if (!option.route) return;
    if (option.children?.length) {
      setItemActive((prev: any) => ({
        ...prev,
        [option.key]: !prev[option.key],
      }));
      return;
    }
    return navigate(option.route);
  };

  useEffect(() => {
    const key = options.find(
      (i) => i.route && location.pathname.includes(i.route),
    )?.key;
    try {
      key &&
        setItemActive((prev: any) => ({
          ...prev,
          [key]: true,
        }));
    } catch {
      //
    }
  }, []);

  return (
    <Box display="flex" gap={"8px"} flexDirection={"column"} flex="1">
      {options?.map((option) => {
        return (
          <div key={option.key}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              padding={"12px 16px"}
              columnGap={"8px"}
              borderRadius={"8px"}
              onClick={() => handleNavigate(option)}
              className={clsx(
                "sidebar-item__wrapper",
                matchRoute(option) ? "is-active" : "",
              )}
            >
              <div
                className={
                  matchRoute(option) ? "start-icon is-active" : "start-icon"
                }
              >
                {option.icon}
              </div>
              <span
                className={clsx(
                  "body1 text-secondary-custom label flex-1",
                  matchRoute(option) ? "is-active" : "",
                )}
              >
                {option.label}
              </span>
              {option.children?.length && (
                <div
                  className={clsx(
                    "end-icon transition",
                    matchRoute(option) ? "is-active" : "",
                    ((itemActive as any)[option.key] as boolean)
                      ? "rotate-180"
                      : "",
                  )}
                >
                  <ArrowDownIcon />
                </div>
              )}
            </Box>
            <Collapse
              in={(itemActive as any)[option.key] as boolean}
              startingHeight={0.5}
              // transition={{ exit: { duration: 0 }, enter: { duration: 0.5 } }}
            >
              <div className="flex flex-col gap-1 mt-1 sub-item__wrapper">
                {option.children?.map((opChild) => {
                  return (
                    <div
                      key={opChild.key}
                      className="flex flex-row justify-start items-center gap-2 px-4 py-1 cursor-pointer"
                      onClickCapture={() => handleNavigate(opChild)}
                    >
                      <div
                        className={clsx(
                          "w-2 h-2 m-[10px] rounded-full bg-[var(--text-secondary)] start-icon",
                          matchRoute(opChild) ? "is-active" : "",
                        )}
                      ></div>
                      <span
                        className={clsx(
                          "subtitle2 text-secondary-custom",
                          matchRoute(opChild) ? "is-active" : "",
                        )}
                      >
                        {opChild.label}
                      </span>
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </div>
        );
      })}
    </Box>
  );
}
