import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle(() => ({
  // define the part you're going to style
  container: {
    height: "24px",
    p: "5px 3px",
    display: "flex",
    alignItems: "center",
  },
  thumb: {
    height: "20px",
    width: "20px",
    // bg: "gray.100",
    bg: "#FAFAFA",
    transform: "translateY(-5px) translateX(-5px)",
    shadow: `0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F`,

    _checked: {
      transform: "translateY(-5px) translateX(15px)",
    },
  },
  track: {
    height: "10px",
    bg: "#959792",
    _checked: {
      bg: "#73be1e",
    },
  },
}));

const boxy = definePartsStyle({
  track: {
    borderRadius: "sm",
    p: 1,
  },
});

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { boxy },
});
