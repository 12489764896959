import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./App";
import { menuTheme } from "./theme/menu.theme";
import { modalTheme } from "./theme/modal.theme";
import { switchTheme } from "./theme/switch.theme.tsx";
import { DEFAULT_QUERY_OPTIONS } from "./api/constant";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: DEFAULT_QUERY_OPTIONS,
  },
});
const theme = extendTheme({
  fonts: {
    heading: `"Noto Sans CJK JP", sans-serif`,
    body: `"Noto Sans CJK JP", sans-serif`,
  },
  components: {
    Menu: menuTheme,
    Modal: modalTheme,
    Switch: switchTheme,
  },
});
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <App />
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>,
);
