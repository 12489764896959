import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import FlipCameraIcon from "@/assets/icon/flip-camera.svg";
import { Box, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { padStart } from "lodash";
import { getMimeTypeRecorder } from "@/shared/get";
import RecordRTC, {
  MediaStreamRecorder,
  RecordRTCPromisesHandler,
} from "recordrtc";
import useScreenSize from "@/hooks/screen";
import { useGlobalStore } from "@/states/global.state";

interface MediaRecorderChromeIosProps {
  isOpen: boolean;
  onClose: () => void;
  onFinish: (recordedData: File | Blob, duration: number) => void;
}

export default function MediaRecorderChromeIos(
  props: MediaRecorderChromeIosProps,
) {
  const refToDisableFocus = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const facingMode = useGlobalStore((state) => state.facingMode); // 'user' (front) or 'environment' (back)
  const setFacingMode = useGlobalStore((state) => state.setFacingMode);
  const mediaRecorderRef = useRef<RecordRTCPromisesHandler | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [recordingTime, setRecordingTime] = useState(0); // Added to track recording time
  const [isReady, setIsReady] = useState(false);
  const [isOnly1Cam, setIsOnly1Cam] = useState(true);
  const { screenWidth } = useScreenSize();

  const videoWidth = useMemo(() => screenWidth, [screenWidth]);
  const enumerateDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      return {
        camCount: devices.filter((device) => device.kind === "videoinput")
          .length,
        audioCount: devices.filter((device_1) => device_1.kind === "audioinput")
          .length,
      };
    } catch (err) {
      console.error(err);
    }
  };

  const getStream = async (facingMode: "user" | "environment") => {
    const em = await enumerateDevices();
    if (em && em?.camCount <= 1 && facingMode === "environment") {
      setIsOnly1Cam(true);
      return;
    }
    setIsOnly1Cam(false);
    const constraints = {
      video: {
        facingMode: facingMode,
      },
      audio: true,
    };
    setFacingMode(facingMode);
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        setIsReady(!!videoRef.current);
        if (facingMode === "user") {
          document
            .querySelector(".video-custom-js > div")
            ?.classList.add("vjs-mirror");
        } else {
          document
            .querySelector(".video-custom-js > div")
            ?.classList.remove("vjs-mirror");
        }
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          mediaStreamRef.current = stream;
        }
      })
      .catch((error) => {
        console.error(error);
        setIsReady(false);
      });
  };

  const initStream = () => {
    setFacingMode("user");
    getStream("user");
  };

  const destroyStream = () => {
    if (mediaStreamRef.current) {
      const mediaStream = mediaStreamRef.current;
      const tracks = mediaStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    mediaRecorderRef.current = null;
  };

  const startRecording = useCallback(() => {
    if (
      !isReady ||
      mediaRecorderRef.current ||
      !videoRef.current ||
      !mediaStreamRef.current
    )
      return;
    let options = {
      type: "video",
      mimeType: getMimeTypeRecorder(),
      width: videoWidth,
      height: (videoWidth / 3) * 4,
    } as RecordRTC.Options;
    if (options.mimeType === "video/mp4") {
      options = {
        ...options,
        recorderType: MediaStreamRecorder,
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2500000,
      };
    }

    mediaRecorderRef.current = new RecordRTCPromisesHandler(
      mediaStreamRef.current,
      options,
    );
    mediaRecorderRef.current.startRecording();
    setIsRecording(true);
  }, [mediaRecorderRef, mediaStreamRef, isReady, videoRef, videoWidth]);

  const stopRecording = useCallback(async () => {
    if (mediaRecorderRef.current) {
      await mediaRecorderRef.current.stopRecording();
      const blob = await mediaRecorderRef.current.getBlob();
      // reset recorder's state
      await mediaRecorderRef.current.reset();
      // clear the memory
      await mediaRecorderRef.current.destroy();
      // window.open(URL.createObjectURL(blob), "_blank");

      props.onFinish(blob, recordingTime);
      onClose();
    }
    setIsRecording(false);
    setRecordingTime(0); // Reset recording time
  }, [mediaRecorderRef, recordingTime]);

  const formatRecordingTime = useCallback((time: number): string => {
    if (time < 60) {
      return `00:${padStart(time.toString(), 2, "0")}`;
    }
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    return `${
      hours > 0 ? `${padStart(hours.toString(), 2, "0")}:` : ""
    }${padStart(minutes.toString(), 2, "0")}:${padStart(
      seconds.toString(),
      2,
      "0",
    )}`;
  }, []);

  const switchCamera = useCallback(() => {
    if (isRecording || !isReady) return;
    destroyStream();
    const newFacingMode = facingMode === "user" ? "environment" : "user";
    getStream(newFacingMode);
  }, [isRecording, videoRef, facingMode, isReady, destroyStream]);

  const onClose = () => {
    setFacingMode("user");
    setIsRecording(false);
    setRecordingTime(0);
    setIsReady(false);
    destroyStream();
    props.onClose();
  };

  useEffect(() => {
    if (isRecording) {
      const intervalId = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1); // Update recording time every second
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isRecording]);

  useEffect(() => {
    if (props.isOpen) initStream();
  }, [props.isOpen]);

  return (
    <Modal
      isCentered
      autoFocus={false}
      onClose={props.onClose}
      isOpen={props.isOpen}
      closeOnEsc={false}
      finalFocusRef={refToDisableFocus}
      closeOnOverlayClick={true}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        className="flex justify-center bg-[#0000006b] mobile-media-recorder"
        borderRadius="0"
      >
        <div className="video-custom-js">
          <div className="w-full h-[calc(100dvh_-_88px_-_30px)] bg-black flex flex-col justify-center vjs-mirror relative">
            {isRecording && (
              <div className="record-indicator" data-label="REC"></div>
            )}
            <video ref={videoRef} autoPlay muted playsInline />
          </div>
          <div className="bg-[#2b333f] flex flex-row justify-between control">
            <div className="time-control flex">
              <div className="current-time">
                <span>{formatRecordingTime(recordingTime)}</span>
              </div>
              <div className="divider">
                <span>/</span>
              </div>
              <div>
                <span>01:00:00:00</span>
              </div>
            </div>
          </div>

          <div>
            <div className="flex py-4 justify-center relative bg-white">
              <Box
                className="absolute top-1/2 left-2 -translate-y-1/2 text-[#F70905] px-2"
                onClick={onClose}
              >
                キャンセル
              </Box>
              <Box
                as="button"
                borderRadius="32px"
                border="1px solid #E6E8EA"
                className={clsx(
                  "bg-white flex items-center justify-center rouned-[32px] w-[56px] h-[56px] [box-shadow:0px_0px_16px_0px_#22390914] cursor-pointer",
                )}
                onClick={isRecording ? stopRecording : startRecording}
              >
                <motion.div
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: isRecording ? "0%" : "50%",
                    backgroundColor: isReady ? "#F70905" : "#919eab",
                    margin: "auto",
                  }}
                  animate={{
                    width: !isRecording ? 32 : 24,
                    height: !isRecording ? 32 : 24,
                  }}
                  whileHover={isReady ? { scale: 1.1 } : {}}
                  whileTap={isReady ? { scale: 0.9 } : {}}
                />
              </Box>
              <Box
                className="absolute top-1/2 right-4 -translate-y-1/2 px-2"
                onClickCapture={switchCamera}
              >
                <FlipCameraIcon
                  width={30}
                  height={30}
                  className={clsx(
                    "[&_path]:fill-[#73be1e]",
                    (isRecording || !isReady || isOnly1Cam) &&
                      "[&_path]:fill-[#919eab]",
                  )}
                />
              </Box>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
