import yup from "@/shared/validation/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

interface ISearchELearningForm {
  title: string;
  user_name: string;
  category: { value: boolean | string; label: string } | any;
  tag: { value: boolean | string; label: string } | any;
  is_public: { value: boolean | string; label: string } | any;
}

export const useSearchELearningForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(),
        user_name: yup.string().required(),
        category: yup.object().nullable().required(),
        tag: yup.object().nullable().required(),
        is_public: yup.object().nullable().required(),
      }),
    [],
  );

  return useForm<ISearchELearningForm>({
    resolver: yupResolver(validationScheme),
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      is_public: { value: "", label: "公開,非公開" },
      category: { value: "", label: "全てのカテゴリ" },
      tag: { value: "", label: "全てのタグ" },
    },
  });
};
