import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/shared/validation/yup";
import { MAX_LENGTH } from "@/configs/constants";

export interface IAddFolderForm {
  name: string;
}

export const useAddFolderForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .label("folder.title")
          .noEmoji()
          .max(MAX_LENGTH.VARCHAR)
          .required(),
      }),
    [],
  );

  return useForm<IAddFolderForm>({
    resolver: yupResolver(validationScheme),
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });
};
