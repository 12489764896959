import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useMemo, useRef } from "react";
import { ICustomEmojiForm, useCustomEmojiForm } from "./validation";
import Button from "@/components/atoms/Button/button";
import { useGlobalStore } from "@/states/global.state";
import { UploadImage } from "./upload";
import { UploadResponse } from "@/hooks/upload";
import { handleApiError, mapAPIError } from "@/hooks/error";
import { useCreateEmojiMutation } from "@/api/emoji";
import { Attachment } from "@/api/type";
import initEmojies from "@emoji-mart/data";
import useScreenSize from "@/hooks/screen";

interface IUploadRef {
  resetImage: () => void;
}
interface UploadEmojiFormProps {
  onSuccess: () => void;
}
export default function UploadEmojiForm({ onSuccess }: UploadEmojiFormProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { loading, setLoading } = useGlobalStore();
  const uploadRef = useRef<IUploadRef | null>(null);
  const { control, formState, setError, setValue, handleSubmit, watch, reset } =
    useCustomEmojiForm();
  const { screenWidth } = useScreenSize();
  const { errors } = formState;
  const createEmojiMutation = useCreateEmojiMutation();

  const isDisabled = useMemo(() => {
    return !watch("name") || !watch("attachment");
  }, [watch()]);

  const existEmojiCode = useMemo(() => {
    if (!(initEmojies as any)?.emojis) return [];
    return Object.keys((initEmojies as any).emojis);
  }, [initEmojies]);

  const onSubmit = async (values: ICustomEmojiForm) => {
    const isExists = existEmojiCode.includes(values.name);
    if (isExists)
      return setError("name", { message: "名前が既に登録されています。" });
    try {
      setLoading(true);
      await createEmojiMutation.mutateAsync({
        code: `:${values.name.replace(/\s/g, "_")}:`,
        name: values.name,
        attachment_attributes: values.attachment as Attachment,
      });
      closePopover();
      onSuccess();
    } catch (e) {
      const err = handleApiError(e);
      mapAPIError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  const closePopover = () => {
    reset({
      name: "",
      code: "",
      attachment: null,
    });
    uploadRef.current?.resetImage();
    onClose();
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={closePopover}
      placement={screenWidth <= 475 ? "top-end" : "top-start"}
      closeOnBlur={true}
      closeOnEsc={true}
    >
      <PopoverTrigger>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          className="cursor-pointer"
        >
          <g
            fill="none"
            stroke="#73be1e"
            strokeLinecap="round"
            strokeWidth={2.55}
          >
            <path
              strokeDasharray="2 4"
              strokeDashoffset={6}
              d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3"
            >
              <animate
                attributeName="stroke-dashoffset"
                dur="0.6s"
                repeatCount="indefinite"
                values="6;0"
              ></animate>
            </path>
            <path
              strokeDasharray={30}
              strokeDashoffset={30}
              d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21"
            >
              <animate
                fill="freeze"
                attributeName="stroke-dashoffset"
                begin="0.1s"
                dur="0.3s"
                values="30;0"
              ></animate>
            </path>
            <path strokeDasharray={10} strokeDashoffset={10} d="M12 16v-7.5">
              <animate
                fill="freeze"
                attributeName="stroke-dashoffset"
                begin="0.5s"
                dur="0.2s"
                values="10;0"
              ></animate>
            </path>
            <path
              strokeDasharray={6}
              strokeDashoffset={6}
              d="M12 8.5l3.5 3.5M12 8.5l-3.5 3.5"
            >
              <animate
                fill="freeze"
                attributeName="stroke-dashoffset"
                begin="0.7s"
                dur="0.2s"
                values="6;0"
              ></animate>
            </path>
          </g>
        </svg>
      </PopoverTrigger>
      <PopoverContent p={4}>
        <Stack spacing={2}>
          <Stack display={"flex"} flexFlow={"row"}>
            <UploadImage
              sizeUnit="KB"
              mimeSize={500}
              onChange={(content: UploadResponse | null) => {
                if (content) {
                  setValue("attachment", content);
                }
              }}
              ref={uploadRef}
              onError={(message: string) =>
                setError("code", {
                  message,
                })
              }
            />

            <ControlledInput
              control={control}
              formField="name"
              isTrim={true}
              maxLength={100}
              className="w-full"
              placeholder="名前を入力する"
              errorMessage={errors.name?.message}
            />
          </Stack>

          {errors.attachment?.message ? (
            <p className="w-full text-error--main input-error__message">
              {errors.attachment.message}
            </p>
          ) : (
            <></>
          )}

          <div className="flex flex-row mt-2 gap-[10px] justify-end">
            <Button
              type="button"
              size="sm"
              variant="info"
              buttonCustom={{
                padding: "8px 16px",
                borderRadius: "8px",
              }}
              onClick={closePopover}
            >
              キャンセル
            </Button>
            <Button
              type="submit"
              size="sm"
              buttonCustom={{
                padding: "8px 16px",
                borderRadius: "8px",
                circleStyles: "var(--primary-main-color)",
                color: "white",
                bg: "var(--primary-main-color)",
              }}
              isLoading={loading?.open}
              isDisabled={isDisabled}
              onClick={handleSubmit(onSubmit)}
            >
              登録
            </Button>
          </div>
        </Stack>
      </PopoverContent>
    </Popover>
  );
}
