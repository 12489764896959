import { create } from "zustand";

export type FileItem = {
  name: string;
  type: string;
  objectURL: string;
};
type State = {
  files: FileItem[];
};

type Action = {
  setFile: (file: FileItem) => void;
  removeFile: (url: string) => void;
  reset: () => void;
};

const useFileStore = create<State & Action>((set) => ({
  files: [],
  setFile(file) {
    set((state) => ({
      ...state,
      files: [...state.files, file],
    }));
  },
  removeFile(url) {
    URL.revokeObjectURL(url);
    set((state) => ({
      ...state,
      files: state.files.filter((i) => i.objectURL !== url),
    }));
  },
  reset() {
    set((state) => {
      state.files.forEach((file) => {
        URL.revokeObjectURL(file.objectURL);
      });
      return {
        files: [],
      };
    });
  },
}));

export { useFileStore };
