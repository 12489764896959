import { easeInOut, motion } from "framer-motion";
import "./loading.scss";
export default function LoadingFC() {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.3 }}
      transition={{ duration: 0.3, ease: easeInOut }}
      className="w-[100vw] h-[100vh] bg-[#0000007a] absolute z-[99998] top-0 left-0"
      id="loading__overlay"
    >
      <div
        className="top-[50%] left-[50%] absolute w-[48px] h-[48px] z-99999  transform -translate-x-1/2 -translate-y-1/2"
        id="loading__spinner"
      >
        <div className="loader z-99999"></div>
        <p className="text-center body1 w-[100px] -ml-[25px]">読み込み中...</p>
      </div>
    </motion.div>
  );
}
