import { Menu, MenuButton, MenuList, Portal } from "@chakra-ui/react";
import data, { EmojiMartData, Emoji } from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import EmojiForm from "./emoji-form";
import useScreenSize from "@/hooks/screen";

interface EmojiMartCustom {
  emojis: {
    id: string;
    name: string;
    keywords: string[];
    skins: { src: string }[];
  }[];
}
interface EmojiProps {
  custom: EmojiMartCustom[];
  data: EmojiMartData;
  children?: React.ReactNode;
  onSelect: (value: Emoji) => void;
  refetchListCustomEmoji: () => void;
}

export default function EmojiPicker({
  data,
  children,
  onSelect,
  custom,
  refetchListCustomEmoji,
}: EmojiProps) {
  const { isMobile } = useScreenSize();
  return (
    <Menu isLazy>
      <MenuButton className="!bg-transparent">{children}</MenuButton>
      <Portal>
        <MenuList className={`!py-0 ${isMobile && "pr-1 !bg-transparent"}`}>
          <Picker
            data={data}
            custom={custom}
            onEmojiSelect={onSelect}
            skinTonePosition={"none"}
            locale="ja"
            theme="light"
          />
          <div className="absolute bottom-[20px] right-[15px] cursor-pointer w-6 h-6 z-10">
            <EmojiForm onSuccess={() => refetchListCustomEmoji()} />
          </div>
        </MenuList>
      </Portal>
    </Menu>
  );
}

EmojiPicker.defaultProps = {
  data,
};
