import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Button from "@/components/atoms/Button/button";
import EditWhiteIcon from "@/assets/icon/edit-white-02.svg";
import {
  ADMIN_ROUTES_CONSTANT,
  ELEARNING_CONTENT_TYPE_LABEL_2,
} from "@/configs/constants";
import { Stepper } from "@/components/molecules/Stepper/stepper";
import CommentElearning from "@/components/molecules/CommentElearning/comment";
import { TabItem } from "@/components/molecules/TabView/tab-view";
import { getRoute } from "@/shared/get/route";

import ElearningDocumentContent from "./document-content";
import ElearningVideoContent from "./video-content";
import ElearningExamContent from "./exam-content";
import ElearningRolePlayingContent from "./role-playing-content";
import ElearningComplianceTrainingContent from "./compliance-training-content";
import { ELEARNING_CONTENT_KIND_ENUM } from "@/configs/enum";
import { ShowElearningResponseBody } from "@/api/e-learning/request";
import clsx from "clsx";
import { ShowQuizzResponseBody } from "@/api/quizz/request";
import { showQuizzQuery } from "@/api/quizz";
import { useQueryClient } from "@tanstack/react-query";

type ElearningContentDetailProps = {
  id: number;
  course: ShowElearningResponseBody | undefined;
};

export default function ElearningContentDetail({
  id,
  course,
}: ElearningContentDetailProps) {
  // #region Data
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [activeIndexStep, setActiveIndexStep] = useState(0);

  const [currentQuizzContent, setCurrentQuizzContent] =
    useState<ShowQuizzResponseBody | null>(null);

  const listStep = useMemo<TabItem[]>(() => {
    const count: Record<string, number> = {};

    return (
      course?.course_contents?.map(({ kind }) => {
        count[kind] = count[kind] !== undefined ? count[kind] + 1 : 0;
        return {
          label:
            ELEARNING_CONTENT_TYPE_LABEL_2[
              kind as keyof typeof ELEARNING_CONTENT_TYPE_LABEL_2
            ],
        };
      }) ?? []
    );
  }, []);

  const currentStep = useMemo(() => {
    return course?.course_contents?.[activeIndexStep];
  }, [course?.course_contents, activeIndexStep]);

  const getQuizzContent = useCallback(async () => {
    const res = await showQuizzQuery(queryClient, currentStep!.quiz!.id);
    setCurrentQuizzContent(res);
  }, [currentStep?.quiz?.id]);

  useEffect(() => {
    if (currentStep?.quiz?.id) {
      getQuizzContent();
    }
  }, [currentStep]);

  const sampleVideo = useMemo(() => {
    if (currentStep?.youtube_id?.length) {
      return {
        youtube_id: currentStep.youtube_id as string,
        youtube_file_name: currentStep.youtube_file_name as string,
      };
    }
    if (currentStep?.video) {
      return {
        youtube_id: currentStep.video.youtube_id as string,
        youtube_file_name: currentStep.video.youtube_file_name as string,
        video_title: currentStep.video.title as string,
      };
    }

    return null;
  }, [currentStep]);

  const onChangeStep = (index: number) => {
    setActiveIndexStep(index);
  };

  const onEditCourse = () => {
    navigate(getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_EDIT, { id }));
  };

  useEffect(() => {
    if (searchParams.size) {
      setSearchParams({});
    }
  }, []);

  return (
    <div>
      <div className="mb-5 flex justify-between items-center flex-wrap">
        <h5 className="font-black">コンテンツ</h5>
        <div className="flex items-center gap-2">
          <Button
            startSlot={<EditWhiteIcon width={24} height={24} />}
            className="gap-1"
            buttonCustom={{
              padding: "8px 20px",
              borderRadius: "20px",
              fontWeight: "700",
              lineHeight: "24px",
            }}
            size="sm"
            onClick={onEditCourse}
          >
            編集する
          </Button>
        </div>
      </div>

      <div
        className={clsx(
          "mb-6 flex px-5 gap-6 rounded-[10px] flex-col bg-white",
          currentStep?.kind === ELEARNING_CONTENT_KIND_ENUM.ROLEPLAYING_THEME
            ? "pt-6"
            : "py-6 ",
        )}
      >
        <Stepper
          listStep={listStep}
          activeIndex={activeIndexStep}
          onChange={onChangeStep}
        />
        <div className={clsx("w-full")}>
          {currentStep?.kind === ELEARNING_CONTENT_KIND_ENUM.DOCUMENT && (
            <ElearningDocumentContent
              documentContent={currentStep.document?.content}
              attachment={currentStep.attachment}
              documentAttachment={currentStep.document?.attachment}
              key={currentStep.course_id}
            />
          )}
          {currentStep?.kind === ELEARNING_CONTENT_KIND_ENUM.VIDEO && (
            <ElearningVideoContent
              attachment={
                currentStep?.youtube_id
                  ? {
                      youtube_file_name: currentStep.youtube_file_name,
                      youtube_id: currentStep.youtube_id,
                    }
                  : undefined
              }
              videoAttachment={
                currentStep?.video
                  ? {
                      youtube_file_name: currentStep.video.youtube_file_name,
                      youtube_id: currentStep.video.youtube_id,
                    }
                  : undefined
              }
              key={currentStep.course_id}
            />
          )}
          {currentStep?.kind === ELEARNING_CONTENT_KIND_ENUM.QUIZ && (
            <ElearningExamContent
              questions={currentQuizzContent?.quiz_questions}
              key={currentStep.course_id}
            />
          )}
          {currentStep?.kind ===
            ELEARNING_CONTENT_KIND_ENUM.ROLEPLAYING_THEME && (
            <ElearningRolePlayingContent
              title={currentStep.roleplaying_theme}
              key={currentStep.course_id}
              sampleVideo={sampleVideo}
            />
          )}
          {currentStep?.kind === ELEARNING_CONTENT_KIND_ENUM.COMPLIANCE && (
            <ElearningComplianceTrainingContent
              documentContent={currentStep?.compliance?.content}
              attachment={currentStep?.attachment}
              documentAttachment={currentStep?.compliance?.attachment}
              key={currentStep.course_id}
            />
          )}
        </div>
      </div>

      <div
        className="flex px-5 pt-2 pb-5 gap-4 rounded-[20px] bg-primary--lighter flex-col"
        id="comment_block"
      >
        <h5 className="font-black">コメント</h5>
        <CommentElearning className="bg-white rounded-[10px]" id={id} />
      </div>
    </div>
  );
}
