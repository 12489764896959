import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface CustomDatePickerProps {
  value: Date | null;
  onChange: (date: Date | null) => void;
  customInput?: React.ReactNode | undefined;
  dateFormat?: string;
  minDate?: Date;
}

export default function CustomDatePicker(props: CustomDatePickerProps) {
  return (
    <DatePicker
      selected={props.value}
      onChange={(date) => props.onChange(date)}
      dateFormat={props?.dateFormat || "yyyy/MM/dd"}
      customInput={props.customInput}
      minDate={props.minDate}
      isClearable
    />
  );
}
