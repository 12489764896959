import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import Button from "@/components/atoms/Button/button";
import { useNavigate } from "react-router-dom";
import { MAX_LENGTH, ROUTES_CONSTANT } from "@/configs/constants";
import LockIcon from "@/assets/icon/lock.svg";
import EyeCloseIcon from "@/assets/icon/eye-close.svg";
import EyeOpenIcon from "@/assets/icon/eye-open.svg";
import { useState } from "react";
import { IChangePasswordForm, useChangePasswordForm } from "./validation";
import { useAuthStore } from "@/states/auth.state";
import { useUpdatePasswordUserMutation } from "@/api/user";
import { handleApiError, mapAPIError } from "@/hooks/error";
import { useGlobalStore } from "@/states/global.state";
import useToast from "@/hooks/toast";

/**
 * This component renders a form for changing the password.
 */
export default function FormChangePassword() {
  const navigate = useNavigate();
  const [typeInput, setTypeInput] = useState<"password" | "text">("password");
  const [typeInput2, setTypeInput2] = useState<"password" | "text">("password");
  const [typeInput3, setTypeInput3] = useState<"password" | "text">("password");
  const [errApi, setErrApi] = useState("");
  const { loading, setLoading } = useGlobalStore();
  const { showToast } = useToast();
  const { userInfo } = useAuthStore();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useChangePasswordForm();
  const updatePasswordUserMutation = useUpdatePasswordUserMutation();
  const onSubmit = async (datas: IChangePasswordForm) => {
    try {
      setLoading(true);
      const param = {
        ...datas,
        id: userInfo?.id,
      };
      await updatePasswordUserMutation.mutateAsync(param);
      showToast({
        title: `パスワードの設定が完了しました`,
        type: "success",
        site: "front",
      });
      navigate(ROUTES_CONSTANT.MY_PAGE);
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError, (e) => {
        const path = e.path;

        if (e.path === "base" || e.path === "id") {
          setErrApi(e.message);
        } else if (e.path === "popup") {
          return;
        }

        setError(path, { message: e.message });
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center whitespace-nowrap bg-white rounded-3xl w-full max-w-[960px] desktop:p-8 mobile:p-8">
      {errApi.length > 0 && (
        <p className="text-error--main input-error__message mb-1">{errApi}</p>
      )}
      <div className="subtitle2 mb-4 text-secondary-custom">
        現在のパスワード
      </div>

      <form
        className="flex flex-col max-md:max-w-full"
        onSubmit={handleSubmit(onSubmit, () => {
          setErrApi("");
        })}
      >
        <ControlledInput
          type={typeInput}
          control={control}
          formField="current_password"
          label="現在のパスワード"
          maxLength={MAX_LENGTH.PASSWORD}
          isRequired={true}
          placeholder="パスワードを入力してください"
          note="※半角英数記号10文字以上"
          startSlot={<LockIcon />}
          endSlot={
            <div
              className="cursor-pointer"
              onClick={() => {
                setTypeInput((prev) => (prev === "text" ? "password" : "text"));
              }}
            >
              {typeInput === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          }
          errorMessage={errors.current_password?.message}
        />

        <div className="subtitle2 mb-4 text-secondary-custom mt-12 ">
          新しいパスワード
        </div>

        <ControlledInput
          type={typeInput2}
          control={control}
          formField="password"
          label="新しいパスワード"
          maxLength={MAX_LENGTH.PASSWORD}
          note="※半角英数記号10文字以上"
          isRequired={true}
          placeholder="パスワードを入力してください"
          startSlot={<LockIcon />}
          endSlot={
            <div
              className="cursor-pointer"
              onClick={() => {
                setTypeInput2((prev) =>
                  prev === "text" ? "password" : "text",
                );
              }}
            >
              {typeInput2 === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          }
          errorMessage={errors.password?.message}
        />

        <ControlledInput
          type={typeInput3}
          className="mt-8"
          control={control}
          formField="password_confirmation"
          label="パスワード確認"
          maxLength={MAX_LENGTH.PASSWORD}
          note="※半角英数記号10文字以上"
          isRequired={true}
          placeholder="パスワードを再度入力してください"
          startSlot={<LockIcon />}
          endSlot={
            <div
              className="cursor-pointer"
              onClick={() => {
                setTypeInput3((prev) =>
                  prev === "text" ? "password" : "text",
                );
              }}
            >
              {typeInput3 === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          }
          errorMessage={errors.password_confirmation?.message}
        />

        <Button
          size="sm"
          className={"mt-12 max-w-[335px] w-full m-auto"}
          type="submit"
          isLoading={loading.open}
        >
          設定する
        </Button>
      </form>
    </div>
  );
}
