import AuthLayout from "@/pages/_layouts/auth";
import Logo from "@/assets/logo.svg";
import FormSetPasswordFirstLogin from "@/components/organisms/FormSettingAccount/setting-password";
export default function SettingPasswordFirstLogin() {
  return (
    <AuthLayout>
      <div className="py-[80px] flex w-full items-center flex-col px-[20px]">
        <Logo />
        <h4 className="font-[900] my-[16px] text-center">管理画面</h4>
        <div className="leading-[21px] font-[400] text-[14px] text-center mb-[48px] ">
          メールに記載されているパスワードを入力し、パスワードを変更してください。
        </div>
        <FormSetPasswordFirstLogin isAdminPage={true} />
      </div>
    </AuthLayout>
  );
}
