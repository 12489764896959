import {
  MutateOptions,
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CreateQuizzRequestBody,
  CreateQuizzResponseBody,
  ShowQuizzResponseBody,
  DeleteQuizzResponseBody,
  showQuizzApi,
  createQuizzApi,
  listQuizzApi,
  deleteQuizzApi,
  updateQuizzApi,
  UpdateQuizzRequestBody,
  UpdateQuizzResponseBody,
  FilterJoinedUsersQuizzRequestBody,
  FilterJoinedUsersQuizzResponseBody,
  listJoinedUsersQuizzApi,
} from "./request";
import { OrderDirection, Paging, TQuizz } from "../type";

export type FilterQuizzesRequestBody = {
  page: number;
  per_page: number;
  order_by?: string;
  order_direction?: OrderDirection;
  title?: string;
};
export type FilterQuizzResponseBody = {
  quizzes: TQuizz[];
  paging: Paging;
};

export const useCreateQuizzMutation = (
  options: MutateOptions<
    CreateQuizzResponseBody,
    unknown,
    CreateQuizzRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: createQuizzApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useUpdateQuizzMutation = (
  options: MutateOptions<
    UpdateQuizzResponseBody,
    unknown,
    UpdateQuizzRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updateQuizzApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useDeleteQuizzMutation = (
  options: MutateOptions<
    DeleteQuizzResponseBody,
    unknown,
    number,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: deleteQuizzApi,
    },
    queryClient,
  );
};

export const fetchFilterQuizzesList = async (
  queryClient: QueryClient,
  params?: Partial<FilterQuizzesRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["filter-quizzes", params],
    queryFn: listQuizzApi,
  });
};

export const useQuizzListQuery = (
  params: Partial<FilterQuizzesRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterQuizzResponseBody,
      unknown, // TError,
      FilterQuizzResponseBody,
      (string | Partial<FilterQuizzesRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-quizzes", params],
    queryFn: listQuizzApi,
    enabled: !!params,
    ...options,
  });
};

export const useShowQuizzQuery = (
  params: number | string,
  options: Omit<
    UseQueryOptions<
      ShowQuizzResponseBody,
      unknown,
      ShowQuizzResponseBody,
      (string | number)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["show-quizz", params],
    queryFn: showQuizzApi,
    enabled: !!params,
    ...options,
  });
};

export const showQuizzQuery = async (
  queryClient: QueryClient,
  params: number | string,
) => {
  return queryClient.fetchQuery({
    queryKey: ["show-quizz", params],
    queryFn: showQuizzApi,
  });
};

export const useJoinedUsersQuizzQuery = (
  params: Partial<FilterJoinedUsersQuizzRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterJoinedUsersQuizzResponseBody,
      unknown, // TError,
      FilterJoinedUsersQuizzResponseBody,
      (string | Partial<FilterJoinedUsersQuizzRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-joined-users-quizz", params],
    queryFn: listJoinedUsersQuizzApi,
    enabled: !!params,
    ...options,
  });
};
