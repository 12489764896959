function PageNotFound({ isAdmin }: { isAdmin?: boolean }): JSX.Element {
  const goToHome = () => {
    location.href = isAdmin ? "/admin" : "/";
  };
  return (
    <div className="flex justify-center h-[100vh] w-full items-center">
      <div className="flex flex-col text-center max-w-[600px] ">
        <div className="self-center w-full max-w-[311px]">
          <h1 className="text-[7rem] text-stone-900 font-black">404</h1>
        </div>
        <div className="flex flex-col mt-4 w-full">
          <div className="flex flex-col justify-center px-5 w-full">
            <h4 className="w-full text-3xl tracking-tight text-stone-700">
              ページが見つかりません
            </h4>
            <p className="mt-3 w-full text-base tracking-wide leading-6 text-stone-500">
              おっと！お探しのページは存在しません
            </p>
          </div>
          <a
            onClickCapture={(e) => {
              e.preventDefault();
              goToHome();
            }}
            className="flex flex-col justify-center self-center mt-6 max-w-full text-sm font-medium tracking-normal leading-5 text-white bg-lime-500 rounded-[100px] w-[146px] cursor-pointer"
          >
            <span className="justify-center px-6 py-2.5">ホームページ</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
