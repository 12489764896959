import { Attachment } from "@/api/type";
import PDFViewer from "@/components/molecules/PDFview/pdf-viewer";
import { getLinkMedia } from "@/shared/get";
import clsx from "clsx";
import DOMPurify from "dompurify";

interface ElearningComplianceTrainingContentProps {
  attachment?: Attachment | null;
  documentAttachment?: Attachment | null;
  documentContent?: string | null;
  handleWatchFullPDF?: () => void;
}

export default function ElearningComplianceTrainingContent({
  attachment,
  documentAttachment,
  documentContent,
  handleWatchFullPDF,
}: ElearningComplianceTrainingContentProps) {
  if (!attachment && !documentAttachment && !documentContent)
    return <div className="h-[682px]"></div>;

  return (
    <>
      {(attachment || documentAttachment) && (
        <PDFViewer
          file={getLinkMedia(attachment?.key ?? documentAttachment?.key, true)}
          onWatchFullPDF={handleWatchFullPDF}
        />
      )}

      {documentContent && (
        <div
          className={clsx(
            "bg-white w-full ck-content",
            (attachment || documentAttachment) && "mt-5",
          )}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(documentContent || "", {
              ADD_TAGS: ["iframe"],
              ADD_ATTR: [
                "allow",
                "allowfullscreen",
                "frameborder",
                "scrolling",
                "src",
                "title",
                "width",
                "height",
                "target",
              ],
              ADD_URI_SAFE_ATTR: ["src"],
            }),
          }}
        ></div>
      )}
    </>
  );
}
