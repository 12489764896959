import Button from "@/components/atoms/Button/button";
import AnimatedRoute from "@/router/transition.route";
import PlusIcon from "@/assets/icon/plus.svg";
import CategorySetting from "@/components/molecules/CategorySetting";
import { v4 as uuidv4 } from "uuid";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  fetchListCategory,
  useDeleteCategoryMutation,
  useUpdatePositionCategoryMutation,
} from "@/api/category";
import { PAGINATION_CONSTANT } from "@/configs/constants";
import { ICategoryForm } from "@/components/molecules/CategorySetting/validation";
import { ModalConfirm } from "@/components/molecules/ModalConfirm/modal-confirm";
import { cutString } from "@/shared/transform";
import { useDisclosure } from "@chakra-ui/react";
import { useGlobalStore } from "@/states/global.state";
import useToast from "@/hooks/toast";
import { handleApiError } from "@/hooks/error";
import { isNumeric } from "@/shared/get/check";
import { ReactSortable, Sortable } from "react-sortablejs";
import { delay } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "@/components/atoms/Spinner/spinner";
import { useQueryClient } from "@tanstack/react-query";
import { ModalError } from "@/components/molecules/ModalError/modal-error";

function CategoryListPage() {
  const { setLoading } = useGlobalStore();
  const updatePosition = useUpdatePositionCategoryMutation();
  const { showToast } = useToast();
  const [page, setPage] = useState<number>(PAGINATION_CONSTANT.DEFAULT_PAGE);
  const [categories, setCategories] = useState<ICategoryForm[]>([
    {
      id: uuidv4(),
      name: "",
      isEdit: true,
      children: [
        {
          child_id: uuidv4(),
          name: "",
        },
      ],
    },
  ]);
  const [dataModal, setDataModal] = useState({
    id: 0,
    title: "",
  });
  const {
    isOpen: isOpenModalDelete,
    onOpen: openModalDelete,
    onClose: closeModalDelete,
  } = useDisclosure();
  const [err, setError] = useState("");
  const [errDelete, setErrorDelete] = useState("");
  const queryClient = useQueryClient();
  const deleteCateMutation = useDeleteCategoryMutation();
  const [isFetching, setIsFetching] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);

  const addNewCateTag = () => {
    setCategories((prev) => [
      ...prev,
      {
        id: uuidv4(),
        name: "",
        isEdit: true,
        children: [],
      },
    ]);
  };

  const getListCategory = async (page = 1) => {
    setPage(page);
    setIsFetching(true);
    const res = await fetchListCategory(queryClient, {
      page,
      per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
      for_admin: true,
      order_by: "position",
      order_direction: "asc",
    });
    const paging = res.paging;
    const data = res.categories;
    setIsFetching(false);
    /** mapping data */
    const _categories = data.map((i) => ({
      id: String(i.id),
      name: i.name,
      isEdit: false,
      children:
        i.sub_categories
          ?.sort((a, b) => a.position - b.position)
          .map((sub) => ({
            child_id: String(sub.id),
            name: sub.name,
          })) ?? [],
    }));
    setTotalRecord(paging.total_records);
    if (paging.current_page === 1) {
      setCategories(_categories);
    } else {
      setCategories((prev) => [...prev, ..._categories]);
    }
  };

  const hasMore = useMemo(() => {
    return categories.length < totalRecord;
  }, [categories, totalRecord]);

  const onDeleteCategory = async () => {
    setErrorDelete("");
    try {
      setLoading(true);
      await deleteCateMutation.mutateAsync({
        id: dataModal.id,
      });
      showToast({
        title: `${cutString(dataModal.title)}を削除しました`,
        type: "success",
      });
      closeModalDelete();
      getListCategory();
    } catch (errRes) {
      const err = handleApiError(errRes);
      if (
        err?.[0]?.path === "id" ||
        err?.[0]?.path === "base" ||
        err?.[0]?.path === "popup"
      ) {
        closeModalDelete();
        setErrorDelete(err[0].message);
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  const handleSort = async (evt: Sortable.SortableEvent) => {
    setErrorDelete("");
    setError("");
    const newIndex = evt.newIndex as number;
    const oldIndex = evt.oldIndex as number;
    if (oldIndex == newIndex) return;

    try {
      let positions = {
        category1: Number(categories[oldIndex].id),
        category2: Number(categories[newIndex].id) as number | null,
      };
      if (oldIndex < newIndex) {
        positions = {
          category1: Number(categories[oldIndex].id),
          category2:
            newIndex === categories.length - 1
              ? null
              : Number(categories[newIndex + 1].id),
        };
      }
      setLoading(true, true);
      await updatePosition.mutateAsync({
        categories: {
          parent_id: null,
          positions,
        },
      });
      // getListCategory();
    } catch (errRes) {
      const err = handleApiError(errRes);
      setError(err?.[0].message || err?.message);
    } finally {
      delay(() => setLoading(false, false), 300);
    }
  };

  const onNextPage = useCallback(() => {
    setTimeout(() => {
      getListCategory(page + 1);
    }, 300);
  }, [page]);

  useEffect(() => {
    getListCategory();
  }, []);

  return (
    <div className="max-w-[100%] m-auto my-6">
      <h4 className="mb-6 font-black">カテゴリ一覧</h4>
      {err.length > 0 && (
        <p className="text-error--main input-error__message mb-1 is-invalid">
          {err.replace(/<br\s*\/?>/, "\n")}
        </p>
      )}
      {page == 1 && isFetching ? (
        <div className="flex justify-center">
          <Spinner
            circleStyles={{
              borderColor: `var(--primary-main-color) transparent transparent transparent`,
            }}
          />
        </div>
      ) : (
        <InfiniteScroll
          dataLength={categories.length}
          next={onNextPage}
          hasMore={hasMore}
          loader={null}
          endMessage={null}
          scrollableTarget="main-container"
        >
          <ReactSortable
            tag="div"
            list={categories}
            setList={setCategories}
            onEnd={(evt) => handleSort(evt)}
            draggable=".drag-handle"
            onMove={(e) => {
              return e.related.className.indexOf("static") === -1;
            }}
            className="flex flex-col gap-y-6"
          >
            {categories.map((data, index) => (
              <CategorySetting
                data={data}
                key={data.id}
                index={index}
                onChange={() => getListCategory(1)}
                onDelete={(data) => {
                  setError("");
                  if (!isNumeric(data.id)) {
                    setCategories((prev) =>
                      prev.filter((i) => i.id !== data.id),
                    );
                  } else {
                    setDataModal({
                      title: data.name,
                      id: Number(data.id),
                    });
                    openModalDelete();
                  }
                }}
              />
            ))}
          </ReactSortable>
        </InfiniteScroll>
      )}

      <Button
        size="xs"
        className="mt-6"
        buttonCustom={{
          padding: "8px 12px",
        }}
        startSlot={<PlusIcon width={20} height={20} />}
        onClick={addNewCateTag}
      >
        大カテゴリを追加する
      </Button>
      <ModalConfirm
        isOpen={isOpenModalDelete}
        onClose={() => {
          setErrorDelete("");
          closeModalDelete();
        }}
        header={`${cutString(dataModal?.title)}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">
              {cutString(dataModal?.title)}を削除します。
            </p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onSubmit={onDeleteCategory}
      />
      <ModalError
        isOpen={!!errDelete.length}
        message={
          <p className="w-full text-error--main input-error__message">
            {errDelete.replace(/<br\s*\/?>/, "\n")}
          </p>
        }
        onClose={() => {
          setErrorDelete("");
        }}
      />
    </div>
  );
}

const AnimatedCategoryListPage = AnimatedRoute(CategoryListPage);
export default AnimatedCategoryListPage;
