import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import MailIcon from "@/assets/icon/mail.svg";
import Button from "@/components/atoms/Button/button";
import { IResetForm, useResetForm } from "./validation";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTES_CONSTANT, ROUTES_CONSTANT } from "@/configs/constants";
import { useResetPasswordMutation } from "@/api/auth";
import { useGlobalStore } from "@/states/global.state";
import { useState } from "react";

/**
 * Represents the props interface for the FormSetInformationFirstLogin component.
 * @interface FormSetPasswordProps
 * @property {boolean} [isAdminPage] - Indicates whether the component is rendered on an admin page.
 */
interface FormResetPasswordProps {
  isAdminPage?: boolean;
}

/**
 * This component renders a form for reset password.
 */
export default function FormResetPassword({
  isAdminPage = false,
}: FormResetPasswordProps) {
  const navigate = useNavigate();
  const resetPasswordMutation = useResetPasswordMutation();
  const { setLoading, loading } = useGlobalStore();
  const [errorResponse, setErrorResponse] = useState<string>("");
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useResetForm();

  const onSubmit = async (datas: IResetForm) => {
    setLoading(true);
    try {
      await resetPasswordMutation.mutateAsync({
        email: datas.email,
        is_admin: isAdminPage,
      });
      navigate(
        isAdminPage
          ? ADMIN_ROUTES_CONSTANT.FORGOT_SUCCESS
          : ROUTES_CONSTANT.FORGOT_SUCCESS,
        {
          state: {
            email: datas.email,
            from: "forgot-page",
          },
        },
      );
    } catch (error) {
      const e = error as {
        response: { data: { error_message: { email: string } } };
      };
      setErrorResponse(e.response.data.error_message.email);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center mobile:py-8 mobile:px-4 desktop:p-8 whitespace-nowrap bg-white max-w-[572px] rounded-3xl w-full">
      <form
        className="flex flex-col max-md:max-w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlledInput
          control={control}
          formField="email"
          name="email"
          maxLength={100}
          autoComplete="email"
          label="メールアドレス"
          isRequired={true}
          startSlot={<MailIcon />}
          placeholder="sample@greenbookcloud.co.jp"
          errorMessage={errors.email?.message ?? errorResponse}
        />

        <Button
          size="sm"
          className={"mt-[48px] max-w-[335px] w-full m-auto"}
          type="submit"
          isLoading={loading?.open}
        >
          送信
        </Button>
      </form>
    </div>
  );
}
