import { getRoute } from "@/shared/get/route";
import { request } from "../axios";
import { API_PATH_CONSTANT } from "../constant";
import { QueryFunctionContext } from "@tanstack/react-query";
// import { TYoutubeResult } from "../type";

export type UploadRequestBody = {
  file: File;
  object_name: string;
};

export type UploadResponseBody = {
  job_id: string;
};

export const uploadApi = (
  data: UploadRequestBody,
  setProgress: React.Dispatch<React.SetStateAction<number>>,
): Promise<UploadResponseBody> => {
  return request({
    url: getRoute(API_PATH_CONSTANT.UPLOAD),
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: function (progressEvent: any) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      setProgress(percentCompleted);
    },
    data,
  });
};

export const getFileApi = (context: QueryFunctionContext) => {
  return request({
    url: `${getRoute(API_PATH_CONSTANT.GET_FILE)}?object_name=${
      context.queryKey[1]
    }`,
    method: "GET",
  });
};

export type UploadYoutubeRequestBody = {
  file: File;
  folder_id?: number;
};

export type UploadYoutubeResponseBody = {
  job_id: string;
};
export const uploadYoutubeApi = (
  data: UploadYoutubeRequestBody,
  setProgress: React.Dispatch<React.SetStateAction<number>>,
): Promise<UploadYoutubeResponseBody> => {
  return request({
    url: getRoute(API_PATH_CONSTANT.UPLOAD_YOUTUBE),
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: Infinity,
    onUploadProgress: function (progressEvent: any) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      setProgress(percentCompleted);
    },
    data,
  });
};

export type DeleteYoutubeResponseBody = any;
export const deleteYoutubeApi = (
  id: string,
): Promise<DeleteYoutubeResponseBody> => {
  return request({
    url: getRoute(API_PATH_CONSTANT.DELETE_YOUTUBE, { id }),
    method: "DELETE",
  });
};

export type StatusJobResponseBody = {
  id: number;
  job_id: string;
  status: "success" | "processing" | "failed";
  youtube_id: string | null;
  youtube_file_name: string | null;
  message: string | null;
};

export const getStatusJobApi = (
  context: QueryFunctionContext,
): Promise<StatusJobResponseBody> => {
  return request({
    url: getRoute(API_PATH_CONSTANT.TRACK_JOB_ID, { id: context.queryKey[1] }),
    method: "GET",
  });
};
