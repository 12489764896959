import {
  MutateOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DeleteOfficeResponseBody,
  FilterOfficeRequestBody,
  FilterOfficeResponseBody,
  UpsertOfficeRequestBody,
  UpsertOfficeResponseBody,
  deleteOfficeApi,
  listOfficeApi,
  upsertOfficeApi,
} from "./request";

export const useListOfficeQuery = (
  params: Partial<FilterOfficeRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterOfficeResponseBody,
      unknown, // TError,
      FilterOfficeResponseBody,
      (string | Partial<FilterOfficeRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-office", params],
    queryFn: listOfficeApi,
    ...options,
  });
};

export const useUpsertOfficeMutation = (
  options: MutateOptions<
    UpsertOfficeResponseBody,
    unknown,
    UpsertOfficeRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: upsertOfficeApi,
      onSuccess: () => {
        // queryClient.invalidateQueries({
        //   queryKey: ["filter-office"],
        // });
      },
    },
    queryClient,
  );
};

export const useDeleteOfficeMutation = (
  options: MutateOptions<
    DeleteOfficeResponseBody,
    unknown,
    number,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: deleteOfficeApi,
      onSuccess: () => {
        // queryClient.invalidateQueries({
        //   queryKey: ["filter-office"],
        // });
      },
    },
    queryClient,
  );
};
