import { MouseEventHandler } from "react";

interface NavItemProps {
  text: string;
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export default function NavItem({
  text,
  isActive = false,
  onClick,
}: NavItemProps) {
  return (
    <div
      onClickCapture={onClick}
      className={`flex items-center justify-center px-2 py-[18px] text-base font-normal whitespace-nowrap cursor-pointer ${
        isActive
          ? "font-bold text-[var(--primary-main-color)] border-[var(--primary-main-color)] border-solid border-b-[3px]"
          : "text-black-custom"
      }`}
    >
      {text}
    </div>
  );
}
