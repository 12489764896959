import { getRoute } from "@/shared/get/route";
import { request } from "../axios";
import { API_PATH_CONSTANT } from "../constant";
import { User } from "../type";
import { omit } from "lodash";

export type LoginRequestBody = {
  email: string;
  password: string;
  is_admin: boolean;
};

export type LoginResponseBody =
  | {
      message: string;
      email: string;
    }
  | {
      error_message: string;
    };

/**
 * Sends a login request to the server.
 *
 * @param user - The user object containing the email and password.
 * @returns A Promise that resolves to the response body containing the email and password.
 */
export const loginApi = (
  user: LoginRequestBody,
): Promise<LoginResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.LOGIN),
    data: {
      user,
    },
  });
};

export type LogoutResponseBody =
  | {
      message: string;
    }
  | {
      error_message: string;
    };

/**
 * Sends a DELETE request to the logout API endpoint.
 *
 * @returns A Promise that resolves to the response body, which contains the email and password of the logged out user.
 */
export const logoutApi = (): Promise<LogoutResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.LOGOUT),
  });
};

export type MyProfileResponseBody = User;

export const myProfileApi = (): Promise<MyProfileResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.PROFILE),
  });
};

export interface TokenObjectStorageResponseBody {
  access: Access;
}

export interface Access {
  token: Token;
  serviceCatalog: ServiceCatalog[];
  user: UserObjectStorage;
  metadata: Metadata;
}

export interface Metadata {
  is_admin: number;
  roles: string[];
}

export interface ServiceCatalog {
  endpoints: Endpoint[];
  endpoints_links: any[];
  type: string;
  name: string;
}

export interface Endpoint {
  region: Region;
  publicURL?: string;
}

export enum Region {
  Han1 = "han1",
  Tyo1 = "tyo1",
}

export interface Token {
  issued_at: Date;
  expires: Date;
  id: string;
  tenant: Tenant;
  audit_ids: string[];
}

export interface Tenant {
  description: string;
  enabled: boolean;
  id: string;
  han1_image_size: string;
  domain_id: string;
  name: string;
}

export interface UserObjectStorage {
  username: string;
  roles_links: any[];
  id: string;
  roles: Role[];
  name: string;
}

export interface Role {
  name: string;
}

export const getTokenUploadApi =
  (): Promise<TokenObjectStorageResponseBody> => {
    return request({
      method: "GET",
      url: getRoute(API_PATH_CONSTANT.TOKEN_UPLOAD),
    });
  };

export type VerifyTokenResetPasswordRequestBody = {
  reset_token?: string;
};
export type VerifyTokenResetPasswordResponseBody = LogoutResponseBody;

export const verifyTokenResetPasswordApi = (
  resetToken: VerifyTokenResetPasswordRequestBody,
): Promise<VerifyTokenResetPasswordResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.VERIFY_RESET_PASSWORDS_TOKEN),
    data: resetToken,
  });
};

export type VerifyResetPasswordRequestBody = {
  reset_token: string;
  password: string;
  password_confirmation: string;
};
export type VerifyResetPasswordResponseBody = {
  email: string;
};

export const verifyResetPasswordApi = (
  password: VerifyResetPasswordRequestBody,
): Promise<VerifyResetPasswordResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.VERIFY_RESET_PASSWORDS),
    data: {
      ...password,
    },
  });
};

export type ResetPasswordResponseBody = {
  email: string;
};
export type ResetPasswordRequestBody = {
  email: string;
  is_admin: boolean;
};

export const resetPasswordApi = (
  data: ResetPasswordRequestBody,
): Promise<ResetPasswordResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.RESET_PASSWORDS),
    data: data,
  });
};

export type UpdateFirstPasswordRequestBody = {
  reset_token: string;
  current_password: string;
  password: string;
  password_confirmation: string;
};

export type UpdateFirstPasswordResponseBody = {
  //
};

export const updateFirstPasswordApi = (
  data: UpdateFirstPasswordRequestBody,
): Promise<UpdateFirstPasswordResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.UPDATE_FIRST_PASSWORD),
    data: data,
  });
};

export type updatePasswordRequestBody = {
  id: number;
  password: string;
  password_confirmation: string;
};

export type updatePasswordResponseBody =
  | {
      id: number;
    }
  | {
      error_message: string;
    }
  | {
      error_message: {
        current_password: string[];
        password_confirmation: string[];
        password: string[];
      };
    };

export const updatePasswordApi = (
  data: updatePasswordRequestBody,
): Promise<updatePasswordResponseBody> => {
  return request({
    method: "PUT",
    url: `${getRoute(API_PATH_CONSTANT.UPDATE_PASSWORD)}?id=${data.id}`,
    data: omit(data, "id"),
  });
};

export type UpdateFirstInfoRequestBody = {
  users: {
    first_name: string;
    last_name: string;
    office_id?: string;
    department_id?: string;
    first_info_changed_flag: boolean;
    avatar_attributes: {
      id?: string;
      key: string;
      name: string;
      content_type: string;
    } | null;
  };
};

export type UpdateFirstInfoResponseBody = User;
export type TUpdateInfoRequestBody = {
  users: Omit<UpdateFirstInfoRequestBody["users"], "first_info_changed_flag">;
};
export type TUpdateInfoResponseBody = User;

export const updateFirstInfoApi = (
  data: UpdateFirstInfoRequestBody,
): Promise<UpdateFirstInfoResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.UPDATE_FIRST_INFO),
    data: data,
  });
};
export type ResetPasswordInitResponseBody =
  | {
      id: number | null;
    }
  | {
      error_message: string;
    };

export type ResetPasswordInitRequestBody = {
  id: number | null;
};

export const resetPasswordInitApi = (
  data: ResetPasswordInitRequestBody,
): Promise<ResetPasswordInitResponseBody> => {
  return request({
    method: "PUT",
    url: `${getRoute(API_PATH_CONSTANT.UPDATE_PASSWORD_INIT)}?id=${data.id}`,
  });
};

export const updateInfoApi = (
  data: TUpdateInfoRequestBody,
): Promise<TUpdateInfoResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.PROFILE),
    data: data,
  });
};
