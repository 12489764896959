import Button from "@/components/atoms/Button/button";
import { ModalCommon } from "@/components/atoms/Modal/modal";
import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import { IAddFolderForm, useAddFolderForm } from "./validation";
import { useGlobalStore } from "@/states/global.state";
import { MAX_LENGTH } from "@/configs/constants";
import useToast from "@/hooks/toast";
import { handleApiError, mapAPIError } from "@/hooks/error";
import { useUpsertFolderMutation } from "@/api/folder";
import { FolderItem, UpsertFolderRequestBody } from "@/api/folder/request";
import { cutString } from "@/shared/transform";
import { useEffect } from "react";

interface ModalProps {
  data: FolderItem;
  isOpen?: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const ModalAddFolder = (props: ModalProps) => {
  const { showToast } = useToast();
  const { loading, setLoading } = useGlobalStore();
  const { control, handleSubmit, setError, formState, reset } =
    useAddFolderForm();
  const { errors } = formState;
  const upsertFolder = useUpsertFolderMutation();

  const onSubmit = async (values: IAddFolderForm) => {
    try {
      setLoading(true);
      const payload: UpsertFolderRequestBody = {
        id: props.data.id,
        folders: {
          name: values.name,
          kind: props.data.kind,
          parent_id: props.data.parent_id,
        },
      };
      await upsertFolder.mutateAsync(payload);
      showToast({
        title: `${cutString(values.name)}${
          props.data.id ? "を保存しました" : "を作成しました"
        }`,
        type: "success",
      });
      reset({
        name: "",
      });
      props.onSuccess();
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    reset({
      name: "",
    });
    props.onClose();
  };

  useEffect(() => {
    if (props.isOpen) {
      if (props.data.id) {
        reset({
          name: props.data.name,
        });
      } else {
        reset({
          name: "",
        });
      }
    }
  }, [props.isOpen]);

  return (
    <ModalCommon
      id="add-folder-modal"
      onClose={props.onClose}
      isOpen={Boolean(props.isOpen)}
      header={props.data.id ? "フォルダの編集" : "フォルダの追加"}
      body={
        <div className="flex flex-col">
          <ControlledInput
            control={control}
            formField="name"
            label="フォルダ名"
            maxLength={MAX_LENGTH.VARCHAR}
            isTrim={true}
            isRequired={true}
            placeholder="フォルダ名を入力してください"
            errorMessage={errors.name?.message?.toString()}
          />
        </div>
      }
      footer={
        <div className="flex gap-3">
          <Button
            className="h-10"
            buttonCustom={{
              padding: "8px 16px",
              borderRadius: "8px",
              borderColor: "#E6E8EA",
              color: "#212B36",
              circleStyles: "#212B36",
            }}
            size="sm"
            variant="outline"
            onClick={onClose}
          >
            キャンセル
          </Button>
          <Button
            className="h-10"
            buttonCustom={{
              padding: "8px 16px",
              borderRadius: "8px",
              circleStyles: "var(--primary-main-color)",
              color: "white",
              bg: "var(--primary-main-color)",
            }}
            size="sm"
            isLoading={loading.open}
            onClick={handleSubmit(onSubmit)}
          >
            保存する
          </Button>
        </div>
      }
    />
  );
};
