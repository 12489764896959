import { OrderDirection, TQuizz } from "@/api/type";
import DataTable from "@/components/molecules/Table/table";
import {
  ADMIN_ROUTES_CONSTANT,
  PAGINATION_CONSTANT,
} from "@/configs/constants";
import { ROLE_ENUM } from "@/configs/enum";
import useRole from "@/hooks/role";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { debounce, isString } from "lodash";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import ThreeDotActionTable from "@/components/molecules/ActionButtonTable/three-dot";
import PencilIcon from "@/assets/icon/pencil.svg";
import { getRoute } from "@/shared/get/route";
import CloseIcon from "@/assets/icon/close.svg";
import { useDeleteQuizzMutation, useQuizzListQuery } from "@/api/quizz";
import { formatDate } from "@/shared/format";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import { ModalConfirm } from "@/components/molecules/ModalConfirm/modal-confirm";
import { useGlobalStore } from "@/states/global.state";
import useToast from "@/hooks/toast";
import { handleApiError } from "@/hooks/error";
import { cutString } from "@/shared/transform";
import qs from "qs";
import { SuggestionSearch } from "@/components/molecules/SuggestionSearch/suggestion-search";
import { ModalError } from "@/components/molecules/ModalError/modal-error";

const pageSize = PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE;
export default function QuizzList(): JSX.Element {
  const navigate = useNavigate();
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);
  const { setLoading, loading } = useGlobalStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchForm, setSearchForm] = useState({
    page: parseInt(searchParams.get("page") ?? "1"),
    title: searchParams.get("title") ?? "",
    order_by: searchParams.get("order_by")?.length
      ? searchParams.get("order_by")
      : "updated_at",
    order_direction: (searchParams.get("order_direction") ??
      "desc") as OrderDirection,
  });
  const [err, setError] = useState("");
  const { showToast } = useToast();
  const deleteMutation = useDeleteQuizzMutation();
  const {
    data: dataResponse,
    refetch,
    isFetching,
  } = useQuizzListQuery({
    page: searchForm.page,
    per_page: pageSize,
    title: searchForm.title ?? "",
    order_by: searchForm.order_by || "updated_at",
    order_direction: searchForm.order_direction,
  });
  const [modalConfig, setModalConfig] = useState({
    open: false,
    data: {
      id: 0,
      title: "",
    },
  });
  const datas: TQuizz[] = useMemo(() => {
    return (
      dataResponse?.quizzes?.map((quizz) => {
        return {
          id: quizz.id,
          title: quizz.title,
          created_at: formatDate(quizz.created_at),
          updated_at: formatDate(quizz.updated_at),
        };
      }) || []
    );
  }, [dataResponse]);

  const paging = useMemo(() => dataResponse?.paging, [dataResponse]);

  const menuItems = useMemo(() => {
    return [
      {
        icon: <PencilIcon />,
        text: <span className="body1 ">編集する</span>,
        onClick: (data: TQuizz) => {
          navigate(
            `${getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_EDIT, {
              id: data.id,
            })}`,
          );
        },
      },
      {
        icon: <CloseIcon />,
        text: <span className="body1 text-error--main">削除する</span>,
        onClick: (data: TQuizz) => {
          setModalConfig({
            open: true,
            data: {
              id: data.id,
              title: data.title,
            },
          });
        },
      },
    ];
  }, [loading, navigate]);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "title",
        header: () => "タイトル",
        cell: (info) => {
          return (
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => goToQuizDetail(info.row.original.id)}
            >
              <span className="subtitle1 text-[#212B36] break-all whitespace-normal">
                <CustomTooltip text={info.row.original.title}></CustomTooltip>
              </span>
            </div>
          );
        },
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: () => "作成日",
        cell: (info) => info.getValue(),
        meta: {
          width: 132,
        },
      },
      {
        id: "updated_at",
        accessorKey: "updated_at",
        header: () => "更新日",
        cell: (info) => info.getValue(),
        meta: {
          width: 132,
        },
      },
      {
        id: "action",
        accessorKey: "",
        enableSorting: false,
        meta: {
          width: 80,
        },
        cell: (info) => (
          <div>
            <ThreeDotActionTable
              menuItems={menuItems}
              data={info.row.original}
            />
          </div>
        ),
      },
    ],
    [],
  );

  const debouncedSearch = debounce((text: string) => {
    if (text !== searchForm.title) {
      setSearchForm((prev) => ({ ...prev, title: text, page: 1 }));
    }
  }, 500);

  const onChangePage = useCallback((p: number) => {
    setSearchForm((prev) => ({ ...prev, page: p }));
  }, []);

  const onSorting = useCallback((sortState: SortingState) => {
    if (!sortState[0]) return;

    setSearchForm((prev) => ({
      ...prev,
      order_by: sortState[0].id,
      order_direction: sortState[0].desc ? "desc" : "asc",
    }));
  }, []);

  const goToQuizDetail = (id: string) => {
    navigate(getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_DETAIL, { id }));
  };

  const refreshSearch = useCallback(() => {
    if (searchForm.page != PAGINATION_CONSTANT.DEFAULT_PAGE) {
      setSearchForm((prev) => ({ ...prev, page: 1 }));
    } else {
      refetch();
    }
  }, [searchForm.page]);

  /** handle close modal delete */
  const onCloseModal = useCallback(() => {
    setError("");
    setModalConfig({
      open: false,
      data: {
        id: 0,
        title: "",
      },
    });
  }, []);
  /** handle confirm delete */
  const onSubmitDelete = useCallback(async () => {
    setLoading(true);
    try {
      await deleteMutation.mutateAsync(modalConfig.data.id);
      showToast({
        title: `${cutString(modalConfig.data.title)}を削除しました`,
        type: "success",
      });
      refreshSearch();
      onCloseModal();
    } catch (errRes) {
      const err = handleApiError(errRes);
      if (
        err?.[0]?.path === "id" ||
        err?.[0]?.path === "base" ||
        err?.[0]?.path === "popup"
      ) {
        onCloseModal();
        setError(err[0].message);
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [modalConfig, deleteMutation, onCloseModal, setLoading, showToast]);

  useEffect(() => {
    const params = qs.stringify(searchForm);
    setSearchParams(params);
    return () => {};
  }, [searchForm, setSearchParams]);

  return (
    <>
      <DataTable
        className="mt-6"
        data={datas}
        loading={isFetching}
        columns={columns}
        total={paging?.total_records || 0}
        defaultSort={[
          {
            id: searchForm.order_by ?? "updated_at",
            desc: searchForm.order_direction === "desc",
          },
        ]}
        defaultPage={searchForm.page}
        defaultPageSize={pageSize}
        onChangePage={onChangePage}
        onSorting={onSorting}
        headerSlot={
          <div className="flex gap-3">
            <SuggestionSearch
              oldValue={searchParams.get("title") as string}
              model="quizzes"
              labelKey={"title"}
              width="100%"
              placeholder="タイトルで絞り込み"
              onSearch={(data) => {
                if (data) {
                  if (isString(data)) debouncedSearch(data);
                  else debouncedSearch(data?.title);
                } else {
                  debouncedSearch("");
                }
              }}
            />
          </div>
        }
      />
      <ModalConfirm
        isOpen={modalConfig.open}
        header={`${cutString(modalConfig.data.title)}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">
              {cutString(modalConfig.data?.title)}を削除します。
            </p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onClose={onCloseModal}
        onSubmit={onSubmitDelete}
      />
      <ModalError
        message={
          err.length > 0 && (
            <p className="text-error--main input-error__message mb-1">
              {err.replace(/<br\s*\/?>/, "\n")}
            </p>
          )
        }
        isOpen={!!err.length}
        onClose={() => {
          setError("");
        }}
      />
    </>
  );
}
