import FolderIcon from "@/assets/icon/folder.svg";
import ThreeDotActionTable from "@/components/molecules/ActionButtonTable/three-dot";
import ThreeDotIcon from "@/assets/icon/three-dot.svg";
import { useCallback, useState } from "react";
import Button from "@/components/atoms/Button/button";
import PlusIcon from "@/assets/icon/plus-02.svg";
import clsx from "clsx";
import PencilIcon from "@/assets/icon/pencil.svg";
import CloseIcon from "@/assets/icon/close.svg";
import { cutString } from "@/shared/transform";
import { ModalAddFolder } from "./modal-add-folder";
import { useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { FilterFolderResponseBody, FolderItem } from "@/api/folder/request";
import { useDeleteFolderMutation } from "@/api/folder";
import { ModalConfirm } from "@/components/molecules/ModalConfirm/modal-confirm";
import { useGlobalStore } from "@/states/global.state";
import { handleApiError } from "@/hooks/error";
import useToast from "@/hooks/toast";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import Spinner from "@/components/atoms/Spinner/spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { Folder, TDocumentList } from "@/api/type";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import { useAuthStore } from "@/states/auth.state";
import { MESSAGES } from "@/shared/validation/message";
import { ModalError } from "@/components/molecules/ModalError/modal-error";

interface FolderLeftPanelProps {
  isAdmin: boolean;
  folderItems: FilterFolderResponseBody["folders"];
  fileItems: TDocumentList[];
  refreshSearch: () => void;
  refreshFileList: () => void;
  kind: "document" | "video";
  isFetching?: boolean;
  onNextPage: (type: "folder" | "document" | "video") => void;
  hasMore: boolean;
  totalFolder: number;
}

export default function FolderLeftPanel({
  isAdmin = true,
  folderItems,
  fileItems,
  refreshSearch,
  isFetching = false,
  onNextPage,
  hasMore,
  kind,
}: FolderLeftPanelProps) {
  const {
    isOpen: isOpenModalAdd,
    onOpen: openModalAdd,
    onClose: closeModalAdd,
  } = useDisclosure();
  const {
    isOpen: isOpenModalDelete,
    onOpen: openModalDelete,
    onClose: closeModalDelete,
  } = useDisclosure();
  const [isDesktop] = useMediaQuery("(min-width: 1024px)");
  const { showToast } = useToast();
  const [err, setError] = useState("");
  const deleteFolderApi = useDeleteFolderMutation();
  const { setLoading } = useGlobalStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userInfo } = useAuthStore();
  const menuItems = (folder: Folder) => {
    const result = [
      {
        icon: <PencilIcon />,
        text: <span className="body1">編集する</span>,
        onClick: (data: FolderItem) => {
          setDataModal(data);
          openModalAdd();
        },
      },
    ];
    if (folder.creator_id === userInfo?.id || isAdmin) {
      result.push({
        icon: <CloseIcon />,
        text: <span className="body1 text-error--main">削除する</span>,
        onClick: (data: FolderItem) => {
          setDataModal(data);
          openModalDelete();
        },
      });
    }
    return result;
  };
  const [dataModal, setDataModal] = useState<FolderItem>({
    id: 0,
    childs: [],
    name: "",
    kind,
    course_id: null,
    parent_id: null,
  });

  const onClickFolder = (id: number) => {
    setSearchParams(
      qs.stringify({
        id,
      }),
    );
  };

  const checkExistInFolder = useCallback(
    (folder: FolderItem) => {
      if (
        fileItems.length > 0 &&
        fileItems.findIndex((i) => i.folder_id === folder.id) !== -1
      ) {
        return true;
      }
      if (fileItems.length === 0 && searchParams.get("id")) {
        return folder.id == Number(searchParams.get("id"));
      }
      return false;
    },
    [fileItems, searchParams],
  );

  const onAddChildFolder = useCallback(
    (id: number) => {
      setDataModal({
        id: 0,
        childs: [],
        name: "",
        kind,
        parent_id: id,
        course_id: null,
      });
      openModalAdd();
    },
    [kind],
  );

  const onDeleteFolder = async () => {
    try {
      setLoading(true);
      await deleteFolderApi.mutateAsync({
        id: dataModal.id,
      });
      showToast({
        title: `${cutString(dataModal.name)}を削除しました`,
        type: "success",
      });
      refreshSearch();
      closeModalDelete();
    } catch (errRes) {
      const err = handleApiError(errRes);
      if (
        err?.[0]?.path === "id" ||
        err?.[0]?.path === "base" ||
        err?.[0]?.path === "popup"
      ) {
        closeModalDelete();
        setError(err[0].message);
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  const renderChildFolder = useCallback(
    (item: FolderItem) => {
      return (
        <div
          className="flex flex-col gap-y-1 min-w-full w-fit"
          style={{ paddingLeft: `${item.parent_id === 1 ? 40 : 34}px` }}
        >
          {item.childs.map((child) => (
            <div key={child.id} className="flex flex-col w-full">
              <div
                className={clsx(
                  "flex gap-y-1 flex-row gap-x-3 items-center px-2 py-1 hover:bg-[#F1F9E8] rounded-[8px] cursor-pointer",
                  checkExistInFolder(child) && "bg-[#F1F9E8]",
                )}
                onClick={() => onClickFolder(child.id)}
              >
                <FolderIcon
                  className="folder-icon--primary min-w-[20px]"
                  width={20}
                  height={20}
                />
                <p className="truncate mb-0 text-base font-bold flex-1 min-w-[150px]">
                  <CustomTooltip text={child.name} max={100} />
                </p>
                {!child?.course_id && (
                  <ThreeDotActionTable
                    menuItems={menuItems(child)}
                    data={child}
                    iconBtn={<ThreeDotIcon width={20} height={20} />}
                  />
                )}
              </div>
              <div
                style={{
                  marginLeft: "42px",
                }}
              >
                <Button
                  size="xs"
                  variant="outline"
                  buttonCustom={{
                    borderRadius: "4px",
                    padding: "4px 6px",
                  }}
                  className={clsx("min-w-[112px] w-fit my-[2px]")}
                  startSlot={<PlusIcon />}
                  onClick={() => {
                    onAddChildFolder(child.id);
                  }}
                >
                  フォルダ追加
                </Button>
              </div>
              {renderChildFolder(child)}
            </div>
          ))}
        </div>
      );
    },
    [menuItems],
  );

  return (
    <>
      <div className="flex gap-y-5 flex-col w-full" id="list-folder">
        {isFetching ? (
          <div className="flex justify-center">
            <Spinner
              circleStyles={{
                borderColor: `var(--primary-main-color) transparent transparent transparent`,
              }}
            />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={folderItems.length}
            height={isDesktop ? 590 : 260}
            next={() => onNextPage("folder")}
            hasMore={hasMore}
            loader={null}
            endMessage={null}
            hasChildren={true}
            scrollableTarget={"list-folder"}
          >
            <div className="block min-w-fit">
              {folderItems.length === 0 && (
                <p className="w-full gap-2 px-4 bg-white text-center subtitle2">
                  {MESSAGES.MSG_010}
                </p>
              )}
              {folderItems.map((folder) => (
                <div className="flex flex-col min-w-full w-fit" key={folder.id}>
                  <div
                    className={clsx(
                      "flex flex-row items-center gap-x-3 p-2 hover:bg-[#F1F9E8] rounded-[8px] cursor-pointer mb-[2px] w-full",
                      checkExistInFolder(folder) && "bg-[#F1F9E8]",
                    )}
                    onClick={() => onClickFolder(folder.id)}
                  >
                    <FolderIcon className="folder-icon--primary min-w-[20px]" />
                    <p className="mb-0 text-base font-bold flex-1 whitespace-pre-wrap break-all">
                      <CustomTooltip text={folder.name} max={100} />
                    </p>
                    {!folder?.course_id && (
                      <ThreeDotActionTable
                        data={folder}
                        menuItems={menuItems(folder)}
                        iconBtn={<ThreeDotIcon width={20} height={20} />}
                      />
                    )}
                  </div>

                  {renderChildFolder(folder)}

                  <div
                    style={{
                      marginLeft: "48px",
                    }}
                  >
                    <Button
                      size="xs"
                      variant="outline"
                      buttonCustom={{
                        borderRadius: "4px",
                        padding: "4px 6px",
                      }}
                      className={clsx("min-w-[112px] w-fit my-[2px]")}
                      startSlot={<PlusIcon />}
                      onClick={() => {
                        onAddChildFolder(folder.id);
                      }}
                    >
                      フォルダ追加
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        )}
      </div>
      <ModalAddFolder
        isOpen={isOpenModalAdd}
        onClose={closeModalAdd}
        data={dataModal}
        onSuccess={() => {
          closeModalAdd();
          refreshSearch();
        }}
      />
      <ModalConfirm
        isOpen={isOpenModalDelete}
        onClose={() => {
          setError("");
          closeModalDelete();
        }}
        header={`${cutString(dataModal?.name)}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">
              {cutString(dataModal?.name)}を削除します。
            </p>
            <p className="body1 text-[#212B36]">
              フォルダの中に入っているファイルも削除されます。
            </p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onSubmit={onDeleteFolder}
      />
      <ModalError
        message={
          err.length > 0 && (
            <p className="text-error--main input-error__message mb-1 body1">
              {err.replace(/<br\s*\/?>/, "\n")}
            </p>
          )
        }
        isOpen={!!err.length}
        onClose={() => {
          setError("");
        }}
      />
    </>
  );
}
