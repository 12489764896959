import { useCallback, useEffect, useRef, useState } from "react";
import {
  getDocument,
  GlobalWorkerOptions,
  PDFDocumentProxy,
  PDFPageProxy,
} from "pdfjs-dist";
import { useDebounceCallback, useResizeObserver } from "usehooks-ts";
import { min } from "lodash";
import useScreenSize from "@/hooks/screen";
type PDFFile = string | File | null;

GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.js",
  import.meta.url,
).toString();
interface PDFViewerProps {
  file?: PDFFile | null | string;
  isShowZoom?: boolean;
  onWatchFullPDF?: () => void;
}
const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};
const ratioScale = 9 / 16;
export default function PDFViewer({
  file = "/src/assets/sample.pdf",
  onWatchFullPDF,
}: PDFViewerProps) {
  const [scale, setScale] = useState<number>(1);
  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy>();
  const [pageNum, setPageNum] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingFail, setLoadingFail] = useState(false);
  const [isRendering, setIsRendering] = useState(false);
  const [containerHeight, setContainerHeight] = useState<number>(600);
  const { isMobile } = useScreenSize();
  const onResize = useDebounceCallback((size) => {
    const height = min([600, size.width * ratioScale]);
    setContainerHeight(height ?? 600);
  }, 200);
  const ref = useRef<HTMLDivElement>(null);

  useResizeObserver({
    ref,
    onResize,
  });

  const loadPdfDocument = async (url: PDFFile) => {
    setIsLoading(true);
    setLoadingFail(false);
    getDocument({ url: url as string, ...options })
      .promise.then((pdfDoc_) => {
        setLoadingFail(false);
        setPdfDocument(pdfDoc_);
      })
      .catch((e) => {
        console.error(e);
        setLoadingFail(true);
        onWatchFullPDF?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNext = useCallback(() => {
    if (!pdfDocument?.numPages || !pageNum || isRendering) return;

    const pdfNumPage = pdfDocument.numPages;
    const page = pageNum + 1;
    if (page === pdfNumPage) {
      onWatchFullPDF?.();
    }
    if (page <= pdfNumPage) {
      renderPage(page);
      setPageNum(page);
    }
  }, [pageNum, pdfDocument, isRendering]);

  const handlePrevious = useCallback(() => {
    if (!pdfDocument?.numPages || !pageNum || isRendering) return;
    if (pageNum > 1) {
      const page = pageNum - 1;
      renderPage(page);
      setPageNum(page);
    }
  }, [pageNum, pdfDocument, isRendering]);

  const renderPage = async (_pageNum = 1, scale = 1) => {
    if (!pdfDocument) return;
    setIsRendering(true);
    pdfDocument?.getPage(_pageNum).then((page: PDFPageProxy) => {
      const canvas = document.getElementById("pdf-canvas") as HTMLCanvasElement;
      const viewport = page.getViewport({ scale });
      const ctx = canvas.getContext("2d");
      if (canvas && ctx) {
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        const renderTask = page.render(renderContext);

        renderTask.promise
          .then(() => {})
          .finally(() => {
            setIsRendering(false);
          });
      }
    });
  };

  const handleZoom = useCallback(
    (type: "zoomIn" | "zoomOut" | "default") => {
      if (!pageNum || isRendering) return;
      let newScale = 1;
      switch (type) {
        case "zoomIn": {
          newScale = scale + 0.2;
          break;
        }
        case "zoomOut": {
          newScale = scale - 0.2;
          break;
        }
        default: {
          newScale = 1;
          break;
        }
      }
      setScale(newScale);
      return renderPage(pageNum, newScale);
    },
    [scale, pageNum, isRendering],
  );

  useEffect(() => {
    loadPdfDocument(file);
  }, [file]);

  useEffect(() => {
    if (pdfDocument) renderPage(1, 1);
  }, [pdfDocument]);

  useEffect(() => {
    if (pdfDocument?.numPages && pdfDocument.numPages == 1) {
      onWatchFullPDF?.();
    }
  }, [pdfDocument?.numPages]);

  return (
    <div
      className="bg-[#e5e5e5] relative"
      ref={ref}
      style={{ height: containerHeight + 40 + "px" }}
    >
      {!isLoading && loadingFail && (
        <div
          className="flex justify-center items-center"
          style={{ height: containerHeight + "px" }}
        >
          <p>PDFファイルのダウンロードに失敗しました</p>
        </div>
      )}
      {isLoading && (
        <div
          className="flex justify-center items-center"
          style={{ height: containerHeight + "px" }}
        >
          <p>お待ちください</p>
        </div>
      )}

      <div
        id="zoom-test"
        className={`flex justify-center max-h-full bg-white ${
          isLoading || loadingFail ? "hidden" : ""
        }`}
      >
        <div
          className="overflow-scroll w-full"
          style={{ height: containerHeight + "px" }}
        >
          <canvas
            id="pdf-canvas"
            width="500"
            height="600"
            className="m-auto"
          ></canvas>
        </div>
      </div>
      <div
        className={`w-full h-[40px] flex justify-center items-center bg-[#00000080] sticky bottom-0 z-[99]`}
      >
        <div className="flex gap-x-3">
          {isMobile && (
            <button className="" onClickCapture={() => handleZoom("zoomIn")}>
              <i className="cursor-pointer">
                <svg
                  fill="#fff"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  width={15}
                  height={15}
                  viewBox="0 0 45.402 45.402"
                >
                  <g>
                    <path
                      d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141
		c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27
		c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435
		c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"
                    />
                  </g>
                </svg>
              </i>
            </button>
          )}
          <i className="cursor-pointer" onClick={() => handlePrevious()}>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.896 8.41465L12.016 12.2946L15.896 16.1746C16.286 16.5646 16.286 17.1946 15.896 17.5846C15.506 17.9746 14.876 17.9746 14.486 17.5846L9.89598 12.9946C9.50598 12.6046 9.50598 11.9746 9.89598 11.5846L14.486 6.99465C14.876 6.60465 15.506 6.60465 15.896 6.99465C16.276 7.38465 16.286 8.02465 15.896 8.41465Z"
                fill="white"
              />
            </svg>
          </i>
          <span className="text-[16px] leading-[22px] text-white font-bold">
            {pageNum}/{pdfDocument?.numPages ?? 1}
          </span>
          <i className="cursor-pointer" onClickCapture={() => handleNext()}>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.89504 16.1654L13.775 12.2854L9.89504 8.40543C9.50504 8.01543 9.50504 7.38543 9.89504 6.99543C10.285 6.60543 10.915 6.60543 11.305 6.99543L15.895 11.5854C16.285 11.9754 16.285 12.6054 15.895 12.9954L11.305 17.5854C10.915 17.9754 10.285 17.9754 9.89504 17.5854C9.51504 17.1954 9.50504 16.5554 9.89504 16.1654Z"
                fill="white"
              />
            </svg>
          </i>
          {isMobile && (
            <button className="" onClickCapture={() => handleZoom("zoomOut")}>
              <i className="cursor-pointer">
                <svg width={15} height={15} viewBox="0 -12 32 32" version="1.1">
                  <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Icon-Set-Filled"
                      transform="translate(-414.000000, -1049.000000)"
                      fill="#fff"
                    >
                      <path
                        d="M442,1049 L418,1049 C415.791,1049 414,1050.79 414,1053 C414,1055.21 415.791,1057 418,1057 L442,1057 C444.209,1057 446,1055.21 446,1053 C446,1050.79 444.209,1049 442,1049"
                        id="minus"
                      ></path>
                    </g>
                  </g>
                </svg>
              </i>
            </button>
          )}
        </div>
      </div>
      {!isMobile && (
        <div className="absolute bottom-[50px] right-[10px] z-[99]">
          <div className="p-[3px] desktop:bg-[#00000080] mobile:bg-[#0000003b] rounded-[8px] mb-[5px]">
            <button
              className="desktop:w-[29px] desktop:h-[29px] mobile:w-[19px] mobile:h-[19px] flex justify-center items-center"
              onClickCapture={() => handleZoom("default")}
            >
              <i className="cursor-pointer">
                <svg width={15} height={15} viewBox="0 0 24 24" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 16.5017C14.4853 16.5017 16.5 14.487 16.5 12.0017C16.5 9.5164 14.4853 7.50168 12 7.50168C9.51472 7.50168 7.5 9.5164 7.5 12.0017C7.5 14.487 9.51472 16.5017 12 16.5017ZM12 14.5034C10.6184 14.5034 9.49832 13.3833 9.49832 12.0017C9.49832 10.62 10.6184 9.5 12 9.5C13.3816 9.5 14.5017 10.62 14.5017 12.0017C14.5017 13.3833 13.3816 14.5034 12 14.5034Z"
                    fill="#fff"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 1C11 0.447715 11.4477 0 12 0C12.5523 0 13 0.447715 13 1V2.04938C17.7244 2.51844 21.4816 6.27558 21.9506 11H23C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13H21.9506C21.4816 17.7244 17.7244 21.4816 13 21.9506V23C13 23.5523 12.5523 24 12 24C11.4477 24 11 23.5523 11 23V21.9506C6.27558 21.4816 2.51844 17.7244 2.04938 13H1C0.447715 13 0 12.5523 0 12C-1.19209e-07 11.4477 0.447715 11 1 11H2.04938C2.51844 6.27558 6.27558 2.51844 11 2.04938V1ZM12 20.0016C7.58083 20.0016 3.99839 16.4192 3.99839 12C3.99839 7.58083 7.58083 3.99839 12 3.99839C16.4192 3.99839 20.0016 7.58083 20.0016 12C20.0016 16.4192 16.4192 20.0016 12 20.0016Z"
                    fill="#fff"
                  />
                </svg>
              </i>
            </button>
          </div>

          <div className="p-[3px] desktop:bg-[#00000080] mobile:bg-[#0000003b] rounded-[8px]">
            <button
              className="desktop:w-[29px] desktop:h-[29px] mobile:w-[19px] mobile:h-[19px] flex justify-center items-center "
              onClickCapture={() => handleZoom("zoomIn")}
            >
              <i className="cursor-pointer">
                <svg
                  fill="#fff"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  width={15}
                  height={15}
                  viewBox="0 0 45.402 45.402"
                >
                  <g>
                    <path
                      d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141
		c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27
		c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435
		c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"
                    />
                  </g>
                </svg>
              </i>
            </button>
            <div className="border-solid border-[1px] border-white mx-[2px]"></div>
            <button
              className="desktop:w-[29px] desktop:h-[29px] mobile:w-[19px] mobile:h-[19px] flex justify-center items-center"
              onClickCapture={() => handleZoom("zoomOut")}
            >
              <i className="cursor-pointer">
                <svg width={15} height={15} viewBox="0 -12 32 32" version="1.1">
                  <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Icon-Set-Filled"
                      transform="translate(-414.000000, -1049.000000)"
                      fill="#fff"
                    >
                      <path
                        d="M442,1049 L418,1049 C415.791,1049 414,1050.79 414,1053 C414,1055.21 415.791,1057 418,1057 L442,1057 C444.209,1057 446,1055.21 446,1053 C446,1050.79 444.209,1049 442,1049"
                        id="minus"
                      ></path>
                    </g>
                  </g>
                </svg>
              </i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
