import { QuizzQuestion } from "@/api/type";
import QuizzItem from "../QuizzManagement/detail/quizz-item";
import { QUESTION_KIND_ENUM } from "@/configs/enum";

interface ElearningExamContentProps {
  questions?: QuizzQuestion[];
}

export default function ElearningExamContent({
  questions,
}: ElearningExamContentProps) {
  return (
    <div className=" bg-white flex flex-col gap-10 w-full">
      {questions?.map((question, index) => (
        <QuizzItem
          key={`questions.${index}`}
          questionNumber={index + 1}
          title={question.title ?? ""}
          kind={question.kind as QUESTION_KIND_ENUM}
          answers={question.quiz_question_answers}
        />
      ))}
    </div>
  );
}
