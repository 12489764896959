import {
  MutateOptions,
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  LoginRequestBody,
  LoginResponseBody,
  LogoutResponseBody,
  MyProfileResponseBody,
  getTokenUploadApi,
  loginApi,
  logoutApi,
  myProfileApi,
  resetPasswordApi,
  ResetPasswordRequestBody,
  ResetPasswordResponseBody,
  updateFirstInfoApi,
  UpdateFirstInfoRequestBody,
  UpdateFirstInfoResponseBody,
  updateFirstPasswordApi,
  updatePasswordApi,
  updatePasswordRequestBody,
  updatePasswordResponseBody,
  UpdateFirstPasswordRequestBody,
  UpdateFirstPasswordResponseBody,
  verifyResetPasswordApi,
  VerifyResetPasswordRequestBody,
  VerifyResetPasswordResponseBody,
  verifyTokenResetPasswordApi,
  VerifyTokenResetPasswordRequestBody,
  VerifyTokenResetPasswordResponseBody,
  resetPasswordInitApi,
  ResetPasswordInitResponseBody,
  ResetPasswordInitRequestBody,
  updateInfoApi,
  TUpdateInfoResponseBody,
  TUpdateInfoRequestBody,
} from "./request";

const useLoginMutation = (
  options: MutateOptions<
    LoginResponseBody,
    unknown,
    LoginRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: loginApi,
      onSuccess: () => {
        // queryClient.invalidateQueries("login");
      },
    },
    queryClient,
  );
};

const useLogoutMutation = (
  options: MutateOptions<LogoutResponseBody, unknown, unknown, unknown> = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: logoutApi,
      onSuccess: () => {
        // queryClient.invalidateQueries("login");
      },
    },
    queryClient,
  );
};

const fetchMyProfile = async (queryClient: QueryClient) => {
  return queryClient.fetchQuery({
    queryKey: ["my-profile"],
    queryFn: myProfileApi,
  });
};

const useMyProfileQuery = (
  options: Omit<
    UseQueryOptions<
      MyProfileResponseBody,
      unknown,
      MyProfileResponseBody,
      string[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["my-profile"],
    queryFn: myProfileApi,
    ...options,
  });
};

export const createTokenUpload = (queryClient: QueryClient) => {
  return queryClient.fetchQuery({
    queryKey: ["token-upload"],
    queryFn: getTokenUploadApi,
  });
};

const useResetPasswordMutation = (
  options: MutateOptions<
    ResetPasswordResponseBody,
    unknown,
    ResetPasswordRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: resetPasswordApi,
      onSuccess: () => {
        // queryClient.invalidateQueries("login");
      },
    },
    queryClient,
  );
};

const useVerifyTokenResetPasswordMutation = (
  options: MutateOptions<
    VerifyTokenResetPasswordResponseBody,
    unknown,
    VerifyTokenResetPasswordRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: verifyTokenResetPasswordApi,
      onSuccess: () => {
        // queryClient.invalidateQueries("login");
      },
    },
    queryClient,
  );
};

const useVerifyResetPasswordMutation = (
  options: MutateOptions<
    VerifyResetPasswordResponseBody,
    unknown,
    VerifyResetPasswordRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: verifyResetPasswordApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

const useUpdateFirstPasswordMutation = (
  options: MutateOptions<
    UpdateFirstPasswordResponseBody,
    unknown,
    UpdateFirstPasswordRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updateFirstPasswordApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

const useUpdateFirstInfoMutation = (
  options: MutateOptions<
    UpdateFirstInfoResponseBody,
    unknown,
    UpdateFirstInfoRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updateFirstInfoApi,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["my-profile"],
          exact: true,
        });
      },
    },
    queryClient,
  );
};

const useUpdatePasswordAdminUserDetailMutation = (
  options: MutateOptions<
    updatePasswordResponseBody,
    unknown,
    updatePasswordRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updatePasswordApi,
      onSuccess: () => {
        // queryClient.invalidateQueries("login");
      },
    },
    queryClient,
  );
};

const useResetPasswordInitMutation = (
  options: MutateOptions<
    ResetPasswordInitResponseBody,
    unknown,
    ResetPasswordInitRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: resetPasswordInitApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

const useUpdateInfoMutation = (
  options: MutateOptions<
    TUpdateInfoResponseBody,
    unknown,
    TUpdateInfoRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updateInfoApi,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["my-profile"],
          exact: true,
        });
      },
    },
    queryClient,
  );
};

export {
  useLoginMutation,
  useLogoutMutation,
  useMyProfileQuery,
  fetchMyProfile,
  useResetPasswordMutation,
  useVerifyTokenResetPasswordMutation,
  useVerifyResetPasswordMutation,
  useUpdateFirstPasswordMutation,
  useUpdatePasswordAdminUserDetailMutation,
  useUpdateFirstInfoMutation,
  useResetPasswordInitMutation,
  useUpdateInfoMutation,
};
