import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import BellIcon from "@/assets/icon/bell-01.svg";
import {
  fetchListUserNotification,
  useCheckUnreadNotification,
  useReadUserNotificationMutation,
} from "@/api/notification";
import { PAGINATION_CONSTANT, ROUTES_CONSTANT } from "@/configs/constants";
import { MESSAGES } from "@/shared/validation/message";
import { formatDate } from "@/shared/format";
import clsx from "clsx";
import { getLinkMedia } from "@/shared/get";
import { useNavigate } from "react-router-dom";
import { getRoute } from "@/shared/get/route";
import useScreenSize from "@/hooks/screen";
import DOMPurify from "dompurify";
import Spinner from "@/components/atoms/Spinner/spinner";
import { useQueryClient } from "@tanstack/react-query";
import { useGlobalStore } from "@/states/global.state";
import { delay } from "lodash";
interface NotificationFrontProps {
  bellIcon?: ReactNode;
}

interface INotificationFrontItem {
  id: number;
  avatar_url: string | null;
  author: string;
  title: string;
  time: string;
  content: string;
  is_read: boolean;
  course_id: number | null;
  is_new: boolean;
  is_old: boolean;
}
export default function NotificationFront({
  bellIcon = <BellIcon />,
}: NotificationFrontProps) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { screenWidth } = useScreenSize();
  const [hasMore, setHasMore] = useState(true);
  const queryClient = useQueryClient();
  const { setLoading } = useGlobalStore();
  const [notifications, setNotifications] = useState<INotificationFrontItem[]>(
    [],
  );
  const [page, setPage] = useState<number>(PAGINATION_CONSTANT.DEFAULT_PAGE);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { data: checkUnread, refetch: reCheck } = useCheckUnreadNotification({
    for_admin: false,
  });
  const readNotifyMutation = useReadUserNotificationMutation();

  const fetchNotifications = async (page = 1) => {
    setIsFetching(true);
    const res = await fetchListUserNotification(queryClient, {
      page,
      per_page: 3,
      order_by: "is_read",
      for_admin: false,
    });
    const datas = res.user_notifications;
    const paging = res.paging;
    setHasMore(paging.total_pages > page);
    const _notifications = datas.map((data) => {
      const author = data.notification.notificationable?.user;
      const courseTitle =
        data.notification.notificationable.commentable?.title ?? "";

      return {
        id: data.id,
        avatar_url: getLinkMedia(author?.avatar?.key),
        author: author?.first_name + " " + author?.last_name,
        title: `から${courseTitle}にコメントがつきました`,
        time: formatDate(data.created_at, "YYYY/MM/DD"),
        content: data.notification.notificationable.content,
        is_read: data.is_read,
        course_id:
          data.notification.notificationable.commentable_type === "Course"
            ? data.notification.notificationable.commentable_id
            : null,
        is_new: false,
        is_old: false,
      };
    });

    if (paging.current_page === 1) {
      setNotifications(_notifications);
    } else {
      setNotifications((prev) => {
        return [...prev, ..._notifications];
      });
    }

    setIsFetching(false);
  };

  const notificationsNew = useMemo(() => {
    return notifications
      .filter((i) => i.is_read === false)
      .map((i, index) => {
        if (index === 0) return { ...i, is_new: true };
        return { ...i, is_new: false };
      });
  }, [notifications]);

  const notificationsOld = useMemo(() => {
    return notifications
      .filter((i) => i.is_read === true)
      .map((i, index) => {
        if (index === 0) return { ...i, is_old: true };
        return { ...i, is_old: false };
      });
  }, [notifications]);

  const onNextPage = useCallback(async () => {
    if (isFetching) return;
    const nextPage = page + 1;
    await fetchNotifications(nextPage);
    setPage(nextPage);
  }, [page, isFetching]);

  const readNotifications = useCallback(
    async (autoFetchNewNotifiy = true) => {
      onClose();
      try {
        const idsUnread = notifications
          .filter((i) => !i.is_read)
          .map((i) => i.id);
        if (idsUnread.length > 0)
          await readNotifyMutation.mutateAsync({ ids: idsUnread });

        await reCheck();
        setNotifications([]);
      } finally {
        if (autoFetchNewNotifiy) {
          setPage(1);
          await fetchNotifications();
        }
      }
    },
    [page, notifications],
  );

  const mergedArrNotify = useMemo(() => {
    return [...notificationsNew, ...notificationsOld];
  }, [notificationsNew, notificationsOld]);

  const goToPost = async (notification: INotificationFrontItem) => {
    setLoading(true, true);
    try {
      await readNotifications();
      setNotifications((prev) =>
        prev.map((item) => {
          if (item.id === notification.id) return { ...item, is_read: true };
          return item;
        }),
      );
      if (notification.course_id) {
        navigate(
          getRoute(ROUTES_CONSTANT.ELEARNING_DETAIL, {
            id: notification.course_id,
          }),
        );
      }
    } catch {
      //
    } finally {
      delay(() => setLoading(false, false), 300);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className="cursor-pointer relative w-8 h-8 flex items-center justify-center rounded-full hover:bg-[var(--primary-lighter-color) ]">
      {checkUnread?.unread && (
        <div
          className={clsx(
            "absolute  bg-[#F70905] rounded-full",
            "top-[3px] right-[1px] w-[6px] h-[6px]",
          )}
        ></div>
      )}
      <Popover
        placement={"bottom-end"}
        closeOnBlur={true}
        returnFocusOnClose={false}
        isOpen={isOpen}
        offset={[0, 15]}
        onClose={async () => {
          await readNotifications();
        }}
      >
        <PopoverTrigger>
          <Box as={"button"} onClick={onOpen}>
            {bellIcon}
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            style={{
              maxWidth: "404px",
              marginLeft: screenWidth > 430 ? "0px" : "5px",
              width: screenWidth > 430 ? "100vw" : "calc(100vw - 15px)",
              borderWidth: 0,
              borderStyle: "solid",
              backgroundColor: "white",
              borderRadius: "0 0 12px 12px",
              boxShadow: "0px 0px 16px 0px #22390914",
            }}
          >
            <div className="px-5 py-4 [border-bottom:1px_solid_#E6E8EA]">
              <h6 className="font-black">通知</h6>
            </div>
            {notifications?.length > 0 ? (
              <div className="">
                <div className="max-h-[272px] overflow-y-auto">
                  {mergedArrNotify.length === 0 && isFetching && (
                    <div className="flex justify-center w-full mt-1">
                      <Spinner
                        circleStyles={{
                          borderColor: `var(--primary-main-color) transparent transparent transparent`,
                        }}
                      />
                    </div>
                  )}
                  {mergedArrNotify.map((notification) => {
                    return (
                      <div key={notification.id}>
                        {notification.is_new && (
                          <div className="caption1 h-[34px] px-5 text-[#637381] flex items-center">
                            NEW
                          </div>
                        )}

                        {notification.is_old && (
                          <div className="caption1 h-[34px] px-5 text-[#637381] flex items-center">
                            過去の通知
                          </div>
                        )}
                        <div
                          onClickCapture={() => goToPost(notification)}
                          className={clsx(
                            "flex flex-row px-5 py-3 gap-x-2 hover:bg-[#919EAB14] cursor-pointer",
                          )}
                        >
                          <div className="flex flex-col w-[calc(100%-40px)]">
                            <div className="flex flex-row gap-x-2 items-center w-full justify-between">
                              <p
                                className="subtitle2 line-clamp-2 font-bold"
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    notification.content ?? "",
                                    {
                                      ALLOWED_TAGS: ["a"],
                                      ALLOWED_ATTR: ["class"],
                                    },
                                  ),
                                }}
                              ></p>
                              {notification.is_read ? (
                                <></>
                              ) : (
                                <div className="min-w-2 min-h-2 w-2 h-2 bg-[#F70905] rounded-full"></div>
                              )}
                            </div>
                            <span className="caption1 text-[#637381] mt-1">
                              {notification?.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {hasMore && (
                  <div className="flex justify-center py-2 underline">
                    <span
                      className="body1 text-[#73be1e] cursor-pointer"
                      onClickCapture={onNextPage}
                    >
                      {isFetching ? (
                        <Spinner
                          styles={{
                            width: 34,
                            height: 34,
                          }}
                          circleStyles={{
                            width: 26,
                            height: 26,
                            borderColor: `var(--primary-main-color) transparent transparent transparent`,
                          }}
                        ></Spinner>
                      ) : (
                        "過去のお知らせを見る"
                      )}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div className="select-none block [font-size:inherit] w-full  text-[#212B36] px-[16px] py-[8px] box-border rounded-[8px] body2 text-center">
                {MESSAGES.MSG_010}
              </div>
            )}
          </PopoverContent>
        </Portal>
      </Popover>
    </div>
  );
}
