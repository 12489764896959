import AuthLayout from "@/pages/_layouts/auth";
import ArrowLeftIcon from "@/assets/icon/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import FormSetInformationFirstLogin from "@/components/organisms/FormSettingAccount/setting-information";
import useScreenSize from "@/hooks/screen";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";
import { ROUTES_CONSTANT } from "@/configs/constants";
import { useEffect } from "react";
export default function FrontSettingInformationFirstLogin() {
  const { userInfo } = useRole(false, [ROLE_ENUM.MEMBER]);
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const goBack = () => {
    navigate(ROUTES_CONSTANT.LOGIN);
  };

  useEffect(() => {
    if (userInfo?.first_info_changed_flag) {
      navigate(ROUTES_CONSTANT.FORBIDDEN);
    }
  }, [userInfo]);
  return (
    <AuthLayout>
      <div className="mobile:pt-12 mobile:pb-20 desktop:py-20 flex w-full items-center flex-col mobile:px-4 desktop:px-5">
        <div className="flex mb-4 justify-center w-full relative">
          <div
            className="absolute left-0 cursor-pointer"
            onClickCapture={goBack}
          >
            <ArrowLeftIcon />
          </div>
          {isMobile ? (
            <h5 className="font-black text-center">アカウント情報設定</h5>
          ) : (
            <h4 className="font-black text-center">アカウント情報設定</h4>
          )}
        </div>
        <div className="desktop:text-sm mobile:text-xs font-normal mb-12 text-center">
          アカウント情報を入力し、設定を完了させてください
        </div>
        <FormSetInformationFirstLogin userInfo={userInfo} isAdminPage={false} />
      </div>
    </AuthLayout>
  );
}
