import { QueryFunctionContext } from "@tanstack/react-query";

import { request } from "../axios";
import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";
import { FAVORITE_TYPE_ENUM } from "@/configs/enum";
import { Favorite } from "../type";

export type ListFavoriteQueryKey = [string, string, string];

export type FilterFavoriteResponseBody = {
  favorites: Favorite[];
};

export type CreateFavoriteRequestBody = {
  code_emoji: string;
};

export type CreateFavoriteResponseBody = {
  id: number;
};

export type DeleteFavoriteRequestBody = {
  id: number;
};

export type DeleteFavoriteResponseBody = {
  favoriteable_type: FAVORITE_TYPE_ENUM;
  favoriteable_id: number;
  id: number;
};

export const listFavoriteApi = (
  context: QueryFunctionContext<ListFavoriteQueryKey>,
): Promise<FilterFavoriteResponseBody> => {
  const [, favoriteable_type, favoriteable_id] = context.queryKey;

  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_FAVORITE, {
      favoriteable_type,
      favoriteable_id,
    }),
    params: {
      order_by: "created_at",
      order_direction: "asc",
    },
  });
};

export const createFavoriteApi = (
  favoriteable_type: string,
  favoriteable_id: number,
  data: CreateFavoriteRequestBody,
): Promise<CreateFavoriteResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.CREATE_FAVORITE, {
      favoriteable_type,
      favoriteable_id,
    }),
    data: { favorites: data },
  });
};

export const deleteFavoriteApi = (
  favoriteable_type: string,
  favoriteable_id: number,
  id: number,
): Promise<DeleteFavoriteResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.DELETE_FAVORITE, {
      favoriteable_type,
      favoriteable_id,
      id,
    }),
  });
};
