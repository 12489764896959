import AuthLayout from "@/pages/_layouts/auth";
import Logo from "@/assets/logo.svg";
import LogoSp from "@/assets/logo-front-sp.svg";
import FormSetPasswordFirstLogin from "@/components/organisms/FormSettingAccount/setting-password";
import useIcon from "@/hooks/get-icon";
import useScreenSize from "@/hooks/screen";
export default function FrontSettingPasswordFirstLogin() {
  const { isMobile } = useScreenSize();
  const logo = useIcon(<LogoSp />, <Logo />);
  return (
    <AuthLayout>
      <div className="mobile:pt-12 mobile:pb-20 desktop:py-20 flex w-full items-center flex-col mobile:px-4 desktop:px-5">
        {logo}
        {isMobile ? (
          <h5 className="font-black my-4 text-center">新規登録</h5>
        ) : (
          <h4 className="font-black my-4 text-center">新規登録</h4>
        )}
        <div className="font-normal desktop:text-sm mobile:xs text-center mb-12">
          メールに記載されているパスワードを入力し、パスワードを変更してください。
        </div>
        <FormSetPasswordFirstLogin isAdminPage={false} />
      </div>
    </AuthLayout>
  );
}
