import { useShowVideoQuery } from "@/api/video";
import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import CreateVideoForm from "@/components/organisms/ContentManagement/video";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { ROLE_ENUM } from "@/configs/enum";
import useRole from "@/hooks/role";
import AnimatedRoute from "@/router/transition.route";
import { Skeleton } from "@chakra-ui/react";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

function EditVideoPage() {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);
  const params = useParams();
  const id = params.id as string;
  const folder_id = params.folder_id as string;

  const { data: videoInfo, isFetching } = useShowVideoQuery(folder_id, id);

  const breadcrumbs: BeardcrumbOption[] = useMemo(() => {
    return [
      {
        label: "動画一覧",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_VIDEO_LIST,
      },
      {
        label: `「${videoInfo?.title}」の編集`,
      },
    ];
  }, [videoInfo]);

  return (
    <div className="max-w-[100%] m-auto my-6">
      <Breadcrumb options={breadcrumbs} />
      <h4 className="mt-3 font-black">編集</h4>
      <div className="mt-8">
        {isFetching ? (
          <div className="flex flex-col gap-y-8">
            <div className="shadow-card bg-white p-6 rounded-[20px]">
              <Skeleton height={"52px"} rounded={12} />
            </div>
            <div>
              <h6 className="font-black mb-3">コンテンツ</h6>
              <div className="shadow-card bg-white p-6 rounded-[20px] mb-3">
                <div className="flex items-center gap-x-4">
                  <Skeleton height={"135px"} width={"240px"} rounded={12} />
                  <Skeleton height={"40px"} width={"240px"} rounded={12} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <CreateVideoForm
            folder_id={Number(params!.folder_id)}
            videoInfo={videoInfo}
          />
        )}
      </div>
    </div>
  );
}

const AnimatedEditVideoPage = AnimatedRoute(EditVideoPage);
export default AnimatedEditVideoPage;
