import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import { ISetBasicInfoForm, useSetBasicInfoForm } from "./validation";
import Button from "@/components/atoms/Button/button";
import UploadAvatar from "@/components/atoms/UploadAvatar/upload-avatar";
import { useEffect, useMemo, useState } from "react";
import { useGlobalStore } from "@/states/global.state";
import { handleApiError, mapAPIError } from "@/hooks/error";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_ROUTES_CONSTANT,
  PAGINATION_CONSTANT,
  ROUTES_CONSTANT,
} from "@/configs/constants";
import { User } from "@/api/type";
import { ROLE_ENUM } from "@/configs/enum";
import { useUpdateFirstInfoMutation } from "@/api/auth";
import { UploadResponse } from "@/hooks/upload";
import { getDepartmentOptions, getLinkMedia } from "@/shared/get";
import { useListDepartmentQuery } from "@/api/department";
import { ControlledSelect } from "@/components/molecules/ControlledSelect/controlled-select";
import { useListOfficeQuery } from "@/api/office";
/**
 * Represents the props interface for the FormSetInformationFirstLogin component.
 * @interface FormSetInformationFirstLoginProps
 * @property {boolean} [isAdminPage] - Indicates whether the component is rendered on an admin page.
 */
interface FormSetInformationFirstLoginProps {
  isAdminPage?: boolean;
  userInfo?: User | null;
}

type TFirstUserInfoParams = {
  first_name: string;
  last_name: string;
  office_id?: string;
  department_id?: string;
  first_info_changed_flag: boolean;
  avatar_attributes: {
    id?: string;
    key: string;
    name: string;
    content_type: string;
  } | null;
};

type TTempDataUser = {
  first_name: string;
  last_name: string;
  department?: {
    value: number | string;
    label: string;
  };
  avatar_attributes?: {
    id?: string;
    key: string;
    name: string;
    content_type: string;
  } | null;
};
/**
 * This component renders a form for setting information during the first login.
 */
export default function FormSetInformationFirstLogin({
  isAdminPage = false,
  userInfo,
}: FormSetInformationFirstLoginProps): JSX.Element {
  const { loading, setLoading } = useGlobalStore();
  const navigate = useNavigate();
  const [uploadErr, setUpErr] = useState<string>("");
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    setValue,
  } = useSetBasicInfoForm(isAdminPage);
  const { data: officeRes } = useListOfficeQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });
  const { data: departmentRes } = useListDepartmentQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });
  const departmentOptions = useMemo(() => {
    return getDepartmentOptions(officeRes?.offices, departmentRes?.departments);
  }, [officeRes, departmentRes]);
  const updateFirstInfoMutation = useUpdateFirstInfoMutation();
  const onSubmit = async (values: ISetBasicInfoForm) => {
    setUpErr("");
    try {
      setLoading(true);
      const payload: TFirstUserInfoParams = {
        first_name: values.first_name,
        last_name: values.last_name,
        avatar_attributes: values.avatar_attributes,
        first_info_changed_flag: true,
      };
      if (!isAdminPage) {
        payload.department_id = values.department?.value.split("-")[0] ?? "";
        payload.office_id = values.department?.value.split("-")[1] ?? "";
      }
      await updateFirstInfoMutation.mutateAsync({
        users: payload,
      });

      if (isAdminPage) {
        if (userInfo?.role.id === ROLE_ENUM.ADMIN_SYSTEM)
          navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_LIST);
        else navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST);
      } else {
        navigate(ROUTES_CONSTANT.ELEARNING_LIST);
      }
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError, (e) => {
        let path = e.path;
        if (e.path === "department_id" || e.path === "office_id") {
          path = "department";
        }
        if (e.path === "base" || e.path === "id") {
          path = "avatar_attributes";
        }

        setError(path, {
          message: e.message,
        });
      });
    } finally {
      setLoading(false);
    }
  };

  const onUpload = async (content: UploadResponse | null) => {
    if (!content) return;
    setValue("avatar_attributes", {
      key: content.key,
      name: content.name,
      content_type: content.content_type,
    });
  };

  useEffect(() => {
    if (userInfo) {
      const temp: TTempDataUser = {
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
      };
      if (!isAdminPage && userInfo.department && userInfo.office) {
        temp.department = {
          value: `${userInfo.department.id}-${userInfo.office.id}`,
          label: `${userInfo.department.name} / ${userInfo.office.name}`,
        };
      }
      reset(temp);
    }
  }, [userInfo]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit, () => {
        setUpErr("");
      })}
      className="flex flex-col justify-center desktop:p-8 mobile:px-4 mobile:py-8 whitespace-nowrap bg-white rounded-3xl w-full max-w-[572px]"
    >
      <div className="flex desktop:gap-x-8 mobile:gap-y-3 mobile:flex-col desktop:flex-row mobile:items-center desktop:items-start">
        <UploadAvatar
          onChange={onUpload}
          onError={(msg) => setUpErr(msg)}
          imageUrl={getLinkMedia(userInfo?.avatar?.key)}
        />
        <div className="flex flex-col gap-4 flex-1 mobile:w-full">
          {uploadErr.length > 0 ? (
            <div className="w-full text-error--main input-error__message">
              {uploadErr}
            </div>
          ) : (
            <></>
          )}
          <ControlledInput
            control={control}
            formField="first_name"
            label="姓"
            isRequired={true}
            isTrim={true}
            placeholder="佐藤"
            maxLength={126}
            name="first_name"
            autoComplete="first_name"
            errorMessage={errors.first_name?.message}
          />
          <ControlledInput
            control={control}
            formField="last_name"
            label="名"
            isTrim={true}
            isRequired={true}
            placeholder="太郎"
            maxLength={126}
            name="last_name"
            autoComplete="last_name"
            errorMessage={errors.last_name?.message}
          />
        </div>
      </div>
      {!isAdminPage && (
        <div className="mt-[15px]">
          <ControlledSelect
            options={departmentOptions}
            control={control}
            isClearable={true}
            isRequired={true}
            formField="department"
            label="所属"
            placeholder="所属を選択してください"
            errorMessage={errors.department?.message?.toString()}
          />
        </div>
      )}
      <Button
        size="sm"
        className={"mt-12 max-w-[335px] w-full m-auto"}
        type="submit"
        isLoading={loading.open}
      >
        登録
      </Button>
    </form>
  );
}
