import useScreenSize from "@/hooks/screen";
import { Tooltip } from "@chakra-ui/react";
import clsx from "clsx";
import { useMemo } from "react";

export interface TooltipProps {
  text?: string;
  max?: number;
  className?: string;
}
export default function CustomTooltip({
  text = "",
  max = 150,
  className,
}: TooltipProps) {
  const { screenWidth } = useScreenSize();

  const maxWidth = useMemo(() => {
    return screenWidth > 800 ? 800 : screenWidth - 20;
  }, [screenWidth]);

  if (!text) return "";
  if (text.length > max)
    return (
      <Tooltip
        hasArrow
        label={text}
        bg="var(--primary-lighter-color)"
        color="black"
        borderRadius={8}
        minWidth={280}
        maxWidth={maxWidth}
        maxHeight={500}
        className={clsx("overflow-auto", className)}
        openDelay={50}
      >
        {text.substring(0, max) + "⋯"}
      </Tooltip>
    );
  return <>{text}</>;
}
