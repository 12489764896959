import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/shared/validation/yup";
import { Attachment } from "@/api/type";

export interface ICustomEmojiForm {
  name: string;
  code: string | null;
  attachment: Attachment | null;
}

export const useCustomEmojiForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .label("emoji.name")
          .matches(/^[a-zA-Z0-9/s]+$/, "半角英文字を入力してください。")
          .max(100)
          .required(),
        code: yup.string().label("emoji.code").max(255).nullable(""),
        attachment: yup
          .object()
          .shape({
            key: yup.string().required(),
            name: yup.string().required(),
            content_type: yup.string().required(),
          })
          .required("画像をアップロードしてください。"),
      }),
    [],
  );

  return useForm<ICustomEmojiForm>({
    resolver: yupResolver(validationScheme) as any,
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });
};
