/**
 * Cuts a string to a specified maximum length.
 *
 * @param {string} str - The string to be cut.
 * @param {number} [max=80] - The maximum length of the string. Defaults to 80.
 * @returns {string} The cut string.
 */
const cutString = (str?: string | null, max: number = 80): string => {
  if (!str) return "";
  if (str.length > max) {
    return str.substring(0, max) + "⋯";
  }
  return str;
};

/**
 * Converts a string to a number based on the specified type.
 *
 * @param {string} str - The string to be converted.
 * @param {string} [type="integer"] - The type of number to convert to. Defaults to "integer".
 * @returns {string} The converted number as a string.
 */
const convertToNumber = (str: string, type = "integer") => {
  if (type === "integer") {
    return str.replace(/[^0-9]/g, "");
  }
  return str;
};

/**
 * Escapes HTML characters in the input text.
 *
 * @param {string} text - The input text to escape HTML characters from
 * @return {string} The text with HTML characters escaped
 */
const escapeHtml = (text: string): string => {
  return text
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;");
};

/**
 * Cleans the content by escaping HTML characters and converting URLs into clickable links.
 *
 * @param {string | null} content - The content to be cleaned
 * @return {string} The cleaned content with HTML characters escaped and URLs converted to clickable links
 */
const cleanContentComment = (content?: string | null): string => {
  if (!content) {
    return "";
  }

  const pattern = new RegExp(
    "^(http(s)?://.)" + // protocol
      "(www.)?" + // www subdomain (optional)
      "(" +
      "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,})" + // domain name
      "|" +
      "((\\d{1,3}\\.){3}\\d{1,3})" + // OR ip (v4) address
      ")" +
      "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-zA-Z\\d_]*)?$", // fragment locator
    "gmi",
  );

  let result = escapeHtml(content);

  const matches = result.match(pattern);

  if (!matches) {
    return result;
  }

  matches.forEach((match) => {
    const link = `<a href='${match}' class='text-primary--main' target='_blank'>${match}</a>`;
    result = result.replace(match, link);
  });

  return result;
};

export { cutString, convertToNumber, cleanContentComment, escapeHtml };
