import { QUESTION_KIND_ENUM } from "@/configs/enum";

import { Control } from "react-hook-form";
import { IQuizContentForm } from "./validation";
import { ControlledCheckbox } from "@/components/molecules/ControlledCheckbox/controlled-checkbox";
import { ControlledRadio } from "@/components/molecules/ControlledRadio/controlled-radio";
import { ControlledTextArea } from "@/components/molecules/ControlledTextare/controlled-textarea";
import { MAX_LENGTH } from "@/configs/constants";

/**
 * Interface: QuizzItemProps
 *
 * Represents the props for the QuizzItem component.
 *
 * @property questionNumber - The number of the question.
 * @property title - The title of the question.
 * @property answers - The list of answers.
 * @property kind - The kind of the question.
 */
interface QuizzItemProps {
  control: Control<IQuizContentForm, any>;
  index: number;
  title: string;
  answers: {
    id: number;
    title: string;
    is_correct: boolean;
    point: number;
  }[];
  kind: QUESTION_KIND_ENUM;
  isReadOnly?: boolean;
  onChange: (v?: any) => void;
}

export default function FrontQuizzItem({
  control,
  index,
  title,
  kind,
  answers,
  onChange,
  isReadOnly = false,
}: QuizzItemProps) {
  return (
    <div className="flex flex-col gap-4">
      <h6>{`Q.${index + 1} ${title}`}</h6>
      <div>
        {kind === QUESTION_KIND_ENUM.MULTI_ANSWER && (
          <ControlledCheckbox
            control={control}
            formField={`questions.${index}.answers`}
            direction="vertical"
            classes={{
              spacing: "2",
            }}
            options={answers.map((answer) => ({
              value: answer.id,
              label: answer.title,
            }))}
            onChangeCallback={onChange}
            isReadOnly={isReadOnly}
          />
        )}
        {kind === QUESTION_KIND_ENUM.SINGLE_ANSWER && (
          <ControlledRadio
            control={control}
            formField={`questions.${index}.answers`}
            direction="vertical"
            classes={{
              spacing: "2",
            }}
            options={answers.map((answer) => ({
              value: answer.id,
              label: answer.title,
            }))}
            onChangeCallback={onChange}
            isReadOnly={isReadOnly}
          />
        )}
        {kind === QUESTION_KIND_ENUM.FREE_INPUT && (
          <ControlledTextArea
            formField={`questions.${index}.answers`}
            control={control}
            maxLength={MAX_LENGTH.TEXT}
            isTrim={true}
            onChangeCallback={onChange}
            isReadOnly={isReadOnly}
          />
        )}
      </div>
    </div>
  );
}
