import { getRoute } from "@/shared/get/route";
import { request } from "../axios";
import { API_PATH_CONSTANT } from "../constant";
import { Attachment, CustomEmoji, Paging } from "../type";

export type FilterEmojiResponseBody = {
  emojis: CustomEmoji[];
  paging: Paging;
};

export type CreateEmojiRequestBody = {
  code: string;
  name: string;
  attachment_attributes: Attachment;
};

export type CreateEmojiResponseBody = {
  id: number;
};

export const listEmojiApi = async (): Promise<FilterEmojiResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(`${API_PATH_CONSTANT.LIST_EMOJI}`),
  });
};

export const createEmojiApi = async (
  data: CreateEmojiRequestBody,
): Promise<CreateEmojiResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.CREATE_EMOJI),
    data: { emojis: data },
  });
};
