import { ELearningItemSkeleton } from "@/components/molecules/ELearningItem/e-learning-item.skeleton";

interface ElearinngListSkeletonProps {
  title: string;
}

export const ElearinngListSkeleton = ({
  title,
}: ElearinngListSkeletonProps) => {
  return (
    <div className="flex flex-col gap-4">
      {title && <h5 className="font-black">{title}</h5>}
      <div className="flex gap-2 flex-col">
        <ELearningItemSkeleton />
        <ELearningItemSkeleton />
      </div>
    </div>
  );
};
