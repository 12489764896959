import { MAX_LENGTH } from "@/configs/constants";
import yup from "@/shared/validation/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

export interface ICreateUserForm {
  first_name: string;
  last_name: string;
  role: { value: number; label: string } | any;
  department: { value: number; label: string } | any;
  email: string;
  password: string | null | undefined;
  avatar_attributes: {
    key: string;
    name: string;
    content_type: string;
  } | null;
}

export const useCreateUserForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        first_name: yup
          .string()
          .label("admin.user.create.first_name")
          .max(126)
          .noEmoji()
          .required(),
        last_name: yup
          .string()
          .label("admin.user.create.last_name")
          .max(126)
          .noEmoji()
          .required(),
        department: yup
          .object()
          .label("admin.user.create.department")
          .nullable(),
        role: yup
          .object()
          .nullable()
          .label("admin.user.create.role_id")
          .required(),
        email: yup
          .string()
          .label("admin.user.create.email")
          .email()
          .max(MAX_LENGTH.EMAIL)
          .required(),
        password: yup.string().nullable(),
        avatar_attributes: yup.object().nullable(),
      }),
    [],
  );

  return useForm<ICreateUserForm>({
    resolver: yupResolver(validationScheme) as any,
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      password: "",
    },
  });
};
