import {
  MutateOptions,
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CheckUnreadNotificationRequestBody,
  CheckUnreadNotificationResponseBody,
  FilterNotificationRequestBody,
  FilterNotificationResponseBody,
  ReadUserNotificationRequestBody,
  ReadUserNotificationResponseBody,
  checkUnreadNotificationApi,
  listNotificationApi,
  readUserNotificationApi,
} from "./request";

export const useListUserNotificationQuery = (
  params: Partial<FilterNotificationRequestBody>,
  options?: UseQueryOptions<
    FilterNotificationResponseBody,
    Error,
    FilterNotificationResponseBody,
    (string | Partial<FilterNotificationRequestBody>)[]
  >,
) => {
  return useQuery({
    queryKey: ["filter-user-notification", params],
    queryFn: listNotificationApi,
    ...options,
  });
};

export const fetchListUserNotification = async (
  queryClient: QueryClient,
  params?: Partial<FilterNotificationRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["filter-notification", params],
    queryFn: listNotificationApi,
  });
};

export const useReadUserNotificationMutation = (
  options: MutateOptions<
    ReadUserNotificationResponseBody,
    unknown,
    ReadUserNotificationRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: readUserNotificationApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useCheckUnreadNotification = (
  params: Partial<CheckUnreadNotificationRequestBody>,
  options?: UseQueryOptions<
    CheckUnreadNotificationResponseBody,
    Error,
    CheckUnreadNotificationResponseBody,
    (Partial<CheckUnreadNotificationRequestBody> | string)[]
  >,
) => {
  return useQuery({
    queryKey: ["check-unread-notification", params],
    queryFn: checkUnreadNotificationApi,
    ...options,
  });
};
