import PencilIcon from "@/assets/icon/edit-02.svg";
import Button from "@/components/atoms/Button/button";
import PlusIcon from "@/assets/icon/plus-02.svg";
import CloseIcon from "@/assets/icon/close.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { ControlledInput } from "../ControlledInput/controlled-input";
import { ICategoryForm, useCategoryForm } from "./validation";
import { v4 as uuidv4 } from "uuid";
import { FieldArrayWithId, useFieldArray } from "react-hook-form";
import useToast from "@/hooks/toast";
import { useGlobalStore } from "@/states/global.state";
import {
  handleApiError,
  mapAPIError,
  useScrollToFirstError,
} from "@/hooks/error";
import { useUpsertCategoryMutation } from "@/api/category";
import { isNumeric } from "@/shared/get/check";
import CircleCloseIcon from "@/assets/icon/circle-close.svg";
import { MAX_LENGTH } from "@/configs/constants";
import { ReactSortable } from "react-sortablejs";
import { Box, useDisclosure } from "@chakra-ui/react";
import { ModalError } from "../ModalError/modal-error";

interface CategoryProps {
  index: number;
  data: ICategoryForm;
  onChange?: (data?: ICategoryForm) => void;
  onDelete?: (data: ICategoryForm) => void;
}
export default function CategorySetting({
  data,
  onChange,
  onDelete,
}: CategoryProps) {
  const { showToast } = useToast();
  const { loading, setLoading } = useGlobalStore();
  const [errorAPI, setErrorAPI] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorPopup, setErrorPopup] = useState("");
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState,
    setError,
    getValues,
  } = useCategoryForm();
  useScrollToFirstError(formState);
  const { append, remove, fields } = useFieldArray({
    control,
    name: "children",
  });
  const [arrDelete, setArrDelete] = useState<string[]>([]);
  const { errors } = formState;
  const watchField = watch();
  const upsertMutation = useUpsertCategoryMutation();

  const handleChangeMode = (type: "edit" | "readonly") => {
    setErrorAPI("");
    if (type === "readonly") setArrDelete([]);
    setValue("isEdit", type === "edit");
  };

  const onSubmit = async (values: ICategoryForm) => {
    setErrorAPI("");
    try {
      setLoading(true);
      await upsertMutation.mutateAsync({
        id: !isNumeric(values.id) ? undefined : Number(values.id),
        categories: {
          name: values.name,
          sub_categories_attributes: [
            ...data.children
              .filter((i) => {
                return arrDelete.includes(i.child_id);
              })
              .map((child) => {
                return {
                  id: Number(child.child_id),
                  _destroy: true,
                  name: child.name,
                };
              }),
            ...values.children.map((child) => {
              return {
                id: !isNumeric(child.child_id)
                  ? undefined
                  : Number(child.child_id),
                _destroy: false,
                name: child.name,
              };
            }),
          ],
        },
      });
      showToast({
        title: `変更を保存しました`,
        type: "success",
      });
      setValue("isEdit", false);
      onChange?.();
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError, (e) => {
        const path = e.path;
        if (
          e.path === "sub_categories.name" ||
          e.path === "base" ||
          e.path === "id"
        ) {
          setErrorAPI(e.message);
        } else if (e.path === "popup") {
          onOpen();
          setErrorPopup(e.message);
        } else {
          setError(path, {
            message: e.message,
          });
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const onCancel = useCallback(() => {
    if (!isNumeric(data.id)) {
      onDelete?.(data);
    } else {
      reset(data);
    }
    handleChangeMode("readonly");
  }, [data]);

  const removeChildCategory = useCallback(
    (index: number, id: string) => {
      if (isNumeric(id)) {
        setArrDelete((prev) => [...prev, id]);
      }
      remove(index);
    },
    [remove, fields],
  );

  const addNewChildCategory = useCallback(() => {
    append({ child_id: uuidv4(), name: "" });
  }, [append]);

  useEffect(() => {
    setArrDelete([]);
    reset(data);
  }, [data]);

  const isSortingRef = useRef(false);
  const updateOrder = async (
    newState: FieldArrayWithId<ICategoryForm, "children", "id">[],
  ) => {
    if (!isSortingRef.current) return;
    isSortingRef.current = false;

    setValue(
      "children",
      newState.map((i) => {
        const index = getValues(`children`).findIndex(
          (child) => child.child_id === i.child_id,
        );
        return {
          ...i,
          name: getValues(`children.${index}.name`),
        };
      }),
    );
  };

  return (
    <div
      className={`rounded-[20px] bg-white ${
        isNumeric(watchField.id) ? "drag-handle" : "static"
      }`}
    >
      <div
        className="px-6 py-5 flex justify-between gap-x-4 w-full items-start [border-bottom:1px_solid_#E6E8EA] [box-shadow:0px_0px_20px_0px_#0000001A];
"
      >
        <div className="flex items-start gap-2 flex-1">
          <div
            className={`bg-primary--main min-w-4 w-4 h-2 rounded-[5px] mt-[${
              watchField.isEdit ? "20" : "10"
            }px]`}
          ></div>
          {watchField.isEdit ? (
            <ControlledInput
              control={control}
              formField="name"
              isTrim={true}
              maxLength={MAX_LENGTH.VARCHAR}
              className="w-full"
              placeholder="大カテゴリを入力してください"
              errorMessage={errors.name?.message}
            />
          ) : (
            <h6
              className="font-black text-wrap"
              style={{
                lineBreak: "anywhere",
              }}
            >
              {watchField.name}
            </h6>
          )}
        </div>
        {watchField.isEdit ? (
          <Button
            variant="danger-outline"
            buttonCustom={{
              padding: "6px 8px",
            }}
            size="xs"
            className="mt-[10px]"
            startSlot={<CloseIcon />}
            onClick={() => onDelete?.(data)}
          >
            大カテゴリ削除
          </Button>
        ) : (
          <PencilIcon
            className="cursor-pointer edit-tag"
            onClickCapture={() => handleChangeMode("edit")}
          />
        )}
      </div>
      <div className="px-6 pt-3 pb-4">
        <div className="flex flex-col gap-y-4">
          {errorAPI.length > 0 && (
            <div className="mt-1 w-full text-error--main input-error__message is-invalid">
              {errorAPI.replace(/<br\s*\/?>/, "\n")}
            </div>
          )}
          {!watchField.isEdit ? (
            <div className="flex flex-col gap-y-[10px]">
              {fields.map((field) => (
                <div
                  className="flex items-start gap-x-4 w-full child-drag-handle"
                  key={field.child_id}
                >
                  <div className="flex items-start gap-x-3 w-full">
                    <span
                      className={`min-w-2 min-h-2 ml-1 w-2 h-2 rounded-full bg-primary--main`}
                      style={{
                        marginTop: "8px",
                      }}
                    ></span>
                    <p
                      className="body1 mb-0 text-wrap"
                      style={{
                        lineBreak: "anywhere",
                      }}
                    >
                      {field.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <ReactSortable
              tag="div"
              list={fields}
              draggable=".child-drag-handle"
              onUpdate={() => (isSortingRef.current = true)}
              setList={(updatedList) => updateOrder(updatedList)}
              onMove={(e) => {
                return e.related.className.indexOf("static") === -1;
              }}
              className="flex flex-col gap-y-[10px]"
            >
              {fields.map((field) => {
                const index = fields.findIndex(
                  (i) => i.child_id === field.child_id,
                );
                return (
                  <div
                    className={`flex items-start gap-x-4 w-full child-drag-handle`}
                    key={field.child_id}
                  >
                    <div className="flex items-start gap-x-3 w-full">
                      <span
                        className={`ml-1 min-w-2 min-h-2 w-2 h-2 rounded-full bg-primary--main cursor-pointer`}
                        style={{
                          marginTop: "20px",
                        }}
                      ></span>
                      <ControlledInput
                        control={control}
                        formField={`children.${index}.name`}
                        className="w-full"
                        isTrim={true}
                        maxLength={MAX_LENGTH.VARCHAR}
                        placeholder="中カテゴリを入力してください"
                        errorMessage={
                          errors.children?.at?.(index)?.name?.message
                        }
                      />
                    </div>
                    <Box as={"button"}>
                      <CircleCloseIcon
                        className="cursor-pointer mt-3"
                        onClickCapture={() =>
                          removeChildCategory(index, field.child_id)
                        }
                      />
                    </Box>
                  </div>
                );
              })}
            </ReactSortable>
          )}
        </div>

        <Button
          variant="outline"
          buttonCustom={{
            padding: "6px 8px",
            borderRadius: "8px",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
            borderColor: "#E6E8EA",
          }}
          startSlot={<PlusIcon width={20} height={20} />}
          className="hover:border-primary mt-4"
          isDisabled={!watchField.isEdit}
          onClick={addNewChildCategory}
        >
          中カテゴリを追加する
        </Button>

        {watchField.isEdit ? (
          <div className="flex gap-x-4 justify-end mt-[34px]">
            <Button
              variant="info"
              size="sm"
              className="min-w-[128px] w-fit"
              onClick={onCancel}
            >
              キャンセル
            </Button>
            <Button
              variant="primary"
              size="sm"
              className="min-w-[128px] w-fit"
              onClick={handleSubmit(onSubmit)}
              isLoading={loading.open}
            >
              保存
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <ModalError
        id="modal-error"
        isOpen={isOpen}
        message={
          <p className="w-full text-error--main input-error__message">
            {errorPopup}
          </p>
        }
        onClose={() => {
          onChange?.();
          setErrorPopup("");
          onClose();
        }}
      />
    </div>
  );
}
