import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

/**
 * Renders the AffiliationPage component.
 *
 * This component is responsible for rendering the Affiliation page in the admin management section.
 * It uses the useNavigate hook from react-router-dom to navigate to the AFFILIATION route defined in the ADMIN_ROUTES_CONSTANT object.
 * The useEffect hook is used to trigger the navigation when the component mounts.
 *
 * @returns The rendered Outlet component.
 */
export default function AffiliationPage() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/admin/management/affiliation")
      navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.OFFICE);
  }, [location.pathname]);

  return <Outlet />;
}
