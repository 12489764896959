import styled, { keyframes } from "styled-components";

const spin = keyframes`
      to {
        transform: rotate(360deg);
      }
    `;

export const SpinnerWrapper = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const SpinnerCircle = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid var(--text-disabled);
  border-radius: 50%;
  animation: ${spin} 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--text-disabled) transparent transparent transparent;
`;
