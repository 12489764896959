import FrontLayout from "@/pages/_layouts/front-main";
import { ELearningList } from "@/components/organisms/ELearningList/e-learning-list";
import { Info } from "@/components/molecules/Info/Info";
import useRole from "@/hooks/role";
import AnimatedRoute from "@/router/transition.route";
import { useListMyCourseQuery } from "@/api/e-learning";
import { useEffect, useState } from "react";
import {
  ELEARNING_CONTENT_TYPE_LABEL,
  PAGINATION_CONSTANT,
  ROUTES_CONSTANT,
} from "@/configs/constants";
import { formatDate } from "@/shared/format";
import { ROLE_ENUM } from "@/configs/enum";
import { getRoute } from "@/shared/get/route";

type TDataList = {
  id: number;
  titleName: string;
  type: string;
  description: string;
};

export function MyPageComponent() {
  const { userInfo } = useRole(false, [
    ROLE_ENUM.MEMBER,
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const { data: listMyCourse } = useListMyCourseQuery({
    page,
    per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
  });
  const [dataList, setDataList] = useState<TDataList[]>([]);

  useEffect(() => {
    if (!listMyCourse?.courses) return;
    const currentPage = listMyCourse.paging.current_page;
    const list = listMyCourse.courses.map((course) => ({
      id: course.id,
      required: course?.required ?? false,
      titleName: course?.title ?? "",
      type:
        course.category && course.sub_category
          ? course.category?.name + " / " + course.sub_category.name
          : course.category?.name ?? "",
      dateLearning: formatDate(course.deadline_date),
      isError: !!course.deadline_date,
      timeLearning: formatDate(course.joined_at),
      description:
        (
          course.kinds?.map((kind) => {
            return ELEARNING_CONTENT_TYPE_LABEL[
              kind as keyof typeof ELEARNING_CONTENT_TYPE_LABEL
            ] as string;
          }) ?? []
        ).join(" - ") ?? "",
    }));
    if (currentPage === 1) {
      setDataList(list);
    } else {
      setDataList((prev) => [...prev, ...list]);
    }
    setHasMore(listMyCourse.paging.total_pages > currentPage);
  }, [listMyCourse]);

  const onloadMore = () => {
    setTimeout(() => {
      setPage(page + 1);
    }, 300);
  };
  return (
    <div className="desktop:py-12 flex max-w-[960px] w-full gap-10 flex-col desktop:mx-auto mobile:py-6 mobile:px-4 desktop:px-0">
      <Info
        data={userInfo}
        setPasswordRoute={getRoute(ROUTES_CONSTANT.SET_PASSWORD, {
          id: userInfo?.id,
        })}
      />
      <ELearningList
        data={dataList}
        title="Eラーニング受講履歴"
        hasMore={hasMore}
        onLoadMore={onloadMore}
        fromMyPage={true}
      />
    </div>
  );
}

const AnimatedFrontMyPage = AnimatedRoute(MyPageComponent, false);

export default function FrontMyPage() {
  return (
    <FrontLayout>
      <AnimatedFrontMyPage />
    </FrontLayout>
  );
}
