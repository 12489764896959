import { useNavigate, useSearchParams } from "react-router-dom";

import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { getRoute } from "@/shared/get/route";
import Button from "@/components/atoms/Button/button";
import EditWhite from "@/assets/icon/edit-white-02.svg";
import QuizzItem from "./quizz-item";
import { QuizzQuestion } from "@/api/type";
import { QUESTION_KIND_ENUM } from "@/configs/enum";
import { useEffect } from "react";

/**
 * Interface: ManagerQuizzQuestionsProps
 *
 * Represents the props for the ManagerQuizzQuestions component.
 *
 * @property id - The id of the quizz.
 * @property questions - The list of questions.
 */
interface ManagerQuizzQuestionsProps {
  id: number;
  questions: QuizzQuestion[];
}

export default function ManagerQuizzQuestions({
  id,
  questions,
}: ManagerQuizzQuestionsProps) {
  // #region Data
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  // #endregion

  // #region Events
  const onEditQuizz = () => {
    navigate(getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_EDIT, { id }));
  };
  // #endregion

  useEffect(() => {
    if (searchParams.size) {
      setSearchParams({});
    }
  }, []);

  return (
    <div>
      <div className="mb-5 flex items-center justify-between">
        <h5>コンテンツ</h5>
        <Button
          size="sm"
          startSlot={<EditWhite className="size-6" />}
          className="w-[140px] h-10 gap-1"
          onClick={onEditQuizz}
        >
          編集する
        </Button>
      </div>

      <div className="shadow-card p-6 bg-white rounded-[20px] flex flex-col gap-10">
        {questions.map((question, index) => (
          <QuizzItem
            key={`questions.${index}`}
            questionNumber={index + 1}
            title={question.title ?? ""}
            kind={question.kind as QUESTION_KIND_ENUM}
            answers={question.quiz_question_answers}
          />
        ))}
      </div>
    </div>
  );
}
