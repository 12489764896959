import AuthLayout from "@/pages/_layouts/auth";
import ArrowLeftIcon from "@/assets/icon/arrow-left-02.svg";
import { useNavigate } from "react-router-dom";
import FormSetPassword from "@/components/organisms/FormSetPassword";
import useScreenSize from "@/hooks/screen";
import { ROUTES_CONSTANT } from "@/configs/constants";

export default function FrontSetPasswordPage() {
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(ROUTES_CONSTANT.LOGIN);
  };
  return (
    <AuthLayout>
      <div className="mobile:py-[48px] desktop:py-[80px] flex w-full items-center flex-col mobile:px-4 desktop:px-5">
        <div className="flex justify-center w-full relative">
          <div
            className={"cursor-pointer absolute top-0 left-0"}
            onClickCapture={goBack}
          >
            <ArrowLeftIcon />
          </div>

          {isMobile ? (
            <h5 className="font-[900] text-center">パスワードの再設定</h5>
          ) : (
            <h4 className="font-[900] text-center">パスワードの再設定</h4>
          )}
        </div>

        <div className="mt-[48px] w-full flex justify-center">
          <FormSetPassword />
        </div>
      </div>
    </AuthLayout>
  );
}
