import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/shared/validation/yup";
import { MAX_LENGTH } from "@/configs/constants";

export interface ISetPassForm {
  current_password: string;
  password: string;
  password_confirmation: string;
}

export const useSetPassForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        current_password: yup
          .string()
          .label("set_account.current_password")
          .max(MAX_LENGTH.PASSWORD)
          .required(),
        password: yup
          .string()
          .label("set_account.new_password")
          .password()
          .max(MAX_LENGTH.PASSWORD)
          .required(),
        password_confirmation: yup
          .string()
          .label("set_account.confirm_password")
          .sameAs("password")
          .required(),
      }),
    [],
  );

  return useForm<ISetPassForm>({
    resolver: yupResolver(validationScheme),
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });
};

export interface ISetBasicInfoForm {
  first_name: string;
  last_name: string;
  department: { value: number; label: string } | any;
  avatar_attributes: {
    key: string;
    name: string;
    content_type: string;
  } | null;
}
export const useSetBasicInfoForm = (isAdminPage = false) => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        first_name: yup
          .string()
          .label("set_account.first_name")
          .max(126)
          .noEmoji()
          .required(),
        last_name: yup
          .string()
          .label("set_account.last_name")
          .max(126)
          .noEmoji()
          .required(),
        department: isAdminPage
          ? yup.object().label("set_account.link").nullable()
          : yup.object().label("set_account.link").required(),
        avatar_attributes: yup.object().nullable(),
      }),
    [isAdminPage],
  );

  return useForm<ISetBasicInfoForm>({
    resolver: yupResolver(validationScheme) as any,
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });
};
