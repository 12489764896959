import Button from "@/components/atoms/Button/button";
import { Skeleton } from "@chakra-ui/react";

import RichTextEditor from "@/components/atoms/RichTextEditor/rich-text-editor";

export default function ComplianceTrainingCreateSkeleton() {
  return (
    <div className="flex flex-col gap-y-8 mb-16">
      <div className="shadow-card bg-white p-6 rounded-[20px] flex flex-col gap-y-6">
        <div>
          <div className="flex gap-2 mb-1 justify-between">
            <div className="flex flex-col justify-center text-xs max-md:max-w-full">
              <div className="flex gap-1 max-md:flex-wrap">
                <div className="text-[var(--text-secondary)]">タイトル</div>
              </div>
            </div>
          </div>
          <Skeleton height="50px" className="!rounded-[12px]" />
        </div>
        <div>
          <div className="flex gap-2 mb-1 justify-between">
            <div className="flex flex-col justify-center text-xs max-md:max-w-full">
              <div className="flex gap-1 max-md:flex-wrap">
                <div className="text-[var(--text-secondary)]">公開設定</div>
              </div>
            </div>
          </div>
          <Skeleton height="24px" />
        </div>
      </div>
      <div>
        <h6 className="font-black mb-3">コンテンツ</h6>
        <div className="shadow-card bg-white p-6 rounded-[20px] mb-3">
          <Skeleton height="58px" className="!rounded-[12px]" />
        </div>

        <RichTextEditor onChange={() => {}} value={null} disabled />
      </div>
      <div className="flex gap-[10px] justify-end">
        <Button
          size="sm"
          variant="info"
          className="min-w-[128px] w-fit"
          buttonCustom={{ padding: "12px 12px" }}
          isLoading={true}
        ></Button>

        <Button
          size="sm"
          className="min-w-[128px] w-fit"
          buttonCustom={{ padding: "12px 12px" }}
          type="submit"
          isLoading={true}
        ></Button>
      </div>
    </div>
  );
}
