import Checkbox from "@/components/atoms/Checkbox/checkbox";
import { RadioProps } from "@chakra-ui/react";
import clsx from "clsx";
import { useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldValue,
  FieldValues,
  Path,
} from "react-hook-form";

export interface OptionRadio {
  value: string | number;
  label: string;
  description?: string;
}

interface ControlledCheckboxProps
  extends Omit<RadioProps, "defaultValue" | "value" | ""> {
  options: OptionRadio[];
  label?: string;
  note?: string;
  isRequired?: boolean;
  errorMessage?: string;
  direction?: "horizontal" | "vertical";
  classes?: {
    radioWrapper?: string;
    spacing?: string;
  };
  isReadOnly?: boolean;
  onChangeCallback?: (v: string | number | undefined) => void;
}

interface OwnProps<Type extends FieldValues>
  extends Omit<ControlledCheckboxProps, "value"> {
  control: Control<FieldValue<Type>>;
  formField: Path<FieldValue<Type>>;
}

export const ControlledCheckbox = <T extends FieldValues>({
  control,
  formField,
  className,
  label,
  note,
  isRequired,
  errorMessage,
  options,
  classes,
  direction = "horizontal",
  onChangeCallback,
  isReadOnly = false,
}: OwnProps<T>) => {
  const [isFocusing] = useState(false);
  const isError = useMemo(
    () => errorMessage && errorMessage.length > 0,
    [errorMessage],
  );

  return (
    <Controller
      control={control}
      name={formField}
      render={({ field }) => {
        return (
          <div className={className}>
            {label?.length && (
              <div className="flex gap-2 mb-1 justify-between">
                <div className="flex flex-col justify-center text-xs max-md:max-w-full">
                  <div className="flex gap-1 max-md:flex-wrap">
                    <div
                      className={clsx(
                        isFocusing && !isError
                          ? "text-[var(--primary-main-color)]"
                          : "text-[var(--text-secondary)]",
                      )}
                    >
                      {label}
                    </div>
                    {isRequired && <div className="text-error--main">*</div>}
                  </div>
                </div>
                {note && (
                  <div className="text-[var(--text-secondary)] caption1">
                    {note}
                  </div>
                )}
              </div>
            )}
            <div
              className={clsx(
                "flex",
                direction === "vertical"
                  ? `flex-col gap-y-${classes?.spacing ?? 8}`
                  : `flex-row gap-x-${classes?.spacing ?? 8}`,
                classes?.radioWrapper,
              )}
            >
              {options.map((option) => (
                <Checkbox
                  key={option.value}
                  isError={Boolean(isError)}
                  label={option.label}
                  name={field.name}
                  value={option.value}
                  checked={field.value?.some(
                    (val: string | number) => option.value === val,
                  )}
                  isReadOnly={isReadOnly}
                  onChange={(v) => {
                    const current: (string | number)[] = field.value ?? [];
                    if (v) {
                      const index = field.value?.findIndex(
                        (val: string | number) => option.value === val,
                      );
                      if (index === undefined || index === -1) {
                        current.push(v);
                      } else {
                        current.splice(index, 1);
                      }
                      field.onChange(current);
                    }
                    onChangeCallback && onChangeCallback(v);
                  }}
                />
              ))}
            </div>
            {isError && (
              <div className="mt-1 w-full text-error--main input-error__message">
                {errorMessage}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
