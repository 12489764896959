import React, { useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";
import "web-streams-polyfill/dist/polyfill.js";

import "webrtc-adapter";
import RecordRTC from "recordrtc";

// register videojs-record plugin with this import
import "videojs-record/dist/css/videojs.record.css";
import "videojs-record/dist/videojs.record.js";
import "videojs-record/dist/plugins/videojs.record.webm-wasm.js";
import { ModalError } from "../ModalError/modal-error";
import { MESSAGES } from "@/shared/validation/message";

interface IVideoJSProps {
  autoGetDevice?: boolean;
  options: any;
  onReady: (player: any) => void;
}

export const MediaRecorder = (props: IVideoJSProps) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<any>(null);
  const newVideoJS = videojs as any;
  const [error, setError] = useState<string>("");
  const { options, onReady, autoGetDevice = true } = props;

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.className =
        "video-js vjs-default-skin video-record-js vjs-mirror";
      if (videoRef.current) videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        // print version information at startup
        const version_info =
          "Using video.js " +
          newVideoJS.VERSION +
          " with videojs-record " +
          newVideoJS.getPluginVersion("record") +
          ", recordrtc " +
          RecordRTC.version +
          " and React " +
          React.version;
        newVideoJS.log(version_info);
        autoGetDevice && playerRef.current.record().getDevice();
        onReady && onReady(player);
      }));
    } else {
      const player = playerRef.current;

      autoGetDevice && player.record().getDevice();
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    if (player) {
      player.on("deviceError", () => {
        console.error("device error:", player.deviceErrorCode, player);
        setError(MESSAGES.MSG_046);
      });
    }

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <>
      <div data-vjs-player>
        <div ref={videoRef} />
      </div>
      <ModalError
        isOpen={!!error.length}
        message={
          <p className="w-full text-error--main input-error__message">
            {error}
          </p>
        }
        btnCloseText="OK"
        onClose={() => setError("")}
      />
    </>
  );
};

export default MediaRecorder;
