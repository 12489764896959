import { NotificationPayload, useGlobalStore } from "@/states/global.state";
// import { delay } from "framer-motion";
import { useCallback } from "react";

export default function useToast() {
  const { notify, clearNotification } = useGlobalStore();

  const showToast = useCallback((payload: NotificationPayload) => {
    notify(payload);
    // if (noti.timeout) {
    //   delay(() => clearNotification(noti.id), noti.timeout);
    // }
  }, []);

  const clearToast = useCallback((id?: string) => {
    clearNotification(id);
  }, []);

  return { showToast, clearToast };
}
