// import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
// import { useEffect } from "react";
import { Outlet } from "react-router-dom";

/**
 * Renders the CategoryTagPage component.
 *
 * This component is responsible for rendering the CategoryTagPage. It uses the `useNavigate` hook from react-router-dom to navigate to the `CATEGORY` route defined in the `ADMIN_ROUTES_CONSTANT` constant.
 *
 * @returns The rendered CategoryTagPage component.
 */
export default function CategoryTagPage() {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.CATEGORY);
  // }, []);
  return <Outlet />;
}
