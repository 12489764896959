import { request } from "../axios";
import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";
import { Paging, Role } from "../type";
import { PAGINATION_CONSTANT } from "@/configs/constants";

export const listRoleApi = async (): Promise<Role[]> => {
  const res: {
    roles: Role[];
    paging: Paging;
  } = await request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_ROLE),
    params: {
      page: 1,
      per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
    },
  });
  return res.roles;
};
