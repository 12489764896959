import { STORAGE_KEYS } from "@/api/axios";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import {
  isErrorPage,
  isLoginPage,
  isPageWithoutAuth,
} from "@/shared/get/check";
import useToast from "@/hooks/toast";
import { fetchMyProfile } from "@/api/auth";
import { useQueryClient } from "@tanstack/react-query";
import { ROLE_ENUM } from "@/configs/enum";
import ToastForAdmin from "@/components/molecules/Toast/toast.admin";
import { useAuthStore } from "@/states/auth.state";

export default function AdminPage() {
  const [isLoading, setLoading] = useState(true);
  const { setUserInfo } = useAuthStore();
  const { clearToast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const pathnames = [
    "/admin",
    "/admin/management",
    "/admin/",
    "/admin/management/",
  ];
  const isBasePath = pathnames.includes(location.pathname);
  const token = Cookies.get(STORAGE_KEYS.ACCESS_TOKEN);

  const checkIsLogined = useCallback(async () => {
    try {
      if (isErrorPage()) return setLoading(false);
      const info = await fetchMyProfile(queryClient);
      setUserInfo(info);
      setLoading(false);

      if (isBasePath || isLoginPage()) {
        if (info.role.id === ROLE_ENUM.ADMIN_SYSTEM) {
          return navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_LIST, {
            replace: true,
          });
        } else if (info.role.id === ROLE_ENUM.CONTENT_MANAGER) {
          return navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST, {
            replace: true,
          });
        } else if (!isLoginPage()) {
          Cookies.remove(STORAGE_KEYS.ACCESS_TOKEN);
          return navigate(ADMIN_ROUTES_CONSTANT.LOGIN, {
            replace: true,
          });
        }
      }
    } catch {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", () => {
      clearToast();
    });

    setLoading(true);
    if (token && !isPageWithoutAuth()) {
      checkIsLogined();
    } else {
      setLoading(false);
      if (isBasePath) {
        return navigate(ADMIN_ROUTES_CONSTANT.LOGIN, {
          replace: true,
        });
      }
    }

    return () => {
      document.removeEventListener("click", () => {});
    };
  }, []);

  if (isLoading)
    return (
      <div className="w-[100vw] h-[100vh] ">
        <div
          className="top-[50%] left-[50%] absolute w-[48px] h-[48px] z-99999  transform -translate-x-1/2 -translate-y-1/2"
          id="loading__spinner"
        >
          <div className="loader z-99999"></div>
          <p className="text-center body1 w-[100px] -ml-[25px]">
            読み込み中...
          </p>
        </div>
      </div>
    );

  return (
    <main>
      <ToastForAdmin />
      <Outlet />
    </main>
  );
}
