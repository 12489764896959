import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import AnimatedRoute from "@/router/transition.route";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";
import Button from "@/components/atoms/Button/button";
import PencilIcon from "@/assets/icon/pencil.svg";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRoute } from "@/shared/get/route";
import PDFViewer from "@/components/molecules/PDFview/pdf-viewer";
import ContentSkeleton from "@/components/organisms/ComplianceTrainingManagement/content.skeleton";
import { useShowComplianceQuery } from "@/api/compliance";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import { API_PATH_CONSTANT } from "@/api/constant";
import DOMPurify from "dompurify";

function DetailComplianceTrainingPage(): JSX.Element {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);
  const navigate = useNavigate();
  const params = useParams();
  const id = parseInt(params.id as string);
  const { data: complianceInfo, isFetching } = useShowComplianceQuery(id, {
    enabled: !!id,
  });

  const breadcrumbs: BeardcrumbOption[] = useMemo(() => {
    return [
      {
        label: "コンプライアンス研修",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.COMPLIANCE_TRAINING,
      },
      {
        label: complianceInfo?.title || "",
      },
    ];
  }, [complianceInfo]);

  const url = useMemo(() => {
    if (!complianceInfo?.attachment) return "";

    return (
      import.meta.env.VITE_API_URL +
      API_PATH_CONSTANT.GET_FILE +
      "?object_name=" +
      complianceInfo?.attachment?.key
    );
  }, [complianceInfo]);

  const htmlContent = useMemo(() => {
    const sanitizedHtml = DOMPurify.sanitize(complianceInfo?.content ?? "", {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: [
        "allow",
        "allowfullscreen",
        "frameborder",
        "scrolling",
        "src",
        "title",
        "width",
        "height",
        "target",
      ],
      ADD_URI_SAFE_ATTR: ["src"],
    });
    return sanitizedHtml;
  }, [complianceInfo]);

  const goToEditPage = useCallback(() => {
    navigate(
      getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.COMPLIANCE_TRAINING_EDIT, {
        id: id,
      }),
    );
  }, []);

  return (
    <div className="max-w-[100%] m-auto my-6">
      <Breadcrumb options={breadcrumbs} />
      <div className="flex justify-between items-start gap-x-2">
        <h4 className="mt-3 font-black">
          <CustomTooltip text={complianceInfo?.title} />
        </h4>
        <Button
          startSlot={<PencilIcon className="white-icon" />}
          className="min-w-[140px]"
          onClick={goToEditPage}
        >
          編集する
        </Button>
      </div>
      <div className="my-6 min-h-[640px]">
        {isFetching ? (
          <ContentSkeleton />
        ) : (
          <>
            {url && (
              <div className="mx-[52px] mb-5">
                <PDFViewer file={url} />
              </div>
            )}
            {htmlContent && (
              <div
                className={
                  "shadow-card bg-white rounded-[20px] p-5 w-full ck-content"
                }
                dangerouslySetInnerHTML={{ __html: htmlContent ?? "" }}
              ></div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
const AnimatedDetailComplianceTrainingPage = AnimatedRoute(
  DetailComplianceTrainingPage,
);
export default AnimatedDetailComplianceTrainingPage;
