import { regexValidate } from "@/configs/regex";
import { LABEL, MESSAGES } from "./message";
import { template, toArray } from "lodash";
import * as yup from "yup";
import dayjs from "dayjs";
import IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(IsSameOrAfter);

export function getValue(obj: Record<string, any>, keyString?: string) {
  if (!keyString) return "";
  const keys = keyString.split(".");
  let value = obj;
  for (let i = 0; i < keys.length; i++) {
    value = value?.[keys[i]];
  }
  return value;
}

export function getFieldName(key?: string) {
  if (!key) return "";
  const keys = key.split(".");
  let label = LABEL as Record<string, any>;
  for (let i = 0; i < keys.length; i++) {
    label = label[keys[i]];
  }

  return label;
}

export function getErrorMessage(msgCode: string, attr: any) {
  const compiled = template(msgCode);
  return compiled({ ...attr });
}

export const getMessageAddMethod = {
  password: ({ label }: { label: string }) => {
    const field = getValue(LABEL, label);
    const compiled = template(MESSAGES.MSG_011);
    return compiled({ field });
  },
  validEmail: ({ label }: { label: string }) => {
    const field = getValue(LABEL, label);
    const compiled = template(MESSAGES.MSG_007);
    return compiled({ field });
  },
  noEmoji: ({ label }: { label: string }) => {
    const field = getValue(LABEL, label);
    const compiled = template(MESSAGES.MSG_015);
    return compiled({ field });
  },
  sameAs: ({ label }: { label: string }) => {
    const field = getValue(LABEL, label);
    const compiled = template(MESSAGES.MSG_012);
    return compiled({ field });
  },
  unique: ({ label }: { label: string }) => {
    const field = getValue(LABEL, label);
    const compiled = template(MESSAGES.MSG_032);
    return compiled({ field });
  },

  // onlyHiragana: ({ label }: any) => {
  //   const field = getValue(LABEL, label);
  //   const compiled = template(MESSAGES.MSG_032);
  //   return compiled({ field });
  // },
  // maxPresent: ({ label }: { label: string }) => {
  //   const field = getValue(LABEL, label);
  //   const compiled = template(MESSAGES.MSG_030);
  //   return compiled({ field });
  // },
};

const defaultMessage = {
  mixed: {
    required: ({ label }: { label: string }) => {
      const field = getValue(LABEL, label);
      const compiled = template(MESSAGES.MSG_001);
      return compiled({ field });
    },
    notType: ({ type }: { type: string }) => {
      switch (type) {
        case "number":
          return MESSAGES.MSG_006;
        case "string":
          return "Not a string error or any other custom error message";
        default:
          return "Wrong type error or any other custom error message";
      }
    },
  },
  string: {
    min: ({ label, min }: { label: string; min: number }) => {
      const field = getValue(LABEL, label);
      const compiled = template(MESSAGES.MSG_005);
      return compiled({ field, min });
    },
    max: ({
      label,
      max,
      errorMsg,
    }: {
      label: string;
      max: number;
      errorMsg?: string;
    }) => {
      const field = getValue(LABEL, label);
      const compiled = template(errorMsg || MESSAGES.MSG_002);
      return compiled({ field, max });
    },
    email: ({ label }: { label: string }) => {
      const field = getValue(LABEL, label);
      const compiled = template(MESSAGES.MSG_007);
      return compiled({ field });
    },
  },
  number: {
    integer: () => {
      return MESSAGES.MSG_006;
    },
  },
  array: {
    min: ({ label, min }: { label: string; min: number }) => {
      const field = getValue(LABEL, label);
      const compiled = template(MESSAGES.MSG_005);
      return compiled({ field, min });
    },
    max: ({ label, max }: { label: string; max: number }) => {
      const field = getValue(LABEL, label);
      const compiled = template(MESSAGES.MSG_002);
      return compiled({ field, max });
    },
  },
};
yup.setLocale(defaultMessage);

yup.addMethod<yup.StringSchema>(
  yup.string,
  "password",
  function (this, errorMsg?: string) {
    return this.test(
      "password",
      errorMsg || getMessageAddMethod.password,
      function (input) {
        if (!input) return true;
        const regex1 = new RegExp(regexValidate.EMOJI);
        if (regex1.test(input)) {
          return false; // Contains emoji
        }
        const regex2 = new RegExp(regexValidate.VALID_PASSWORD);
        return regex2.test(input);
      },
    );
  },
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  "noEmoji",
  function (this, errorMsg?: string) {
    return this.test(
      "noEmoji",
      errorMsg || getMessageAddMethod.noEmoji,
      function (input) {
        if (!input) return true;

        const regexEmoji = new RegExp(regexValidate.EMOJI);
        if (regexEmoji.test(input)) {
          return false; // Contains emoji
        }

        return true;
      },
    );
  },
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  "noEmoji",
  function (this, errorMsg?: string) {
    return this.test(
      "noEmoji",
      errorMsg || getMessageAddMethod.noEmoji,
      function (input) {
        if (!input) return true;
        const regexOnlyWord = new RegExp(regexValidate.ALPHA_NUMERIC);
        if (regexOnlyWord.test(input)) {
          return true;
        }

        return false;
      },
    );
  },
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  "email",
  function (this, errorMsg?: string) {
    return this.test(
      "email",
      errorMsg || getMessageAddMethod.validEmail,
      function (input) {
        if (!input) return true;
        const regex = new RegExp(regexValidate.VALID_EMAIL);
        return regex.test(input);
      },
    );
  },
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  "noEmoji",
  function (this, errorMsg?: string) {
    return this.test(
      "notEmoji",
      errorMsg || getMessageAddMethod.noEmoji,
      function (input) {
        if (!input) return true;
        const regex = new RegExp(regexValidate.EMOJI);
        if (regex.test(input)) {
          return false; // Contains emoji
        }
        return true;
      },
    );
  },
);

// yup.addMethod<yup.StringSchema>(
//   yup.string,
//   'isValidPhoneNumber',
//   function (this, errorMsg?: string) {
//     return this.test(
//       'isValidPhoneNumber',
//       errorMsg || getMessageAddMethod.isValidPhoneNumber,
//       function (input) {
//         if (!input) return true
//         const regex = new RegExp(regexValidate.VALID_PHONE_NUMBER)
//         return !!input.match(regex)
//       }
//     )
//   }
// )

yup.addMethod<yup.StringSchema>(
  yup.string,
  "sameAs",
  function (this: yup.StringSchema, field: string, errorMsg?: string) {
    return this.test(
      "sameAs",
      errorMsg || getMessageAddMethod.sameAs,
      function (value) {
        return value === this.parent[field];
      },
    );
  },
);
// yup.addMethod<yup.StringSchema>(yup.string, 'isValidDate', function (this, errorMsg?: string) {
//   return this.test('isValidDate', errorMsg || getMessageAddMethod.isValidDate, function (input) {
//     if (!input) return true
//     const dateSplit = input.split('-')
//     if (dateSplit.length != 3) return false
//     const year = dateSplit[0]
//     const month = dateSplit[1]
//     const day = dateSplit[2]
//     if (!year && !month && !day) return true
//     if (!year || !month || !day) return false

//     const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))

//     return (
//       date.getFullYear() === parseInt(year) &&
//       date.getMonth() === parseInt(month) - 1 &&
//       date.getDate() === parseInt(day)
//     )
//   })
// })

// yup.addMethod<yup.StringSchema>(yup.string, 'onlyHiragana', function (this, errorMsg?: string) {
//   return this.test('onlyHiragana', errorMsg || getMessageAddMethod.onlyHiragana, function (input) {
//     if (!input) return true
//     const regex = new RegExp(regexValidate.VALID_HIRAGANA)
//     return !!input.match(regex)
//   })
// })

yup.addMethod<yup.StringSchema>(
  yup.string,
  "max",
  function (this, maxLength?: number, errorMsg?: string) {
    return this.test(
      "max",
      () => {
        return defaultMessage.string.max({
          label: this.spec.label as string,
          max: maxLength as number,
          errorMsg: errorMsg,
        });
      },
      function (value) {
        if (
          typeof maxLength === "number" &&
          toArray(value).length > maxLength
        ) {
          return false;
        }
        return true;
      },
    );
  },
);

// yup.addMethod<yup.StringSchema>(
//   yup.string,
//   "maxPresent",
//   function (this, errorMsg?: string) {
//     return this.test(
//       "maxPresent",
//       errorMsg || getMessageAddMethod.maxPresent,
//       function (input?: string) {
//         if (!input) return true;
//         const inputDate = dayjs(input);
//         if (!inputDate.isValid()) return true;

//         return dayjs().isSameOrAfter(dayjs(inputDate));
//       },
//     );
//   },
// );

yup.addMethod(
  yup.array,
  "transformFilterEmpty",
  function (this, defaultValue?: any[]) {
    return this.transform((val: any[]) => {
      if (!val || !Array.isArray(val)) return defaultValue || [];
      const filterArray = val.filter((item) => {
        return !!Object.values(item)?.filter((value) => !!value).length;
      });
      return filterArray.length ? filterArray : defaultValue || [];
    });
  },
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  "unique",
  function (
    this: yup.StringSchema,
    fieldList: string,
    fieldUnique: string,
    errorMsg?: string,
  ) {
    return this.test(
      "unique",
      errorMsg || getMessageAddMethod.unique,
      function (value) {
        const tags = this?.from ?? [];
        const isUnique =
          tags[1]?.value[fieldList]?.filter(
            (item: any) => item?.[fieldUnique] && item?.[fieldUnique] === value,
          )?.length < 2;
        return isUnique;
      },
    );
  },
);

export default yup;
