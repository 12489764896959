/**
 * Interface: QuizzFreeInputProps
 *
 * Represents the props for the QuizzFreeInput component.
 *
 * @property questionNumber - The number of the question.
 * @property answers - The list of answers.
 */
interface QuizzFreeInputProps {
  questionNumber: number;
  answers: any;
}

export default function QuizzFreeInput({
  questionNumber,
}: QuizzFreeInputProps) {
  return (
    <div>
      <textarea
        className="gap-1 flex w-full h-[200px] border-primary py-3 px-4 rounded-lg resize-none text-black-custom  focus:outline-none placeholder:text-disabled"
        name={`question.${questionNumber}.answer`}
        placeholder="Aa"
        readOnly
      />
    </div>
  );
}
