import AuthLayout from "@/pages/_layouts/auth";
import ArrowLeftIcon from "@/assets/icon/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import FormSetPassword from "@/components/organisms/FormSetPassword";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
export default function SetPasswordPage() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(ADMIN_ROUTES_CONSTANT.LOGIN);
  };
  return (
    <AuthLayout>
      <div className="py-[80px] flex w-full items-center flex-col px-[20px]">
        <div className="flex mb-[16px] justify-center w-full relative">
          <div className="absolute left-0 cursor-pointer" onClick={goBack}>
            <ArrowLeftIcon />
          </div>
          <h4 className="font-[900] text-center">パスワード再設定</h4>
        </div>

        <div className="mt-[48px] w-full flex justify-center">
          <FormSetPassword isAdminPage={true} />
        </div>
      </div>
    </AuthLayout>
  );
}
