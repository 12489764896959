import { useMemo, useRef, useState } from "react";
import Switch from "@/components/atoms/Switch/switch";

import {
  SuggestionSearch,
  SuggestionSearchRef,
} from "@/components/molecules/SuggestionSearch/suggestion-search";
import ELearningList from "@/components/organisms/ELearningList/e-learning-list-2";
import ELearningTab from "@/components/organisms/ElearningTab/e-learning-tab";
import AnimatedRoute from "@/router/transition.route";
import { useFrontListCategoryQuery } from "@/api/category";
import { TSearchCourse } from "@/api/type";
import { ELEARNING_JOINED_ENUM } from "@/configs/enum";
import { isString } from "lodash";
import FrontLayout from "@/pages/_layouts/front-main";

const SHOW_ALL_CATEGORY = { id: 0, name: "全て" };

function FrontELearningListPage() {
  // #region Data
  const searchRef = useRef<SuggestionSearchRef>(null);

  const { data: listCategoryRes } = useFrontListCategoryQuery({
    is_parent: true,
  });

  const tabData = useMemo(
    () => Array.of(SHOW_ALL_CATEGORY, ...(listCategoryRes?.categories ?? [])),
    [listCategoryRes],
  );

  const [isStudied, setStudied] = useState(false);

  const [seleced, setSelected] = useState(0);

  const [keyword, setKeyword] = useState("");
  // #endregion

  // #region Events
  const onSearch = (data: TSearchCourse | string) => {
    setKeyword(isString(data) ? data : data?.text_search ?? "");
  };

  const onSelected = (id: number) => {
    setSelected(id);
    setKeyword("");
    searchRef.current?.reset();
  };
  // #endregion

  return (
    <div
      id="elearning-page"
      className="elearning-page desktop:py-12 flex max-w-[960px] w-full gap-10 flex-col desktop:mx-auto mobile:py-6 mobile:px-4 desktop:px-0"
    >
      <div className="flex flex-col gap-y-6">
        <h4>Eラーニング</h4>
        <SuggestionSearch
          model="front-elearning"
          oldValue={keyword}
          labelKey={"text_search"}
          width="320px"
          onSearch={onSearch}
          ref={searchRef}
          isAdminPage={false}
        />
      </div>
      <div>
        <div className="flex gap-x-4 w-full mb-4">
          <div className="overflow-hidden">
            <ELearningTab
              data={tabData}
              selected={seleced}
              onSelected={onSelected}
            />
          </div>
          <div className="flex gap-x-2 pb-[16px] items-center w-[104px]">
            <span className="whitespace-nowrap text-sm">受講済み</span>
            <Switch
              id="isChecked"
              isChecked={isStudied}
              onChange={() => setStudied(!isStudied)}
            />
          </div>
        </div>
        <div className="elearning-list flex flex-col gap-y-4">
          <ELearningList
            search={keyword}
            isStudied={isStudied ? ELEARNING_JOINED_ENUM.DONE : ""}
            category={seleced}
            show={10}
          />
        </div>
      </div>
    </div>
  );
}

const AnimatedELearningListPage = AnimatedRoute(FrontELearningListPage, false);

export default function ELearningListPage() {
  return (
    <FrontLayout>
      <AnimatedELearningListPage />
    </FrontLayout>
  );
}
