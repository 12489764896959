import { MESSAGES } from "@/shared/validation/message";
import clsx from "clsx";
import { ReactNode, useMemo } from "react";
import Select, {
  ActionMeta,
  GroupBase,
  InputActionMeta,
  SelectComponentsConfig,
  StylesConfig,
} from "react-select";

export interface Option {
  value: string | number | null;
  label: ReactNode | string;
  custom_field?: any;
}

export interface SelectProps {
  defaultValue?: Option[] | Option | null;
  value?: Option[] | Option | null;
  options: Option[] | [];
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  className?: string;
  onChange?:
    | ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void)
    | undefined;
  onBlur?: () => void;
  onFocus?: () => void;
  inputValue?: string;
  isSearchable?: boolean;
  isHideIconDrop?: boolean;
  errorMessage?: string;
  classNameError?: string;
  startSlot?: ReactNode | string | null;
  components?:
    | Partial<SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>>
    | undefined;
  onInputChange?:
    | ((newValue: string, actionMeta: InputActionMeta) => void)
    | undefined;
  onMenuScrollToBottom?: ((event: WheelEvent | TouchEvent) => void) | undefined;
  unsetMenuPortalTarget?: boolean;
  filterOption?: (option: { data: Option }, inputValue: string) => boolean;
}

export const SelectBox = ({
  defaultValue,
  options = [],
  isDisabled = false,
  isLoading = false,
  isClearable = false,
  isMulti = false,
  placeholder = "",
  className,
  onChange,
  onBlur,
  onFocus,
  inputValue,
  isSearchable = false,
  isHideIconDrop = false,
  errorMessage = "",
  classNameError,
  startSlot,
  value,
  components,
  onInputChange,
  onMenuScrollToBottom,
  unsetMenuPortalTarget,
  filterOption,
}: SelectProps) => {
  const isError = useMemo(
    () => errorMessage && errorMessage.length > 0,
    [errorMessage],
  );
  /**
   * Styles configuration for the Select component.
   *
   * @typedef {object} StylesConfig
   * @property {function} control - Function to style the control element.
   * @property {function} option - Function to style the option element.
   * @property {function} input - Function to style the input element.
   * @property {function} multiValue - Function to style the multiValue element.
   * @property {function} multiValueRemove - Function to style the multiValueRemove element.
   * @property {function} placeholder - Function to style the placeholder element.
   * @property {function} indicatorsContainer - Function to style the indicatorsContainer element.
   * @property {function} indicatorSeparator - Function to style the indicatorSeparator element.
   * @property {function} menu - Function to style the menu element.
   * @property {function} menuList - Function to style the menuList element.
   */

  /**
   * Styles configuration for the Select component.
   *
   * @type {StylesConfig}
   */
  const colorStyles: StylesConfig = {
    control: (styles, { isFocused }) => {
      const paddingIconSearch = startSlot ? "12px 12px 12px 44px" : "12px";
      return {
        ...styles,
        borderColor: isFocused
          ? "#73BE1E"
          : isError
          ? "var(--error-main-color)"
          : "#E6E8EA",
        boxShadow: "unset",
        color: "#212B36",
        background: "white",
        padding: paddingIconSearch,
        borderRadius: "12px",
        gap: "8px",
        minHeight: "48px",
        ":hover": {
          ...styles[":hover"],
          borderColor: "#73BE1E",
          boxShadow: "unset",
          background: "white",
        },
        ":active": {
          ...styles[":active"],
          borderColor: "#73BE1E",
          boxShadow: "unset",
          background: "white",
        },
        "> div:first-of-type": {
          gap: "4px",
          padding: 0,
        },
      };
    },
    option: (styles, { isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? "#F1F9E8" : "white",
      borderRadius: "8px",
      color: "#212B36",
      padding: "8px 16px",
      ":focus-visible": {
        backgroundColor: "#F1F9E8",
      },
      ":hover": {
        ...styles[":hover"],
        backgroundColor: "#F1F9E8",
      },
    }),
    input: (styles) => ({
      ...styles,
      color: "#212B36",
      margin: 0,
      padding: 0,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#212B36",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        gap: "8px",
        backgroundColor: "#F1F9E8",
        borderRadius: "14px",
        margin: 0,
        color: "#212B36",
        padding: "2px 8px 2px 12px",
        "> div:first-of-type": {
          padding: 0,
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
        },
      };
    },
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#637381",
      padding: 0,
      svg: {
        width: "16px",
        height: "16px",
      },
      ":hover": {
        color: "#637381",
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      padding: 0,
      margin: 0,
      color: "#919EAB",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "24px",
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      svg: {
        color: "#637381",
      },
      "> div": {
        padding: 0,
      },
      "> div:last-child": {
        visibility: isHideIconDrop ? "hidden" : "visible",
        padding: 0,
      },
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    menu: (styles) => ({
      ...styles,
      marginTop: 1,
      backgroundColor: "white",
      borderRadius: "12px",
      boxShadow: "0px 0px 20px 0px #0000001A",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "24px",
      marginBottom: 0,
    }),
    menuList: (styles) => ({
      ...styles,
      padding: "8px",
    }),
  };

  return (
    <>
      <div className="">
        <div className={clsx(isError && "is-invalid", "relative")}>
          {startSlot && (
            <div className="absolute z-[1] inset-y-3 start-4">{startSlot}</div>
          )}
          <Select
            defaultValue={defaultValue}
            value={value}
            options={options}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isMulti={isMulti}
            styles={colorStyles}
            inputValue={inputValue}
            placeholder={placeholder}
            className={className}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            isSearchable={isSearchable}
            noOptionsMessage={() => MESSAGES.MSG_010}
            components={components}
            onInputChange={onInputChange}
            onMenuScrollToBottom={onMenuScrollToBottom}
            {...(filterOption && { filterOption: filterOption as any })}
            {...(!unsetMenuPortalTarget && { menuPortalTarget: document.body })}
          />
        </div>
        {isError && (
          <div
            className={`${classNameError} mt-1 w-full text-error--main input-error__message`}
          >
            {errorMessage}
          </div>
        )}
      </div>
    </>
  );
};
