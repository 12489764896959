import Button from "@/components/atoms/Button/button";
import PlusIcon from "@/assets/icon/plus.svg";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { useCallback } from "react";
import AnimatedRoute from "@/router/transition.route";
import ELearningListForAdmin from "@/components/organisms/ELearningManagement/e-learning-list-management";

/**
 * Renders the ELearning List Page component.
 *
 * This component displays a page for managing users. It includes a heading, a button for creating a new user, and a ELearningListForAdmin component.
 *
 * @returns {JSX.Element} The rendered ELearning List Page component.
 */
function ELearningListPage(): JSX.Element {
  const navigate = useNavigate();

  const goToCreateELearningPage = useCallback(() => {
    navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_CREATE);
  }, []);

  return (
    <div className="max-w-[100%] m-auto my-6">
      <div className="flex justify-between items-start">
        <h4>Eラーニング管理</h4>
        <Button
          startSlot={<PlusIcon />}
          className="w-[160px]"
          onClick={goToCreateELearningPage}
        >
          新規作成
        </Button>
      </div>
      <ELearningListForAdmin />
    </div>
  );
}
const AnimatedELearningListPage = AnimatedRoute(ELearningListPage);
export default AnimatedELearningListPage;
