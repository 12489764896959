import { MESSAGES } from "@/shared/validation/message";
import clsx from "clsx";
import { min } from "lodash";
import { ReactNode, useMemo } from "react";
import Select, {
  ActionMeta,
  InputActionMeta,
  StylesConfig,
} from "react-select";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import List from "react-virtualized/dist/commonjs/List";

export interface Option {
  value: string | number | null;
  label: ReactNode | string;
}

export interface SelectProps {
  defaultValue?: Option[] | Option | null;
  value?: Option[] | Option | null;
  options: Option[] | [];
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  className?: string;
  onChange?:
    | ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void)
    | undefined;
  onBlur?: () => void;
  onFocus?: () => void;
  inputValue?: string;
  isSearchable?: boolean;
  isHideIconDrop?: boolean;
  errorMessage?: string;
  classNameError?: string;
  startSlot?: ReactNode | string | null;
  onInputChange?:
    | ((newValue: string, actionMeta: InputActionMeta) => void)
    | undefined;
  onMenuScrollToBottom?: ((event: WheelEvent | TouchEvent) => void) | undefined;
}

export const VirtualSelectBox = ({
  defaultValue,
  options = [],
  isDisabled = false,
  isLoading = false,
  isClearable = false,
  isMulti = false,
  placeholder = "",
  className,
  onChange,
  onBlur,
  onFocus,
  inputValue,
  isSearchable = false,
  isHideIconDrop = false,
  errorMessage = "",
  classNameError,
  startSlot,
  value,
  onInputChange,
  onMenuScrollToBottom,
}: SelectProps) => {
  const isError = useMemo(
    () => errorMessage && errorMessage.length > 0,
    [errorMessage],
  );
  const colorStyles: StylesConfig = {
    control: (styles, { isFocused }) => {
      const paddingIconSearch = startSlot ? "12px 12px 12px 44px" : "12px";
      return {
        ...styles,
        borderColor: isFocused
          ? "#73BE1E"
          : isError
          ? "var(--error-main-color)"
          : "#E6E8EA",
        boxShadow: "unset",
        color: "#212B36",
        background: "white",
        padding: paddingIconSearch,
        borderRadius: "12px",
        gap: "8px",
        minHeight: "48px",
        ":hover": {
          ...styles[":hover"],
          borderColor: "#73BE1E",
          boxShadow: "unset",
          background: "white",
        },
        ":active": {
          ...styles[":active"],
          borderColor: "#73BE1E",
          boxShadow: "unset",
          background: "white",
        },
        "> div:first-of-type": {
          gap: "4px",
          padding: 0,
        },
      };
    },
    option: (styles, { isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? "#F1F9E8" : "white",
      borderRadius: "8px",
      color: "#212B36",
      padding: "8px 16px",
      ":focus-visible": {
        backgroundColor: "#F1F9E8",
      },
      ":hover": {
        ...styles[":hover"],
        backgroundColor: "#F1F9E8",
      },
    }),
    input: (styles) => ({
      ...styles,
      color: "#212B36",
      margin: 0,
      padding: 0,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#212B36",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        gap: "8px",
        backgroundColor: "#F1F9E8",
        borderRadius: "14px",
        margin: 0,
        color: "#212B36",
        padding: "2px 8px 2px 12px",
        "> div:first-of-type": {
          padding: 0,
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
        },
      };
    },
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#637381",
      padding: 0,
      svg: {
        width: "16px",
        height: "16px",
      },
      ":hover": {
        color: "#637381",
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      padding: 0,
      margin: 0,
      color: "#919EAB",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "24px",
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      svg: {
        color: "#637381",
      },
      "> div": {
        padding: 0,
      },
      "> div:last-child": {
        visibility: isHideIconDrop ? "hidden" : "visible",
        padding: 0,
      },
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    menu: (styles) => ({
      ...styles,
      marginTop: 1,
      backgroundColor: "white",
      borderRadius: "12px",
      boxShadow: "0px 0px 20px 0px #0000001A",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "24px",
      marginBottom: 0,
    }),
    menuList: (styles) => ({
      ...styles,
      padding: "8px",
    }),
  };

  const MenuList = (props: any) => {
    const rows = props.children;
    const rowRenderer = ({ key, index, style, width }: any) => (
      <div
        key={key}
        style={{
          ...style,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          width,
        }}
        className="virtual-select"
      >
        {rows[index]}
      </div>
    );
    const height = min([props.options.length * 40 + 16, 300]);

    return (
      <AutoSizer disableHeight>
        {({ width }) => (
          <div
            style={{
              width,
            }}
          >
            <List
              style={{
                width: "100%",
                padding: `8px ${height && height < 300 ? "8px" : "0"} 8px 8px`,
                zIndex: 99,
              }}
              width={width}
              height={height ?? 300}
              rowHeight={40}
              rowCount={rows.length}
              rowRenderer={({ key, index, style }) =>
                rowRenderer({ key, index, style, width })
              }
            />
          </div>
        )}
      </AutoSizer>
    );
  };

  return (
    <>
      <div className="">
        <div className={clsx(isError && "is-invalid", "relative")}>
          {startSlot && (
            <div className="absolute z-[1] inset-y-3 start-4">{startSlot}</div>
          )}
          <Select
            defaultValue={defaultValue}
            value={value}
            options={options}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isMulti={isMulti}
            styles={colorStyles}
            inputValue={inputValue}
            placeholder={placeholder}
            className={className}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            isSearchable={isSearchable}
            noOptionsMessage={() => MESSAGES.MSG_010}
            components={{
              MenuList,
            }}
            onInputChange={onInputChange}
            onMenuScrollToBottom={onMenuScrollToBottom}
            menuPortalTarget={document.body}
          />
        </div>
        {isError && (
          <div
            className={`${classNameError} mt-1 w-full text-error--main input-error__message`}
          >
            {errorMessage}
          </div>
        )}
      </div>
    </>
  );
};
