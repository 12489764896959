const VERSION = "v1";

export const API_PATH_CONSTANT = {
  TOKEN_UPLOAD: `/${VERSION}/object_storages/token`,
  UPLOAD: `/${VERSION}/object_storages/upload_object`,
  UPLOAD_YOUTUBE: `/${VERSION}/youtubes`,
  DELETE_YOUTUBE: `/${VERSION}/youtubes/:id`,
  TRACK_JOB_ID: `/${VERSION}/tracking_jobs/:id`,
  GET_FILE: `/${VERSION}/object_storages/get_object`,
  LOGIN: `/${VERSION}/users/login`,
  PROFILE: `/${VERSION}/user_profiles`,
  LOGOUT: `/${VERSION}/users/logout`,
  LIST_ROLE: `/${VERSION}/admin/roles`,
  LIST_USER: `/${VERSION}/admin/users`,
  CREATE_USER: `/${VERSION}/admin/users`,
  UPDATE_USER: `/${VERSION}/admin/users/:id`,
  SHOW_USER: `/${VERSION}/admin/users/:id`,
  DELETE_USER: `/${VERSION}/admin/users/:id`,
  LIST_OFFICE: `/${VERSION}/admin/offices`,
  CREATE_OFFICE: `/${VERSION}/admin/offices`,
  UPDATE_OFFICE: `/${VERSION}/admin/offices/:id`,
  DELETE_OFFICE: `/${VERSION}/admin/offices/:id`,
  LIST_DEPARTMENT: `/${VERSION}/admin/departments`,
  CREATE_DEPARTMENT: `/${VERSION}/admin/departments`,
  UPDATE_DEPARTMENT: `/${VERSION}/admin/departments/:id`,
  DELETE_DEPARTMENT: `/${VERSION}/admin/departments/:id`,
  RESET_PASSWORDS: `/${VERSION}/reset_passwords`,
  VERIFY_RESET_PASSWORDS: `/${VERSION}/verify_reset_passwords`,
  VERIFY_RESET_PASSWORDS_TOKEN: `/${VERSION}/verify_reset_passwords/verify_token`,
  UPDATE_FIRST_PASSWORD: `/${VERSION}/user_passwords/user_first_passwords`,
  UPDATE_PASSWORD: `/${VERSION}/user_passwords`,
  UPDATE_FIRST_INFO: `/${VERSION}/user_profiles/first_update`,
  UPDATE_PASSWORD_INIT: `/${VERSION}/user_passwords/mail_first_passwords`,
  LIST_TAG: `/${VERSION}/admin/tags`,
  UPDATE_TAG: `/${VERSION}/admin/tags/manage`,
  LIST_CATEGORY: `/${VERSION}/admin/categories`,
  CREATE_CATEGORY: `/${VERSION}/admin/categories`,
  UPDATE_CATEGORY: `/${VERSION}/admin/categories/:id`,
  UPDATE_POSITION_CATEGORY: `/${VERSION}/admin/categories/update_positions`,
  DELETE_CATEGORY: `/${VERSION}/admin/categories/:id`,
  CREATE_QUIZZ: `/${VERSION}/admin/quizzes`,
  UPDATE_QUIZZ: `/${VERSION}/admin/quizzes/:id`,
  LIST_QUIZZ: `/${VERSION}/admin/quizzes`,
  SHOW_QUIZZ: `/${VERSION}/admin/quizzes/:id`,
  DELETE_QUIZZ: `/${VERSION}/admin/quizzes/:id`,
  LIST_JOINED_USERS_QUIZZ: `/${VERSION}/admin/quizzes/:id/joined_users`,
  LIST_FOLDER: `/${VERSION}/folders`,
  CREATE_FOLDER: `/${VERSION}/folders`,
  UPDATE_FOLDER: `/${VERSION}/folders/:id`,
  DELETE_FOLDER: `/${VERSION}/folders/:id`,
  LIST_DOCUMENT: `/${VERSION}/documents`,
  CREATE_DOCUMENT: `/${VERSION}/folders/:folder_id/documents`,
  SHOW_DOCUMENT: `/${VERSION}/folders/:folder_id/documents/:id`,
  UPDATE_DOCUMENT: `/${VERSION}/folders/:folder_id/documents/:id`,
  DELETE_DOCUMENT: `/${VERSION}/folders/:folder_id/documents/:id`,
  LIST_VIDEO: `/${VERSION}/videos`,
  CREATE_VIDEO: `/${VERSION}/folders/:folder_id/videos`,
  CHECK_CREATE_VIDEO: `/${VERSION}/folders/:folder_id/videos/create_check_valid`,
  SHOW_VIDEO: `/${VERSION}/folders/:folder_id/videos/:id`,
  CHECK_UPDATE_VIDEO: `/${VERSION}/folders/:folder_id/videos/:id/update_check_valid`,
  UPDATE_VIDEO: `/${VERSION}/folders/:folder_id/videos/:id`,
  DELETE_VIDEO: `/${VERSION}/folders/:folder_id/videos/:id`,
  LIST_COMPLIANCE: `/${VERSION}/admin/compliances`,
  CREATE_COMPLIANCE: `/${VERSION}/admin/compliances`,
  SHOW_COMPLIANCE: `/${VERSION}/admin/compliances/:id`,
  UPDATE_COMPLIANCE: `/${VERSION}/admin/compliances/:id`,
  DELETE_COMPLIANCE: `/${VERSION}/admin/compliances/:id`,
  // Elearning
  SHOW_COURSE: `/${VERSION}/admin/courses/:id`,

  LIST_COMMENT: `/${VERSION}/:commentable_type/:commentable_id/comments`,
  CREATE_COMMENT: `/${VERSION}/:commentable_type/:commentable_id/comments`,

  LIST_FAVORITE: `/${VERSION}/:favoriteable_type/:favoriteable_id/favorites`,
  CREATE_FAVORITE: `/${VERSION}/:favoriteable_type/:favoriteable_id/favorites`,
  DELETE_FAVORITE: `/${VERSION}/:favoriteable_type/:favoriteable_id/favorites/:id`,
  LIST_NOTIFICATION: `/${VERSION}/user_notifications`,
  READ_NOTIFICATION: `/${VERSION}/user_notifications/read`,
  CHECK_UNREAD_NOTIFICATION: `/${VERSION}/user_notifications/unread`,
  LIST_EMOJI: `/${VERSION}/emojis`,
  CREATE_EMOJI: `/${VERSION}/emojis`,

  LIST_ELEARNING: `/${VERSION}/admin/courses`,
  CREATE_ELEARNING: `/${VERSION}/admin/courses`,
  CHECK_CREATE_ELEARNING: `/${VERSION}/admin/courses/create_check_valid`,
  SHOW_ELEARNING: `/${VERSION}/admin/courses/:id`,
  UPDATE_ELEARNING: `/${VERSION}/admin/courses/:id`,
  CHECK_UPDATE_ELEARNING: `/${VERSION}/admin/courses/:id/update_check_valid`,
  DELETE_ELEARNING: `/${VERSION}/admin/courses/:id`,
  LIST_USER_JOINED_ELEARNING: `/${VERSION}/admin/courses/:id/user_courses`,
  LIST_USER_NOT_JOIN_ELEARNING: `/${VERSION}/admin/courses/:id/not_join_users`,
  FRONT_SHOW_COURSE: `/${VERSION}/courses/:id`,
  FRONT_LIST_CATEGORY: `/${VERSION}/categories`,
  FRONT_LIST_COURSE: `/${VERSION}/courses`,
  FRONT_SUGGESTION_COURSE: `/${VERSION}/course_suggestions`,
  FRONT_LIST_MY_COURSE: `/${VERSION}/my_courses`,
  FRONT_LIST_SUGGESTION_COURSE: `/${VERSION}/course_suggestions`,
  FRONT_SHOW_USER_COURSE: `/${VERSION}/courses/:course_id/user_courses/:id`,
  FRONT_CREATE_USER_COURSE: `/${VERSION}/courses/:course_id/user_courses`,
  FRONT_UPDATE_USER_COURSE: `/${VERSION}/courses/:course_id/user_courses/:user_course_id`,
  FRONT_CREATE_USER_COURSE_CONTENT: `/${VERSION}/user_courses/:user_course_id/user_course_contents`,
  FRONT_UPDATE_USER_COURSE_CONTENT: `/${VERSION}/user_courses/:user_course_id/user_course_contents/:user_course_content_id`,
};

export const DEFAULT_QUERY_OPTIONS = {
  staleTime: 1000,
  refetchOnWindowFocus: false,
  retry: false,
} as const;

export const INITIAL_QUERY_OPTIONS = {
  defaultOptions: {
    queries: {
      ...DEFAULT_QUERY_OPTIONS,
      staleTime: 1000,
    },
  },
} as const;
