import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import CreateDocumentForm from "@/components/organisms/ContentManagement/document";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { ROLE_ENUM } from "@/configs/enum";
import useRole from "@/hooks/role";
import AnimatedRoute from "@/router/transition.route";
import { useParams } from "react-router-dom";

const breadcrumbs: BeardcrumbOption[] = [
  {
    label: "ドキュメント一覧",
    route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_DOCUMENT_LIST,
  },
  {
    label: "新規作成",
  },
];

function CreateDocumentPage() {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);
  const params = useParams();

  return (
    <div className="max-w-[100%] m-auto my-6">
      <Breadcrumb options={breadcrumbs} />
      <h4 className="mt-3 font-black">新規作成</h4>
      <div className="mt-8">
        <CreateDocumentForm folder_id={Number(params!.folder_id)} />
      </div>
    </div>
  );
}

const AnimatedCreateDocumentPage = AnimatedRoute(CreateDocumentPage);
export default AnimatedCreateDocumentPage;
