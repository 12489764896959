import ButtonIcon from "@/components/atoms/ButtonIcon/button-icon";
import ThreeDotIcon from "@/assets/icon/three-dot.svg";
import { Menu, MenuButton, MenuItem, MenuList, Portal } from "@chakra-ui/react";
import { ReactNode } from "react";
import clsx from "clsx";
import { useGlobalStore } from "@/states/global.state";

export interface MenuItem {
  icon: ReactNode;
  text: ReactNode;
  onClick?: (data: any) => void;
}

interface ThreeDotActionTableProps {
  boudaryEl?: HTMLDivElement | null;
  menuItems: MenuItem[];
  iconBtn?: ReactNode;
  classes?: {
    button?: string;
  };
  data?: object;
}
export default function ThreeDotActionTable({
  menuItems,
  iconBtn = <ThreeDotIcon />,
  classes,
  data,
  boudaryEl,
}: ThreeDotActionTableProps) {
  const { loading } = useGlobalStore();
  return (
    <Menu isLazy boundary={boudaryEl ?? "clippingParents"}>
      <MenuButton
        as={ButtonIcon}
        icon={iconBtn}
        className={clsx("three-dot-icon", classes?.button ?? "")}
      ></MenuButton>
      <Portal>
        <MenuList className="chakra-menu__menu-list w-[168px]" minWidth="168px">
          {menuItems.map((item, index) => {
            return (
              <div key={index}>
                <MenuItem
                  padding={"4px"}
                  display={"flex"}
                  columnGap={"12px"}
                  justifyContent={"start"}
                  alignItems={"center"}
                  onClick={() => {
                    if (loading?.open) return;
                    item.onClick?.(data);
                  }}
                >
                  <>{item.icon}</>
                  <>{item.text}</>
                </MenuItem>
                {menuItems.length > index + 1 && (
                  <div className="bg-[#E6E8EA] w-full h-[1px] my-2"></div>
                )}
              </div>
            );
          })}
        </MenuList>
      </Portal>
    </Menu>
  );
}
