import Radio from "@/components/atoms/Radio/radio";
import { RadioProps } from "@chakra-ui/react";
import clsx from "clsx";
import { ReactNode, useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldValue,
  FieldValues,
  Path,
} from "react-hook-form";

export interface OptionRadio {
  value: string | number;
  label: string;
  description?: string | ReactNode;
}

interface ControlledRadioProps
  extends Omit<RadioProps, "defaultValue" | "value" | ""> {
  options: OptionRadio[];
  label?: string;
  note?: string;
  isRequired?: boolean;
  errorMessage?: string;
  direction?: "horizontal" | "vertical";
  classes?: {
    radio?: string;
    label?: string;
    radioWrapper?: string;
    spacing?: string;
  };
  isReadOnly?: boolean;
  disableCursorPointer?: boolean;
  onChangeCallback?: (
    v: string | number | undefined,
    hasChangeValue?: boolean,
  ) => void;
}

interface OwnProps<Type extends FieldValues>
  extends Omit<ControlledRadioProps, "value"> {
  control: Control<FieldValue<Type>>;
  formField: Path<FieldValue<Type>>;
}

export const ControlledRadio = <T extends FieldValues>({
  control,
  formField,
  className,
  label,
  note,
  isRequired,
  errorMessage,
  options,
  classes,
  direction = "horizontal",
  onChangeCallback,
  isReadOnly = false,
  disableCursorPointer,
}: OwnProps<T>) => {
  const [isFocusing] = useState(false);
  const isError = useMemo(
    () => errorMessage && errorMessage.length > 0,
    [errorMessage],
  );

  return (
    <Controller
      control={control}
      name={formField}
      render={({ field }) => {
        return (
          <div className={className}>
            {label?.length && (
              <div
                className={clsx(
                  "flex gap-2 mb-1 justify-between",
                  classes?.label,
                )}
              >
                <div className="flex flex-col justify-center text-xs max-md:max-w-full">
                  <div className="flex gap-1 max-md:flex-wrap">
                    <div
                      className={clsx(
                        isFocusing && !isError
                          ? "text-[var(--primary-main-color)]"
                          : "text-[var(--text-secondary)]",
                      )}
                    >
                      {label}
                    </div>
                    {isRequired && <div className="text-error--main">*</div>}
                  </div>
                </div>
                {note && (
                  <div className="text-[var(--text-secondary)] caption1">
                    {note}
                  </div>
                )}
              </div>
            )}
            <div
              className={clsx(
                "flex",
                direction === "vertical"
                  ? `flex-col gap-y-${classes?.spacing ?? 8}`
                  : `flex-row gap-x-${classes?.spacing ?? 8}`,
                classes?.radioWrapper,
              )}
            >
              {options.map((option) => (
                <Radio
                  key={option.value}
                  isError={Boolean(isError)}
                  label={option.label}
                  name={field.name}
                  value={option.value}
                  className={classes?.radio}
                  description={option.description}
                  checked={option.value === field.value}
                  isReadOnly={isReadOnly}
                  onChange={(v) => {
                    field.onChange(v);
                    onChangeCallback && onChangeCallback(v, field.value !== v);
                  }}
                  disableCursorPointer={disableCursorPointer}
                />
              ))}
            </div>
            {isError && (
              <div className="mt-1 w-full text-error--main input-error__message">
                {errorMessage}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
