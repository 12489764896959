import { SelectOption } from "./validation";

export function combineArrays(
  arrayA: Array<SelectOption>,
  arrayB: Array<SelectOption>,
): SelectOption[] {
  const newArrayA = arrayA.map((item) => {
    if (!arrayB.find((x) => x.id === item.id)) {
      return { ...item, _destroy: true };
    }
    return item;
  });

  const filteredArrayB = arrayB.filter((item) => item.value !== "all");

  const resultArray = [
    ...newArrayA,
    ...filteredArrayB.filter(
      (item) => !newArrayA.find((x) => x.id === item.id),
    ),
  ];

  const resultSet = [...new Set(resultArray)];

  return resultSet;
}
