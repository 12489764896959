import {
  MutateOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CreateCommentRequestBody,
  CreateCommentResponseBody,
  FilterCommentRequestBody,
  FilterCommentResponseBody,
  ListCommentQueryKey,
  createCommentApi,
  listCommentApi,
} from "./request";
import { COMMENT_TYPE_ENUM } from "@/configs/enum";

export const useCommentListQuery = (
  type: COMMENT_TYPE_ENUM,
  id: number,
  params: Partial<FilterCommentRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterCommentResponseBody,
      unknown, // TError,
      FilterCommentResponseBody,
      ListCommentQueryKey
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-comment", type, `${id}`, params],
    queryFn: listCommentApi,
    enabled: !!params,
    ...options,
  });
};

export const useCreateCommentMutation = (
  type: COMMENT_TYPE_ENUM,
  id: number,
  options: MutateOptions<
    CreateCommentResponseBody,
    unknown,
    CreateCommentRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: (body) => createCommentApi(type, id, body),
      onSuccess: () => {},
    },
    queryClient,
  );
};
