import { useGlobalStore } from "@/states/global.state";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo } from "react";

export default function ToastForAdmin() {
  const { notifications } = useGlobalStore();

  const isAdminPage = useMemo(() => {
    return location.pathname.startsWith("/admin");
  }, [location.pathname]);

  return (
    <div
      className={clsx(
        "fixed left-[50%] translate-x-[-50%] max-w-[761px] z-[9999]",
        isAdminPage && "w-full bottom-[40px]",
      )}
      {...(!isAdminPage && {
        style: { top: window.innerHeight - 80, width: "90vw" },
      })}
    >
      <AnimatePresence>
        {notifications
          .filter((notification) => notification.site === "admin")
          .map((notification) => (
            <motion.div
              key={notification.id}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              className="px-[10px] py-3 bg-[#212B36] text-white body2 w-full text-center rounded-[50px] mb-1 whitespace-pre-wrap break-all"
            >
              {notification?.title?.replace(/<br\s*\/?>/, "\n")}
            </motion.div>
          ))}
      </AnimatePresence>
    </div>
  );
}
