import {
  MutateOptions,
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CreateElearningRequestBody,
  CreateElearningResponseBody,
  CreateUserCourseContentRequestBody,
  CreateUserCourseContentResponseBody,
  CreateUserCourseResponseBody,
  DeleteELearningResponseBody,
  FilterELearningRequestBody,
  FilterELearningResponseBody,
  FilterListCourseRequestBody,
  FilterListCourseResponseBody,
  FilterListMyCourseRequestBody,
  FilterListMyCourseResponseBody,
  FilterListSuggestionCourseRequestBody,
  FilterListSuggestionCourseResponseBody,
  FrontFilterListCourseRequestBody,
  FrontFilterListCourseResponseBody,
  FrontFilterListSuggestionCourseRequestBody,
  ListUserJoinedCourseRequestBody,
  ListUserJoinedCourseResponseBody,
  ListUserNotJoinCourseRequestBody,
  ListUserNotJoinCourseResponseBody,
  ShowCourseResponseBody,
  ShowElearningResponseBody,
  ShowUserCoursesRequestBody,
  UpdateUserCourseContentRequestBody,
  UpdateUserCourseContentResponseBody,
  UpdateUserCourseRequestBody,
  UpdateUserCourseResponseBody,
  checkCreateElearningApi,
  checkEditElearningApi,
  createElearningApi,
  createUserCourseApi,
  createUserCourseContentCourseApi,
  deleteELearningApi,
  editElearningApi,
  frontListCourseApi,
  frontSuggestionCourseApi,
  getDetailElearningApi,
  listCourseApi,
  listELearningApi,
  listMyCourseApi,
  listSuggestionCourseApi,
  listUserJoinedELearningApi,
  listUserNotJoinELearningApi,
  showCourseApi,
  showELearningApi,
  showUserCoursesApi,
  updateUserCourseApi,
  updateUserCourseContentCourseApi,
} from "./request";
import { TDetailELearning } from "../type";

export const useListELearningQuery = (
  params: Partial<FilterELearningRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterELearningResponseBody,
      unknown, // TError,
      FilterELearningResponseBody,
      (string | Partial<FilterELearningRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-e-learnings", params],
    queryFn: listELearningApi,
    enabled: !!params,
    ...options,
  });
};

export const fetchFilterELearningList = async (
  queryClient: QueryClient,
  params?: Partial<FilterELearningRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["filter-e-learnings", params],
    queryFn: listELearningApi,
  });
};

export const useShowELearningQuery = (
  params: number | string,
  options: Omit<
    UseQueryOptions<
      ShowElearningResponseBody,
      unknown,
      ShowElearningResponseBody,
      (string | number)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["show-e-learning", params],
    queryFn: showELearningApi,
    ...options,
  });
};

export const useGetDetailELearningQuery = (
  id: number,
  options: Omit<UseQueryOptions<TDetailELearning, unknown>, "queryKey"> = {},
) => {
  return useQuery({
    queryKey: ["detail-e-learning", id],
    queryFn: () => getDetailElearningApi(id),
    ...options,
  });
};

export const useDeleteELearningMutation = (
  options: MutateOptions<
    DeleteELearningResponseBody,
    unknown,
    number,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: deleteELearningApi,
    },
    queryClient,
  );
};

export const useCreateElearningCourseMutation = (
  options: MutateOptions<
    CreateElearningResponseBody,
    unknown,
    CreateElearningRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: createElearningApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useCheckCreateElearningCourseMutation = (
  options: MutateOptions<
    CreateElearningResponseBody,
    unknown,
    CreateElearningRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: checkCreateElearningApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useEditElearningCourseMutation = (
  options: MutateOptions<
    CreateElearningResponseBody,
    unknown,
    CreateElearningRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: editElearningApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useCheckEditElearningCourseMutation = (
  options: MutateOptions<
    CreateElearningResponseBody,
    unknown,
    CreateElearningRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: checkEditElearningApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useListUserJoinedCourseQuery = (
  params: Partial<ListUserJoinedCourseRequestBody>,
  options: Omit<
    UseQueryOptions<
      ListUserJoinedCourseResponseBody,
      unknown, // TError,
      ListUserJoinedCourseResponseBody,
      (string | Partial<ListUserJoinedCourseRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-users-joined-e-learning", params],
    queryFn: listUserJoinedELearningApi,
    ...options,
  });
};

export const useListUserNotJoinCourseQuery = (
  params: Partial<ListUserNotJoinCourseRequestBody>,
  options: Omit<
    UseQueryOptions<
      ListUserNotJoinCourseResponseBody,
      unknown, // TError,
      ListUserNotJoinCourseResponseBody,
      (string | Partial<ListUserNotJoinCourseRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-users-not-join-e-learning", params],
    queryFn: listUserNotJoinELearningApi,
    ...options,
  });
};

export const useShowCourseQuery = (
  id: number,
  options: Omit<
    UseQueryOptions<
      ShowCourseResponseBody,
      unknown,
      ShowCourseResponseBody,
      (string | number)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["show-course", id],
    queryFn: showCourseApi,
    enabled: !!id,
    ...options,
  });
};

export const useListCourseQuery = (
  params: Partial<FilterListCourseRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterListCourseResponseBody,
      unknown,
      FilterListCourseResponseBody,
      (string | Partial<FilterListCourseRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["list-course", params],
    queryFn: listCourseApi,
    enabled: !!params,
    ...options,
  });
};

export const useFrontListCourseQuery = (
  params: Partial<FrontFilterListCourseRequestBody>,
  options: Omit<
    UseQueryOptions<
      FrontFilterListCourseResponseBody,
      unknown,
      FrontFilterListCourseResponseBody,
      (string | Partial<FrontFilterListCourseRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-front-e-learnings", params],
    queryFn: frontListCourseApi,
    enabled: !!params,
    ...options,
  });
};

export const fetchFrontListCourseQuery = async (
  queryClient: QueryClient,
  params?: Partial<FrontFilterListCourseRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["filter-front-e-learnings", params],
    queryFn: frontListCourseApi,
  });
};

export const fetchFrontFilterELearningSuggestionList = async (
  queryClient: QueryClient,
  params?: Partial<FrontFilterListSuggestionCourseRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["filter-front-suggestion-e-learnings", params],
    queryFn: frontSuggestionCourseApi,
  });
};

export const useListMyCourseQuery = (
  params: Partial<FilterListMyCourseRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterListMyCourseResponseBody,
      unknown,
      FilterListMyCourseResponseBody,
      (string | Partial<FilterListMyCourseRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["list-my-course", params],
    queryFn: listMyCourseApi,
    enabled: !!params,
    ...options,
  });
};

export const useListSuggestionCourseQuery = (
  params: Partial<FilterListSuggestionCourseRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterListSuggestionCourseResponseBody,
      unknown,
      FilterListSuggestionCourseResponseBody,
      (string | Partial<FilterListSuggestionCourseRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["list-my-course", params],
    queryFn: listSuggestionCourseApi,
    enabled: !!params,
    ...options,
  });
};

export const useCreateUserCourseMutation = (
  options: MutateOptions<
    CreateUserCourseResponseBody,
    unknown,
    number,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: createUserCourseApi,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["show-course"],
          exact: true,
        });
      },
    },
    queryClient,
  );
};

export const useUpdateUserCourseMutation = (
  options: MutateOptions<
    UpdateUserCourseResponseBody,
    unknown,
    UpdateUserCourseRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updateUserCourseApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useCreateUserCourseContentMutation = (
  options: MutateOptions<
    CreateUserCourseContentResponseBody,
    unknown,
    CreateUserCourseContentRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: createUserCourseContentCourseApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useUpdateUserCourseContentMutation = (
  options: MutateOptions<
    UpdateUserCourseContentResponseBody,
    unknown,
    UpdateUserCourseContentRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updateUserCourseContentCourseApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const showUserCoursesQuery = async (
  queryClient: QueryClient,
  params?: Partial<ShowUserCoursesRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["show-user-course", params],
    queryFn: showUserCoursesApi,
  });
};
