import {
  MutateOptions,
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DeleteDocumentRequestBody,
  FilterDocumentRequestBody,
  FilterDocumentResponseBody,
  ShowDocumentResponseBody,
  UpsertDocumentRequestBody,
  UpsertDocumentResponseBody,
  deleteDocumentApi,
  listDocumentApi,
  showDocumentApi,
  upsertDocumentApi,
} from "./request";

export const useListDocumentQuery = (
  params: Partial<FilterDocumentRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterDocumentResponseBody,
      unknown, // TError,
      FilterDocumentResponseBody,
      (string | Partial<FilterDocumentRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-document", params],
    queryFn: listDocumentApi,
    ...options,
  });
};

export const fetchListDocument = async (
  queryClient: QueryClient,
  params?: Partial<FilterDocumentRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["filter-document-1", params],
    queryFn: listDocumentApi,
  });
};

export const useSuggestionDocumentListQuery = (
  params: Partial<FilterDocumentRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterDocumentResponseBody,
      unknown, // TError,
      FilterDocumentResponseBody,
      (string | Partial<FilterDocumentRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-suggestion-document", params],
    queryFn: listDocumentApi,
    ...options,
  });
};

export const useUpsertDocumentMutation = (
  options: MutateOptions<
    UpsertDocumentResponseBody,
    unknown,
    UpsertDocumentRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: upsertDocumentApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useShowDocumentQuery = (
  id: number | string,
  folder_id: number | string,
  options: Omit<
    UseQueryOptions<
      ShowDocumentResponseBody,
      unknown,
      ShowDocumentResponseBody,
      (string | number)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["show-document", id, folder_id],
    queryFn: showDocumentApi,
    enabled: !!folder_id,
    ...options,
  });
};

export const useDeleteDocumentMutation = (
  options: MutateOptions<
    UpsertDocumentResponseBody,
    unknown,
    DeleteDocumentRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: deleteDocumentApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};
