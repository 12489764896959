import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";
import { request } from "../axios";
import { omit } from "lodash";
import { QueryFunctionContext } from "@tanstack/react-query";
import { OrderDirection, Paging, User } from "../type";

export type CreateUserRequestBody = {
  email: string;
  first_name: string;
  last_name: string;
  office_id: string;
  department_id: string;
  role_id: string;
  avatar_attributes: {
    key: string;
    name: string;
    content_type: string;
  } | null;
};

export type CreateUserResponseBody = {
  id: string;
};

export const createUserApi = (
  data: CreateUserRequestBody,
): Promise<CreateUserResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.CREATE_USER),
    data: {
      users: data,
    },
  });
};

export type UpdateUserRequestBody = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  office_id: string;
  department_id: string;
  role_id: string;
  avatar_attributes: {
    id?: number;
    key: string;
    name: string;
    content_type: string;
  } | null;
};

export type UpdateUserResponseBody = {
  id: string | number;
};

export const updateUserApi = (
  data: UpdateUserRequestBody,
): Promise<UpdateUserResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.UPDATE_USER, {
      id: data.id,
    }),
    data: {
      users: omit(data, ["id"]),
    },
  });
};

export type FilterUserRequestBody = {
  page: number;
  per_page: number;
  order_by?: string;
  order_direction?: OrderDirection;
  fullname?: string;
  role_id?: string;
  department_id?: string;
  office_id?: string;
  department_ids?: number[] | null;
  office_ids?: number[] | null;
  all?: boolean;
  status?: "done" | string;
};

export type FilterUserResponseBody = {
  users: User[];
  paging: Paging;
};

export type UpdatePasswordUserRequestBody = {
  id?: number;
  current_password?: string;
  password?: string;
  password_confirmation?: string;
};

export type UpdatePasswordUserResponseBody =
  | {
      id: number;
    }
  | {
      error_message: string;
    }
  | {
      error_message: {
        current_password: string[];
        password_confirmation: string[];
        password: string[];
      };
    };

export type TUpdateEmailRequestBody = {
  users: {
    email: string;
  };
};
export type TUpdateEmailResponseBody = User;
export const listUserApi = (
  context: QueryFunctionContext,
): Promise<FilterUserResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_USER),
    params: context.queryKey[1],
  });
};

export type DeleteUserResponseBody = {
  id: string;
};
export const deleteUserApi = (id: number): Promise<DeleteUserResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.DELETE_USER, { id }),
  });
};

export type ShowUserResponseBody = User;
export const showUserApi = (
  context: QueryFunctionContext,
): Promise<ShowUserResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.SHOW_USER, { id: context.queryKey[1] }),
  });
};

export const updatePasswordUserApi = (
  data: UpdatePasswordUserRequestBody,
): Promise<UpdatePasswordUserResponseBody> => {
  return request({
    method: "PUT",
    url: `${getRoute(API_PATH_CONSTANT.UPDATE_PASSWORD)}?id=${data.id}`,
    data: omit(data, "id"),
  });
};

export const updateEmailApi = (
  data: TUpdateEmailRequestBody,
): Promise<TUpdateEmailResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.PROFILE),
    data: data,
  });
};
