import Button from "@/components/atoms/Button/button";
import {
  EContentRadioOptions,
  ICreateELearningForm,
  IElearningChildFormProps,
  SelectOption,
} from "./validation";
import { ControlledSelect } from "@/components/molecules/ControlledSelect/controlled-select";
import {
  ELEARNING_CONTENT_TYPE,
  ELEARNING_CONTENT_TYPE_OPTIONS,
  MAX_LENGTH,
  PAGINATION_CONSTANT,
} from "@/configs/constants";
import CloseIcon from "@/assets/icon/close.svg";
import { ControlledRadio } from "@/components/molecules/ControlledRadio/controlled-radio";
import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import { FieldArrayWithId, useFieldArray } from "react-hook-form";
import PlusIcon from "@/assets/icon/plus-02.svg";
import UploadPdf from "@/components/atoms/UploadDocument/upload-pdf";
import { useCallback, useMemo, useRef } from "react";
import useToast from "@/hooks/toast";
import UploadVideo from "@/components/atoms/UploadDocument/upload-video";
import { useListVideoQuery } from "@/api/video";
import { useQuizzListQuery } from "@/api/quizz";
import { useListComplianceQuery } from "@/api/compliance";
import { useListDocumentQuery } from "@/api/document";
import { isNull } from "lodash";
import { ReactSortable } from "react-sortablejs";
import { v4 as uuidv4 } from "uuid";
import DraggableIcon from "@/assets/icon/dragable.svg";
import { isObjectURL } from "@/shared/get/check";

export default function ContentElearning({
  control,
  values,
  errors,
  setValue,
  setError,
  clearErrors,
  getValues,
}: IElearningChildFormProps) {
  const { showToast } = useToast();
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "content",
    keyName: "child_id",
  });

  const { data: documentRes } = useListDocumentQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });

  const { data: videoRes } = useListVideoQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });

  const { data: quizzRes } = useQuizzListQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });

  const { data: complianceRes } = useListComplianceQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
    is_public: true,
  });

  const documents = useMemo(() => {
    return (documentRes?.documents || []).map((doc) => ({
      value: doc.id,
      label: doc.title,
    }));
  }, [documentRes]);

  const videos = useMemo(() => {
    return (videoRes?.videos || []).map((video) => ({
      value: video.id,
      label: video.title,
    }));
  }, [videoRes]);

  const quizzes = useMemo(() => {
    return (quizzRes?.quizzes || []).map((quizz) => ({
      value: quizz.id,
      label: quizz.title,
    }));
  }, [quizzRes]);

  const compliances = useMemo(() => {
    return (complianceRes?.compliances || []).map((compliance) => ({
      value: compliance.id,
      label: compliance.title,
    }));
  }, [complianceRes]);

  const handleConfirmModalSubmit = (targetIndex: {
    index: number;
    id: number;
  }) => {
    if (targetIndex?.id) {
      setValue("deleted_content_ids", [
        ...(values.deleted_content_ids || []),
        ...[
          {
            ...values.content?.[targetIndex?.index - 1],
            _destroy: true,
          },
        ],
      ]);
    }
    targetIndex?.index && remove(targetIndex?.index - 1);
    showToast({
      title: `コンテンツ ${targetIndex?.index}を削除しました`,
      type: "success",
    });
  };

  const handleSwitchRadioOption = (
    v: number,
    key: keyof ICreateELearningForm,
    index: number,
  ) => {
    const currentOption = values?.content?.[index];
    clearErrors && clearErrors(key);
    setValue(
      key as keyof ICreateELearningForm,
      {
        id: currentOption?.id,
        type: currentOption?.type,
        child_id: currentOption?.child_id,
        option: v,
        document_id: null,
        attachment_attributes: null,
        video_id: null,
        youtube_id: null,
        youtube_file_name: null,
      } as any,
    );
  };

  const handleSwitchRolePlayingRadioOption = (
    v: number,
    key: keyof ICreateELearningForm,
    index: number,
  ) => {
    const currentOption = values?.content?.[index];
    const isLinkLocal = isObjectURL(currentOption.youtube_id);
    if (isLinkLocal) {
      URL.revokeObjectURL(currentOption.youtube_id);
    }

    clearErrors && clearErrors(key);
    setValue(
      key as keyof ICreateELearningForm,
      {
        id: currentOption?.id,
        type: currentOption?.type,
        child_id: currentOption?.child_id,
        role_playing_title: currentOption?.role_playing_title,
        option: v,
        video_id: null,
        youtube_id: null,
        youtube_file_name: null,
      } as any,
    );
  };

  const isSortingRef = useRef(false);

  const updateOrder = async (
    newState: FieldArrayWithId<ICreateELearningForm, "content", "id">[],
  ) => {
    if (!isSortingRef.current || !getValues) return;
    isSortingRef.current = false;

    setValue(
      "content",
      newState.map((i) => {
        const index = getValues(`content`).findIndex(
          (child) => child.id === i.id,
        );

        const values = getValues(`content.${index}`);
        const type = values.type;
        const option = values.option;
        const videoId = values.video_id;
        const documentId = values.document_id;
        const testId = values.test_id;
        const complianceTrainingId = values.compliance_training_id;
        const rolePlayingTheme = values.role_playing_title;

        return {
          ...i,
          id: values.id,
          type: type,
          option: option,
          ...(videoId && { video_id: videoId }),
          ...(documentId && { document_id: documentId }),
          ...(testId && { test_id: testId }),
          ...(complianceTrainingId && {
            compliance_training_id: complianceTrainingId,
          }),
          ...(rolePlayingTheme && { role_playing_title: rolePlayingTheme }),
          ...(type?.value === ELEARNING_CONTENT_TYPE.DOCUMENT.value &&
          option === EContentRadioOptions.UPLOAD
            ? {
                attachment_attributes: values.attachment_attributes,
              }
            : {
                attachment_attributes: null,
              }),
          ...([
            ELEARNING_CONTENT_TYPE.VIDEO.value,
            ELEARNING_CONTENT_TYPE.ROLE_PLAYING.value,
          ].includes(type?.value as 2 | 4) &&
          option === EContentRadioOptions.UPLOAD
            ? {
                youtube_id: values.youtube_id,
                youtube_file_name: values.youtube_file_name,
              }
            : {
                youtube_id: null,
                youtube_file_name: null,
              }),
        };
      }),
    );
  };

  const getYoutubeInfo = useCallback(
    (index: number) => {
      return getValues?.(`content.${index}.youtube_id`)
        ? {
            id: getValues?.(`content.${index}.youtube_id`),
            name: getValues?.(`content.${index}.youtube_file_name`),
          }
        : null;
    },
    [getValues],
  );

  return (
    <div>
      <ReactSortable
        tag="div"
        list={fields as any}
        draggable=".drag-handle"
        onUpdate={() => {
          isSortingRef.current = true;
          clearErrors && clearErrors("content");
        }}
        setList={(value) => updateOrder(value as any)}
        className="flex flex-col gap-y-[10px]"
      >
        {fields.map((item, index) => {
          return (
            <div
              key={item.child_id}
              data-id={item.id}
              className="mt-8 drag-handle"
            >
              <div className="flex flex-row justify-between items-center gap-x-2">
                <div className="group relative cursor-move">
                  <h6 className="font-black">コンテンツ {index + 1}</h6>
                  <div className="opacity-0 group-hover:opacity-100 absolute top-[2px] left-[-25px]">
                    <DraggableIcon />
                  </div>
                </div>
                {index !== 0 && (
                  <Button
                    variant="danger-outline"
                    buttonCustom={{
                      padding: "6px 8px",
                    }}
                    size="xs"
                    startSlot={<CloseIcon />}
                    onClick={() =>
                      handleConfirmModalSubmit({
                        id: values?.content?.[index]?.id,
                        index: index + 1,
                      })
                    }
                  >
                    コンテンツ{index + 1}を削除
                  </Button>
                )}
              </div>
              <div className="mt-3 shadow-card bg-white p-6 rounded-[20px] flex flex-col gap-y-6">
                {(errors?.content?.[index] as any)?.base && (
                  <div className="mt-1 w-full text-error--main input-error__message is-invalid">
                    {(errors?.content?.[index] as any)?.base?.message}
                  </div>
                )}

                <ControlledSelect
                  isRequired={true}
                  label="コンテンツ"
                  control={control}
                  formField={`content.${index}.type`}
                  options={ELEARNING_CONTENT_TYPE_OPTIONS}
                  unsetMenuPortalTarget
                  className="w-[400px]"
                  placeholder="コンテンツタイプを選択してください"
                  errorMessage={
                    (errors?.content?.[index]?.type as any)?.message
                  }
                  onChangeCallback={(e) => {
                    if (e) {
                      const isLinkLocal =
                        values.content?.[index].youtube_id?.startsWith("blob:");
                      if (isLinkLocal) {
                        URL.revokeObjectURL(values.content?.[index].youtube_id);
                      }

                      if (clearErrors) {
                        clearErrors(`content.${index}`);
                      }

                      setValue(`content.${index}`, {
                        type: e as SelectOption,
                        id: values.content?.[index]?.id,
                        child_id: values.content?.[index]?.child_id,
                        option: 0,
                        video_id: null,
                        document_id: null,
                        test_id: null,
                        compliance_training_id: null,
                        role_playing_title: null,
                        attachment_attributes: null,
                        youtube_file_name: null,
                        youtube_id: null,
                      });
                    }
                  }}
                />
                {values?.content?.[index]?.type?.value ===
                  ELEARNING_CONTENT_TYPE.DOCUMENT.value && (
                  <ControlledRadio
                    label="アップロード方法"
                    control={control}
                    formField={`content.${index}.option`}
                    classes={{
                      radio: "w-full",
                    }}
                    direction="vertical"
                    onChangeCallback={(v) =>
                      handleSwitchRadioOption(
                        v as number,
                        `content.${index}` as keyof ICreateELearningForm,
                        index,
                      )
                    }
                    options={[
                      {
                        label: "コンテンツを選択",
                        value: EContentRadioOptions.TITLE,
                        description: (
                          <div>
                            <div></div>
                            <ControlledSelect
                              label="ドキュメントタイトル"
                              control={control}
                              formField={`content.${index}.document_id`}
                              options={documents}
                              unsetMenuPortalTarget
                              className="w-full mt-[12px]"
                              placeholder="ドキュメントタイトルを入力し選択してください"
                              isDisabled={
                                values?.content?.[index]?.option ===
                                  EContentRadioOptions.UPLOAD || false
                              }
                              errorMessage={
                                errors?.content?.[index]?.document_id?.message
                              }
                            />
                          </div>
                        ),
                      },
                      {
                        label: "新しくアップロード",
                        value: EContentRadioOptions.UPLOAD,
                        description: (
                          <>
                            <div className="mt-[10px]">
                              <UploadPdf
                                isError={
                                  !!errors?.content?.[index]
                                    ?.attachment_attributes?.message?.length
                                }
                                onChange={(file) =>
                                  file &&
                                  setValue(
                                    `content.${index}.attachment_attributes`,
                                    file,
                                  )
                                }
                                onRemove={() =>
                                  setValue(
                                    `content.${index}.attachment_attributes`,
                                    null,
                                  )
                                }
                                onError={(message) =>
                                  setError &&
                                  setError(
                                    `content.${index}.attachment_attributes`,
                                    {
                                      type: "custom",
                                      message: message,
                                    },
                                  )
                                }
                                isDisabled={
                                  values?.content?.[index]?.option ===
                                  EContentRadioOptions.TITLE
                                }
                                attachment={
                                  isNull(
                                    values?.content?.[index]
                                      ?.attachment_attributes,
                                  )
                                    ? {}
                                    : values?.content?.[index]
                                        ?.attachment_attributes
                                }
                              />
                            </div>
                            {errors?.content?.[index]?.attachment_attributes
                              ?.message && (
                              <div className="mt-1 w-full text-error--main input-error__message is-invalid">
                                {
                                  errors?.content?.[index]
                                    ?.attachment_attributes?.message
                                }
                              </div>
                            )}
                          </>
                        ),
                      },
                    ]}
                  />
                )}
                {values?.content?.[index]?.type?.value ===
                  ELEARNING_CONTENT_TYPE.VIDEO.value && (
                  <ControlledRadio
                    label="アップロード方法"
                    control={control}
                    formField={`content.${index}.option`}
                    classes={{
                      radio: "w-full",
                    }}
                    onChangeCallback={(v, hasChangeValue) => {
                      hasChangeValue &&
                        handleSwitchRadioOption(
                          v as number,
                          `content.${index}` as keyof ICreateELearningForm,
                          index,
                        );
                    }}
                    direction="vertical"
                    options={[
                      {
                        label: "コンテンツを選択",
                        value: EContentRadioOptions.TITLE,
                        description: (
                          <div>
                            <ControlledSelect
                              label="動画タイトル"
                              control={control}
                              formField={`content.${index}.video_id`}
                              options={videos}
                              unsetMenuPortalTarget
                              className="w-full mt-[12px]"
                              placeholder="動画タイトルを入力し選択してください"
                              isDisabled={
                                values?.content?.[index]?.option ===
                                  EContentRadioOptions.UPLOAD || false
                              }
                              errorMessage={
                                errors?.content?.[index]?.video_id?.message
                              }
                            />
                          </div>
                        ),
                      },
                      {
                        label: "アップロード",
                        value: EContentRadioOptions.UPLOAD,
                        description: (
                          <>
                            <div className="mt-[10px]">
                              <UploadVideo
                                mimeSize={2}
                                sizeUnit="GB"
                                isError={
                                  !!errors?.content?.[index]
                                    ?.attachment_attributes?.message ||
                                  !!errors?.content?.[index]?.youtube_id
                                    ?.message
                                }
                                onRemove={async () => {
                                  setValue(`content.${index}.youtube_id`, null);
                                  setValue(
                                    `content.${index}.youtube_file_name`,
                                    null,
                                  );

                                  clearErrors &&
                                    clearErrors(`content.${index}.youtube_id`);

                                  clearErrors &&
                                    clearErrors(
                                      `content.${index}.attachment_attributes`,
                                    );
                                }}
                                onChange={async (content) => {
                                  if (!content) return;
                                  setValue(
                                    `content.${index}.youtube_id`,
                                    content.objectURL ?? null,
                                  );
                                  setValue(
                                    `content.${index}.youtube_file_name`,
                                    content.name ?? null,
                                  );

                                  clearErrors &&
                                    clearErrors(`content.${index}.youtube_id`);

                                  clearErrors &&
                                    clearErrors(
                                      `content.${index}.attachment_attributes`,
                                    );
                                }}
                                onError={(message) =>
                                  setError &&
                                  setError(`content.${index}.youtube_id`, {
                                    type: "custom",
                                    message: message,
                                  })
                                }
                                placeholder="flv・mp4・mov・mpegファイルのみ対応"
                                isDisabled={
                                  values?.content?.[index]?.option ===
                                    EContentRadioOptions.TITLE || false
                                }
                                youtubeInfo={getYoutubeInfo(index)}
                              />
                            </div>
                            {(errors?.content?.[index]?.attachment_attributes
                              ?.message ||
                              errors?.content?.[index]?.youtube_id
                                ?.message) && (
                              <div className="mt-1 w-full text-error--main input-error__message is-invalid">
                                {errors?.content?.[index]?.attachment_attributes
                                  ?.message ||
                                  errors?.content?.[index]?.youtube_id?.message}
                              </div>
                            )}
                          </>
                        ),
                      },
                    ]}
                  />
                )}
                {values?.content?.[index]?.type?.value ===
                  ELEARNING_CONTENT_TYPE.TEST.value && (
                  <ControlledSelect
                    label="テストタイトル"
                    control={control}
                    formField={`content.${index}.test_id`}
                    options={quizzes}
                    unsetMenuPortalTarget
                    className="w-full mt-[12px]"
                    placeholder="テストタイトルを入力し選択してください"
                    errorMessage={errors?.content?.[index]?.test_id?.message}
                  />
                )}
                {values?.content?.[index]?.type?.value ===
                  ELEARNING_CONTENT_TYPE.ROLE_PLAYING.value && (
                  <>
                    <ControlledInput
                      label="テーマ"
                      placeholder="テーマを入力してください"
                      control={control}
                      formField={`content.${index}.role_playing_title`}
                      isTrim={true}
                      maxLength={MAX_LENGTH.VARCHAR}
                      className="w-full"
                      errorMessage={
                        errors?.content?.[index]?.role_playing_title?.message
                      }
                    />
                    <ControlledRadio
                      label="参考動画"
                      control={control}
                      formField={`content.${index}.option`}
                      classes={{
                        radio: "w-full",
                      }}
                      onChangeCallback={(v, hasChangeValue) => {
                        hasChangeValue &&
                          handleSwitchRolePlayingRadioOption(
                            v as number,
                            `content.${index}` as keyof ICreateELearningForm,
                            index,
                          );
                      }}
                      direction="vertical"
                      options={[
                        {
                          label: "コンテンツを選択",
                          value: EContentRadioOptions.TITLE,
                          description: (
                            <div>
                              <ControlledSelect
                                label="動画タイトル"
                                control={control}
                                formField={`content.${index}.video_id`}
                                options={videos}
                                unsetMenuPortalTarget
                                className="w-full mt-[12px]"
                                placeholder="動画タイトルを入力し選択してください"
                                isDisabled={
                                  values?.content?.[index]?.option ===
                                    EContentRadioOptions.UPLOAD || false
                                }
                                errorMessage={
                                  errors?.content?.[index]?.video_id?.message
                                }
                              />
                            </div>
                          ),
                        },
                        {
                          label: "アップロード",
                          value: EContentRadioOptions.UPLOAD,
                          description: (
                            <>
                              <div className="mt-[10px]">
                                <UploadVideo
                                  mimeSize={2}
                                  sizeUnit="GB"
                                  isError={
                                    !!errors?.content?.[index]
                                      ?.attachment_attributes?.message ||
                                    !!errors?.content?.[index]?.youtube_id
                                      ?.message
                                  }
                                  onRemove={async () => {
                                    setValue(
                                      `content.${index}.youtube_id`,
                                      null,
                                    );
                                    setValue(
                                      `content.${index}.youtube_file_name`,
                                      null,
                                    );

                                    clearErrors &&
                                      clearErrors(
                                        `content.${index}.youtube_id`,
                                      );

                                    clearErrors &&
                                      clearErrors(
                                        `content.${index}.attachment_attributes`,
                                      );
                                  }}
                                  onChange={async (content) => {
                                    if (!content) return;
                                    setValue(
                                      `content.${index}.youtube_id`,
                                      content.objectURL ?? null,
                                    );

                                    setValue(
                                      `content.${index}.youtube_file_name`,
                                      content.name ?? null,
                                    );

                                    clearErrors &&
                                      clearErrors(
                                        `content.${index}.youtube_id`,
                                      );

                                    clearErrors &&
                                      clearErrors(
                                        `content.${index}.attachment_attributes`,
                                      );
                                  }}
                                  onError={(message) =>
                                    setError &&
                                    setError(`content.${index}.youtube_id`, {
                                      type: "custom",
                                      message: message,
                                    })
                                  }
                                  placeholder="flv・mp4・mov・mpegファイルのみ対応"
                                  isDisabled={
                                    values?.content?.[index]?.option ===
                                      EContentRadioOptions.TITLE || false
                                  }
                                  youtubeInfo={getYoutubeInfo(index)}
                                />
                              </div>
                              {(errors?.content?.[index]?.attachment_attributes
                                ?.message ||
                                errors?.content?.[index]?.youtube_id
                                  ?.message) && (
                                <div className="mt-1 w-full text-error--main input-error__message is-invalid">
                                  {errors?.content?.[index]
                                    ?.attachment_attributes?.message ||
                                    errors?.content?.[index]?.youtube_id
                                      ?.message}
                                </div>
                              )}
                            </>
                          ),
                        },
                      ]}
                    />
                  </>
                )}
                {values?.content?.[index]?.type?.value ===
                  ELEARNING_CONTENT_TYPE.COMPLIANCE_TRAINING.value && (
                  <ControlledSelect
                    label="コンプライアンス研修タイトル"
                    control={control}
                    formField={`content.${index}.compliance_training_id`}
                    options={compliances}
                    unsetMenuPortalTarget
                    className="w-full mt-[12px]"
                    placeholder="コンプライアンス研修タイトルを入力し選択してください"
                    errorMessage={
                      errors?.content?.[index]?.compliance_training_id?.message
                    }
                  />
                )}
              </div>
            </div>
          );
        })}
      </ReactSortable>
      <Button
        size="xs"
        variant="outline"
        className="mt-3"
        buttonCustom={{
          padding: "8px 12px",
        }}
        startSlot={<PlusIcon width={20} height={20} />}
        onClick={() => {
          append({
            id: uuidv4(),
            child_id: uuidv4(),
            type: null,
            option: 0,
            video_id: null,
            document_id: null,
            _destroy: false,
            youtube_file_name: null,
            youtube_id: null,
          });
        }}
      >
        コンテンツを追加する
      </Button>
    </div>
  );
}
