import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import {
  CategorySelectOption,
  ICreateELearningForm,
  IElearningChildFormProps,
  SelectOption,
  UserSelectOption,
} from "./validation";
import { ControlledSelect } from "@/components/molecules/ControlledSelect/controlled-select";
import { ControlledRadio } from "@/components/molecules/ControlledRadio/controlled-radio";
import { useEffect, useMemo, useState } from "react";
import { ControlledDatePicker } from "@/components/molecules/ControlledDatePicker/controlled-datepicker";
import { MAX_LENGTH, PAGINATION_CONSTANT } from "@/configs/constants";
import { useListOfficeQuery } from "@/api/office";
import { useListDepartmentQuery } from "@/api/department";
import { useListTagQuery } from "@/api/tag";
import { useListCategoryQuery } from "@/api/category";
import { useListUserQuery } from "@/api/user";
import { ControlledCheckbox } from "@/components/molecules/ControlledCheckbox/controlled-checkbox";
import { Box } from "@chakra-ui/react";
import { Option } from "@/components/atoms/SelectBox/select-box";
import Avatar from "@/components/atoms/Avatar/avatar";
import { getLinkMedia } from "@/shared/get";
import { isEmpty, isNull, pullAll } from "lodash";

type TCreateElearningForm = keyof ICreateELearningForm;

export default function BasicInfoElearning({
  control,
  errors,
  values,
  setValue,
  contentError,
}: IElearningChildFormProps & { contentError: string }) {
  const { data: officeRes } = useListOfficeQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });
  const { data: departmentRes } = useListDepartmentQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });
  const { data: tagRes } = useListTagQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });
  const { data: categoryRes } = useListCategoryQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
    for_admin: true,
  });
  const { data: userRes, isFetching } = useListUserQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
    all: true,
  });

  const offices = useMemo(() => {
    const isSelectAll =
      values?.offices?.findIndex(
        (office: SelectOption) => office.value === "all",
      ) >= 0;
    if (isSelectAll) return [];
    const list = (officeRes?.offices || []).map((office) => ({
      value: office.id,
      label: office.name,
    }));
    return [
      ...(list?.length > 1 ? [{ value: "all", label: "全て" }] : []),
      ...list,
    ];
  }, [officeRes, values?.offices]);

  const departments = useMemo(() => {
    const isSelectAll =
      values?.departments?.findIndex(
        (dep: SelectOption) => dep.value === "all",
      ) >= 0;
    if (isSelectAll) return [];
    const list = (departmentRes?.departments || []).map((department) => ({
      value: department.id,
      label: department.name,
    }));
    return [
      ...(list?.length > 1 ? [{ value: "all", label: "全て" }] : []),
      ...list,
    ];
  }, [departmentRes, values?.departments]);

  const categories = useMemo(() => {
    return (categoryRes?.categories || [])
      ?.sort((a, b) => a.position - b.position)
      .flatMap((category) => {
        const subCategories = category.sub_categories;
        if (subCategories?.length === 0) {
          return {
            value: category.id,
            label: category.name,
            parent_category_id: category.parent?.id || category.id || null,
            sub_category_id: null,
          };
        } else {
          return subCategories
            ?.sort((a, b) => a.position - b.position)
            ?.flatMap((subCategory) => {
              return {
                value: subCategory.id,
                label: category.name + " / " + subCategory.name,
                parent_category_id: category.parent?.id || category.id || null,
                sub_category_id: subCategory.id || null,
              };
            })
            .filter(Boolean);
        }
      })
      .filter(Boolean);
  }, [categoryRes]);

  const tags = useMemo(() => {
    return (tagRes?.tags || []).map((tag) => ({
      value: tag.id as number,
      label: tag.name,
    }));
  }, [tagRes]);

  const users = useMemo(() => {
    const list = (userRes?.users || []).map((user) => ({
      value: user.id,
      label: (
        <div className="flex items-center">
          <Avatar
            url={getLinkMedia(user?.thumbnail?.key || user?.avatar?.key)}
            size="sm"
          />
          <div className="ml-2">{user.first_name + " " + user.last_name}</div>
        </div>
      ),
      custom_field: user.first_name + " " + user.last_name,
      office_id: user?.office?.id || null,
      department_id: user?.department?.id || null,
    }));
    return [
      ...(list?.length >= 1 && list.length !== values?.users?.length
        ? [
            {
              value: "all",
              label: "全て",
              office_id: null,
              department_id: null,
            },
          ]
        : []),
      ...list,
    ];
  }, [userRes, values?.users]);

  const handleSelectAll = (e: SelectOption[], key: TCreateElearningForm) => {
    if (values?.[key]?.includes("all")) return;
    if (e.findIndex((item) => item?.value === "all") >= 0) {
      setValue(key, [
        {
          value: "all",
          label: "全て",
        },
      ]);
    }
  };

  const customTagFilter = (option: { data: Option }, inputValue: string) => {
    return ((option?.data?.label as string) || "")
      ?.toLowerCase()
      .includes(inputValue?.toLowerCase())
      ? true
      : false;
  };

  const customUserFilter = (option: { data: Option }, inputValue: string) => {
    return ((option?.data?.custom_field as string) || "")
      ?.toLowerCase()
      .includes(inputValue?.toLowerCase())
      ? true
      : false;
  };

  const isEmptyOffice = useMemo(
    () => isEmpty(values?.offices) || values?.offices?.length === 0,
    [values?.offices],
  );
  const isEmptyDepartment = useMemo(
    () => isEmpty(values?.departments) || values?.departments?.length === 0,
    [values?.departments],
  );

  const [startFilterByOffice, setStartFilterByOffice] = useState(false);
  const [startFilterByDepartment, setStartFilterByDepartment] = useState(false);

  const [oldOffice, setOldOffice] = useState<SelectOption[]>([]);
  const [oldDepartment, setOldDepartment] = useState<SelectOption[]>([]);

  function findDifferenceInObjects(arr1: SelectOption[], arr2: SelectOption[]) {
    const diffArr1 = arr1.filter(
      (a) => !arr2.some((b) => JSON.stringify(a) === JSON.stringify(b)),
    );
    const diffArr2 = arr2.filter(
      (a) => !arr1.some((b) => JSON.stringify(a) === JSON.stringify(b)),
    );
    return [...diffArr1, ...diffArr2];
  }

  useEffect(() => {
    if (!startFilterByOffice) return;
    // CASE 1: RESET USER LIST WHEN UNSELECT ALL OFFICE AND DEPARTMENT
    if (isEmptyOffice && isEmptyDepartment) {
      setValue("users", []);
      setStartFilterByOffice(false);
      return;
    }

    const cloneUser = [...(values?.users || [])];
    let diffOffices: number[] = [];
    const tempAddUserArr: UserSelectOption[] = [];
    const isRemoveDepartment = oldOffice?.length > values?.offices?.length;
    const isSelectAllOffice =
      values?.offices
        ?.map((item: SelectOption) => item?.value)
        ?.includes("all") ||
      values.offices?.length === officeRes?.offices?.length;

    // CASE 2: IF ANY DEPARTMENT IS SELECTED, IGNORE THE OFFICE FILTER
    if ((values?.departments || []).length !== 0) {
      setStartFilterByOffice(false);
      return;
    }

    // CASE 3: REMOVE DEPARTMENT OPTION
    if (isRemoveDepartment && !isSelectAllOffice) {
      // 3.1. Get different between old office and new office option array
      diffOffices = findDifferenceInObjects(
        oldOffice || [],
        values?.offices || [],
      )?.map((item) => item?.value as number);

      // 3.2. Filter user by different office
      const filterUserList = cloneUser?.filter(
        (user) =>
          !diffOffices?.includes(user?.office_id) || isNull(user?.office_id),
      );
      setValue("users", filterUserList);
    } else {
      // CASE 4: ADD MORE DEPARTMENT OPTION
      // 4.1. Check option ALL isSelected or not -> get all office list depend on it
      const currentOffice =
        values?.offices
          ?.map((item: SelectOption) => item?.value)
          .includes("all") ||
        values.offices?.length === officeRes?.offices?.length
          ? (officeRes?.offices || []).map((office) => ({
              value: office.id,
              label: office.name,
            }))
          : values?.offices;

      // 4.2. Find different between old office and new office option array
      const addMoreIds = (diffOffices = findDifferenceInObjects(
        oldOffice || [],
        currentOffice || [],
      )?.map((item) => item?.value as number));

      // 4.3. Get list user has new selected office option (not include duplicated user in users list)
      addMoreIds?.forEach((office: number) => {
        const valuesUsersIds = cloneUser?.map(
          (user: UserSelectOption) => user?.value,
        );
        const usersIds = (users || [])
          ?.filter((user: UserSelectOption) => user?.office_id === office)
          .map((item) => item?.value);
        // 4.4. find office_id in usersIds and not in valuesUsersIds
        const different = pullAll(usersIds, valuesUsersIds) || [];
        // 4.5. Push user in different list and not in tempAddUserArr to tempAddUserArr
        users?.forEach((user: UserSelectOption) => {
          if (
            different.includes(user?.value) &&
            tempAddUserArr.findIndex((item) => item?.value === user?.value) < 0
          ) {
            tempAddUserArr.push(user);
          }
        });
      });
      setValue("users", [...cloneUser, ...tempAddUserArr]);
    }
    setStartFilterByOffice(false);
  }, [startFilterByOffice]);

  useEffect(() => {
    if (!startFilterByDepartment) return;

    // CASE 1: RESET USER LIST WHEN UNSELECT ALL OFFICE AND DEPARTMENT
    if (isEmptyOffice && isEmptyDepartment) {
      setValue("users", []);
      setStartFilterByDepartment(false);
      return;
    }

    const currentDepartmentIds =
      values?.departments?.map((item: SelectOption) => item?.value) || [];
    // RESET USER LIST TO BE NOT DEPEND ON OFFICE FILTER
    const cloneUser = [
      ...((values?.users || [])?.filter((user: UserSelectOption) =>
        currentDepartmentIds.includes(user?.department_id),
      ) || []),
    ];

    let diffDepartmentIds: number[] = [];
    const tempAddUserArr: UserSelectOption[] = [];
    const isRemoveDepartment =
      oldDepartment?.length > values?.departments?.length;
    const isSelectAllDepartment =
      values?.departments
        ?.map((item: SelectOption) => item?.value)
        ?.includes("all") ||
      values.departments?.length === departmentRes?.departments?.length;

    // CASE 2: REMOVE DEPARTMENT OPTION
    if (isRemoveDepartment && !isSelectAllDepartment) {
      // CASE 2.1: REMOVE ALL DEPARTMENT OPTION
      if (
        values.departments?.length === 0 ||
        oldDepartment.map((item) => item?.value).includes("all")
      ) {
        const users = cloneUser?.filter((user) => user?.department_id === null);
        setValue("users", users);
        setStartFilterByDepartment(false);
        // TRIGGER FILTER BY OFFICE
        setOldOffice([]);
        setStartFilterByOffice(true);
        return;
      }

      // CASE 2.2: NORMAL REMOVE BY DEPARTMENT OPTION
      diffDepartmentIds = findDifferenceInObjects(
        oldDepartment || [],
        values?.departments || [],
      )?.map((item) => item?.value as number);

      const filterUserList = [...cloneUser]?.filter(
        (user) =>
          !diffDepartmentIds?.includes(user?.department_id) ||
          isNull(user?.department_id),
      );
      setValue("users", filterUserList);
    } else {
      // CASE 4: ADD MORE DEPARTMENT OPTION
      // SAME AS OFFICE 4.X CASES
      const currentDepartment =
        values?.departments
          ?.map((item: SelectOption) => item?.value)
          .includes("all") ||
        values.departments?.length === departmentRes?.departments?.length
          ? (departmentRes?.departments || []).map((department) => ({
              value: department.id,
              label: department.name,
            }))
          : values?.departments;

      const addMoreIds = (diffDepartmentIds = findDifferenceInObjects(
        oldDepartment || [],
        currentDepartment || [],
      )?.map((item) => item?.value as number));

      addMoreIds?.forEach((dep: number) => {
        const valuesUsersIds = cloneUser?.map(
          (user: UserSelectOption) => user?.value,
        );
        const usersIds = (users || [])
          ?.filter((user: UserSelectOption) => user?.department_id === dep)
          .map((item) => item?.value);
        const different = pullAll(usersIds, valuesUsersIds) || [];
        users?.forEach((user: UserSelectOption) => {
          if (
            different.includes(user?.value) &&
            tempAddUserArr.findIndex((item) => item?.value === user?.value) < 0
          ) {
            tempAddUserArr.push(user);
          }
        });
      });
      setValue("users", [...cloneUser, ...tempAddUserArr]);
    }

    setStartFilterByDepartment(false);
  }, [startFilterByDepartment]);

  return (
    <>
      {contentError && (
        <p className="w-full mb-3 text-error--main input-error__message is-invalid">
          {contentError}
        </p>
      )}
      <h6 className="font-black mb-3">基本情報</h6>
      <div className="shadow-card bg-white rounded-[20px] flex flex-col p-6 gap-y-6">
        <ControlledInput
          control={control}
          formField="title"
          name="title"
          label="タイトル"
          isTrim={true}
          isRequired={true}
          maxLength={MAX_LENGTH.VARCHAR}
          placeholder="タイトルを入力してください"
          errorMessage={errors?.title?.message}
        />
        <ControlledSelect
          label="カテゴリ"
          isClearable={true}
          control={control}
          formField="category"
          options={categories as CategorySelectOption[]}
          unsetMenuPortalTarget
          className="w-[400px]"
          placeholder="カテゴリを選択してください"
          errorMessage={errors?.category?.message}
        />
        <ControlledSelect
          label="タグ"
          isSearchable={true}
          options={tags}
          isMulti={true}
          isHideIconDrop={true}
          control={control}
          unsetMenuPortalTarget
          filterOption={customTagFilter}
          formField="tags"
          className="w-[400px]"
          placeholder="タグを入力して選択してください"
          errorMessage={errors?.tags?.message}
        />
        <Box sx={{ "& .custom-date-picker": { color: "inherit" } }}>
          <ControlledDatePicker
            label="受講期日"
            control={control}
            formField="deadline_date"
            className="w-[400px]"
            errorMessage={errors?.deadline_date?.message}
          />
        </Box>
        <ControlledRadio
          isRequired
          options={[
            { label: "公開", value: 1 },
            { label: "非公開", value: 0 },
          ]}
          control={control}
          formField="is_public"
          label="公開設定"
          errorMessage={errors?.is_public?.message}
        />
        <ControlledCheckbox
          options={[{ label: "必須受講にする", value: 1 }]}
          control={control}
          formField="required"
          label="必須受講"
          errorMessage={errors?.required?.message}
        />
        <div className="flex flex-col gap-y-4">
          <label className="subtitle2 text-[#637381]">対象者</label>
          <div className="flex flex-row gap-x-4">
            <ControlledSelect
              label="支社"
              control={control}
              formField="offices"
              options={offices}
              isMulti={true}
              unsetMenuPortalTarget
              className="w-[400px] max-w-[50%]"
              placeholder="支社を選択してください"
              errorMessage={errors?.offices?.message as string}
              onChange={(e: SelectOption[]) => {
                handleSelectAll(e, "offices" as TCreateElearningForm);
                setStartFilterByOffice(true);
              }}
              onChangeCallback={(_, oldValue) => {
                // WHEN DEPARTMENT IS SELECTED, RESET OLD OFFICE TO TRIGGER FILTER WHEN DEPARTMENT IS UNSELECTED
                if ((values?.departments || []).length > 0) {
                  setOldOffice([]);
                } else {
                  setOldOffice(oldValue || []);
                }
              }}
            />
            <ControlledSelect
              label="事業部"
              control={control}
              formField="departments"
              options={departments}
              isMulti={true}
              unsetMenuPortalTarget
              className="w-[400px] max-w-[50%]"
              placeholder="事業部を選択してください"
              errorMessage={errors?.departments?.message as string}
              onChange={(e: SelectOption[]) => {
                handleSelectAll(e, "departments" as TCreateElearningForm);
                setStartFilterByDepartment(true);
              }}
              onChangeCallback={(_, e) => {
                setOldDepartment(e || []);
              }}
            />
          </div>
          <ControlledSelect
            label="対象ユーザー"
            isMulti={true}
            isSearchable={true}
            isHideIconDrop={true}
            isDisabled={isFetching}
            filterOption={customUserFilter}
            control={control}
            formField="users"
            options={users}
            unsetMenuPortalTarget
            placeholder="対象ユーザーを入力して選択してください"
            errorMessage={errors?.users?.message as string}
            onChange={(e: SelectOption[]) => {
              if (e?.map((item) => item?.value)?.includes("all")) {
                setValue(
                  "users",
                  users?.filter((item) => item?.value !== "all"),
                );
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
