import { regexValidate } from "@/configs/regex";
import { getFileExtension } from ".";

const isPageWithoutAuth = (isAdmin = true) => {
  const path = location.pathname;
  if (!isAdmin) return regexValidate.FRONT_PATH_NO_AUTH.test(path);
  return regexValidate.ADMIN_PATH_NO_AUTH.test(path);
};

const isLoginPage = () => {
  const path = location.pathname;
  return path.startsWith("/admin/login") || path.startsWith("/login");
};

const isAdminLoginPage = () => {
  const path = location.pathname;
  return path.startsWith("/admin/login");
};

const isErrorPage = () => {
  const path = location.pathname;
  return regexValidate.ERROR_PAGE_PATH.test(path);
};

const isNumeric = (str?: string | null) => {
  if (str === null || str === undefined) return false;
  return !isNaN(Number(str));
};

const isValidMimeTypeVideo = (file: File) => {
  if (file.type) {
    return [
      "video/mp4",
      "video/mpeg",
      "video/x-flv",
      "video/flv",
      "video/quicktime",
    ].includes(file.type);
  }
  const ext = getFileExtension(file.name);
  return ["mp4", "mpeg", "flv", "quicktime"].includes(ext);
};

const isValidMimeTypePDF = (file: File) => {
  if (file.type) {
    return ["application/pdf"].includes(file.type);
  }
  const ext = getFileExtension(file.name);
  return [".pdf"].includes(ext);
};

const isMacOrIOS = () => {
  const userAgent = navigator.userAgent;
  return /Mac|iPhone|iPad|iPod/.test(userAgent);
};

const isObjectURL = (value: string | undefined | null) => {
  return value?.startsWith("blob:");
};

export {
  isPageWithoutAuth,
  isAdminLoginPage,
  isErrorPage,
  isLoginPage,
  isNumeric,
  isValidMimeTypeVideo,
  isValidMimeTypePDF,
  isMacOrIOS,
  isObjectURL,
};
