import { Box, Skeleton } from "@chakra-ui/react";

export default function SidebarSkeleton() {
  return (
    <>
      <div className="w-full h-[1px] bg-[var(--border-primary)] my-7"></div>
      <div className="flex flex-col gap-y-2 flex-1">
        {Array.from({ length: 6 }).map((_, i) => (
          <Skeleton height="52px" width="248px" key={i} rounded={8} />
        ))}
      </div>

      <Box
        as="button"
        marginTop={"28px"}
        className="body2"
        width={"fit-content"}
        color={"var(--error-main-color)"}
      >
        <Skeleton height="32px" width="102px" rounded={4} />
      </Box>
    </>
  );
}
