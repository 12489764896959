import ArrowLeftIcon from "@/assets/icon/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import AnimatedRoute from "@/router/transition.route";
import FormSetPasswordUser from "@/components/organisms/FormSetPasswordUser";

function SetPasswordUserPage() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="py-[80px] flex w-full items-center flex-col px-[20px]">
      <div className="flex mb-[16px] justify-center w-full relative">
        <div className="absolute left-0 cursor-pointer" onClick={goBack}>
          <ArrowLeftIcon />
        </div>
        <h4 className="font-[900] text-center">パスワード再設定</h4>
      </div>

      <div className="mt-[48px] w-full flex justify-center">
        <FormSetPasswordUser />
      </div>
    </div>
  );
}
const AnimatedSetPasswordUserPage = AnimatedRoute(SetPasswordUserPage);
export default AnimatedSetPasswordUserPage;
