import {
  MutateOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CreateFavoriteRequestBody,
  CreateFavoriteResponseBody,
  DeleteFavoriteRequestBody,
  DeleteFavoriteResponseBody,
  FilterFavoriteResponseBody,
  ListFavoriteQueryKey,
  createFavoriteApi,
  deleteFavoriteApi,
  listFavoriteApi,
} from "./request";
import { FAVORITE_TYPE_ENUM } from "@/configs/enum";

export const useCreateFavoriteMutation = (
  favoriteable_type: FAVORITE_TYPE_ENUM,
  favoriteable_id: number,
  options: MutateOptions<
    CreateFavoriteResponseBody,
    unknown,
    CreateFavoriteRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: (body) =>
        createFavoriteApi(favoriteable_type, favoriteable_id, body),
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useDeleteFavoriteMutation = (
  favoriteable_type: FAVORITE_TYPE_ENUM,
  favoriteable_id: number,
  options: MutateOptions<
    DeleteFavoriteResponseBody,
    unknown,
    DeleteFavoriteRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: (body) =>
        deleteFavoriteApi(favoriteable_type, favoriteable_id, body.id),
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useFavoriteListQuery = (
  type: FAVORITE_TYPE_ENUM,
  id: number,
  options: Omit<
    UseQueryOptions<
      FilterFavoriteResponseBody,
      unknown, // TError,
      FilterFavoriteResponseBody,
      ListFavoriteQueryKey
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-favorite", type, `${id}`],
    queryFn: listFavoriteApi,
    ...options,
  });
};
