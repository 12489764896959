import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import CreateElearningForm from "@/components/organisms/ElearningCreate";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { ROLE_ENUM } from "@/configs/enum";
import useRole from "@/hooks/role";
import AnimatedRoute from "@/router/transition.route";

const breadcrumbs: BeardcrumbOption[] = [
  {
    label: "Eラーニング一覧",
    route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST,
  },
  {
    label: "新規作成",
  },
];

function CreateELearningPage() {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);
  return (
    <div className="max-w-[100%] m-auto my-6">
      <Breadcrumb options={breadcrumbs} />
      <h4 className="mt-3 font-black">新規作成</h4>
      <div className="mt-8">
        <CreateElearningForm />
      </div>
    </div>
  );
}

const AnimatedCreateELearningPage = AnimatedRoute(CreateELearningPage);
export default AnimatedCreateELearningPage;
