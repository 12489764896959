import {
  MutateOptions,
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DeleteCategoryRequestBody,
  DeleteCategoryResponseBody,
  FilterCategoryRequestBody,
  FilterCategoryResponseBody,
  FrontFilterCategoryRequestBody,
  FrontFilterCategoryResponseBody,
  UpdatePositionCategoryRequestBody,
  UpdatePositionCategoryResponseBody,
  UpsertCategoryRequestBody,
  UpsertCategoryResponseBody,
  deleteCategoryApi,
  frontListCategoryApi,
  listCategoryApi,
  updatePositionCategoryApi,
  upsertCategoryApi,
} from "./request";

export const useListCategoryQuery = (
  params: Partial<FilterCategoryRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterCategoryResponseBody,
      unknown, // TError,
      FilterCategoryResponseBody,
      (string | Partial<FilterCategoryRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-categories", params],
    queryFn: listCategoryApi,
    enabled: !!params,
    ...options,
  });
};

export const useFrontListCategoryQuery = (
  params: Partial<FrontFilterCategoryRequestBody>,
  options: Omit<
    UseQueryOptions<
      FrontFilterCategoryResponseBody,
      unknown, // TError,
      FrontFilterCategoryResponseBody,
      (string | Partial<FrontFilterCategoryRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["front-filter-category", params],
    queryFn: frontListCategoryApi,
    enabled: !!params,
    ...options,
  });
};

export const fetchListCategory = async (
  queryClient: QueryClient,
  params?: Partial<FilterCategoryRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["filter-categories", params],
    queryFn: listCategoryApi,
  });
};

export const useUpsertCategoryMutation = (
  options: MutateOptions<
    UpsertCategoryResponseBody,
    unknown,
    UpsertCategoryRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: upsertCategoryApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useUpdatePositionCategoryMutation = (
  options: MutateOptions<
    UpdatePositionCategoryResponseBody,
    unknown,
    UpdatePositionCategoryRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: updatePositionCategoryApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useDeleteCategoryMutation = (
  options: MutateOptions<
    DeleteCategoryResponseBody,
    unknown,
    DeleteCategoryRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: deleteCategoryApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};
