import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import AnimatedRoute from "@/router/transition.route";
import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import { TabItem, TabView } from "@/components/molecules/TabView/tab-view";
import ElearningContentDetail from "@/components/organisms/ElearningContentDetail";
import ElearningUserList from "@/components/organisms/ELearningManagement/detail/users-elearning";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import { useShowELearningQuery } from "@/api/e-learning";
import { Skeleton } from "@chakra-ui/react";
import { ELEARNING_CONTENT_KIND_ENUM } from "@/configs/enum";

/**
 * Renders the ELearning Detail Page component.
 *
 * This component displays a page for managing users. It includes a heading, a button for creating a new user, and a ELearningDetailForAdmin component.
 *
 * @returns {JSX.Element} The rendered ELearning Detail Page component.
 */
function ELearningDetailPage(): JSX.Element {
  // #region Data
  const params = useParams();
  const id = parseInt(params.id as string);

  const navigate = useNavigate();

  const [activeIndexTab, setActiveIndexTab] = useState(0);

  const { data: courseRes, isFetching } = useShowELearningQuery(id, {
    // * If course not exist then return to course list
    throwOnError() {
      navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST);
      return false;
    },
  });

  const course = useMemo(() => courseRes, [courseRes]);

  const breadcrumbs: BeardcrumbOption[] = useMemo(() => {
    return [
      {
        label: "Eラーニング一覧", //todo: replace name account
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST,
      },
      { label: course?.title },
    ];
  }, [course]);

  const totalScore = useMemo(() => {
    let max = 0;
    course?.course_contents.forEach((item) => {
      if (
        item.kind === ELEARNING_CONTENT_KIND_ENUM.QUIZ &&
        item.quiz?.point &&
        item.quiz.point > max
      ) {
        max = Number(item.quiz.point);
      }
    });
    return max;
  }, [course]);

  const listTab: TabItem[] = useMemo(() => {
    return [
      { label: "Eラーニングコンテンツ" },
      { label: `受講者一覧` },
      { label: `未受講者一覧` },
    ];
  }, []);
  // #endregion

  // #region Events
  const onChangeTabView = (index: number) => {
    setActiveIndexTab(index);
  };

  // #endregion

  return (
    <div className="flex w-full gap-6 flex-col mt-6">
      <div className="flex gap-3 flex-col">
        <div className="w-full">
          <Breadcrumb options={breadcrumbs} />
        </div>
        <h4 className="font-black">
          <CustomTooltip text={course?.title} />
        </h4>
      </div>
      <TabView
        listTab={listTab}
        activeIndex={activeIndexTab}
        onChange={onChangeTabView}
      />

      {isFetching ? (
        <div className="flex flex-col gap-y-6">
          <Skeleton height="810px" width="100%" />
          <Skeleton height="410px" width="100%" />
        </div>
      ) : (
        <div className="mb-12">
          {activeIndexTab === 0 && (
            <ElearningContentDetail id={id} course={course} />
          )}
          {activeIndexTab === 1 && (
            <ElearningUserList id={id} title="受講者一覧" kind="joined" />
          )}
          {activeIndexTab === 2 && (
            <ElearningUserList
              id={id}
              title="未受講者一覧"
              kind="not-join"
              totalScore={totalScore}
            />
          )}
        </div>
      )}
    </div>
  );
}
const AnimatedELearningDetailPage = AnimatedRoute(ELearningDetailPage);
export default AnimatedELearningDetailPage;
