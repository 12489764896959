import { Box } from "@chakra-ui/react";
import { MouseEventHandler, ReactNode, useMemo } from "react";
import Spinner from "../Spinner/spinner";
import "./button.scss";
import clsx from "clsx";
interface ButtonCustom {
  fontSize?: string;
  borderRadius?: string;
  fontWeight?: string;
  lineHeight?: string;
  padding?: string;
  bg?: string;
  borderColor?: string;
  color?: string;
  border?: string;
  borderStyle?: string;
  circleStyles?: string;
}

interface StyleButton {
  xs: ButtonCustom;
  xs2: ButtonCustom;
  sm: ButtonCustom;
  md: ButtonCustom;
  md2: ButtonCustom;
  primary: ButtonCustom;
  outline: ButtonCustom;
  info: ButtonCustom;
  "danger-outline": ButtonCustom;
}

interface ButtonProps {
  className?: string;
  children?: ReactNode;
  size?: "xs" | "xs2" | "sm" | "md" | "md2";
  isLoading?: boolean;
  isDisabled?: boolean;
  variant?: "primary" | "outline" | "info" | "danger-outline";
  type?: "button" | "submit" | "reset";
  onClick?:
    | MouseEventHandler<HTMLDivElement>
    | MouseEventHandler<HTMLButtonElement>;
  startSlot?: ReactNode | string;
  endSlot?: ReactNode | string;
  buttonCustom?: ButtonCustom;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}
export default function Button({
  children,
  type = "button",
  size = "sm",
  isLoading = false,
  isDisabled = false,
  variant = "primary",
  className,
  onClick,
  startSlot,
  endSlot,
  buttonCustom,
}: ButtonProps) {
  const _disabled = useMemo(
    () => isDisabled || isLoading,
    [isLoading, isDisabled],
  );

  const styleButton: StyleButton = {
    xs: {
      fontSize: buttonCustom?.fontSize ?? "12px",
      borderRadius: buttonCustom?.borderRadius ?? "8px",
      fontWeight: buttonCustom?.fontWeight ?? "400",
      lineHeight: buttonCustom?.lineHeight ?? "16px",
      padding: buttonCustom?.padding ?? "6px 12px",
      border: buttonCustom?.border ?? "1px",
    },
    xs2: {
      fontSize: buttonCustom?.fontSize ?? "14px",
      borderRadius: buttonCustom?.borderRadius ?? "8px",
      fontWeight: buttonCustom?.fontWeight ?? "400",
      lineHeight: buttonCustom?.lineHeight ?? "24px",
      padding: buttonCustom?.padding ?? "8px 16px",
      border: buttonCustom?.border ?? "1px",
    },
    sm: {
      fontSize: buttonCustom?.fontSize ?? "16px",
      borderRadius: buttonCustom?.borderRadius ?? "24px",
      fontWeight: buttonCustom?.fontWeight ?? "700",
      lineHeight: buttonCustom?.lineHeight ?? "24px",
      padding: buttonCustom?.padding ?? "11px 16px",
      border: buttonCustom?.border ?? "1px",
    },
    md: {
      fontSize: buttonCustom?.fontSize ?? "16px",
      borderRadius: buttonCustom?.borderRadius ?? "24px",
      fontWeight: buttonCustom?.fontWeight ?? "900",
      lineHeight: buttonCustom?.lineHeight ?? "28px",
      padding: buttonCustom?.padding ?? "11px 18px",
      border: buttonCustom?.border ?? "1px",
    },
    md2: {
      fontSize: buttonCustom?.fontSize ?? "18px",
      borderRadius: buttonCustom?.borderRadius ?? "32px",
      fontWeight: buttonCustom?.fontWeight ?? "900",
      lineHeight: buttonCustom?.lineHeight ?? "28px",
      padding: buttonCustom?.padding ?? "18px 20px",
      border: buttonCustom?.border ?? "1px",
    },
    primary: {
      bg: isDisabled
        ? "var(--bg-disabled)"
        : buttonCustom?.bg ?? "var(--primary-main-color)",
      borderColor: isDisabled
        ? "var(--bg-disabled)"
        : buttonCustom?.borderColor ?? "var(--primary-main-color)",
      borderStyle: buttonCustom?.borderStyle ?? "none",
      color: isDisabled
        ? "var(--text-disabled)"
        : buttonCustom?.color ?? "var(--text-white)",
      circleStyles: isDisabled
        ? "var(--text-disabled)"
        : buttonCustom?.circleStyles ?? "#fff",
    },
    outline: {
      bg: buttonCustom?.bg ?? "var(--text-white)",
      borderColor: buttonCustom?.borderColor ?? "var(--primary-main-color)",
      borderStyle: "solid",
      color: buttonCustom?.color ?? "var(--primary-main-color)",
      circleStyles: buttonCustom?.circleStyles ?? "var(--primary-main-color)",
    },
    info: {
      bg: isDisabled ? "var(--bg-disabled)" : "#fff",
      borderColor: isDisabled ? "var(--bg-disabled)" : "var(--border-primary)",
      borderStyle: "solid",
      color: isDisabled ? "var(--text-disabled)" : "var(--text-primary)",
      circleStyles: isDisabled ? "var(--text-disabled)" : "var(--text-primary)",
    },
    "danger-outline": {
      bg: isDisabled ? "var(--bg-disabled)" : "#fff",
      borderColor: isDisabled ? "var(--bg-disabled)" : "var(--border-primary)",
      borderStyle: "solid",
      color: isDisabled ? "var(--text-disabled)" : "var(--error-main-color)",
      circleStyles: isDisabled
        ? "var(--text-disabled)"
        : "var(--error-main-color)",
    },
  };

  return (
    <Box
      className={clsx(
        "flex justify-center items-center",
        className,
        `btn__${variant}`,
      )}
      as="button"
      type={type}
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      border={styleButton[size].border}
      borderRadius={styleButton[size].borderRadius}
      fontSize={styleButton[size].fontSize}
      fontWeight={styleButton[size].fontWeight}
      lineHeight={styleButton[size].lineHeight}
      bg={styleButton[variant].bg}
      borderColor={styleButton[variant].borderColor}
      borderStyle={styleButton[variant].borderStyle}
      color={styleButton[variant].color}
      padding={styleButton[size].padding}
      cursor={isDisabled || isLoading ? "not-allowed" : "pointer"}
      opacity={isLoading ? 0.4 : 1}
      outline={"none"}
      _hover={{
        opacity: isLoading ? 0.4 : 0.8,
      }}
      disabled={_disabled}
      // _active={{
      //   bg: "#dddfe2",
      //   transform: "scale(0.98)",
      //   borderColor: "#bec3c9",
      // }}
      // _focus={{
      //   boxShadow:
      //     "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
      // }}
      onClickCapture={(e: any) => {
        if (_disabled) return e.preventDefault();
        onClick?.(e);
      }}
    >
      {isLoading ? (
        <Spinner
          styles={{
            marginRight: 8,
            width: size === "md" ? 32 : 24,
            height: size === "md" ? 32 : 24,
          }}
          circleStyles={{
            borderColor: `${styleButton[variant].circleStyles} transparent transparent transparent`,
            width: size === "md" ? 28 : 18,
            height: size === "md" ? 28 : 18,
            borderWidth: size === "md" ? 3 : 2,
          }}
        />
      ) : startSlot ? (
        <div className="inline-flex mr-[8px]">{startSlot}</div>
      ) : (
        <></>
      )}
      {typeof children === "string" ? <span>{children}</span> : children}
      {endSlot ? <div className="inline-flex ml-[8px]">{endSlot}</div> : <></>}
    </Box>
  );
}
