import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import Button from "@/components/atoms/Button/button";
import UploadAvatar from "@/components/atoms/UploadAvatar/upload-avatar";
import { useEffect, useState } from "react";
import { ISetBasicInfoForm, useSetBasicInfoForm } from "./validation";
import { UploadResponse } from "@/hooks/upload";
import { useUpdateInfoMutation } from "@/api/auth";
import { handleApiError, mapAPIError } from "@/hooks/error";
import { useGlobalStore } from "@/states/global.state";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONSTANT } from "@/configs/constants";
import useToast from "@/hooks/toast";
import { useAuthStore } from "@/states/auth.state";
import { getLinkMedia } from "@/shared/get";

export default function FormChangeInfor() {
  const [uploadErr, setUpErr] = useState<string>("");
  const { userInfo } = useAuthStore();
  const { loading, setLoading } = useGlobalStore();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    reset,
  } = useSetBasicInfoForm();
  const updateInfoMutation = useUpdateInfoMutation();
  const onSubmit = async (datas: ISetBasicInfoForm) => {
    setUpErr("");
    try {
      setLoading(true);
      await updateInfoMutation.mutateAsync({
        users: datas,
      });
      showToast({
        title: `ユーザー情報の変更が完了しました`,
        type: "success",
        site: "front",
      });
      navigate(ROUTES_CONSTANT.MY_PAGE);
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError);
    } finally {
      setLoading(false);
    }
  };
  const onUpload = async (content: UploadResponse | null) => {
    if (!content) return;
    setValue("avatar_attributes", {
      key: content.key,
      name: content.name,
      content_type: content.content_type,
    });
  };

  useEffect(() => {
    reset({
      avatar_attributes: null,
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
    });
  }, [userInfo]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit, () => {
        setUpErr("");
      })}
      className="flex flex-col justify-center whitespace-nowrap bg-white rounded-3xl w-full max-w-[960px] desktop:p-8 mobile:p-8"
    >
      <div className="flex desktop:gap-x-8 mobile:gap-y-3 mobile:flex-col desktop:flex-row mobile:items-center desktop:items-start">
        <UploadAvatar
          onChange={onUpload}
          onError={(msg) => setUpErr(msg)}
          imageUrl={getLinkMedia(userInfo?.avatar?.key)}
        />
        <div className="flex flex-col gap-4 flex-1 mobile:w-full">
          {uploadErr.length > 0 ? (
            <div className="w-full text-error--main input-error__message">
              {uploadErr}
            </div>
          ) : (
            <></>
          )}
          <ControlledInput
            control={control}
            formField="first_name"
            label="姓"
            isRequired={true}
            isTrim={true}
            maxLength={126}
            placeholder="佐藤"
            errorMessage={errors.first_name?.message}
          />
          <ControlledInput
            control={control}
            formField="last_name"
            label="名"
            isTrim={true}
            maxLength={126}
            isRequired={true}
            placeholder="太郎"
            errorMessage={errors.last_name?.message}
          />
        </div>
      </div>
      <Button
        size="sm"
        className={"mt-12 max-w-[335px] w-full m-auto"}
        type="submit"
        isLoading={loading.open}
      >
        設定する
      </Button>
    </form>
  );
}
