import Button from "@/components/atoms/Button/button";
import AuthLayout from "@/pages/_layouts/auth";
import CheckIcon from "@/assets/icon/check.svg";
import { useCallback } from "react";
import { ROUTES_CONSTANT } from "@/configs/constants";
import { useNavigate } from "react-router-dom";
import useScreenSize from "@/hooks/screen";
export default function FrontSetPasswordSuccessPage() {
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();

  const goToLogin = useCallback(() => {
    navigate(ROUTES_CONSTANT.LOGIN);
  }, []);
  return (
    <AuthLayout>
      <div className="mobile:py-[48px] desktop:py-[80px] flex w-full items-center flex-col px-[20px]">
        {isMobile ? (
          <h5 className="font-[900] text-center">
            パスワードの再設定が完了しました
          </h5>
        ) : (
          <h4 className="font-[900] text-center">
            パスワードの再設定が完了しました
          </h4>
        )}

        <div className="flex flex-col justify-center mobile:py-8 mobile:px-4 desktop:p-8 whitespace-nowrap bg-white rounded-3xl w-full max-w-[572px] mt-[48px]">
          <div className="flex flex-col justify-center max-md:max-w-full">
            <div className="flex justify-center items-center px-16 max-md:px-5 max-md:max-w-full">
              <div className="flex justify-center items-center px-5 bg-lime-50 h-[88px] rounded-[44px] w-[88px]">
                <CheckIcon />
              </div>
            </div>
            <p className="body1 mt-5 text-black-custom text-center whitespace-pre-line">
              パスワードの更新が完了しました。
              <br />
              ログインページから再度ログインしてください。
            </p>
            <div className="w-full h-[1px] bg-[var(--border-primary)] my-5"></div>
            <Button
              size="sm"
              className={"max-w-[335px] w-full m-auto"}
              type="button"
              onClick={goToLogin}
            >
              ログインページへ
            </Button>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
