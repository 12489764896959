import { QueryFunctionContext } from "@tanstack/react-query";
import omit from "lodash/omit";

import { request } from "../axios";
import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";
import { Compliance, CompliancePayload, OrderDirection, Paging } from "../type";

export type CreateComplianceRequestBody = CompliancePayload;

export type CreateComplianceResponseBody = { id: string | number };

export type UpdateComplianceRequestBody = CompliancePayload & { id: number };

export type UpdateComplianceResponseBody = { id: string | number };

export type ShowComplianceResponseBody = Compliance;

export type FilterComplianceRequestBody = {
  page: number;
  per_page: number;
  order_by?: string | null;
  order_direction?: OrderDirection;
  title?: string;
  is_public?: boolean;
};

export type FilterComplianceResponseBody = {
  compliances: Compliance[];
  paging: Paging;
};
export type DeleteComplianceResponseBody = {
  id: string;
};

export const createComplianceApi = (
  data: CreateComplianceRequestBody,
): Promise<CreateComplianceResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.CREATE_COMPLIANCE),
    data: { compliance: data },
  });
};

export const updateComplianceApi = (
  data: UpdateComplianceRequestBody,
): Promise<UpdateComplianceResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.UPDATE_COMPLIANCE, {
      id: data.id,
    }),
    data: {
      compliance: omit(data, ["id"]),
    },
  });
};

export const showComplianceApi = (
  context: QueryFunctionContext,
): Promise<ShowComplianceResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.SHOW_COMPLIANCE, {
      id: context.queryKey[1],
    }),
  });
};

export const listComplicateApi = (
  context: QueryFunctionContext,
): Promise<FilterComplianceResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_COMPLIANCE),
    params: context.queryKey[1],
  });
};

export const listComplianceApi = (
  context: QueryFunctionContext,
): Promise<FilterComplianceResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_COMPLIANCE),
    params: context.queryKey[1],
  });
};

export const deleteComplianceApi = (
  id: number,
): Promise<DeleteComplianceResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.DELETE_COMPLIANCE, { id }),
  });
};
