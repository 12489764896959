import { QueryFunctionContext } from "@tanstack/react-query";
import {
  Paging,
  UpsertCategoryPayload,
  Category,
  OrderDirection,
  UpdatePositionCategoryPayload,
  TUserCategory,
} from "../type";
import { request } from "../axios";
import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";

export type FilterCategoryRequestBody = {
  page: number;
  per_page: number;
  for_admin: boolean;
  order_by?: string;
  order_direction?: OrderDirection;
};

export type FilterCategoryResponseBody = {
  categories: Category[];
  paging: Paging;
};

export const listCategoryApi = (
  context: QueryFunctionContext,
): Promise<FilterCategoryResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_CATEGORY),
    params: context.queryKey[1],
  });
};

export type FrontFilterCategoryRequestBody = {
  is_parent: boolean;
  order_by: null | "updated_at" | "created_at";
  order_direction: OrderDirection;
};

export type FrontFilterCategoryResponseBody = {
  categories: TUserCategory[];
};

export const frontListCategoryApi = (
  context: QueryFunctionContext,
): Promise<FrontFilterCategoryResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.FRONT_LIST_CATEGORY),
    params: context.queryKey[1],
  });
};

export type UpsertCategoryRequestBody = {
  id?: number;
  categories: UpsertCategoryPayload;
};

export type UpsertCategoryResponseBody = {
  id: number;
};

export const upsertCategoryApi = (
  data: UpsertCategoryRequestBody,
): Promise<UpsertCategoryResponseBody> => {
  return request({
    method: data.id ? "PATCH" : "POST",
    url: data.id
      ? getRoute(API_PATH_CONSTANT.UPDATE_CATEGORY, { id: data.id })
      : API_PATH_CONSTANT.CREATE_CATEGORY,
    data: {
      categories: data.categories,
    },
  });
};

export type UpdatePositionCategoryRequestBody = {
  categories: UpdatePositionCategoryPayload;
};

export type UpdatePositionCategoryResponseBody = {
  id: number;
};

export const updatePositionCategoryApi = (
  data: UpdatePositionCategoryRequestBody,
): Promise<UpdatePositionCategoryResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.UPDATE_POSITION_CATEGORY),
    data: {
      categories: data.categories,
    },
  });
};

export type DeleteCategoryRequestBody = {
  id: number;
};

export type DeleteCategoryResponseBody = {
  id: number;
};

export const deleteCategoryApi = (
  data: DeleteCategoryRequestBody,
): Promise<DeleteCategoryResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.DELETE_CATEGORY, { id: data.id }),
  });
};
