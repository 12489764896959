export const regexValidate = {
  JAPANESE_SPECIAL_CHAR: /[\u3040-\u30FF\uFF65-\uFF9F]/,
  VALID_EMAIL:
    /^[A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]+(?:\.[A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/,
  IS_LOGIN_PAGE: /^(\/login|\/admin\/login)/,
  ADMIN_PATH_NO_AUTH:
    /^\/admin\/(forgot-password|setting-password|setting-account).*/,
  FRONT_PATH_NO_AUTH: /^\/(forgot-password|setting-password|setting-account).*/,
  ERROR_PAGE_PATH: /^(\/admin|)\/(403|404|500)/,
  VALID_PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~‘’“”])[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~‘’“”]{10,}$/,
  SPECIAL_CHAR: /[!@#$%^&*()_+{}\\[\]:;'"<>,.?/\\|~-]/,
  ALPHA_NUMERIC:
    /^[ぁ-んァ-ン一-龯ーｦ-ﾝｰA-Za-z0-9Ａ-Ｚａ-ｚ０-９\u0020\u3000]+$/,
  // ALPHA_NUMERIC:
  // /^[ぁ-んァ-ン一-龯A-Za-z0-9Ａ-Ｚａ-ｚ０-９ｦ-ﾟｧ-ﾝﾞﾟ\u0020\u3000]+$/,
  EMOJI:
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g,
} as const;
