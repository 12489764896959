import InfiniteScroll from "react-infinite-scroll-component";

import { ELearningItem } from "@/components/molecules/ELearningItem/e-learning-item";

import "./e-learning-list.scss";
import { MESSAGES } from "@/shared/validation/message";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { ADMIN_ROUTES_CONSTANT, ROUTES_CONSTANT } from "@/configs/constants";
import { getRoute } from "@/shared/get/route";

/** Represents the data structure for an e-learning list*/

interface ELearningItemProps {
  id: number;
  isError?: boolean;
  className?: string;
  titleName?: string;
  timeLearning?: string | null;
  dateLearning?: string | null;
  description?: string;
  type?: string;
  required?: boolean;
}

/** Properties for the ELearningItem component*/
interface ELearningListProps {
  idScroll?: string;
  data: ELearningItemProps[];
  title?: string;
  onLoadMore?: () => void;
  hasMore?: boolean;
  scrollableTarget?: string;
  height?: string;
  allowGoDetailByClick?: boolean;
  fromMyPage?: boolean;
}

/**
 * ELearningList is a component that displays information about a list e-learning.
 * @param props - Properties of e-Learning list
 * @returns A JSX element that displays information about a list e-learning.
 */
export const ELearningList = ({
  data,
  title,
  scrollableTarget,
  height = "auto",
  idScroll = "scrollableDiv",
  allowGoDetailByClick = true,
  fromMyPage = false,
  ...props
}: ELearningListProps) => {
  const location = useLocation();

  const navigate = useNavigate();
  const isAdminPage = useMemo(() => {
    return location.pathname.startsWith("/admin");
  }, [location.pathname]);

  return (
    <div className="flex flex-col gap-4">
      {title && <h5 className="font-black">{title}</h5>}
      <div id={idScroll} style={{ height: height, overflow: "auto" }}>
        {data.length > 0 ? (
          <InfiniteScroll
            dataLength={data.length}
            next={() => props.onLoadMore?.()}
            hasMore={props.hasMore ?? false}
            loader={null}
            endMessage={null}
            hasChildren={true}
            scrollableTarget={scrollableTarget}
            className="flex gap-2 flex-col"
          >
            {data?.map((item, index) => {
              return (
                <ELearningItem
                  data={{
                    ...item,
                    className: allowGoDetailByClick ? " cursor-pointer" : "",
                  }}
                  key={index}
                  onClick={() => {
                    if (!allowGoDetailByClick) return;
                    navigate(
                      getRoute(
                        isAdminPage
                          ? ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_DETAIL
                          : fromMyPage
                          ? ROUTES_CONSTANT.MYPAGE_ELEARNING_DETAIL
                          : ROUTES_CONSTANT.ELEARNING_DETAIL,
                        {
                          id: item.id,
                        },
                      ),
                    );
                  }}
                />
              );
            })}
          </InfiniteScroll>
        ) : (
          <p className="w-full rounded-xl gap-2 p-4 border-primary bg-white text-center subtitle2">
            {MESSAGES.MSG_010}
          </p>
        )}
      </div>
    </div>
  );
};

ELearningList.defaultProps = {
  show: 4,
  infinity: false,
};
