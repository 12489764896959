import {
  Box,
  Breadcrumb as BreadcrumbCharkra,
  BreadcrumbItem,
  BreadcrumbLink,
  Tooltip,
} from "@chakra-ui/react";
import ArrowRightIcon from "@/assets/icon/arrow-right-breadcrumb.svg";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ReactNode, useMemo } from "react";
import clsx from "clsx";
import CustomTooltip from "../Tooltip/tooltip";
import useScreenSize from "@/hooks/screen";

export interface BeardcrumbOption {
  route?: string;
  label: string | ReactNode;
}
interface BreadcrumbProps {
  options: BeardcrumbOption[];
}

export default function Breadcrumb({ options }: BreadcrumbProps) {
  const MAX_CONTENT = 100;
  const { screenWidth } = useScreenSize();

  const breadcrumbs = useMemo(() => {
    return options.map((option) => ({ id: uuidv4(), ...option }));
  }, [options]);

  const maxWidth = useMemo(() => {
    return screenWidth > 800 ? 800 : screenWidth - 20;
  }, [screenWidth]);

  const max = useMemo(() => {
    if (breadcrumbs.length === 3) return 50;
    if (breadcrumbs.length >= 4) return 30;
    return MAX_CONTENT;
  }, [breadcrumbs.length]);

  return (
    <BreadcrumbCharkra separator={<ArrowRightIcon />} spacing={"6px"}>
      {breadcrumbs.map((breadcrumb, index) => (
        <BreadcrumbItem key={breadcrumb.id}>
          {index !== breadcrumbs.length - 1 ? (
            <BreadcrumbLink
              as={Link}
              to={breadcrumb.route}
              className={clsx(
                "desktop:text-xs mobile:text-xs font-normal break-all whitespace-nowrap",
                "!text-[var(--text-secondary)] breadcrumb-underline",
              )}
            >
              {typeof breadcrumb.label === "string" ? (
                <CustomTooltip text={breadcrumb.label} max={max} />
              ) : (
                breadcrumb.label
              )}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink
              as={Box}
              className="desktop:text-xs mobile:text-xs font-normal break-all !text-[var(--primary-main-color)] !cursor-default !no-underline"
            >
              {typeof breadcrumb.label === "string" ? (
                breadcrumb.label.length > max ? (
                  <Tooltip
                    hasArrow
                    label={breadcrumb.label}
                    bg="var(--primary-lighter-color)"
                    color="black"
                    borderRadius={8}
                    minWidth={280}
                    maxWidth={maxWidth}
                    maxHeight={500}
                  >
                    {breadcrumb.label.substring(0, max) + "⋯"}
                  </Tooltip>
                ) : (
                  breadcrumb.label
                )
              ) : (
                breadcrumb.label
              )}
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
      ))}
    </BreadcrumbCharkra>
  );
}
