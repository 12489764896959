import yup from "@/shared/validation/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

export interface ISetBasicInfoForm {
  avatar_attributes: {
    key: string;
    name: string;
    content_type: string;
  } | null;
  first_name: string;
  last_name: string;
}
export const useSetBasicInfoForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        avatar_attributes: yup.object().nullable(),
        first_name: yup
          .string()
          .label("set_account.first_name")
          .max(126)
          .noEmoji()
          .required(),
        last_name: yup
          .string()
          .label("set_account.last_name")
          .max(126)
          .noEmoji()
          .required(),
      }),
    [],
  );

  return useForm<ISetBasicInfoForm>({
    resolver: yupResolver(validationScheme) as any,
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });
};
