import { ReactNode, useMemo } from "react";
import {
  UseFormClearErrors,
  UseFormGetValues,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/shared/validation/yup";
import dayjs from "dayjs";
import { Control, FieldErrors } from "react-hook-form";
import { ELEARNING_CONTENT_TYPE, MAX_LENGTH } from "@/configs/constants";
import { UploadResponse } from "@/hooks/upload";
import { isNull } from "lodash";

export interface SelectOption {
  value: number | string;
  label: string;
  id?: number;
  _destroy?: boolean;
}

export interface CategorySelectOption extends SelectOption {
  parent_category_id: number | null;
  sub_category_id: number | null;
}

export interface UserSelectOption {
  id?: number;
  value: number | string;
  office_id: number | null;
  department_id: number | null;
  label: string | ReactNode;
  custom_field?: string;
}

export interface ICreateELearningForm {
  title: string;
  is_public: number;
  deadline_date: string | Date | null;
  category: CategorySelectOption;
  offices?: SelectOption[];
  users?: UserSelectOption[];
  departments?: SelectOption[];
  tags: SelectOption[];
  required: number[];
  // parent_category_id: number;
  // sub_category_id: number;
  // taggables_attributes: string;
  content: {
    type?: SelectOption | null;
    // type: video + document
    option: number;
    // type: document
    document_id?: SelectOption | null;
    // document_file_id?: string;
    attachment_attributes?: UploadResponse | null;
    // type: video
    youtube_id: string | null;
    youtube_file_name: string | null;
    video_id?: SelectOption | null;
    // video_file_id?: string;
    // type: test
    test_id?: SelectOption | null;
    // type: role_playing
    role_playing_title?: string | null;
    // type: compliance_training
    compliance_training_id?: SelectOption | null;
    id?: number | string;
    _destroy?: boolean;
    child_id?: string;
  }[];
  deleted_content_ids?: any[];
}

export interface IElearningChildFormProps {
  control: Control<ICreateELearningForm, any>;
  errors: FieldErrors<ICreateELearningForm>;
  values: any;
  setValue: UseFormSetValue<ICreateELearningForm>;
  reset?: UseFormReset<ICreateELearningForm>;
  setError?: UseFormSetError<ICreateELearningForm>;
  clearErrors?: UseFormClearErrors<ICreateELearningForm>;
  getValues?: UseFormGetValues<ICreateELearningForm>;
  ytbIds?: string[];
}

export enum EContentRadioOptions {
  TITLE = 0,
  UPLOAD = 1,
}

export const useCreateElearningForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        title: yup
          .string()
          .max(MAX_LENGTH.TEXT)
          .noEmoji()
          .label("admin.elearning.create.title")
          .required(),
        is_public: yup.number().default(1),
        required: yup.array().of(yup.number().default(0)),
        deadline_date: yup
          .date()
          .nullable()
          .test("notPassDate", "未来の日付を入力してください。", (value) => {
            if (isNull(value)) return true;
            return !dayjs().startOf("day").isAfter(dayjs(value));
          }),
        offices: yup
          .array(yup.object())
          .label("admin.elearning.create.office")
          .optional()
          .nullable(),
        departments: yup
          .array()
          .of(yup.object())
          .label("admin.elearning.create.department")
          .optional()
          .nullable(),
        users: yup
          .array()
          .of(yup.object())
          .label("admin.elearning.create.users")
          .optional()
          .nullable(),
        category: yup
          .object()
          .label("admin.elearning.create.category")
          .optional()
          .nullable(),
        tags: yup
          .array()
          .label("admin.elearning.create.tag")
          .optional()
          .nullable(),
        content: yup
          .array()
          .of(
            yup.object().shape({
              type: yup
                .object()
                .label("admin.elearning.create.content")
                .required(),
              option: yup.number().optional(),
              // type: document
              document_id: yup.object().when(["type", "option"], {
                is: (type: SelectOption, option: number) =>
                  ELEARNING_CONTENT_TYPE.DOCUMENT.value === type?.value &&
                  option === EContentRadioOptions.TITLE,
                then: (schema) =>
                  schema
                    .label("admin.elearning.create.document_title")
                    .required(),
                otherwise: (schema) => schema.optional().nullable(),
              }),
              // type: video
              video_id: yup.object().when(["type", "option"], {
                is: (type: SelectOption, option: number) =>
                  [ELEARNING_CONTENT_TYPE.VIDEO.value].includes(
                    type?.value as 2,
                  ) && option === EContentRadioOptions.TITLE,
                then: (schema) =>
                  schema.label("admin.elearning.create.video_title").required(),
                otherwise: (schema) => schema.optional().nullable(),
              }),
              // type: document uploaded file
              attachment_attributes: yup.object().when(["type", "option"], {
                is: (type: SelectOption, option: number) =>
                  [ELEARNING_CONTENT_TYPE.DOCUMENT.value].includes(
                    type?.value as 1,
                  ) && option === EContentRadioOptions.UPLOAD,
                then: (schema) =>
                  schema.label("admin.elearning.create.upload").required(),
                otherwise: (schema) => schema.optional().nullable(),
              }),
              youtube_id: yup.string().when(["type", "option"], {
                is: (type: SelectOption, option: number) =>
                  [ELEARNING_CONTENT_TYPE.VIDEO.value].includes(
                    type?.value as 2,
                  ) && option === EContentRadioOptions.UPLOAD,
                then: (schema) =>
                  schema.label("admin.elearning.create.upload").required(),
                otherwise: (schema) => schema.optional().nullable(),
              }),
              // type: test
              test_id: yup.object().when("type", {
                is: (type: SelectOption) =>
                  ELEARNING_CONTENT_TYPE.TEST.value === type?.value,
                then: (schema) =>
                  schema.label("admin.elearning.create.test_title").required(),
                otherwise: (schema) => schema.optional().nullable(),
              }),
              // type: role_playing
              role_playing_title: yup
                .string()
                .max(MAX_LENGTH.TEXT)
                .noEmoji()
                .when("type", {
                  is: (type: SelectOption) =>
                    ELEARNING_CONTENT_TYPE.ROLE_PLAYING.value === type?.value,
                  then: (schema) =>
                    schema
                      .label("admin.elearning.create.role_playing_title")
                      .required(),
                  otherwise: (schema) => schema.optional().nullable(),
                }),
              // type: compliance_training
              compliance_training_id: yup.object().when("type", {
                is: (type: SelectOption) =>
                  ELEARNING_CONTENT_TYPE.COMPLIANCE_TRAINING.value ===
                  type?.value,
                then: (schema) =>
                  schema
                    .label("admin.elearning.create.compliance_title")
                    .required(),
                otherwise: (schema) => schema.optional().nullable(),
              }),
            }),
          )
          .label("admin.elearning.create.content")
          .required(),
      }),
    [],
  );

  return useForm<ICreateELearningForm>({
    resolver: yupResolver(validationScheme) as any,
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      deadline_date: null,
      is_public: 1,
      required: [0],
      content: [
        {
          type: undefined,
          option: 0,
          _destroy: false,
        },
      ],
      deleted_content_ids: [],
    },
  });
};
