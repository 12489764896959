import { User } from "@/api/type";
import { create } from "zustand";

type State = {
  userInfo: User | null;
};

type Action = {
  setUserInfo: (info?: User) => void;
};

const useAuthStore = create<State & Action>((set) => ({
  userInfo: null,
  setUserInfo(info) {
    set({
      userInfo: info,
    });
  },
}));

export { useAuthStore };
