import { ROUTES_CONSTANT } from "@/configs/constants";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function FrontELearning() {
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/e-learning")
      navigate(ROUTES_CONSTANT.ELEARNING_LIST);
  }, [location.pathname]);

  return <Outlet />;
}
