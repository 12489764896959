import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/shared/validation/yup";
import { MAX_LENGTH } from "@/configs/constants";

export interface IChangePasswordForm {
  current_password: string;
  password: string;
  password_confirmation: string;
}

export const useChangePasswordForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        current_password: yup
          .string()
          .label("my_page.change_password.current_password")
          .max(MAX_LENGTH.PASSWORD)
          .required(),
        password: yup
          .string()
          .label("my_page.change_password.new_password")
          .max(MAX_LENGTH.PASSWORD)
          .password()
          .required(),
        password_confirmation: yup
          .string()
          .label("my_page.change_password.password_confirmation")
          .max(MAX_LENGTH.PASSWORD)
          .sameAs("password")
          .required(),
      }),
    [],
  );

  return useForm<IChangePasswordForm>({
    resolver: yupResolver(validationScheme),
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });
};
