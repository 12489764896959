import ThreeDotActionTable from "@/components/molecules/ActionButtonTable/three-dot";
import DataTable from "@/components/molecules/Table/table";
import AnimatedRoute from "@/router/transition.route";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import PencilIcon from "@/assets/icon/pencil.svg";
import CloseIcon from "@/assets/icon/close.svg";
import Button from "@/components/atoms/Button/button";
import PlusIcon from "@/assets/icon/plus.svg";
import { ModalConfirm } from "@/components/molecules/ModalConfirm/modal-confirm";
import { ModalCommon } from "@/components/atoms/Modal/modal";
import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import { IChangeOfficeForm, useChangeOfficeForm } from "./validation";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";
import {
  useDeleteOfficeMutation,
  useListOfficeQuery,
  useUpsertOfficeMutation,
} from "@/api/office";
import { useSearchParams } from "react-router-dom";
import { Office, OrderDirection } from "@/api/type";
import { MAX_LENGTH, PAGINATION_CONSTANT } from "@/configs/constants";
import qs from "qs";
import { useGlobalStore } from "@/states/global.state";
import { UpsertOfficeRequestBody } from "@/api/office/request";
import { handleApiError, mapAPIError } from "@/hooks/error";
import useToast from "@/hooks/toast";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import { cutString } from "@/shared/transform";
import { ModalError } from "@/components/molecules/ModalError/modal-error";

const pageSize = PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE;

/**
 * Function component representing the OfficeListPage.
 */
function OfficeListPage() {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM]);
  const { showToast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading, loading } = useGlobalStore();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useChangeOfficeForm();
  const [searchForm, setSearchForm] = useState({
    page: parseInt(searchParams.get("page") ?? "1"),
    order_by: searchParams.get("order_by")?.length
      ? searchParams.get("order_by")
      : "updated_at",
    order_direction: (searchParams.get("order_direction") ??
      "desc") as OrderDirection,
  });
  const [isModalDelete, setModalDelete] = useState(false);
  const [isModalForm, setModalForm] = useState(false);
  const [isModeEdit, setModeEdit] = useState(false);
  const [rowData, setRowData] = useState<Office>();
  const {
    data: officeRes,
    refetch,
    isFetching,
  } = useListOfficeQuery({
    page: searchForm.page,
    per_page: pageSize,
    order_by: searchForm.order_by,
    order_direction: searchForm.order_direction,
  });
  const upsertMutation = useUpsertOfficeMutation();
  const deleteMutation = useDeleteOfficeMutation();
  const menuItems = useMemo(
    () => [
      {
        icon: <PencilIcon />,
        text: <span className="body1">編集する</span>,
        onClick: (data: Office) => {
          openModalEdit(data);
        },
      },
      {
        icon: <CloseIcon />,
        text: <span className="body1 text-error--main">削除する</span>,
        onClick: (data: Office) => {
          openModalDelete(data);
        },
      },
    ],
    [loading],
  );

  const columns = useMemo<ColumnDef<Office>[]>(
    () => [
      {
        accessorKey: "name",
        header: () => "支社名",
        meta: {
          width: 1024,
        },
        cell: (info) => {
          return <CustomTooltip text={info.row.original.name} />;
        },
      },
      {
        id: "action",
        accessorKey: "",
        enableSorting: false,
        meta: {
          width: 80,
        },
        cell: (info) => {
          return (
            <div className="flex justify-end">
              <ThreeDotActionTable
                menuItems={menuItems}
                data={info.row.original}
              />
            </div>
          );
        },
      },
    ],
    [],
  );
  const datas = useMemo(() => officeRes?.offices || [], [officeRes]);
  const paging = useMemo(() => officeRes?.paging, [officeRes]);

  const openModalCreate = () => {
    reset({
      name: undefined,
    });
    setModeEdit(false);
    setModalForm(true);
  };

  const openModalDelete = (data: Office) => {
    setError("id", {
      message: "",
    });
    setRowData(data);
    setModalDelete(true);
  };

  const openModalEdit = (data: Office) => {
    setRowData(data);
    reset({
      name: data.name,
    });
    setModeEdit(true);
    setModalForm(true);
  };

  const refreshSearch = useCallback(() => {
    if (searchForm.page != PAGINATION_CONSTANT.DEFAULT_PAGE) {
      setSearchForm((prev) => ({ ...prev, page: 1 }));
    } else {
      refetch();
    }
  }, [searchForm.page]);

  const onChangePage = useCallback((p: number) => {
    setSearchForm((prev) => ({ ...prev, page: p }));
  }, []);

  const onSorting = useCallback((sortState: SortingState) => {
    if (!sortState[0]) return;

    setSearchForm((prev) => ({
      ...prev,
      order_by: sortState[0].id,
      order_direction: sortState[0].desc ? "desc" : "asc",
    }));
  }, []);

  const onConfirmDelete = async () => {
    if (!rowData) return;
    try {
      setLoading(true);
      await deleteMutation.mutateAsync(rowData.id);
      showToast({
        title: `${cutString(rowData.name)}を削除しました`,
        type: "success",
      });
      setModalDelete(false);
      refreshSearch();
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError);
      setModalDelete(false);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  const onSubmit = async (values: IChangeOfficeForm) => {
    setLoading(true);
    try {
      const payload: UpsertOfficeRequestBody = {
        name: values.name,
      };

      if (isModeEdit && rowData) {
        payload.id = rowData.id;
      }

      await upsertMutation.mutateAsync(payload);
      showToast({
        title: isModeEdit
          ? `${cutString(payload.name)}を保存しました`
          : `${cutString(payload.name)}を作成しました`,
        type: "success",
      });

      setModalForm(false);
      refreshSearch();
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = qs.stringify(searchForm);
    setSearchParams(params);
    return () => {};
  }, [searchForm, setSearchParams]);

  return (
    <>
      <div className="max-w-[100%] m-auto my-6">
        <div className="flex justify-between items-start">
          <h4 className="font-black">支社一覧</h4>
          <Button
            startSlot={<PlusIcon />}
            className="w-[160px]"
            onClick={openModalCreate}
          >
            新規作成
          </Button>
        </div>
        <DataTable
          className="mt-6"
          loading={isFetching}
          data={datas}
          columns={columns}
          total={paging?.total_records || 0}
          defaultSort={[
            {
              id: searchForm.order_by ?? "updated_at",
              desc: searchForm.order_direction === "desc",
            },
          ]}
          defaultPage={searchForm.page}
          defaultPageSize={pageSize}
          onChangePage={onChangePage}
          onSorting={onSorting}
          headerSlot={null}
        />
      </div>
      <ModalConfirm
        isOpen={isModalDelete}
        onClose={() => setModalDelete(false)}
        header={`${rowData?.name}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">
              {rowData?.name}を削除します。
            </p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onSubmit={onConfirmDelete}
      />
      <ModalError
        isOpen={!!errors.id?.message?.length}
        message={
          <p className="text-error--main input-error__message mb-1">
            {errors.id?.message?.replace(/<br\s*\/?>/, "\n")}
          </p>
        }
        onClose={() => {
          setError("id", {
            message: "",
          });
        }}
      />
      <ModalCommon
        isOpen={isModalForm}
        onClose={() => setModalForm(false)}
        header={`${isModeEdit ? "支社名の編集" : "支社の新規作成"}`}
        body={
          <ControlledInput
            type="text"
            control={control}
            formField="name"
            label="支社名"
            isRequired={true}
            isTrim={true}
            placeholder=""
            errorMessage={errors.name?.message}
            maxLength={MAX_LENGTH.VARCHAR}
          />
        }
        footer={
          <div className="flex gap-3">
            <Button
              className="h-10"
              buttonCustom={{
                padding: "8px 16px",
                borderRadius: "8px",
                borderColor: "#E6E8EA",
                color: "#212B36",
                circleStyles: "#212B36",
              }}
              size="sm"
              variant="outline"
              onClick={() => setModalForm(false)}
            >
              キャンセル
            </Button>
            <Button
              type="submit"
              className="h-10"
              buttonCustom={{
                padding: "8px 16px",
                borderRadius: "8px",
              }}
              onClick={handleSubmit(onSubmit)}
              isLoading={loading?.open}
            >
              {isModeEdit ? "保存する" : "作成する"}
            </Button>
          </div>
        }
      />
    </>
  );
}

const AnimatedOfficeListPage = AnimatedRoute(OfficeListPage);
export default AnimatedOfficeListPage;
