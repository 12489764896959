import { FilterFolderResponseBody } from "@/api/folder/request";
import FolderLeftPanel from "./folder-left-panel";
import FolderRightPanel from "./folder-right-panel";
import { TDocumentList } from "@/api/type";
import { useRef } from "react";

interface TreeFolderProps {
  isAdmin?: boolean;
  folderItems?: FilterFolderResponseBody["folders"];
  fileItems?: (TDocumentList & { folder_id: number })[];
  refreshSearch: () => void;
  refreshFileList: () => void;
  kind: "document" | "video";
  isFetchingFile?: boolean;
  isFetchingFolder?: boolean;
  onNextPage: (type: "folder" | "document" | "video") => void;
  totalFolder: number;
  totalFile: number;
  hasMoreFolder: boolean;
  hasMoreFile: boolean;
}
export default function TreeFolder({
  isAdmin = true,
  folderItems = [],
  fileItems = [],
  refreshSearch,
  refreshFileList,
  kind,
  isFetchingFile = false,
  isFetchingFolder = false,
  onNextPage,
  totalFolder,
  totalFile,
  hasMoreFolder,
  hasMoreFile,
}: TreeFolderProps) {
  const topRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="shadow-card bg-white rounded-[30px] flex mobile:flex-col desktop:flex-row w-full">
      <div
        className="left-side p-[20px] flex-grow border-none] mobile:w-full desktop:w-1/2 mobile:max-h-[280px] desktop:max-h-none"
        ref={topRef}
      >
        <FolderLeftPanel
          isAdmin={isAdmin}
          kind={kind}
          fileItems={fileItems}
          folderItems={folderItems}
          refreshSearch={refreshSearch}
          refreshFileList={refreshFileList}
          isFetching={isFetchingFolder}
          onNextPage={onNextPage}
          totalFolder={totalFolder}
          hasMore={hasMoreFolder}
        />
      </div>

      <div className="right-side overflow-x-hidden px-[20px] py-6 flex mobile:gap-y-4 desktop:gap-y-6 flex-col flex-grow mobile:border-t desktop:border-t-0 mobile:border-l-0 desktop:border-l-[1px] mobile:max-h-[280px] desktop:max-h-none border-[#E6E8EA] mobile:w-full desktop:w-1/2">
        <FolderRightPanel
          isAdmin={isAdmin}
          kind={kind}
          fileItems={fileItems}
          refreshFileList={refreshFileList}
          isFetching={isFetchingFile}
          totalFile={totalFile}
          onNextPage={onNextPage}
          hasMore={hasMoreFile}
        />
      </div>
    </div>
  );
}
