import { useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface AvatarProps {
  url: string | undefined | null;
  size: "xs" | "sm" | "md" | number;
  className?: string;
}

const sizeProps: Record<"xs" | "sm" | "md", string> = {
  xs: "16px",
  sm: "32px",
  md: "64px",
};

export default function Avatar({ size, url, className }: AvatarProps) {
  const noImage = new URL("@/assets/no-images.png", import.meta.url).href;

  const getSize = useMemo(() => {
    if (typeof size === "number") {
      return size + "px";
    }
    return sizeProps[size];
  }, [size]);

  return (
    <LazyLoadImage
      alt={""}
      src={url || noImage}
      height={getSize}
      width={getSize}
      style={{
        borderRadius: "999px",
        width: getSize,
        height: getSize,
        minWidth: getSize,
        minHeight: getSize,
      }}
      effect="blur"
      className={`border-[1px] border-solid border-[#f4f4f4] object-cover ${className}`}
    />
  );
}
