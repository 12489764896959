// import { useToast } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { router } from "./router/intex";
import { RouterProvider } from "react-router-dom";

export default function App() {
  // const toast = useToast();
  // useEffect(() => {
  //   toast({
  //     title: ` toast`,
  //     position: "top",
  //     isClosable: true,
  //   });
  // }, []);

  return (
    <AnimatePresence mode="wait">
      <RouterProvider router={router} />
    </AnimatePresence>
  );
}
