import useScreenSize from "@/hooks/screen";
import AuthLayout from "@/pages/_layouts/auth";
import { useMemo } from "react";

export default function FrontResetPasswordSuccessPage() {
  const { isMobile } = useScreenSize();
  const imgUrl = new URL("@/assets/fly.png", import.meta.url).href;
  const email = useMemo(() => {
    const history = window.history;
    if (!history || !history.state?.usr?.email) return "";
    return history.state.usr.email;
  }, []);

  return (
    <AuthLayout>
      <div className="mobile:py-[48px] desktop:py-[80px] flex w-full items-center flex-col mobile:px-4 desktop:px-5">
        <div className="flex flex-col gap-y-4">
          {isMobile ? (
            <h5 className="w-auto md:w-[360px] font-[900] text-center">
              パスワード再設定メールを送信しました
            </h5>
          ) : (
            <h4 className="font-[900] text-center">
              パスワード再設定メールを送信しました
            </h4>
          )}

          <div className="mobile:text-xs desktop:text-sm text-center text-secondary-custom">
            <p>パスワードをリセットします。</p>
            <p>ご登録済みのメールアドレスを入力してください。</p>
          </div>
        </div>

        <div className="flex flex-col justify-center p-8 whitespace-nowrap bg-white rounded-3xl w-full max-w-[572px] mobile:px-4 desktop:px-8 mt-[48px]">
          <div className="flex justify-center">
            <img src={imgUrl} width={146} height={99} alt="send-icon" />
          </div>
          <p className="body1 mt-[20px] text-[#212B36] whitespace-pre-wrap">
            パスワード再設定用のメールを（{email}）宛にお送りいたしました。
            ご確認の上、メールに記載のあるURLからパスワードを再設定してください。
          </p>
          <div className="w-full h-[1px] bg-[var(--border-primary)] my-[20px]"></div>
          <p className="body2 text-secondary-custom  whitespace-pre-wrap">
            ※しばらく経ってもメールが届かない場合は以下をご確認ください <br />
            ・メールアドレスに間違いがないか <br />
            ・受信拒否設定をしていないか <br />
            ・迷惑メールフォルダに入っていないか <br />
          </p>
        </div>
      </div>
    </AuthLayout>
  );
}
