import { forwardRef, ReactNode } from "react";
import clsx from "clsx";

interface ButtonIconProps {
  icon: ReactNode;
  isDisabled?: boolean;
  className?: string;
  isRounded?: boolean;
  onClick?: () => void;
}

const ButtonIcon = forwardRef<HTMLDivElement, ButtonIconProps>(
  ({ icon, onClick, isRounded = true, className }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          "w-fit cursor-pointer p-1 hover:bg-[#F1F9E8] focus:bg-[#F1F9E8]",
          isRounded && "rounded-full",
          className,
        )}
        onClickCapture={onClick}
      >
        {icon}
      </div>
    );
  },
);

ButtonIcon.displayName = "ButtonIcon";

export default ButtonIcon;
