import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import Button from "@/components/atoms/Button/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ADMIN_ROUTES_CONSTANT,
  MAX_LENGTH,
  ROUTES_CONSTANT,
} from "@/configs/constants";
import { useSetPassForm, ISetPassForm } from "./validation";
import LockIcon from "@/assets/icon/lock.svg";
import EyeCloseIcon from "@/assets/icon/eye-close.svg";
import EyeOpenIcon from "@/assets/icon/eye-open.svg";
import { useCallback, useEffect, useState } from "react";
import {
  useUpdateFirstPasswordMutation,
  useVerifyTokenResetPasswordMutation,
} from "@/api/auth";
import { useGlobalStore } from "@/states/global.state";
import { handleApiError, mapAPIError } from "@/hooks/error";
import { HttpStatusCode } from "axios";

interface FormSetPasswordFirstLoginProps {
  isAdminPage?: boolean;
}

/**
 * This component renders a form for setting a new password during the first login.
 */
export default function FormSetPasswordFirstLogin({
  isAdminPage,
}: FormSetPasswordFirstLoginProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const updateFirstPasswordMutation = useUpdateFirstPasswordMutation();
  const verifyTokenResetPasswordMutation =
    useVerifyTokenResetPasswordMutation();
  const { loading, setLoading } = useGlobalStore();
  const resetToken = searchParams.get("token");
  const [typeInput, setTypeInput] = useState<"password" | "text">("password");
  const [typeInput2, setTypeInput2] = useState<"password" | "text">("password");
  const [typeInput3, setTypeInput3] = useState<"password" | "text">("password");

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useSetPassForm();

  useEffect(() => {
    (async () => {
      if (!resetToken) {
        navigate(
          isAdminPage
            ? ADMIN_ROUTES_CONSTANT.SET_FIRST_PASSWORD_FAIL
            : ROUTES_CONSTANT.SET_FIRST_PASSWORD_FAIL,
          {
            replace: true,
            state: {
              from: isAdminPage
                ? ADMIN_ROUTES_CONSTANT.SET_PASSWORD_FIRST_LOGIN
                : ROUTES_CONSTANT.SET_PASSWORD_FIRST_LOGIN,
            },
          },
        );
      } else {
        try {
          await verifyTokenResetPasswordMutation.mutateAsync({
            reset_token: resetToken,
          });
        } catch (e) {
          navigate(
            isAdminPage
              ? ADMIN_ROUTES_CONSTANT.SET_FIRST_PASSWORD_FAIL
              : ROUTES_CONSTANT.SET_FIRST_PASSWORD_FAIL,
            {
              replace: true,
            },
          );
        }
      }
    })();
  }, []);

  const onSubmit = async (datas: ISetPassForm) => {
    if (!resetToken) return;
    try {
      setLoading(true);
      await updateFirstPasswordMutation.mutateAsync({
        current_password: datas.current_password,
        password: datas.password,
        password_confirmation: datas.password_confirmation,
        reset_token: resetToken,
      });

      navigate(
        isAdminPage
          ? ADMIN_ROUTES_CONSTANT.SET_INFOR_FIRST_LOGIN
          : ROUTES_CONSTANT.SET_INFOR_FIRST_LOGIN,
      );
    } catch (errRes) {
      const err = handleApiError(errRes);

      if (err.statusCode === HttpStatusCode.BadRequest) {
        navigate(
          isAdminPage
            ? ADMIN_ROUTES_CONSTANT.SET_FIRST_PASSWORD_FAIL
            : ROUTES_CONSTANT.SET_FIRST_PASSWORD_FAIL,
          {
            replace: true,
          },
        );
      } else mapAPIError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  const goToLogin = useCallback(() => {
    navigate(isAdminPage ? ADMIN_ROUTES_CONSTANT.LOGIN : ROUTES_CONSTANT.LOGIN);
  }, [navigate]);

  return (
    <div className="flex flex-col justify-center desktop:p-8 mobile:px-4 mobile:py-8 mob whitespace-nowrap bg-white rounded-3xl w-full max-w-[572px]">
      <div className="subtitle2 mb-4 text-secondary-custom">
        現在のパスワード
      </div>

      <form
        className="flex flex-col max-md:max-w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlledInput
          type={typeInput}
          control={control}
          formField="current_password"
          label="現在のパスワード"
          isRequired={true}
          placeholder="パスワードを入力してください"
          maxLength={MAX_LENGTH.PASSWORD}
          startSlot={<LockIcon />}
          endSlot={
            <div
              className="cursor-pointer"
              onClick={() => {
                setTypeInput((prev) => (prev === "text" ? "password" : "text"));
              }}
            >
              {typeInput === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          }
          errorMessage={errors.current_password?.message}
        />

        <div className="subtitle2 mb-4 text-secondary-custom mt-12 ">
          新しいパスワード
        </div>

        <ControlledInput
          type={typeInput2}
          control={control}
          formField="password"
          label="新しいパスワード"
          note="※半角英数記号10文字以上"
          isRequired={true}
          placeholder="パスワードを入力してください"
          startSlot={<LockIcon />}
          maxLength={MAX_LENGTH.PASSWORD}
          endSlot={
            <div
              className="cursor-pointer"
              onClick={() => {
                setTypeInput2((prev) =>
                  prev === "text" ? "password" : "text",
                );
              }}
            >
              {typeInput2 === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          }
          errorMessage={errors.password?.message}
        />

        <ControlledInput
          type={typeInput3}
          className="mt-8"
          control={control}
          formField="password_confirmation"
          label="パスワード確認"
          note="※半角英数記号10文字以上"
          isRequired={true}
          placeholder="パスワードを再度入力してください"
          startSlot={<LockIcon />}
          maxLength={MAX_LENGTH.PASSWORD}
          endSlot={
            <div
              className="cursor-pointer"
              onClick={() => {
                setTypeInput3((prev) =>
                  prev === "text" ? "password" : "text",
                );
              }}
            >
              {typeInput3 === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          }
          errorMessage={errors.password_confirmation?.message}
        />

        <Button
          size="sm"
          className={"mt-14 max-w-[335px] w-full m-auto"}
          type="submit"
          isLoading={loading.open}
        >
          アカウント登録
        </Button>

        <div className="caption1 mt-3 text-[#8E8EA9] w-fit m-auto">
          すでにアカウントをお持ちの方は
          <span className="text-info-main cursor-pointer" onClick={goToLogin}>
            こちら
          </span>
        </div>
      </form>
    </div>
  );
}
