import clsx from "clsx";
import { motion } from "framer-motion";
import { JSX } from "react/jsx-runtime";

const AnimatedRoute = (OgComponent: () => JSX.Element, isAdmin = true) => {
  const isUserPage = !location.pathname.startsWith("/admin");
  return () => (
    <>
      <motion.div
        className={clsx(
          "slide-in",
          isUserPage && "desktop:pt-[64px] mobile:pt-[56px]",
        )}
        initial={isAdmin ? { opacity: 0, x: 40 } : { opacity: 0, y: 40 }}
        animate={isAdmin ? { opacity: 1, x: 0 } : { opacity: 1, y: 0 }}
        exit={isAdmin ? { opacity: 0, x: 40 } : { opacity: 0, y: 40 }}
      >
        <OgComponent />
      </motion.div>
    </>
  );
};

export default AnimatedRoute;
