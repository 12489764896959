import Button from "@/components/atoms/Button/button";
import AnimatedRoute from "@/router/transition.route";
import PlusIcon from "@/assets/icon/plus.svg";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";
import QuizzList from "@/components/organisms/QuizzManagement/quizz-list-management";

const QuizzListPage = (): JSX.Element => {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);
  const navigate = useNavigate();
  const goToCreatePage = useCallback(() => {
    navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_CREATE);
  }, []);

  return (
    <div className="max-w-[100%] m-auto my-6">
      <div className="flex justify-between items-start">
        <h4 className="font-black">テスト一覧</h4>
        <Button
          startSlot={<PlusIcon />}
          className="w-[160px]"
          onClick={goToCreatePage}
        >
          新規作成
        </Button>
      </div>
      <QuizzList />
    </div>
  );
};

const AnimatedQuizzListPage = AnimatedRoute(QuizzListPage);
export default AnimatedQuizzListPage;
