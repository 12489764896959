/**
 * Enum representing different roles in the system.
 *
 * @enum {number}
 * @property {number} ADMIN_SYSTEM - Represents the role of a system administrator.
 * @property {number} CONTENT_MANAGER - Represents the role of a content manager.
 * @property {number} MEMBER - Represents the role of a member.
 */
export enum ROLE_ENUM {
  /** システム管理者 */
  ADMIN_SYSTEM = 1,
  /** コンテンツ管理者 */
  CONTENT_MANAGER = 2,
  /** メンバー */
  MEMBER = 3,
}

export enum QUESTION_KIND_ENUM {
  /** 複数選択 */
  MULTI_ANSWER = "multi",
  /** 単一選択 */
  SINGLE_ANSWER = "single",
  /** 自由入力 */
  FREE_INPUT = "free_input",
}

export enum ELEARNING_CONTENT_TYPE_ENUM {
  /**ドキュメント */
  DOCUMENT = 1,
  /** 動画学習 */
  VIDEO = 2,
  /** テスト */
  QUIZ = 3,
  /** ロールプレイング  */
  ROLEPLAYING_THEME = 4,
  /** コンプライアンス研修 */
  COMPLIANCE = 5,
}

export enum ELEARNING_CONTENT_KIND_ENUM {
  /**ドキュメント */
  DOCUMENT = "document",
  /** 動画学習 */
  VIDEO = "video",
  /** テスト */
  QUIZ = "quiz",
  /** ロールプレイング  */
  ROLEPLAYING_THEME = "roleplaying_theme",
  /** コンプライアンス研修 */
  COMPLIANCE = "compliance",
}

export enum ELEARNING_JOINED_ENUM {
  DONE = "done",

  DOING = "doing",
}

export enum COMMENT_TYPE_ENUM {
  COURSE = "Course",
}

export enum FAVORITE_TYPE_ENUM {
  COURSE = "Course",
  COMMENT = "Comment",
}

export enum ELEARNING_KIND_ENUM {
  DOCUMENT = 1,
  VIDEO = 2,
  TEST = 3,
  ROLE_PLAYING = 4,
  COMPLIANCE_TRAINING = 5,
}

export enum DateFormat {
  YYYY_DD_MM = "YYYY-MM-DD",
}
