import { fetchMyProfile } from "@/api/auth";
import { ROLE_ENUM } from "@/configs/enum";
import { isErrorPage } from "@/shared/get/check";
import { useAuthStore } from "@/states/auth.state";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export default function useRole(
  isAdmin: boolean = true,
  allow: ROLE_ENUM[] = [],
) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useAuthStore();

  const fetchUserInfo = async () => {
    const info = await fetchMyProfile(queryClient);
    setUserInfo(info);
    return info;
  };

  const checkRoleAdmin = async () => {
    const info = await fetchUserInfo();
    if (!allow.length) return true;
    if (!allow.includes(info.role.id))
      navigate(`${isAdmin ? "/admin/" : "/"}403`, {
        replace: true,
      });
  };

  const isAdminSystem = useMemo(() => {
    return userInfo?.role.id === ROLE_ENUM.ADMIN_SYSTEM;
  }, [userInfo]);

  const isAdminContent = useMemo(() => {
    return userInfo?.role.id === ROLE_ENUM.CONTENT_MANAGER;
  }, [userInfo]);

  useEffect(() => {
    if (isErrorPage()) return;
    if (isAdmin) checkRoleAdmin();
    else fetchUserInfo();
  }, []);

  return {
    isAdminSystem,
    isAdminContent,
    userInfo,
  };
}
