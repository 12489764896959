import yup from "@/shared/validation/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

interface ISearchUserForm {
  fullname: string;
  role: { value: number; label: string } | any;
  department: { value: number; label: string } | any;
}

export const useSearchUserForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        fullname: yup.string().required(),
        department: yup.object().nullable().required(),
        role: yup.object().nullable().required(),
      }),
    [],
  );

  return useForm<ISearchUserForm>({
    resolver: yupResolver(validationScheme),
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      role: { value: "", label: "全ての権限" },
      department: { value: "", label: "全ての支社" },
    },
  });
};
