import Button from "@/components/atoms/Button/button";
import { ModalCommon } from "@/components/atoms/Modal/modal";
import { ReactNode } from "react";

interface ModalProps {
  id?: string;
  header?: ReactNode | string;
  message?: ReactNode | string;
  btnCloseText?: ReactNode | string;
  isOpen?: boolean;
  onClose?: () => void;
}

export const ModalError = (prop: ModalProps) => {
  return (
    <ModalCommon
      id={prop.id}
      onClose={() => {
        if (prop?.onClose) prop.onClose();
      }}
      isOpen={!!prop.isOpen}
      header={prop.header ?? "通知"}
      body={
        <div className="flex flex-col">
          <div className="text-base font-normal whitespace-break-spaces">
            {prop.message}
          </div>
        </div>
      }
      footer={
        <div className="flex gap-3">
          <Button
            className="h-10"
            buttonCustom={{
              padding: "8px 16px",
              borderRadius: "8px",
              borderColor: "#E6E8EA",
              color: "#212B36",
              circleStyles: "#212B36",
            }}
            size="sm"
            variant="outline"
            onClick={prop.onClose}
          >
            {prop.btnCloseText ?? "キャンセル"}
          </Button>
        </div>
      }
    />
  );
};
