import { useEffect, useRef, ContextType, WheelEvent } from "react";
import {
  ScrollMenu,
  VisibilityContext,
  type publicApiType,
} from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";

import { TUserCategory } from "@/api/type";
import ElearningTabItem from "./e-learning-tab-item";

import "./e-learning-tab.scss";
import { DragDealer } from "./drag-dealer";

interface ELearningTabProps {
  data: TUserCategory[];
  selected: number;
  onSelected: (id: number) => void;
}

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>;

export default function ELearningTab({
  data,
  selected,
  onSelected,
}: ELearningTabProps) {
  // #region Data
  const dragState = useRef(new DragDealer());

  // #endregion

  // #region Events

  const handleDrag =
    ({ scrollContainer }: ScrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragState.current.dragMove(ev, (posDiff: number) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const onWheel = (_: publicApiType, ev: WheelEvent) => {
    ev.stopPropagation();
  };

  // #endregion

  useEffect(() => {
    window.history.scrollRestoration = "manual";

    window.addEventListener("mouseup", () => {
      dragState.current.dragStop();
    });
  }, []);

  return (
    <div>
      <ScrollMenu
        wrapperClassName="elearning-tab"
        scrollContainerClassName="flex gap-5"
        itemClassName="whitespace-nowrap"
        onWheel={onWheel}
        onMouseDown={() => dragState.current.dragStart}
        onMouseUp={() => dragState.current.dragStop}
        onMouseMove={handleDrag}
      >
        {data.map((item) => (
          <ElearningTabItem
            key={item.id}
            data={item}
            selected={item.id === selected}
            onClick={() => onSelected(item.id)}
          />
        ))}
      </ScrollMenu>
    </div>
  );
}
