import EditorCustomBuild from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "@/ckeditor.css";
import clsx from "clsx";
import MyUploadAdapter from "./custom-upload-adapter";
import { useGlobalStore } from "@/states/global.state";
import { useEffect, useState } from "react";

const defaultConfig = {
  removePlugins: ["Title"],
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      {
        icon: "text",
        label: "Basic styles",
        items: [
          "fontSize",
          // "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "subscript",
          "superscript",
          "code",
        ],
      },
      "removeFormat",
      "|",
      "alignment",
      "|",
      "numberedList",
      "bulletedList",
      // "todoList",
      "outdent",
      "indent",
      // "subscript",
      // "superscript",
      "|",
      // "codeBlock",
      "blockQuote",
      "link",
      "imageInsert",
      "mediaEmbed",
      "specialCharacters",
      "|",
      // "undo",
      // "redo",
    ],
  },
  language: "ja",
  fontSize: {
    options: [9, 11, 13, "default", 17, 19, 21, 24],
  },
  fontFamily: {
    supportAllValues: true,
  },
  mediaEmbed: {
    previewsInData: true,
  },
  image: {
    upload: {
      types: ["jpeg", "png", "bmp", "webp", "tiff"],
    },
    toolbar: [
      "imageTextAlternative",
      "toggleImageCaption",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
      "linkImage",
    ],
  },
  link: {
    addTargetToExternalLinks: true,
  },
  placeholder: "テキストを入力してください",
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
};

interface RichTextEditorProps {
  disabled?: boolean;
  onChange: (v: string) => void;
  errorMessage?: string;
  value: string | null;
  onError?: (msg: string) => void;
}
export default function RichTextEditor({
  errorMessage,
  disabled,
  onChange,
  value = "",
  onError,
}: RichTextEditorProps) {
  const { setLoading } = useGlobalStore();
  const [imageKeys, setImageKeys] = useState<string[]>([]);
  const { Editor } = EditorCustomBuild;

  useEffect(() => {
    if (imageKeys.length > 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [imageKeys]);

  if (!Editor) return <></>;
  return (
    <>
      <div
        className={clsx(
          "shadow-card bg-white rounded-[20px] overflow-hidden",
          errorMessage?.length && "is-invalid",
        )}
      >
        <CKEditor
          editor={Editor}
          data={value ?? ""}
          config={defaultConfig}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            // console.log("Editor is ready to use!", editor);
            editor.plugins.get("FileRepository").createUploadAdapter = (
              loader,
            ) => {
              return MyUploadAdapter(loader, {
                mimeSize: 20,
                mimeTypes: [
                  "image/png",
                  "image/jpeg",
                  "image/jpg",
                  "image/gif",
                  "image/bmp",
                ],
                onStart: (key) => {
                  setImageKeys((prev) => [...prev, key]);
                },
                onFinish: (key) => {
                  setImageKeys((prev) => prev.filter((i) => i !== key));
                },
                onError: (msg) => {
                  onError?.(msg);
                },
              });
            };
          }}
          onChange={(_event, editor) => {
            const value = editor.getData();
            if (value === "<p>&nbsp;</p>") return onChange("");
            onChange(editor.getData());
          }}
          disabled={disabled}
        />
      </div>
    </>
  );
}
