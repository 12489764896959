import {
  MutateOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CreateEmojiRequestBody,
  CreateEmojiResponseBody,
  FilterEmojiResponseBody,
  createEmojiApi,
  listEmojiApi,
} from "./request";

export const useEmojiListQuery = (
  options?: UseQueryOptions<
    FilterEmojiResponseBody,
    Error,
    FilterEmojiResponseBody,
    string[]
  >,
) => {
  return useQuery({
    queryKey: ["filter-emoji"],
    queryFn: listEmojiApi,
    ...options,
    staleTime: 1000 * 60 * 60,
  });
};

export const useCreateEmojiMutation = (
  options: MutateOptions<
    CreateEmojiResponseBody,
    unknown,
    CreateEmojiRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: createEmojiApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};
