import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/shared/validation/yup";
import { MAX_LENGTH } from "@/configs/constants";
import { MESSAGES } from "@/shared/validation/message";

export interface ICategoryForm {
  id: string;
  name: string;
  isEdit: boolean;
  children: {
    child_id: string;
    name: string;
  }[];
}

export const useCategoryForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        id: yup.string().required(),
        name: yup
          .string()
          .label("admin.category.create.parent_name")
          .max(MAX_LENGTH.VARCHAR)
          .noEmoji()
          .required(MESSAGES.MSG_027),
        isEdit: yup.boolean().required(),
        children: yup
          .array()
          .of(
            yup.object().shape({
              child_id: yup.string().required(),
              name: yup
                .string()
                .label("admin.category.create.child_name")
                .max(MAX_LENGTH.VARCHAR)
                .noEmoji()
                .unique("children", "name")
                .required(MESSAGES.MSG_027),
            }),
          )
          .required(),
      }),
    [],
  );

  return useForm<ICategoryForm>({
    resolver: yupResolver(validationScheme),
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });
};
