import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import AnimatedRoute from "@/router/transition.route";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";
import CreateComplianceTrainingForm from "@/components/organisms/ComplianceTrainingManagement/create";

const breadcrumbs: BeardcrumbOption[] = [
  {
    label: "コンプライアンス研修",
    route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.COMPLIANCE_TRAINING,
  },
  {
    label: "新規作成",
  },
];

function CreateComplianceTrainingPage(): JSX.Element {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);

  return (
    <div className="max-w-[100%] m-auto my-6">
      <Breadcrumb options={breadcrumbs} />
      <h4 className="mt-3 font-black">新規作成</h4>
      <div className="mt-8">
        <h6 className="font-black mb-3">基本情報</h6>
        <CreateComplianceTrainingForm />
      </div>
    </div>
  );
}
const AnimatedCreateComplianceTrainingPage = AnimatedRoute(
  CreateComplianceTrainingPage,
);
export default AnimatedCreateComplianceTrainingPage;
