import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useRef } from "react";
import VideoPlayer from "./video-view";
import { useGlobalStore } from "@/states/global.state";

interface ModalProps {
  sources?: {
    type: string;
    src: string;
  }[];
  isOpen: boolean;
  onClose: () => void;
  setDuration?: (duration: number) => void;
}

export const VideoViewModal = (prop: ModalProps) => {
  const facingMode = useGlobalStore((state) => state.facingMode); // 'user' (front) or 'environment' (back)
  const refToDisableFocus = useRef(null);
  if (!prop.sources?.length) return <></>;

  return (
    <Modal
      isCentered
      autoFocus={false}
      onClose={prop.onClose}
      isOpen={prop.isOpen}
      closeOnEsc={false}
      finalFocusRef={refToDisableFocus}
      closeOnOverlayClick={true}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent className="!mx-2 my-0 flex justify-center bg-[#0000006b !max-w-[928px]">
        <VideoPlayer
          isFlip={facingMode === "user"}
          options={{
            fluid: true,
            sources: prop.sources,
          }}
          onReady={(player) => {
            player.one("loadedmetadata", function () {
              const duration = player.duration();
              prop.setDuration?.(duration);
            });
          }}
        />
      </ModalContent>
    </Modal>
  );
};
